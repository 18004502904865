/* login-popup start */
.code-select {
	background-image: unset;
	padding-right: 1vw;
	width: 8vw;
	font-size: 1.66vw;
	border-radius: 0.3vw;
	padding: 0.57vw 1vw;
	color: var(--grey);
}
.country-select {
	background-image: unset;
	font-size: 1.66vw;
	padding: 0.6vw 1vw;
	border-radius: 0.3vw;
	color: var(--grey);
}

.caret-content .country-select{
	margin-bottom: 1vw;
}

.caret-content {
	position: relative;
}

.caret-content .select-caret {
	position: absolute;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	right: 1vw;
}

.caret-content .select-caret i {
	font-size: 1.66vw;
	color: var(--grey);
}

.input-group .password__show {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 1vw;
	top: 1.3vw;
}

.input-group .password__show i {
	font-size: 1.5vw;
	cursor: pointer;
}

/* change password start */
.creategroup-modal .creategroup-content form label {
	font-size: 1.3vw;
	font-weight: bold;
	color: var(--white);
}

.submit-button {
	margin-top: 1vw;
}

.submit-button button.submit-pass {
	width: 50% !important;
}

.input-group .password__change {
	top: 2.8vw;
}
/* change password end */

/* forgot password */
.forgot-pass-link {
	text-align: left;
	margin-bottom: 1vw;
	transition: all 0.3s ease;
	width: 42%;
	cursor: pointer;
}
.forgot-pass-link h2 {
	font-weight: bold;
	margin: 0.5vw 0.5vw 0.5vw 1vw;
}
.forgot-pass-link:hover {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 50vw;
	text-decoration: underline;
}

/* forgot modal css start */
.forgot-header {
	color: var(--white);
	text-align: left;
}

.forgot-header h1 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.forgot-modal {
	align-items: flex-start !important;
}

.forgot-pass-content {
	text-align: left;
}

.creategroup-modal .creategroup-content form input.forgot-email {
	width: 100%;
	margin: 2vw 0 2vw 0;
	border-radius: 0.3vw;
}

.forgot-buttons {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.forgot-buttons button.forgot-content {
	width: 100%;
	border-radius: 50vw;
	background-color: var(--close-button) !important;
	color: var(--white) !important;
}

.forgot-buttons button.forgot-content:hover {
	background-color: var(--white) !important;
	color: var(--close-button) !important;
}

.forgot-buttons button.back-button {
	background-color: transparent !important;
	margin-top: 2vw;
}

.forgot-buttons button.back-button:hover {
	text-decoration: underline;
	background-color: transparent !important;
	color: var(--white) !important;
}

/* forgot modal css end */

.input-group {
	position: relative;
}

.form_control {
	background-color: var(--white);
	position: relative;
}
.form_control + label {
	position: absolute;
	top: 0.6vw;
	left: 1vw;
	font-size: 1.66vw;
	color: var(--grey);
	transition: 0.1s ease-in-out;
}
.form_control:focus {
	outline: none;
	transition: all 0.1s ease-in-out;
}
.form_control:focus + label,
.form_control.has_class + label {
	top: -1.3vw;
	color: var(--close-button);
	font-size: 1.66vw;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
	> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
	border-radius: 0.3vw;
}

.login-popup {
	margin: 0vw 30vw;
	padding: 8vw 0;
}

.login-popup-container {
	position: relative;
	background-color: var(--grey);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2vw 4vw;
	border-radius: 0.3vw;
	text-align: center;
	transition: 0.3s ease-in-out;
}

@media screen and (max-width: 800px) {
}
.login-popup-container button.login-close-modal {
	background-color: var(--close-button);
}

button.close-button-modal {
	position: absolute;
	background-color: #757571;
	color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2vw;
	height: 2vw;
	top: 1vw;
	right: 1vw;
}

button.close-button-modal i {
	font-size: 1.5vw;
}

.login-popup-container .pexit-logo img {
	width: 10vw;
}

.login-popup-container .login-container h1.header {
	margin: 1vw 0;
}

.login-popup-container .login-container h1.login-header {
	margin-bottom: 3vw;
}

.login-content-fields {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.social-button{
	width: 100%;
}

.staticEdit_outerdiv{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.staticEdit_outerdiv .staticEdit_innerdiv{
	width:100%;
	font-size: 0.95vw;
} 

.staticEdit_outerdiv button{
	height: 30px;
	margin-left: 50px;
	padding: 0 3vw;
} 

.login-content-fields form,
.social-buttons-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
	align-items: center;
}

.linkedin h1 {
	color: var(--grey);
	font-weight: bold;
	font-size: 1.66vw;
}

.login-content-fields form input,
.creategroup-modal .creategroup-content form textarea,
.creategroup-modal .creategroup-content form select {
	margin-bottom: 1vw;
	padding: 0.6vw 1vw;
	border-radius: 0.3vw;
	width: 100%;
	font-size: 1.66vw;
	color: var(--black);
	position: relative;
	transition: 0.1s ease-out;
}

.social-buttons-content .social-button button,
.login-content-fields form button.login-button {
	padding: 0.8vw 5vw;
	margin-bottom: 1vw;
	width: 100%;
	transition: all 0.3s ease-in-out;
}

.social-buttons-content .social-button button:hover,
.login-content-fields form button.login-button:hover {
	background-color: var(--medium-grey);
	color: var(--white);
}

.login-content-fields form button.login-button {
	margin-bottom: 0;
}

.login-content-fields form button.login-button {
	background-color: var(--medium-grey);
}

.social-buttons-content .social-button button h1,
.login-content-fields form button.login-button {
	font-weight: bold;
}

.login-content-fields form input::placeholder,
.social-buttons-content .social-button button,
.creategroup-modal .creategroup-content form textarea::placeholder,
.creategroup-modal .creategroup-content form select {
	color: var(--grey);
	font-weight: bold;
	font-size: 1.66vw;
}

.login-content-fields form button.login-button {
	color: var(--white);
	transition: all 0.3s ease-in-out;
}

.login-content-fields form button.login-button:hover {
	background-color: var(--white);
	color: var(--grey);
}

.login-popup-container .login-container .seperator {
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-popup-container .login-container .seperator .seperator-text {
	margin: 0 3vw;
}

.login-popup-container .login-container .seperator .line {
	background-color: var(--black);
	height: 0.1vw;
	width: 11vw;
}

/* login-popup end */

/* joinnow-popup start */
.login-popup-container .joinnow-container h1.header {
	margin: 0 0 2vw;
}

.joinnow-popup {
	margin: 0vw 15vw;
}

.login-container.joinnow-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 1%;
}

.joinnow-social-buttons {
	width: 64%;
}

.login-popup-container .joinnow-content-fields form {
	width: 80%;
}

.login-popup-container .joinnow-content-fields form h1.joinnow-text {
	margin: 1vw 1vw 2vw;
}

.joinnow-content-fields form .mobile-number {
	display: flex;
	gap: 5%;
}

.joinnow-content-fields form .mobile-number .country-code {
	width: 25%;
}
#joinnowcontent {
	align-items: center;
}
/* joinnow-popup end */

/* creategroup-popup start */
.creategroup-modal-container.open::-webkit-scrollbar,
.create-post-modal.open::-webkit-scrollbar,
.chat-message-modal.open::-webkit-scrollbar,
.search-modal-container.open::-webkit-scrollbar,
.sendmessage-modal-container.open::-webkit-scrollbar,
.sending-modal.open::-webkit-scrollbar,
.group-modal-container::-webkit-scrollbar {
	display: none;
	scrollbar-width: none; /* for Firefox */
}

.creategroup-modal-container,
.create-post-modal,
.chat-message-modal,
.search-modal-container,
.sendmessage-modal-container,
.sending-modal,
.group-modal-container {
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.51) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	backdrop-filter: blur(5px);
}

.creategroup-modal-container,
.create-post-modal,
.chat-message-modal,
.search-modal-container,
.sendmessage-modal-container,
.sending-modal,
.group-modal-container {
	/* margin: 8vw 26vw 8vw 24vw; */
	margin: 0;
	/* padding: 8vw 0; */
	opacity: 0;
	visibility: hidden;
	transition: 0.3s ease-in-out;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	display: none;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
	z-index: 99;
}

.creategroup-modal-container.open,
.create-post-modal.open,
.chat-message-modal.open,
.search-modal-container.open,
.sendmessage-modal-container.open,
.sending-modal.open,
.group-modal-container.open {
	opacity: 1;
	visibility: visible;
	/* padding-top: 14vw;
	padding-bottom: 5vw; */
	/* padding-bottom: 0vw; */
	overflow-y: scroll;
	transition: 0.3s ease-in-out;
	display: flex;
	/* z-index: 99999; */
}

.creategroup-modal-container.topSpace {
	padding-top: 8vw;
}

.creategroup-modal-container.add.open {
	padding-top: 10vw;
	padding-bottom: 0vw;
}

#modaloverflow {
	height: 35vw;
	justify-content: flex-start;
	overflow-y: auto;
	margin-top: 0;
}

.CommentCont {
	width: 100%;
}

.userCont {
	display: flex;
	justify-content: space-between;
}
.creategroup-modal-container.add.isOpenValidation {
	padding-top: 15vw;
}

.creategroup-modal-container.add.isOpenValidation {
	padding-top: 15vw;
}

.creategroup-modal {
	position: relative;
	background-color: var(--medium-grey);
	border-radius: 0.3vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2vw 4vw;
	text-align: center;
	/* height: 90vh !important;
	overflow-y: auto; */
	min-width: 50%;
	max-width: 50%;
}

button.close-button-modal {
	background-color: var(--grey);
	transition: all 0.3s ease-in-out;
}

button.close-button-modal.dark-button {
	background-color: var(--dark-grey);
}

.creategroup-modal .creategroup-header {
	border-bottom: 0.1vw solid var(--white);
	width: 96%;
}

.creategroup-modal .creategroup-header h1 {
	font-weight: bold;
	color: var(--white);
	margin-bottom: 1vw;
}

.creategroup-modal .creategroup-header h2 {
	margin-bottom: 1vw;
}

.creategroup-modal .creategroup-content {
	margin: 2vw 0;
	width: 66%;
}

.creategroup-modal .creategroup-content form textarea {
	border: none;
}

.creategroup-modal .creategroup-content form textarea:focus {
	outline: none;
}

.creategroup-modal .creategroup-content form input,
.creategroup-modal .creategroup-content form textarea,
.creategroup-modal .creategroup-content form input::placeholder,
.creategroup-modal .creategroup-content form textarea::placeholder,
.creategroup-modal .creategroup-content form select,
.creategroup-modal .creategroup-content form button.creategroup-button {
	font-size: 1.1vw;
	padding: 0.7vw;
}

.creategroup-modal .creategroup-content form button.creategroup-button {
	color: var(--close-button);
	background-color: var(--white);
	transition: all 0.3s ease-in-out;
}

.creategroup-modal .creategroup-content form button.creategroup-button:hover {
	background-color: var(--close-button);
	color: var(--white);
}

.creategroup-modal .creategroup-content form button.creategroup-button h2,
.creategroup-modal .creategroup-content form h2.header-text {
	font-weight: bold;
}

.creategroup-modal .creategroup-content form h2.header-text {
	color: var(--white);
	margin: 0.5vw 0 1.5vw;
	max-width: 32vw;
	/* width: 155%;
	position: relative;
	left: -6.8vw; */
}

.creategroup-modal .creategroup-content form select {
	border: none;
	background-image: unset;
}

.creategroup-modal .creategroup-content form .public-select {
	position: relative;
	width: 100%;
}

.creategroup-modal .creategroup-content form .public-select .public-caret {
	position: absolute;
	top: -1vw;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0;
	right: 0;
}

.creategroup-modal .creategroup-content form .public-select i {
	font-size: 1.66vw;
	color: var(--medium-grey);
}

/* creategroup-popup end */
/* sendmessage-popup start */
.send-message-modal {
	width: 45%;
	margin-top: 8vw;
	margin-bottom: 8vw;
	justify-content: flex-start;
}

#profileviewmodal {
	justify-content: flex-start;
	height: 70vh !important;
	margin-bottom: 8vw;
	overflow: auto;
}

.dropdown-container {
	display: none;
	position: absolute;
	background-color: var(--post-background);
	color: var(--close-button);
	width: 100%;
	top: 4vw;
	border-radius: 0.3vw;
	z-index: 9;
	padding: 1vw;
	text-align: left;
	height: 25vh;
	overflow-y: scroll;
	transition: height 0.3s ease-in-out;
}

/* .dropdown-container option {
	font-size: 1.5vw;
	font-weight: bold;
} */

.dropdown-container.show {
	display: block;
}

.dropdown-container .dropdown-info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5%;
	margin-bottom: 1vw;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.dropdown-container .dropdown-info:hover {
	background-color: var(--grey);
}

.dropdown-container .dropdown-info .profile-info {
	border-radius: 50%;
	width: 5vw;
	height: 5vw;
	overflow: hidden;
}

.dropdown-container .dropdown-info .profile-info img {
	width: 100%;
	height: 100%;
}

.dropdown-container .dropdown-info .name-info h2 {
	font-weight: bold;
}

.send-message-modal .sendmessage-content {
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 3vw;
}

.sendmessage-content .send-message-profile {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	align-items: center;
	gap: 3%;
}

.sendmessage-content .send-message-profile .profile {
	border-radius: 50%;
	overflow: hidden;
	width: 4vw;
	height: 4vw;
}

.sendmessage-content .send-message-profile .profile img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.sendmessage-content .send-message-profile .profile-name h1 {
	color: var(--white);
	font-weight: bold;
}

.sendmessage-content form.sendmessage-form {
	position: relative;
	margin-top: 2vw;
}

.sendmessage-form > div {
	width: 100%;
}

.sendmessage-content form.sendmessage-form button.search {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 1vw;
	top: 0;
	right: 0;
}

.sendmessage-content form.sendmessage-form button.search i {
	font-size: 2vw;
}

.sendmessage-content form.send-message-type {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.send-message-modal .sendmessage-content form input::placeholder {
	color: var(--medium-grey);
}

.sendmessage-content form input.sendmessage {
	width: 70%;
	margin-bottom: 0;
}

.sendmessage-content form button.send {
	width: 25%;
	font-size: 1.66vw;
	margin: 0.3vw 0;
	background-color: var(--post-background);
	color: var(--close-button);
	transition: all 0.3s ease-in-out;
}

.sendmessage-content form button.send h1 {
	font-weight: bold;
}
/* sendmessage-popup end */

/* contactsupplier-popup start */
.contactsuuplier-modal {
	width: 45%;
}
/* contactsupplier-popup end */
/* downloadcaepipe-popup start */
.creategroup-modal .creategroup-content form button.caepipe-program {
	margin-top: 3vw;
}
/* downloadcaepipe-popup end */

/* profile-count modal start */
.profile-count-modal {
	/* height: 100%; */
}
/* profile-count modal end */

/* company-profile modal start */
.company-profile-container {
	position: absolute;
	background-color: #8a8989;
	padding: 4vw;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 50vw;
	overflow-y: auto;
	border-radius: 0.3vw;
	margin-top: 1vw;
}

.company-profile-container .cmpy-modal {
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 0;
}
/* company-profile modal end */

/* createpost-popup start */
.creategroup-modal .creategroup-content {
	margin: 2vw 0 0;
}

.postcreate-modal {
	width: 52%;
}

.create-post-content {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	width: 100%;
}

.sendmessage-content .create-post-profile {
	width: 100%;
	gap: 3%;
	justify-content: flex-start;
}

.sendmessage-content .create-post-profile .post-profile {
	border-radius: 50%;
	overflow: hidden;
	width: 5vw;
	height: 5vw;
}

.sendmessage-content .create-post-profile .post-profile img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.sendmessage-content .create-post-profile .post-name {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.sendmessage-content .create-post-profile .post-name h2 {
	color: var(--white);
	font-weight: bold;
	margin-bottom: 1vw;
	text-align: left;
}

.sendmessage-content .create-post-profile .post-name .my-group,
.sendmessage-content .createpost-form textarea,
.sendmessage-content .createpost-form input {
	background-color: var(--post-background);
	border-radius: 0.3vw;
}

.sendmessage-content .create-post-profile .post-name .individual-group {
	margin-top: 1vw;
}

.sendmessage-content .createpost-form textarea::placeholder,
.sendmessage-content .createpost-form input::placeholder {
	color: var(--close-button) !important;
	font-weight: bold;
}

.sendmessage-content .create-post-profile .post-name .my-group img.post-globe {
	width: 2vw;
	height: 2vw;
	padding: 0.2vw;
	top: 0.3vw;
	position: relative;
}

.sendmessage-content
	.create-post-profile
	.post-name
	.my-group
	select.selectpicker {
	border: none;
	padding: 0.5vw 2.8vw 0.5vw 0.5vw;
	background-color: transparent;
	border-left: 1px solid var(--light-grey);
	border-radius: 0;
	color: var(--close-button);
	font-size: 0.97vw;
	font-weight: bold;
}

.sendmessage-content
	.create-post-profile
	.post-name
	.individual-group
	select.individual-select {
	border-left: none;
}

.sendmessage-content .create-post-profile .post-name .my-group span {
	border-left: 1px solid var(--light-grey);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	right: 0;
}

.sendmessage-content .create-post-profile .post-name .my-group span i {
	font-size: 1.5vw;
	color: var(--close-button);
}

.form-control:focus {
	border-color: none;
	box-shadow: unset;
}

/* show-user start */
.show-user .show-user-form-group {
	position: absolute;
	top: -5vw;
	right: -8vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.show-user .color-box {
	position: absolute;
	display: flex;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	width: 2.5vw;
	height: 2.5vw;
	top: -5.15vw;
	right: -8.45vw;
}

.show-user .show-user-form-group label.show-user-text {
	font-weight: bold;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
}

.show-user .show-user-form-group input[type="checkbox"] {
	display: none;
	cursor: pointer;
}

.show-user-form-group input[type="checkbox"] + label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--close-button);
	border: 1px solid var(--close-button);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 0vw;
	margin-left: 1.5vw;
	width: 1.5vw;
	height: 1.5vw;
	z-index: 2;
}

.show-user-form-group input[type="checkbox"]:checked + label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.5vw;
	right: 0.5vw;
	width: 0.4vw;
	height: 1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	transform: rotate(45deg);
	z-index: 2;
}

.create-post-content .show-user {
	position: relative;
	width: 100%;
	margin-bottom: 0.8vw;
}

.show-user .createpost-colorbox {
	top: -0.35vw;
	right: -0.35vw;
	width: 2.2vw;
	height: 2.2vw;
}

.show-user .show-user-post-group {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	top: 0;
	right: 0;
}

.show-user .createpost-colorbox {
	width: 2.2vw !important;
	height: 2.2vw !important;
	top: -2.3vw !important;
	right: 0.65vw !important;
}

.show-user .show-user-post-group {
	top: -2vw !important;
	right: 1vw !important;
}

.show-user .show-user-post-group label.post-user-text {
	font-size: 0.97vw !important;
	color: var(--white) !important;
}

.create-post-content
	.show-user-form-group
	input[type="checkbox"]:checked
	+ label:after {
	top: 0.2vw;
}

.postmodal-pannel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 1vw;
}

.postmodal-pannel .postmodal-content {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.postmodal-pannel .postmodal-content-image {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	padding: 7px;
}

.postmodal-pannel .postmodal-content-image img {
	/* width: 3vw;
	height: 3vw; */
	width: 100%;
}

.postmodal-pannel .postmodal-content h2 {
	color: var(--white);
	margin-top: 0.5vw;
}

.postmodal-pannel .postmodal-content h2,
.postmodal-pannel .postmodal-content button h1 {
	font-weight: bold;
}

.postmodal-pannel .postmodal-content button {
	background-color: var(--post-background);
	color: var(--close-button);
	padding: 0.6vw 2.5vw;
	transition: all 0.3s ease-in-out;
}

.postmodal-pannel .postmodal-content button:hover {
	background-color: var(--close-button);
	color: var(--post-background);
}
/* createpost-popup end */

/* profile count popup start */
.creategroup-modal .creategroup-content.count-container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	/* gap: 5%; */
	overflow-y: auto;
}
/* profile count popup end */

/* chatmessage-popup start */
#chat-modal {
	cursor: pointer;
}
.chat-send-message-modal {
	padding: 1.5vw 2vw 1vw;
	width: 40%;
}

.creategroup-modal .chat-group-box {
	border-bottom: 0.1vw solid var(--white);
	width: 100%;
}

.creategroup-modal .chat-group-box h1 {
	margin-bottom: 2vw;
}

.chat-message-container {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	padding: 1vw 2vw;
	margin: 2vw 0 1vw;
	width: 100%;
}

.chat-message-container .chat-message-profile {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 4%;
}

.chat-message-profile .profile-content {
	border-radius: 50%;
	overflow: hidden;
	width: 5vw;
	height: 5vw;
}

.chat-message-profile .profile-content img {
	width: 5vw;
}

.chat-message-profile .profile-info h2,
.chat-message-container .chat-message-content h3 {
	font-weight: bold;
}

.chat-message-container .chat-message-content {
	margin: 1vw 0;
}

.chat-message-content .chat-date {
	text-align: center;
	margin-bottom: 2vw;
}

.chat-message-content .message-box-one,
.chat-message-content .message-box-two {
	position: relative;
	margin-bottom: 2vw;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.chat-message-content .message-box-two {
	justify-content: flex-end;
	align-items: flex-end;
}

.chat-message-content .message-box-one .message-content-one,
.chat-message-content .message-box-two .message-content-two {
	background-color: var(--white);
	border-radius: 0.3vw;
	border: 1px solid var(--light-grey);
	padding: 1vw 2vw;
	width: 50%;
	text-align: left;
	/* margin-top: 1vw; */
}

.chat-message-content .message-box-two .message-content-two {
	background-color: transparent;
	border: 1px solid var(--medium-grey);
}

.chat-message-content .message-box-one .message-content-one h3,
.chat-message-content .message-box-one .time-content-one h3,
.chat-message-content .message-box-two .message-content-two h3,
.chat-message-content .message-box-two .time-content-two h3 {
	color: var(--close-button);
}

.chat-message-content .message-box-one .time-content-one,
.chat-message-content .message-box-two .time-content-two {
	position: relative;
	top: 0.5vw;
	left: 2vw;
}

.chat-message-content .message-box-two .time-content-two {
	left: -10.5vw;
}

.chat-message-form {
	margin: 1vw 0;
	position: relative;
	width: 100%;
}

.chat-message-form input,
.chat-message-form input::placeholder {
	font-size: 1.24vw;
	color: var(--medium-grey);
}

.chat-message-form input {
	border-radius: 0.3vw;
	padding: 1vw 1.5vw;
	color: var(--black);
	width: 100%;
}

.chat-message-form button {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	right: 1vw;
}

.chat-message-form button i {
	font-size: 1.5vw;
}
/* chatmessage-popup end */

/* search-popup start */
.search-field-container {
	margin-top: 2vw;
}

.navbar-action-search .search-form-popup {
	margin-left: 0vw;
}

.search-radiobutton {
	margin: 2vw 3vw 0vw;
}

.search-radiobutton input[type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}
.search-radiobutton input[type="radio"]:checked + label,
.search-radiobutton input[type="radio"]:not(:checked) + label {
	position: relative;
	padding-left: 6vw;
	cursor: pointer;
	line-height: 2;
	display: inline-block;
	color: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: bold;
	margin: 1vw 0;
}
.search-radiobutton input[type="radio"]:checked + label:before,
.search-radiobutton input[type="radio"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 2.5vw;
	height: 2.5vw;
	border: 1px solid #ddd;
	border-radius: 100%;
	background-color: var(--light-grey);
}
.search-radiobutton input[type="radio"]:checked + label:after,
.search-radiobutton input[type="radio"]:not(:checked) + label:after {
	content: "";
	width: 1.5vw;
	height: 1.5vw;
	background-color: var(--close-button);
	position: absolute;
	top: 0.5vw;
	left: 0.5vw;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.search-radiobutton input[type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
.search-radiobutton input[type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
/* search-popup end */

/* multi-select */
.option-multi-select {
	margin-bottom: 0vw;
	background-image: unset;
	padding: 0;
}

.login > .option-multi-select > div{
padding: 0.6vw 1vw ;
}

.creategroup-content .option-multi-select {
	margin-bottom: 1vw;
}

.css-1s2u09g-control {
	border: none !important;
}

.css-319lph-ValueContainer {
	padding: 0 !important;
	text-align: left !important;
	font-size: 1.24vw !important;
}

.css-14el2xx-placeholder {
	font-size: 1.24vw !important;
	color: var(--grey) !important;
}

.css-1okebmr-indicatorSeparator,
.css-tlfecz-indicatorContainer {
	display: none !important;
}

.css-12jo7m5 {
	font-size: 1vw !important;
}

.srv-validation-message {
	font-size: 1.1vw;
}

@media screen and (max-width: 900px) {
	.css-319lph-ValueContainer,
	.css-14el2xx-placeholder {
		font-size: 2vw !important;
	}

	.creategroup-modal .creategroup-content form label {
		font-size: 2vw;
	}

	.creategroup-modal .creategroup-content form input,
	.creategroup-modal .creategroup-content form textarea,
	.creategroup-modal .creategroup-content form input::placeholder,
	.creategroup-modal .creategroup-content form textarea::placeholder,
	.creategroup-modal .creategroup-content form select,
	.creategroup-modal .creategroup-content form button.creategroup-button {
		font-size: 2vw;
		padding: 1.5vw;
		margin-bottom: 2vw;
	}

	.sendmessage-content form input.sendmessage {
		margin-bottom: 0;
	}

	.staticEdit_outerdiv .staticEdit_innerdiv{
		width:100%;
		/*font-size: 2vw;*/
	} 

	button.close-button-modal i {
		font-size: 2vw;
	}

	/* create post modal start */
	#modaloverflow {
		height: 55vw;
	}
	.creategroup-modal-container.add.isOpenValidation {
		padding-top: 20vw;
	}
	.creategroup-modal {
		padding: 3vw 4vw !important;
		max-width: 100% !important;
		width: 70%;
		overflow-y: auto;
		margin-bottom: 8vw;
		margin-top: 8vw;
	}
	button.close-button-modal {
		top: 2vw;
		right: 2vw;
	}
	.creategroup-modal .creategroup-content {
		margin: 4vw 0 0;
	}
	.sendmessage-content .create-post-profile {
		align-items: flex-start;
		gap: 4%;
	}
	.sendmessage-content .create-post-profile .post-profile img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.sendmessage-content .create-post-profile .post-name {
		width: 80%;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		img.post-globe {
		width: 3vw;
		height: 3vw;
		padding: 0.2vw;
		top: 0.7vw;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		select.selectpicker {
		padding: 1vw 3vw 1vw 1vw;
		font-size: 1.6vw;
	}
	.sendmessage-content .create-post-profile .post-name .my-group span i {
		font-size: 1.6vw;
	}
	.create-post-content .show-user {
		margin-bottom: 2vw;
	}
	
	.show-user .createpost-colorbox {
		width: 4vw !important;
		height: 4vw !important;
		top: -2.5vw !important;
		right: 0.4vw !important;
	}
	.show-user .show-user-post-group label.post-user-text {
		font-size: 2vw !important;
	}
	.sendmessage-content form.sendmessage-form {
		margin-top: 2vw;
	}
	.postmodal-pannel {
		margin-top: 2vw;
	}
	.postmodal-pannel .postmodal-content-image {
		width: 6vw;
		height: 6vw;
	}
	.postmodal-pannel .postmodal-content-image img {
		width: 100%;
	}
	.srv-validation-message {
		font-size: 2vw;
	}
	.postmodal-pannel .postmodal-content button {
		padding: 1vw 2.5vw;
	}
	.creategroup-modal-container.topSpace {
		padding-top: 18vw;
	}
	/* create post modal end */

	/* profile view modal start */
	#profileviewmodal {
		height: 60vw !important;
	}
	.like-modal {
		margin-bottom: 3vw;
	}
	/* profile view modal end */

	/* create group modal start */
	.create-model {
		padding-top: 18vw !important;
	}
	.creategroup-modal .creategroup-content form h2.header-text {
		max-width: 100%;
	}
	.creategroup-content .option-multi-select {
		margin-bottom: 2vw;
	}
	.creategroup-modal .creategroup-content form .public-select i {
		font-size: 2vw;
	}
	.creategroup-modal .creategroup-content form button.creategroup-button {
		width: 20vw;
	}
	/* create group modal end */

	/* message modal start */
	.like-modal-container.open {
		padding-bottom: 10vw !important;
		height: 100%;
	}
	.message-modal {
		width: 70%;
	}
	/* message modal end */

	/* textarea */
	.tox:not([dir="rtl"]) {
		width: 100% !important;
		margin-bottom: 2vw;
	}

	/* react selectors */
	.css-tj5bde-Svg {
		width: 2vw;
		height: 2vw;
	}
	.css-12jo7m5 {
		font-size: 2vw !important;
	}

	/* contact supplier modal start */
	.contactSup_modal {
		padding-top: 25vw !important;
	}
	/* contact supplier modal end */

	/* change password modal start */
	.input-group .password__change {
		top: 4vw;
	}
	.input-group .password__show i {
		font-size: 2.5vw;
	}
	/* change password modal end */

	/* login modal start */
	.login-popup {
		margin: 0vw 15vw;
		padding: 13vw 0;
	}
	.login-popup-container {
		padding: 4vw 4vw;
	}
	.login-popup-container .pexit-logo {
		width: 15vw;
	}
	.login-popup-container .pexit-logo img {
		width: 100%;
	}
	.login-popup-container .login-container {
		width: 70%;
	}
	.login-popup-container .login-container h1.header {
		margin: 2vw 0;
	}

	.login-content-fields form input,
	.creategroup-modal .creategroup-content form textarea,
	.creategroup-modal .creategroup-content form select {
		padding: 1vw 1vw;
		font-size: 2vw;
	}
	.social-buttons-content .social-button button,
	.login-content-fields form button.login-button {
		padding: 1vw 1vw;
		margin-bottom: 1vw;
		width: 100%;
		transition: all 0.3s ease-in-out;
	}
	.forgot-pass-link {
		padding: 1vw;
		width: fit-content;
	}
	/* login modal end */

	/* join now modal start */
	#joinnowmodal {
		width: 100%;
	}
	.joinnow-social-buttons {
		width: 65%;
	}
	.country-select {
		font-size: 2vw;
		padding: 1vw 1vw;
	}
	.login-content-fields form input::placeholder,
	.social-buttons-content .social-button button,
	.creategroup-modal .creategroup-content form textarea::placeholder,
	.creategroup-modal .creategroup-content form select,
	.linkedin h1,
	.caret-content .select-caret i {
		font-size: 2vw;
	}
	.code-select {
		font-size: 2vw;
		padding: 0.87vw 1vw;
	}
	/* join now modal end */
	#mycaepipe {
		width: fit-content;
	}

	/* checkbox changes in  createpost modal start */
	.show-user #createcolorbox,
	.show-user #sharecolorbox,
	.show-user #updatecolorbox {
		width: 3.5vw !important;
		height: 3.5vw !important;
		top: -2.3vw !important;
		right: 0.5vw !important;
	}
	/* checkbox changes in  createpost modal end */
}

/* mobile view popup css */
@media screen and (max-width: 599px) {
	/* login modal end */
	.login-popup {
		margin: 0vw 1vw;
		padding: 20vw 0;
	}
	.login-popup-container {
		padding: 6vw;
	}
	.login-popup-container .pexit-logo {
		width: 20vw;
	}
	.login-popup-container .login-container {
		width: 80%;
	}
	.login-popup-container .login-container h1.header {
		margin: 3vw 0;
	}
	.login-content-fields form input,
	.creategroup-modal .creategroup-content form textarea,
	.creategroup-modal .creategroup-content form select {
		padding: 2vw 2vw;
		font-size: 3vw;
		margin-bottom: 2vw;
	}
	.input-group .password__show {
		right: 1vw;
		top: 2.7vw;
	}
	.input-group .password__show i {
		font-size: 3.5vw;
	}
	.forgot-pass-link {
		margin-bottom: 2vw;
		width: 50%;
	}
	.login-popup-container .login-container .seperator {
		margin-bottom: 2vw;
	}
	.login-popup-container .login-container .seperator .line {
		width: 100%;
	}
	.social-buttons-content .social-button {
		width: 100%;
	}
	.social-buttons-content .social-button button,
	.login-content-fields form button.login-button {
		margin-bottom: 2vw;
		padding: 2vw 1vw;
	}
	.login-content-fields form input::placeholder,
	.social-buttons-content .social-button button,
	.creategroup-modal .creategroup-content form textarea::placeholder,
	.creategroup-modal .creategroup-content form select,
	.linkedin h1,
	.caret-content .select-caret i {
		font-size: 3vw;
	}
	button.close-button-modal {
		height: 7vw;
		width: 7vw;
	}
	button.close-button-modal i {
		font-size: 3vw;
	}
	/* login modal end */

	/* join now modal start */
	.login-container.joinnow-container {
		gap: 6%;
	}
	.login-popup-container .joinnow-content-fields form {
		width: 100%;
	}
	.joinnow-social-buttons {
		width: 70%;
	}
	#joinnowcontent {
		align-items: flex-end;
	}
	#joinnowcontent .joinnow-header {
		width: 100%;
	}
	.joinnow-content-fields form .mobile-number {
		gap: 3%;
	}
	.code-select {
		font-size: 3vw;
		width: fit-content;
	}
	.country-select {
		font-size: 3vw;
		padding: 2vw 2vw;
	}
	.login-popup-container .joinnow-content-fields form h1.joinnow-text {
		margin: 4vw 0vw 4vw;
	}
	#joinnowcaret {
		width: unset;
	}
	/* join now modal end */

	/* create group modal start */
	.creategroup-modal-container.add.isOpenValidation {
		padding-top: 5vw;
	}
	.creategroup-modal {
		padding: 6vw 4vw !important;
		width: 100%;
		margin: 5vw 1vw;
		justify-content: flex-start;
	}
	.sendmessage-content .create-post-profile .post-profile {
		width: 8vw;
		height: 8vw;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		img.post-globe {
		width: 5vw;
		height: 5vw;
		padding: 0.5vw;
		top: 0.3vw;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		select.selectpicker,
	.sendmessage-content .create-post-profile .post-name .my-group span i {
		font-size: 2.2vw;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		select.selectpicker {
		padding: 1vw 10vw 1vw 1vw;
	}
	.create-post-content .show-user {
		margin-bottom: 3vw;
	}
	.show-user #createpostmodal,
	.show-user #sharepostmodal,
	.show-user #updatepostmodal {
		width: 4vw !important;
		height: 4vw !important;
		top: -1.8vw !important;
		right: 0.2vw !important;
	}
	.show-user .show-user-post-group label.post-user-text {
		font-size: 3vw !important;
	}
	.create-post-content
		.show-user-form-group
		input[type="checkbox"]:checked
		+ label:after {
		top: 1vw;
	}
	.sendmessage-content form.sendmessage-form {
		margin-top: 4vw;
	}

	.creategroup-modal .creategroup-content form input,
	.creategroup-modal .creategroup-content form textarea,
	.creategroup-modal .creategroup-content form input::placeholder,
	.creategroup-modal .creategroup-content form textarea::placeholder,
	.creategroup-modal .creategroup-content form select,
	.creategroup-modal .creategroup-content form button.creategroup-button {
		font-size: 3vw;
		padding: 2vw;
		margin-bottom: 2vw;
	}
	.postmodal-pannel .postmodal-content-image {
		width: 12vw;
		height: 12vw;
	}
	.postmodal-pannel .postmodal-content button {
		padding: 2vw 2.5vw;
	}
	.postmodal-pannel .postmodal-content h2 {
		margin-top: 2vw;
	}
	.creategroup-modal .creategroup-content form .public-select i {
		font-size: 4vw;
	}
	/* create group modal end */

	/* invite modal start */
	.contact-modal.open {
		padding-top: 5vw !important;
	}
	.invite-modal {
		width: 100% !important;
	}
	.creategroup-modal .creategroup-content .contact-container label.name {
		width: 40vw;
	}
	.creategroup-modal .creategroup-content form label {
		font-size: 3vw;
	}
	/* invite modal end */

	/* react selector */
	.css-319lph-ValueContainer,
	.css-14el2xx-placeholder {
		font-size: 3vw !important;
	}
	.css-tj5bde-Svg {
		width: 4vw;
		height: 4vw;
	}

	/* send message modal start */
	.sendmessage-content .send-message-profile .profile {
		width: 8vw;
		height: 8vw;
	}
	.sendmessage-content .send-message-profile .profile img {
		width: 100%;
		height: 100%;
	}
	.sendmessage-content form.send-message-type {
		flex-direction: column;
		width: 100%;
	}
	.creategroup-modal .creategroup-content form input {
		width: 100%;
	}
	.sendmessage-content form button.send {
		margin: 1vw 0;
	}
	/* send message modal end */

	/* report modal start */
	.report-modal-container {
		padding-top: 5vw;
	}
	.report-modal {
		width: 100%;
	}
	.creategroup-modal .creategroup-content {
		margin: 6vw 0 0;
	}
	.report-content i,
	.report-content .form-select {
		font-size: 3vw;
	}
	.report-content .form-select {
		padding: 2vw;
	}
	.report-submit .submit {
		padding: 2vw;
		margin-top: 5vw;
	}
	.srv-validation-message {
		font-size: 3vw;
	}
	.company-profile-container .cmpy-modal iframe {
		width: 100%;
		height: 100vh;
	}
	/* report modal end */

	/* apply for job modal start */
	form button.jobapplyBtn {
		font-size: 3vw;
		padding: 2vw 1vw;
	}

	.show-job-container {
		margin: 3vw 0 0 0;
	}
	.creategroup-modal-container.applyjobmodal {
		padding-top: 5vw;
		padding-bottom: 0vw;
	}
	.input-group .password__show {
		right: 1vw;
		top: 3vw;
	}
	.submit-button {
		margin-top: 6vw;
	}

	.creategroup-modal .creategroup-content form button.creategroup-button {
		margin-top: 2vw;
		width: fit-content;
	}
	/* apply for job modal end */

	/* message modal start */
	.login-content-fields form,
	.social-buttons-content {
		align-items: flex-start;
	}
	.like-modal-container.open {
		padding-bottom: 5vw !important;
	}
	.like-modal {
		margin: 5vw 0vw 5vw 0vw;
	}
	.message-modal form textarea.msg-description {
		font-size: 3vw;
	}
	.login-content-fields form button.msg-button {
		width: 20vw !important;
		padding: 2vw 2vw !important;
		margin-top: 4vw;
	}
	.message-modal {
		width: 100%;
		/* height: 100vw; */
	}
	#messagemodal {
		margin: 5vw 3vw 5vw 3vw;
		height: 60vh;
	}
	/* message modal end */

	/* search modal start */
	.search-radiobutton {
		margin: 7vw 3vw 0vw;
	}

	.search-radiobutton input[type="radio"]:checked + label,
	.search-radiobutton input[type="radio"]:not(:checked) + label {
		padding-left: 10vw;
		margin: 3vw 0;
	}
	.search-radiobutton input[type="radio"]:checked + label:before,
	.search-radiobutton input[type="radio"]:not(:checked) + label:before {
		width: 4.5vw;
		height: 4.5vw;
	}
	.search-radiobutton input[type="radio"]:checked + label:after,
	.search-radiobutton input[type="radio"]:not(:checked) + label:after {
		width: 3vw;
		height: 3vw;
		top: 0.75vw;
		left: 0.75vw;
	}
	/* search modal end */

	#mySupplier {
		width: fit-content;
		padding: 3vw 8vw;
	}
	.creategroup-modal-container,
	.create-post-modal,
	.chat-message-modal,
	.search-modal-container,
	.sendmessage-modal-container,
	.sending-modal,
	.group-modal-container {
		align-items: flex-start;
	}
	#profileviewmodal {
		height: 100vw !important;
	}
	.like-modal .user-profile {
		width: 9vw;
		height: 9vw;
	}
	.like-modal .user-profile img {
		width: 100%;
		height: 100%;
	}
	.creategroup-modal-container.add.open {
		padding-top: 10vw;
		padding-bottom: 0vw;
	}
	.creategroup-modal-container.topSpace {
		padding-top: 5vw;
	}
	#modaloverflow {
		height: auto;
	}
	.creategroup-modal .creategroup-content.invite-friends-modal {
		width: 100%;
	}
	.contactSup_modal {
		padding-top: 5vw !important;
	}

	/* createpost modal changes start */
	.show-user-form-group input[type="checkbox"] + label#createlabelbox::after,
	.show-user-form-group input[type="checkbox"] + label#sharelabelbox::after,
	.show-user-form-group input[type="checkbox"] + label#updatelabelbox::after {
		top: 0.4vw;
	}

	.show-user .createpost-colorbox,
	.show-user .createpost-colorbox,
	.show-user .createpost-colorbox {
		width: 6vw !important;
		height: 6vw !important;
		top: -2.6vw !important;
		right: -0.1vw !important;
	}
	.sendmessage-content .create-post-profile .post-name h2 {
		font-size: 3vw;
	}
	.sendmessage-content .create-post-profile .post-name .my-group span i {
		display: block;
	}
	/* createpost modal changes end */

	/* modals fonts changes start */
	.creategroup-modal .creategroup-content form button.creategroup-button h2,
	.creategroup-modal .creategroup-content form h2.header-text,
	.creategroup-modal .creategroup-content.delete-buttons-container button h2,
	.login-content-fields form button.msg-button h2 {
		font-size: 3vw;
	}

	.creategroup-modal .creategroup-content form button.creategroup-button h2,
	.creategroup-modal .creategroup-content.delete-buttons-container button h2,
	.login-content-fields form button.msg-button h2 {
		white-space: nowrap;
	}
	.staticEdit_outerdiv .staticEdit_innerdiv{
		/*font-size: 3.1vw;*/
		width: 100%;
	} 
	/* modals fonts changes end */
}
