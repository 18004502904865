.month-time {
	width: 50%;
}

.software_desc {
	color: #fff;
	text-align: left;
}

.software_desc p {
	font-size: 1.2vw;
}

.software_desc p a {
	color: #3130f5;
	cursor: pointer;
}

.software_desc p.center {
	text-align: center;
	margin: 1vw;
}

#caepipeimage {
	height: 100%;
	width: 100%;
}

.software-related-content{
	width: 60vw;
}

.software-related-content a{
	width: 18vw;
}

.software-related-content .software-boxes-content{
	padding-top: 3vw;
}

.software-related-content .software-boxes-content img{
	height: auto;
	/* height: 15vw;
    object-fit: cover; */
}

@media screen and (max-width: 900px) {
	#caepipecontainer {
		margin: 4vw 2vw 4vw 2vw;
		gap: 4%;
	}
}

.softErrorMsg{
	text-align: left;
	color: #ffffff;
	margin-bottom: 2vw;
}

.creategroup-modal .creategroup-content form button.softDownBtn{
	margin-top: 1vw;
	margin-bottom:1vw;
}