/* navbar start */
.navbar,
.footer {
	background-color: var(--grey);
	padding: 0vw 2vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navbar {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 99;
	width: 100%;
	height: 4vw;
}

.navbar .navbar-logo {
	cursor: pointer;
}

.navbar .navbar-logo img {
	width: 8vw;
}

.navbar .navbar-action-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar .navbar-action-buttons .login-button {
	margin-right: 0.8vw;
}

.navbar .navbar-action-buttons .login-button button:hover,
.navbar .navbar-action-buttons .join-button button:hover {
	background-color: var(--grey);
}
/* navbar end */

/* navbar-login start */

.navbar-action-search {
	display: block;
	display: flex;
	align-items: center;
}
/* login-header page start */
.navbar-action-search .form {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	margin-left: 3vw;
}

.navbar-action-search .form .caret {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 28%;
	left: 10%;
	cursor: pointer;
}

.navbar-action-search .form .caret i,
.navbar-action-search .form .search-button i {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5vw;
}

.navbar-action-search .form input {
	border: none;
	width: 90%;
	padding: 0.2vw 4vw;
	border-radius: 0.3vw;
	font-size: 1.4vw;
	height: 3vw;
}

.navbar-action-search .form input:focus {
	outline: none;
}

.navbar-action-search .form input::placeholder {
	color: var(--grey);
}

.navbar-action-search .form .search-button {
	display: flex;
	position: absolute;
	bottom: 0;
	justify-content: flex-start;
	align-items: stretch;
	right: 1.7vw;
	padding: 0.49vw;
	top: 0vw;
}

.navbar-action-search .form .search-button .search-icon i {
	font-size: 1vw;
}

.new-msg-logo{
	font-size: 2.2vw;
}

.notification-action {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 12%;
}

.notification-action > div {
	/* padding: 0.5vw; */
	cursor: pointer;
}

.noNotify{
	margin-left: 2%;
}

.notification-action .add-button button.add {
	/* background-color: var(--light-grey-one); */
	/* border: 1px solid var(--close-button); */
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 2.5vw;
	height: 2.5vw;
}

.notification-action .add-button button.add i {
	font-size: 2vw;
	/* color: var(--close-button); */
}

.notification-action img {
	width: 2.5vw;
}

.notifyList .user-image {
	width: 3vw;
	height: 3vw;
	border-radius: 50%;
}

.notification-action .profile-display {
	overflow: hidden;
	width: 2.2vw;
	height: 2.2vw;
	border-radius: 50%;
}

.notification-action .profile-display img.user-image {
	max-width: 100%;
	width: 2.5vw;
	height: 2.5vw;
}
.notification-action .fa-bars,
.notification-action .fa-search,
.notification-action .fa-plus-circle {
	font-size: 2.5vw;
	display: none;
}

.toolbar {
	position: fixed;
	top: 4vw;
	width: 100%;
	/* left: 25vw; */
	/* z-index: 999; */
	z-index: 9;
}

.toolbar .toolbar-container {
	background-color: var(--light-grey);
	border: 1px solid var(--white);
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* width: 47vw; */
	padding: 0.3vw 2.5vw;
	border-radius: 0.3vw;
	width: 50%;
	margin: 0 auto;
}

.toolbar .toolbar-container .toolbar-content > img {
	width: 2.4vw;
}

.toolbar .toolbar-container .toolbar-content > p {
	margin-bottom: 0;
}

.toolbar .toolbar-container .toolbar-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.toolbar-content-a {
	margin-top: 0.3vw;
}
/* login-header page end */

.dropdown {
	padding: 0 !important;
	height: 4vw;
	display: flex;
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
	padding: 0 !important;
	background-color: transparent !important;
	border-color: transparent !important;
}

.btn-check:focus + .btn-success,
.btn-success:focus,
.btn-success {
	border-color: transparent !important;
	background-color: transparent !important;
	box-shadow: unset !important;
}
.left-sidebar-div,
.right-sidebar-div {
	display: block;
}

.dropdown-item {
	padding: 0.5vw 2vw;
	font-size: 1.24vw;
	font-weight: 600;
}

.dropdown button {
	padding: 0 !important;
}

.dropdown-menu.show {
	background-color: #c4c4c4;
}

.dropdown-menu {
	min-width: unset !important;
	border-radius: 0.3vw !important;
}

.dropdown-toggle::after {
	display: none !important;
}

/* navbar-login end */

.notifyList {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	/* display: block; */
	font-size: 1.24vw;
	display: flex;
	grid-gap: 3%;
	gap: 3%;
	align-items: centers;
}

.responseBtns {
	display: flex;
	gap: 2%;
	padding: 0 5% 5%;
}

.responseBtns button {
	font-size: 1.24vw;
	padding: 2px 10px 2px !important;
}

.bell-notification {
	position: relative;
}

.bell-notification:hover .dropdown-content {
	display: block;
}

.bell-notification a:hover,
.bell-notification a:focus,
.bell-notification a:active {
	background-color: #e9ecef;
}

.notiUserName {
	font-weight: bold;
	font-size: 1.2vw;
}

.notiUserName img {
	margin-right: 2%;
	border-radius: 50%;
}

/* .dropdown-content a{
	display: flex;
	gap: 3%;
} */

.toolbar-content-a .fa-home {
	font-size: 2.2vw;
}
*/ .google_serach_icon {
	font-size: 1vw;
}

.userNameMsg {
	display: flex;
	align-items: baseline;
}


.within_serach {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	margin-left: 1vw;
}

.within_serach label {
	padding-left: 5px;
}

.message-notification {
	position: relative;
}

.notiCount {
	position: absolute;
	color: #fff;
	background-color: #000;
	border-radius: 50%;
	height: 1.5vw;
	width: 1.5vw;
	vertical-align: middle;
	text-align: center;
	right: -10%;
	font-size: 1vw;
}

#notiDropdown {
	min-width: 500px !important;
	max-height: 60vh;
	overflow: auto;
	height: auto;
}

/* media queries from 900px */
@media screen and (max-width: 900px) {
	.navbar {
		height: auto;
	}
	.navbar,
	.footer {
		padding: 1vw 2vw;
	}
	.navbar .navbar-logo {
		width: 15vw;
	}
	.navbar .navbar-logo img {
		width: 100%;
	}
	.google_serach_icon {
		font-size: 2vw;
	}
	.navbar .navbar-action-buttons .login-button {
		margin-right: 2vw;
	}

	/* after login navbar */
	.navbar-action-search .form .caret {
		top: 30%;
		left: 4%;
	}
	.navbar-action-search .form .caret i,
	.navbar-action-search .form .search-button i,
	.navbar-action-search .form .search-button .search-icon i {
		font-size: 2vw;
	}

	.navbar-action-search .form input {
		width: 25vw;
		padding: 1vw 3vw;
		border-radius: 3px;
		font-size: 2vw;
		height: auto;
	}
	.navbar .navbar-action-search .SearReset{
		padding: 1vw 1vw;
		margin-left: 10px;
	}
	.navbar-action-search .form .search-button {
		right: 0%;
		padding: 1vw;
	}
	.navbar-action-search .form .search-icon {
		padding: 0;
	}
	.notification-action {
		width: unset;
	}
	.message-notification {
		width: 4vw;
		margin-right: 2vw;
	}
	.notifyList .user-image {
		width: 5vw;
		border-radius: 50%;
		height: 5vw;
	}
	.responseBtns button {
		font-size: 1.5vw;
	}
	.notification-action .profile-display {
		width: 4vw;
		height: 4vw;
		margin-right: 2vw;
	}
	.notiCount {
		height: 2.5vw;
		width: 2.5vw;
		right: 22%;
		font-size: 1.5vw;
		padding-top: 4%;
	}
	.dropdown-item {
		font-size: 1.8vw;
	}
	.dropdown-menu.show {
		padding: 1vw;
	}

	/* toolbar */
	.toolbar {
		top: 8vw;
	}
	.toolbar .toolbar-container {
		border: 2px solid var(--white);
		padding: 2vw 3vw;
		border-radius: 3px;
		width: 65%;
		box-sizing: content-box;
	}
	.toolbar-content-a {
		margin-top: 0vw;
	}
	.toolbar-content-a .fa-home {
		font-size: 3vw;
	}
	.toolbar .toolbar-container .toolbar-content > img {
		width: 4vw;
	}

	.notification-action img {
		width: 4vw;
		height: 4vw;
	}

	#notiDropdown {
		min-width: 300px !important;
	}
	.navbar-action-search .within_serach label {
		font-size: 2vw;
	}

	.new-msg-logo{
		font-size: 4vw;
	}
	
}

@media screen and (max-width: 599px) {
	/* .navbar{
		height: 9vw;
	}	 */
	.navbar,
	.footer {
		padding: 2vw 2vw;
	}
	.navbar .navbar-logo {
		width: 20vw;
	}
	.navbar .navbar-logo img {
		/* width: 15vw; */
		width: 100%;
	}
	.navbar .navbar-action-buttons .login-button {
		margin-right: 2vw;
	}
	.navbar-action-search .form input {
		padding: 2vw 8vw;
		font-size: 3vw;
		width: 100%;
	}
	.navbar-action-search .form input::placeholder {
		font-size: 3vw;
	}
	.navbar-action-search .form .caret i {
		font-size: 3vw;
	}
	.navbar-action-search .form .search-button .search-icon i {
		font-size: 3vw;
	}
	.notification-action img {
		width: 5.2vw;
		height: 5.2vw;
	}
	.notification-action {
		justify-content: center;
		align-items: center;
		width: unset;
		line-height: 2;
	}
	/* .notification-action {
		width: 33vw;
	} */

	.dropdown {
		height: auto;
	}

	.notification-action .fa-bars,
	.notification-action .fa-search,
	.notification-action .fa-plus-circle {
		display: block;
		font-size: 4.5vw;
		margin-left: 2vw;
	}
	.left-sidebar-div,
	.right-sidebar-div {
		display: none;
	}
	.google_serach_icon {
		font-size: 3vw;
	}
	.toolbar .toolbar-container {
		width: 100%;
		padding: 3vw 0 2vw 0;
		border: none;
		border-radius: 0;
		justify-content: space-evenly;
	}
	.toolbar {
		top: 12vw;
	}
	.notification-action .profile-display {
		width: 4.6vw;
		height: 4.6vw;
		margin-right: 0;
	}
	.navbar-action-search {
		display: none;
	}
	.toolbar .toolbar-container .toolbar-content > img {
		width: 5vw;
	}
	.toolbar .toolbar-container .toolbar-content > p {
		font-size: 2.5vw;
	}
	.notiCount {
		font-size: 2vw;
		height: 3vw;
		width: 3vw;
		right: -6%;
	}
	.dropdown-item {
		font-size: 2.4vw;
	}
	.notifyList .userNameMsg .notiUserName {
		font-size: 2.7vw;
	}
	.notifyList .userNameMsg span {
		font-size: 2.5vw;
	}
	.notifyList div:not(.userNameMsg) {
		width: 15vw;
	}
	.notifyList .user-image {
		width: 10vw;
		height: 10vw;
	}
	.responseBtns {
		padding: 2% 5% 2%;
	}
	.responseBtns button {
		font-size: 2.5vw;
	}
	.message-notification {
		width: 100%;
		margin-right: 0vw;
		margin-left: 2vw;
	}
	.notification-action > div {
		margin-left: 2vw;
	}

	.new-msg-logo{
		font-size: 5vw;
	}
}
@media screen and (max-width: 499px) {
	
	.notification-action img {
		width: 5.9vw;
		height: 5.9vw;
	}
	.notification-action .fa-bars,
	.notification-action .fa-plus-circle {
		font-size: 6.1vw;
		/* margin-right: 2VW; */
	}
	.google_serach_icon {
		font-size: 3.1vw;
	}
	.notification-action .profile-display {
		width: 5.8vw;
		height: 5.8vw;
		margin-right: 0;
	}
	
	.toolbar {
		top: 12vw;
	}
	
	.toolbar .toolbar-container .toolbar-content > img {
		width: 6vw;
	}
	.toolbar .toolbar-container .toolbar-content > p {
		font-size: 2.7vw;
	}
	.notiCount {
		font-size: 2vw;
		height: 3vw;
		width: 3vw;
		right: 1%;
	}
	.dropdown-item {
		font-size: 2.8vw;
	}
	.notifyList .userNameMsg .notiUserName {
		font-size: 3.3vw;
	}
	.notifyList .userNameMsg span {
		font-size: 3.1vw;
	}
}
@media screen and (max-width: 399px) {
	
	.notification-action img {
		width: 6.5vw;
		height: 6.5vw;
	}
	.google_serach_icon {
		font-size: 3.8vw;
	}
	.notification-action .profile-display {
		width: 6.2vw;
		height: 6.2vw;
		margin-right: 0;
	}

	.toolbar {
		top: 12vw;
	}
	
	.toolbar .toolbar-container .toolbar-content > img {
		width: 6.5vw;
	}
	.toolbar .toolbar-container .toolbar-content > p {
		font-size: 3.1vw;
	}
	.notiCount {
		font-size: 2.7vw;
		height: 4vw;
		width: 4vw;
		right: -8%;
	}
	.dropdown-item {
		font-size: 3.1vw;
	}
	.notifyList .userNameMsg .notiUserName {
		font-size: 4.2vw;
	}
	.notifyList .userNameMsg span {
		font-size: 4.1vw;
	}
	.notifyList {
		display: block;
	}
}

@media screen and (max-width: 350px) {
	.toolbar {
		top: 13vw;
	}
}
