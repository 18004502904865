.success-section{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15vw 17vw 7vw;
}

.success-section img{
    width: 200px;
    height: 200px;
}

.success-section-header{
    text-align: center;
}

.success-section .success-section-header h1{
    font-size: 2.22vw;
    font-weight: bold;
    color: var(--blue);
    margin-bottom: 2vw;
}