/* software-page start */
.softwares-container .softwares-check {
	position: absolute;
	right: 0vw;
}

.softwares-container {
	/* margin: 15vw 9vw 15vw 9.2vw; */
	margin: 11vw 9vw 15vw 9.2vw;
}

.softwares-container .software-box-container {
	border-left: none;
	margin-left: 0vw;
}

.softwares-container .software-box-container .software-boxes {
	/* padding: 0vw 0vw 4vw 0vw; */
	padding: 0vw 0vw 6vw 0vw;
}

.software-boxes .software-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	/* grid-template-rows: repeat(4, 23.2vw); */
}

.software-boxes-content .software-info .software-content h2 span {
	color: var(--close-button);
}

.software-container .software-boxes-content{
	padding-top: 2.5vw;
}

.software-boxes-content
	.software-info
	.software-content
	h2.truncate
	span
	a.my-anchor-css-class {
	color: var(--red);
}

.software-boxes-content .software-image img {
	
	height: auto;
	width: 100%;
	object-fit: cover;
	
}

.software-boxes-content .software-info {
	padding: 1vw 0vw 0vw 1vw;
}

.software-boxes-content .software-info .software-content {
	padding-right: 1vw;
}
/* software-page end */

@media (max-width: 900px) {
	.softwares-container {
		margin: 24vw 12vw 15vw 12vw;
	}

	.product-container .product-suppliers label {
		font-size: 2vw;
	}
	.product-container .product-suppliers input[type="checkbox"] + label:before {
		width: 3vw;
		height: 3vw;
	}
	.product-container
		.product-suppliers
		input[type="checkbox"]:checked
		+ label:after {
		top: 0.2vw;
		right: 1vw;
		width: 0.8vw;
		height: 1.9vw;
		border-width: 0 0.4vw 0.4vw 0;
	}
	.software-boxes .software-container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 3vw;
		margin-top: 4vw;
	}
	.software-boxes-content .software-info {
		padding: 2vw 0vw 2vw 1vw;
	}
}

@media screen and (max-width: 599px) {
	.softwares-container {
		margin: 34vw 4vw 15vw 4vw;
	}
	.product-container .product-suppliers label {
		font-size: 3vw;
	}
	.product-container .product-suppliers input[type="checkbox"] + label:before {
		width: 4vw;
		height: 4vw;
	}
	.product-container
		.product-suppliers
		input[type="checkbox"]:checked
		+ label:after {
		top: 0.5vw;
		right: 1.4vw;
		width: 1.2vw;
		height: 2.9vw;
		border-width: 0 0.6vw 0.6vw 0;
	}
	.software-boxes .software-container {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 4vw;
		margin-top: 5vw;
	}
}
