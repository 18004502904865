.link-tag {
	color: var(--black) !important;
	text-decoration: underline !important;
	word-break: break-word;
}

.link-header {
	margin-top: 2vw;
	margin-bottom: 2vw;
}

.like-pannel-container .header {
	cursor: pointer;
}

/* feed-page start */
.left-sidebar::-webkit-scrollbar,
.right-sidebar::-webkit-scrollbar {
	display: none;
	scrollbar-width: none; /* for Firefox */
}

.feed-section .left-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	z-index: 1;
	top: 10vw;
	left: 0;
	bottom: 0;
	padding: 1vw 0vw 18vw 3vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
}

.feed-section .left-sidebar .chat-arrow {
	position: absolute;
	top: 0vw;
	right: 2vw;
}

.feed-section .left-sidebar .chat-arrow img {
	width: 2vw;
}

.edit-profile-content .edit-profile {
	display: flex;
	/* justify-content: space-between; */
	gap: 5%;
	align-items: center;
	margin-bottom: 2vw;
	cursor: pointer;
}

.edit-profile-content .edit-profile .profile-content {
	border-radius: 50%;
	overflow: hidden;
	width: 4vw;
	height: 4vw;
	border: 0;
}

.edit-profile-content .edit-profile .profile-content img {
	/* width: 100%; */
	width: 4vw;
	height: 4vw;
}

.edit-profile-content .edit-profile .profile-name {
	width: 13vw;
}

.edit-profile-content .edit-profile .profile-name h1 {
	margin-bottom: 0;
}

.edit-profile-content .edit-profile .profile-name .name-edit {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 4%;
	margin-bottom: 0.4vw;
}

.edit-profile-content .edit-profile .profile-name .name-edit h1 {
	margin-bottom: 0;
	font-weight: 600;
}

.edit-profile-content .edit-profile .profile-name .name-edit img {
	width: 1.5vw;
}

.edit-profile-content .profile-contacts {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 4%;
	margin-bottom: 1.5vw;
	cursor: pointer;
}

.edit-profile-content .profile-contacts .profile-contact-icon img {
	width: 2vw;
}

.edit-profile-content .profile-contacts h2 {
	margin-bottom: 0;
	font-weight: bold;
	color: var(--medium-grey);
}

.edit-profile-content .profile-contacts .profile-contact-number {
	position: relative;
	left: 0vw;
}

.edit-profile-content .profile-contacts .profile-number {
	left: 0vw;
}

.homepageChatBox{
	width: 100%;
	min-height: 45vh;
    height: 45vh;
	padding: 2vw 2vw 0vw !important;
	text-align: left;
}

.your-posts-container {
	margin-top: 5vw;
}

.your-posts-container .your-post-accordion,
.your-posts-container .pannel-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5%;
}

.your-post-accordion .view-icon {
	align-self: flex-start;
}

.your-posts-container .your-post-accordion .view-icon img,
.your-posts-container .pannel-content .pannel-icon img {
	width: 2vw;
}

.view-accordion-content form input {
	position: relative;
	width: 100%;
	padding: 0.5vw 0.8vw;
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	font-size: 1.2vw;
}

.view-accordion-content form input::placeholder {
	color: var(--close-button);
}

.view-accordion-content form input:focus {
	outline: none;
}

.view-accordion-content form .form-button {
	position: relative;
}

.view-accordion-content form .form-button button {
	position: absolute;
	top: -2.8vw;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0;
	background-color: transparent;
}

.view-accordion-content form .form-button button i {
	font-size: 1vw;
}

.textComment{
	width: 74%;
    border: none;
    border-radius: 0.3vw;
}

.textComment:focus-visible{
    border: none;
    border-radius: 0.3vw;
}
/* accordion start */

.accordion-item {
	border: 0;
}

.accordion-body {
	padding: 0;
}

.accordion-button {
	padding: 0;
	font-size: 1.1vw;
	color: var(--black);
	font-weight: bold;
	align-items: flex-start;
	justify-content: flex-start;
}

.accordion-button:not(.collapsed) {
	color: var(--black);
	background-color: unset;
	box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
	background-image: unset;
	transform: rotate(-180deg);
}

.accordion-button:focus {
	z-index: unset;
	border-color: none;
	outline: 0;
	box-shadow: none;
}

.accordion-button::after {
	flex-shrink: 0;
	content: "\f078";
	font-family: "Font Awesome 5 Free";
	width: unset;
	height: unset;
	margin-left: 2.19vw;
	transition: transform 0.2s ease-in-out;
	background-image: unset;
}

/* accordion end */

/* select bootstrap start */
.custom-select {
	background-color: #fff;
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 1.5vw 0;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 1;
}

.custom-select:hover {
	border-color: #999;
}

.custom-select::before {
	position: absolute;
	top: 0;
	right: 0;
	display: none;
}

.custom-select select {
	background-color: transparent;
	border: 0 none;
	box-shadow: none;
	color: var(--close-button);
	display: block;
	font-size: 1.1vw;
	line-height: normal;
	margin: 0;
	padding: 0.5vw 0.8vw;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.custom-select select::-ms-expand {
	display: none; /* to ie 10 */
}

.custom-select select:focus {
	outline: none;
}

.custom-select .select-caret {
	position: absolute;
	top: 0;
	right: 1vw;
	bottom: 0;
	z-index: -1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom-select .select-caret i {
	font-size: 1vw;
	color: var(--close-button);
}
/* select bootstrap end */

.view-accordion-content .show-post {
	position: relative;
	margin-top: 1.5vw;
}

.view-accordion-content .show-post .view-color-box {
	position: absolute;
	display: flex;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	width: 2.2vw;
	height: 2.2vw;
	top: -0.2vw;
	left: 1.9vw;
}

.view-accordion-content .show-post .post-form-group {
	display: flex;
	justify-content: center;
	align-items: center;
}

.view-accordion-content .show-post .post-form-group .post-check {
	justify-content: flex-start;
}

.view-accordion-content .show-post .post-form-group input[type="checkbox"] {
	display: none;
	cursor: pointer;
}

.view-accordion-content .show-post .post-form-group label.info-text {
	font-size: 1.2vw;
	font-weight: bold;
	color: var(--close-button);
	display: flex;
	justify-content: center;
	align-items: center;
}

.view-accordion-content .show-post .post-form-group label.label-text {
	position: relative;
	left: -3vw;
}

.view-accordion-content .show-post .post-form-group label.label-text-one {
	left: -3.2vw;
}

.view-accordion-content
	.show-post
	.post-form-group
	input[type="checkbox"]:checked
	+ .label-text:after {
	top: 0.3vw;
	left: 0.6vw;
}

.view-accordion-content
	.show-post
	.post-form-group
	input[type="checkbox"]:checked
	+ .label-text-one:after {
	top: 0.5vw;
	left: 1vw;
}

.view-accordion-content .show-post .post-form-group label.text-gvr {
	text-transform: uppercase;
}

.view-accordion-content
	.show-post
	.post-form-group
	input[type="checkbox"]
	+ label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--close-button);
	border: 1px solid var(--close-button);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 1vw;
	margin-left: 0vw;
	width: 1.5vw;
	height: 1.5vw;
	z-index: 2;
}

.view-accordion-content
	.show-post
	.post-form-group
	input[type="checkbox"]:checked
	+ label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.3vw;
	left: 2.9vw;
	width: 0.4vw;
	height: 1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	transform: rotate(45deg);
	z-index: 2;
}

.view-accordion-content
	.show-post
	.post-check
	input[type="checkbox"]
	+ label:before {
	background-color: var(--grey);
	border: 1px solid var(--grey);
	margin-right: 1vw;
	margin-left: 0vw;
	width: 2.2vw;
	height: 2.2vw;
}

.your-posts-container .pannel-container {
	margin-top: 2vw;
}

.your-posts-container .pannel-content {
	align-items: center;
	margin-bottom: 1.5vw;
}

.your-posts-container .pannel-content .pannel-name h2 {
	margin-bottom: 0;
	font-weight: bold;
	color: var(--black);
}

.feed-section .right-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	/* position: absolute; */
	z-index: 1;
	top: 9vw;
	right: 0;
	bottom: 0;
	padding: 1vw 3vw 18vw 0vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
}

.topSpace .overflowModal{
	height: 35vw;
	justify-content: flex-start;
	overflow-y: auto;
	margin-top: 0;
}

@media screen and (max-width: 900px){
	.topSpace .overflowModal {
		height: 55vw;
	}
}

@media screen and (max-width: 599px){
	.topSpace .overflowModal {
		height: auto;
	}
}

.right-sidebar .components-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 1.5vw;
	gap: 8%;
	cursor: pointer;
}

.right-sidebar .components-content .component-image img {
	width: 2vw;
}

.right-sidebar .components-content .component-info h2 {
	margin-bottom: 0;
	font-weight: bold;
	transition: all 0.3s ease-in-out;
}

.right-sidebar .components-content .component-info h2.black-header {
	color: var(--black);
}

.right-sidebar .components-content .component-info h2.grey-header {
	color: var(--medium-grey);
}

.right-sidebar .components-content .component-info h2.grey-header:hover {
	color: var(--black);
}

.right-sidebar .active-users {
	margin-top: 5vw;
}

/* .active-users {
	overflow-y: scroll;
} */

.right-sidebar .active-users .active-users-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8%;
	margin-bottom: 1.5vw;
}

.right-sidebar .active-users .active-users-content .user-image {
	border-radius: 50%;
	overflow: hidden;
	width: 3vw;
	height: 3vw;
}

.right-sidebar .active-users .active-users-content .user-image img {
	width: 100%;
	object-fit: cover;
	height: 100%;
}

.right-sidebar .active-users .active-users-content .user-content h2,
.right-sidebar .active-users h2 {
	font-weight: bold;
	margin-bottom: 0;
	word-break: break-all;
}

.user-content {
	width: 10vw;
}

.active-users-content .active_user_list {
	width: 100%;
}

.active-users-content .user-content{
	cursor: pointer;
}

.right-sidebar .active-users h2 {
	margin-bottom: 2vw;
}

.right-sidebar .active-users .active-users-content .user-content h2 span {
	text-transform: uppercase;
}

/* post-section start */
.post-section {
	/* margin: 13.5vw 25vw 0; */
	margin: 11vw 25vw 0;
}

.post-section-container a {
	color: unset;
	text-decoration: none;
}

.post-section-container .new-post {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	padding: 1.5vw;
	width: 100%;
}

.post-section-container .new-post .post-field,
.post-section-container .new-post .post-pannel,
.post-section-container .new-post .post-pannel .post-pannel-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0.5vw;
}

.post-section-container .new-post .post-field {
	gap: 2%;
}

.post-section-container .new-post .post-field .field-profile,
.posting-content .profile-content .post-info .post-profile,
.comment-box .comment-giving-box .user-comment .comment-profile,
.comment-box .comment-giving-box .user-comment .reply-profile,
.posting-content .add-comment-msg .msg-profile {
	border-radius: 50%;
	overflow: hidden;
	width: 3vw;
	height: 3vw;
	border: 0;
}

.post-section-container .new-post .post-field .field-profile img,
.posting-content .profile-content .post-info .post-profile img,
.comment-box .comment-giving-box .user-comment .comment-profile img,
.comment-box .comment-giving-box .user-comment .reply-profile img,
.posting-content .add-comment-msg .msg-profile img,
.edit-profile-content .edit-profile .profile-content img,
.posting-content .profile-content .post-info .post-profile img {
	max-width: 100%;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.post-section-container .new-post .post-field .form-field input,
.posting-content .add-comment-msg .msg-field input {
	padding: 0.6vw 1.5vw;
	border-radius: 0.3vw;
	font-size: 1.1vw;
	width: 37vw;
}

.post-section-container .new-post .post-field .form-field input::placeholder,
.posting-content .add-comment-msg .msg-field input::placeholder {
	color: var(--close-button);
	font-size: 1.2vw;
}

.post-section-container .new-post .post-pannel {
	justify-content: space-around;
	padding: 0 0 0 3.2vw;
	margin-bottom: 0;
}

.post-section-container .new-post .post-pannel .post-pannel-content {
	margin-bottom: 0;
}


.post-pannel .post-pannel-content .pannel-content p {
	margin-left: 0.3vw;
	color: var(--close-button);
}

.posting-container {
	width: 100%;
	margin-top: 1.5vw;
	padding-bottom: 4.5vw;
}
.loader-loading{
	padding-bottom: 5vw !important;
}

.posting-content {
	padding: 1.5vw;
	background-color: var(--post-background);
	border-radius: 0.3vw;
	margin: 1.5vw 0;
	padding-bottom: 0;
}

.posting-content .profile-content,
.posting-content .profile-content .post-info {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.posting-content .profile-content .post-info {
	justify-content: flex-start;
	align-items: center;
	gap: 3%;
	width: 100%;
}

.posting-content .profile-content .post-info .post-profile {
	width: 3vw;
	height: 3vw;
	align-self: flex-start;
}

.cross-icon-header{
	cursor: not-allowed !important;
}

.posting-content .profile-content .post-info .post-content .post-header h2 {
	font-weight: bold;
}

.posting-content .profile-content .post-info .post-content .post-header h2,
.posting-content .profile-content .post-info .post-content .posted h3 {
	margin-bottom: 0.2vw;
}

.posting-content .profile-content .post-info .post-content .posted {
	color: var(--close-button);
}

.posting-content .profile-content .menu-dots img {
	width: 0.4vw;
	cursor: pointer;
}

.posting-content .profile-text {
	margin: 1vw -1vw 1vw 0;
}

.posting-content .profile-text .profile-subtext h2.stack-header {
	margin-bottom: 2vw;
}

.posting-content .profile-text .profile-subtext h2.bold-header {
	font-weight: bold;
	margin-bottom: 0.8vw;
}

.posting-content .profile-text .profile-subtext h2.truncate span {
	color: var(--black);
}

.posting-content
	.profile-text
	.profile-subtext
	h2.truncate
	span
	a.my-anchor-css-class {
	color: var(--red);
}

.posting-content .feeds-container {
	/* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1vw; */
	/* width: 100%; */
	/* margin-top: 2vw; */
	margin-bottom: 1.5vw;
}

.posting-content .feeds-container .feeds-content {
	display: grid;
	/* grid-template-columns: repeat(2, 0fr); */
	grid-template-columns: 1fr 1fr;
	grid-gap: 1vw;
	width: 100%;
}

.posting-content .feeds-container .single-feed-img {
	display: block;
}

.posting-content .feeds-container .feeds-content img {
	/* width: 21vw; */
	width: 100%;
	cursor: pointer;
}

.posting-content .feeds-container .feeds-second {
	display: grid;
	/* grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 14vw); */
	grid-gap: 1.2vw;
}

.posting-content .feeds-container .feeds-second .feeds-content img {
	width: 21.2vw;
	cursor: pointer;
}

.posting-content .like-pannel {
	border-top: 0.15vw solid var(--light-grey);
	/* border-bottom: 0.15vw solid var(--light-grey); */
	padding: 1vw 0;
}

.posting-content .like-pannel.next-post-pannel {
	border-bottom: 0;
}

.posting-content .like-pannel .like-pannel-container,
.posting-content .like-pannel .like-pannel-container .like-pannel-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.posting-content .like-pannel .like-pannel-container .like-pannel-content {
	justify-content: flex-start;
	gap: 8%;
	width: 100%;
}

.posting-content .like-pannel .like-pannel-container .like-contents {
	width: 75%;
}

.posting-content .like-pannel .like-pannel-container .comment-pannel-content {
	width: 115%;
	transition: all 0.3s ease-in-out;
}

.like-pannel-container .like-pannel-content .like-icon img,
.email-icon-button button img {
	width: 1.7vw;
	cursor: pointer;
}

.like-pannel-container .like-pannel-content .like-icon i {
	font-size: 1.7vw;
	color: var(--close-button);
	cursor: pointer;
}


.like-icon {
	display: flex;
	align-items: center;
	gap: 10%;
}

.like-icon .icon-content {
	word-break: unset;
}

.icon-content .header {
	line-height: 1;
}

.like-pannel-container .like-pannel-content .icon-content.like-number {
	cursor: pointer;
}

.like-pannel-container .like-pannel-content .icon-content h2,
.email-icon-button .email-text h3,
.posting-content .comments-section .comments-text h2 {
	margin-bottom: 0;
	color: var(--close-button);
}

.email-icon-button {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.email-icon-button button {
	background-color: transparent;
	padding: 0;
}

.posting-content .comments-section .comments-text {
	padding: 1vw 0;
	border-bottom: 0.15vw solid var(--light-grey);
}

.comment-box {
	padding: 1.5vw 0 0.5vw 0;
}

.comment-box .comment-giving-box,
.comment-box .comment-giving-box .user-comment,
.posting-content .add-comment-msg {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 3%;
}

.posting-content .add-comment-msg {
	display: block;
	gap: 3%;
}

.comment-box .comment-giving-box .user-comment,
.posting-content .add-comment-msg {
	gap: 3%;
	width: 100%;
}

.posting-content .add-comment-msg {
	margin-top: 1.6vw;
	padding-bottom: 2vw;
}

.posting-content .add-comment-msg.add-comment-reply {
	display: none;
}

.posting-content .add-comment-msg.add-comment-reply.open {
	/* display: flex; */
	padding-left: 6vw;
	/* align-items: flex-start; */
	display: block;
}

.posting-content .add-comment-msg.add-comment-reply .msg-profile img {
	width: 3vw;
	height: 3vw;
	max-width: 100%;
}

.posting-content .add-comment-msg.add-comment-reply .msg-profile {
	width: 3vw;
	height: 3vw;
}

.posting-content .add-comment-msg.add-comment-reply .msg-field {
	/* width: 72%; */
	width: 100%;
}

.posting-content .add-comment-msg.add-comment-reply .msg-field input {
	width: 100%;
}

.comment-box .comment-giving-box .user-comment .comment-profile {
	border: none;
	width: 3.3vw;
	height: 3vw;
}

.user-comment .comment-giving-container {
	width: 100%;
}

.comment-box .comment-giving-box .user-comment .comment-profile img {
	width: 100%;
	height: 100%;
}

.comment-box .comment-giving-box .user-comment .comment-giving {
	background-color: var(--white);
	border-radius: 0.3vw;
	padding: 0.7vw 1.5vw;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 1vw;
}

.user-comment .comment-giving .user .user-name h3,
.user-comment .comment-giving .user .user-reply h3,
.user-comment .comment-giving .reply-timing p {
	margin-bottom: 0;
	white-space: pre-line;
}

.user-comment .comment-giving .user .user-name h3 {
	font-weight: bold;
	margin-bottom: 0.5vw;
}

.comment-box .reply-container {
	display: flex;
}

.comment-box .reply-container .display-reply {
	cursor: pointer;
}

.comment-box .reply-container .display-reply p,
.comment-box .reply-container .reply-count p {
	margin: 0.5vw 0 0 0;
	position: relative;
	left: 6.5vw;
}

.comment-box .reply-container .reply-count p {
	left: 7.5vw;
}

.comment-box .comment-giving-box .user-comment .reply-profile {
	border: none;
	width: 3vw;
	height: 3vw;
}

.comment-box .comment-giving-box .user-comment .reply-profile img {
	width: 3vw;
	height: 3vw;
}

.comment-box ul.give-reply-box {
	list-style-type: none;
	padding-left: 6vw;
	margin-top: 1vw;
	display: none;
	margin-bottom: 0;
}

.comment-box ul.give-reply-box.open {
	display: block;
}

.comment-box .comment-giving-box .user-comment .reply-giving {
	width: 90%;
}

.posting-content .add-comment-msg .msg-profile {
	border: none;
}

.posting-content .add-comment-msg .msg-field input {
	padding: 1vw 1.5vw;
}

.posting-content .pexit-feeds {
	grid-template-columns: repeat(1, 1fr);
}

.posting-content .pexit-feeds .pexit-content img {
	width: 43.4vw;
	cursor: pointer;
}

/* post-section end */

/* hover effect start */
.post-pannel .post-pannel-content .pannel-content p,
.right-sidebar .components-content .component-info h2,
.edit-profile-content .profile-contacts h2 {
	transition: all 0.3s ease-in-out;
}

.post-pannel .post-pannel-content .pannel-content p:hover,
.right-sidebar .components-content .component-info h2:hover,
.edit-profile-content .profile-contacts h2:hover {
	color: var(--black);
}
/* hover effect end */

/* feed-page end */

.post-media-section {
	text-align: left;
	color: var(--white);
}

.srv-validation-message {
	text-align: left;
	color: #c40000;
	padding-bottom: 1vw;
}

.posting-content .comment-container {
	display: none;
}

.posting-content .comment-container.open {
	border-top: 0.15vw solid var(--light-grey);
	display: block;
	transition: all 0.3s ease-in-out;
}

/* like modal css start */
.like-modal-container.open::-webkit-scrollbar {
	display: none;
	scrollbar-width: none; /* for Firefox */
}
.like-modal-container {
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.51) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	backdrop-filter: blur(5px);
	margin: 0;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s ease-in-out;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
	z-index: 99;
}

.like-modal-container.open {
	opacity: 1;
	visibility: visible;
	padding-top: 14vw;
	padding-bottom: 0vw;
	overflow-y: scroll;
	transition: 0.3s ease-in-out;
}

.like-adjust-modal {
	height: 100% !important;
}

.like-modal {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5%;
	width: 100%;
	height: 100%;
	height: 100%;
	margin-bottom: 2vw;
}

.like-modal .user-name h1 {
	font-weight: bold;
	color: var(--white);
	margin-bottom: 1vw;
}

.like-modal .user-profile {
	border-radius: 50%;
	overflow: hidden;
	width: 4vw;
	height: 4vw;
}

.like-modal .user-profile img {
	max-width: 100%;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
/* like modal css end */

/* menu-container css start */
.posting-content .profile-content .menu-dots {
	transition: all 0.3s ease;
}
.posting-content .profile-content .menu-container {
	position: relative;
}

.posting-content .profile-content .menu-container .user-menu-content {
	background-color: var(--post-background);
	position: absolute;
	border: 0.1vw solid var(--close-button);
	padding: 0.5vw;
	right: 1vw;
	top: 0vw;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	width: 9vw;
	display: none;
}

.posting-content .profile-content .menu-container .user-menu-content.open {
	opacity: 1;
	display: block;
}

.posting-content .profile-content .menu-container .user-menu-content h2 {
	color: var(--close-button);
	font-weight: bold;
	cursor: pointer;
}

.posting-content .profile-content .menu-container .user-menu-content h2.edit {
	margin-bottom: 1vw;
}
/* menu-container css end */

/* message modal css start */
.message-modal {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: flex-start;
	padding: 4vw;
	background-color: #8a8989;
	width: 45%;
	border-radius: 0.3vw;
	position: relative;
}

.msg-content-fields {
	width: 100%;
}

.message-modal h1 {
	font-weight: bold;
	color: var(--white);
}

.message-modal h1 span {
	color: var(--black);
}

.message-modal form textarea.msg-description {
	padding: 2vw;
	border-radius: 0.5vw;
	width: 100%;
}

.message-modal form textarea.msg-description:focus {
	outline: none;
}

.login-content-fields form button.msg-button {
	background-color: var(--white) !important;
	color: var(--medium-grey) !important;
	width: 12vw !important;
	padding: 1vw 2vw !important;
	margin-top: 2vw;
}

.login-content-fields form button.msg-button:hover {
	background-color: var(--medium-grey) !important;
	color: var(--white) !important;
}

.login-content-fields form button.msg-button h2 {
	font-weight: bold;
}
/* message modal css end */

/* delete modal start  */
.delete-modal {
	height: 53% !important;
	width: 50%;
}

.contact-modal.open {
	padding-top: 5vw !important;
}

.creategroup-modal .creategroup-content.delete-buttons-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6%;
}

.creategroup-modal .creategroup-content.delete-buttons-container button {
	width: 14vw;
	padding: 1vw 1.5vw;
	color: var(--close-button);
}

.creategroup-modal .creategroup-content.delete-buttons-container button h2 {
	font-weight: bold;
}

.creategroup-modal
	.creategroup-content.delete-buttons-container
	button.cancel-button:hover {
	background-color: var(--light-grey);
}

.creategroup-modal
	.creategroup-content.delete-buttons-container
	button.delete-button {
	background-color: var(--dark-grey);
	color: var(--white);
}

.creategroup-modal
	.creategroup-content.delete-buttons-container
	button.delete-button:hover {
	background-color: var(--black);
	color: var(--white);
}
/* delete modal end  */

/* change password */
.forgot-modal {
	height: auto !important;
}

/* invite friends start */
.invite-modal {
	width: 55vw !important;
}
.creategroup-modal .creategroup-content.invite-friends-modal {
	width: 95%;
}

.contact-container {
	display: flex;
	justify-content: center;
	align-items: baseline;
	text-align: left;
	width: 100%;
}

.creategroup-modal .creategroup-content .contact-container label.name {
	width: 20vw;
}

.creategroup-modal .creategroup-content .contact-container label.attachmentTag {
	margin-top: 1vw;
}

.creategroup-modal .creategroup-content .attachmentListDiv{
	width: 100%;
	display: flex;
	flex-direction: column;
}

.profile-subtext .content-css h1 {
	font-size:1.2vw;
	white-space: pre-line;
}

.content-css span{
	white-space: pre-line;
}

.staticEditTitle{
	background-color: #fff;
    border-radius: 0.3vw;
    padding: 1vw;
    text-align: left;
}

.staticEditDesc{
	background-color: #fff;
    margin-top: 2%;
    border-radius: 0.3vw;
    padding: 1vw;
    text-align: left;
}

@media screen and (max-width: 900px) {
	.profile-subtext .content-css h1 {
		font-size: 1.8vw;
	}
	.textComment{
		height: 7vh;
    	font-size: 2vw;
		width: 100%;
	}
	
}

@media screen and (max-width: 599px) {
	.profile-subtext .content-css h1 {
		font-size: 2.6vw;
	}

	.textComment{
		height: 10vh;
		font-size: 3vw;
		width: 100%;
	}
}

.contact-container .attachmentListDiv .attachmentList{
	width: 3vw;
    margin-right: 2%;
}

.tox-tinymce {
	width: 100%;
	border-radius: 0.3vw !important;
	margin-bottom: 0.5vw;
}

.creategroup-modal .creategroup-content form button.invite-button {
	width: 10vw;
}
/* invite friends end */

.document_image {
	max-width: 60px;
}

.sendEmailCont .srv-validation-message {
	text-align: center;
}

.CommentCont {
	width: 100%;
}

.userCont {
	display: flex;
	justify-content: space-between;
}

/* Comment Box Start */

.comment_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.comment_box .profile-pic {
	width: 4vw;
	height: 4vw;
	border-radius: 50% !important;
	margin-right: 1% !important;
}

.open .comment_box .reply-pic {
	/* width: 3.5vw;
    height: 2.5vw; */
    object-fit: cover;
}

.comment_box .cmnt-pic {
	width: 4vw;
	height: 3.5vw;
}

.comment_box .profile-pic {
	/* width: 4vw; */
	width: 3vw;
	height: 3vw;
	object-fit: cover;
}

.comment_box > .input-msg {
	padding: 0.5vw !important;
	margin-right: 1% !important;
}

.comment_box > .input-file {
	width: fit-content !important;
	cursor: pointer;
}
.comment_box > .input-file .input_file_element {
	display: none !important;
}

.comment_box > .send-button {
	font-size: 1.2vw;
	cursor: pointer;
	margin-left: 1%;
}

.selected_media_file {
	border: 1px solid #ccc;
	padding: 1%;
	margin-top: 1%;
}
/* Comment Box End */

.image-area {
	position: relative;
	width: 8vw;
	background: transparent;
	margin-right: 5%;
	margin-top: 3%;
}

.image-area img {
	max-width: 100%;
	height: auto;
	cursor: pointer;
}

.remove-image {
	position: absolute;
	padding: 3px 7px 5px;
	top: -11px;
	right: -11px;
	border-radius: 10em;
	text-decoration: none;
	font: 700 15px/15px sans-serif;
	background: #555;
	border: 1px solid #fff;
	color: #fff;
	box-shadow: 0 2px 6px rgb(0 0 0 / 50%), inset 0 2px 4px rgb(0 0 0 / 30%);
	text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
	transition: background 0.5s;
}

.remove-image:hover {
	background: #e54e4e;
	padding: 3px 7px 5px;
	top: -11px;
	right: -11px;
}

.remove-image:active {
	background: #e54e4e;
	top: -10px;
	right: -11px;
}

.d_preview_images {
	justify-content: flex-start;
	width: 100%;
	margin-top: 1%;
	flex-wrap: wrap;
}

.input-group .btn {
	position: relative;
	z-index: 2;
	display: flex;
}

.sendModalHeader .noteleft{
	text-align: left;
}
.posting-container  .posting-content  .profile-text .profile-subtext  .truncate .content-css span  h1 {
	font-size: 1.2vw;
	background-color: transparent;
	color: black;
	font-family: "Roboto",sans-serif;
}

.profile-text .profile-subtext h2.truncate p span.read-or-hide {
	color: var(--red);
	cursor: pointer;
}


/* postpage padding css from top section and two sidebars */
@media screen and (max-width: 900px) {
	.posting-container  .posting-content  .profile-text .profile-subtext  .truncate .content-css span  h1 {
		font-size: 1.8vw;
		background-color: transparent;
		color: black;
		font-family: "Roboto",sans-serif;
	}
	.post-section {
		margin: 23vw 25vw 0;
	}
	.feed-section .left-sidebar {
		padding: 13vw 0vw 24vw 2vw;
	}
	.feed-section .right-sidebar {
		padding: 13vw 2vw 24vw 0vw;
	}
	.edit-profile-content .edit-profile .profile-content {
		width: 6vw;
		height: 6vw;
	}
	.edit-profile-content .edit-profile .profile-content img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit {
		justify-content: space-between;
		gap: 4%;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit img,
	.edit-profile-content .profile-contacts .profile-contact-icon img {
		width: 100%;
		height: 100%;
	}

	.edit-profile-content .edit-profile .profile-name .name-edit img {
		width: 2vw;
	}

	.edit-profile-content .edit-profile .profile-name h1 {
		font-size: 2.5vw;
	}

	.edit-profile-content .edit-profile .profile-name .name-edit h1 {
		font-size: 2.1vw;
	}

	.edit-profile-content .profile-contacts {
		gap: 4%;
		margin-bottom: 2vw;
		width: 100%;
	}
	.edit-profile-content .profile-contact-icon,
	.your-posts-container .pannel-content .pannel-icon,
	.right-sidebar .components-content .component-image,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon {
		width: 3vw;
	}
	.edit-profile-content .profile-contacts .profile-contact-number,
	.edit-profile-content .profile-contacts .profile-number {
		left: 0vw;
	}
	.your-posts-container {
		margin-top: 6vw;
	}
	.accordion-button {
		font-size: 2vw;
	}
	.accordion-button::after {
		margin-left: 1vw;
	}
	.your-post-accordion .view-icon {
		width: 4vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 100%;
		height: auto;
	}
	.right-sidebar .components-content .component-image img{
		width: 2.5vw;
	}
	.post-section-container .new-post {
		padding: 1.8vw;
		width: 100%;
	}

	.post-section-container .new-post .post-field .field-profile,
	.posting-content .profile-content .post-info .post-profile,
	.comment-box .comment-giving-box .user-comment .comment-profile,
	.comment-box .comment-giving-box .user-comment .reply-profile,
	.posting-content .add-comment-msg .msg-profile {
		width: 5vw;
		height: 5vw;
	}

	.comment-box .comment-giving-box .user-comment .comment-profile {
		width: 6vw;
	}

	.post-section-container .new-post .post-field .field-profile {
		height: 5vw;
		max-width: 11vw;
		width: 5vw;
		display: flex;
		align-items: flex-start;
		max-height: 5vw;
		justify-content: center;
	}

	.post-section-container .new-post .post-field .field-profile img,
	.posting-content .profile-content .post-info .post-profile img,
	.comment-box .comment-giving-box .user-comment .comment-profile img,
	.comment-box .comment-giving-box .user-comment .reply-profile img,
	.posting-content .add-comment-msg .msg-profile img {
		max-width: 100%;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.post-section-container .new-post .post-field .form-field {
		width: 40.5vw;
	}
	.post-section-container .new-post .post-field .form-field input,
	.posting-content .add-comment-msg .msg-field input {
		padding: 0.8vw 1.5vw;
		font-size: 2vw;
		width: 100%;
	}
	.post-section-container .new-post .post-field .form-field input::placeholder,
	.posting-content .add-comment-msg .msg-field input::placeholder {
		font-size: 2vw;
	}
	.post-section-container .new-post .post-field,
	.post-section-container .new-post .post-pannel,
	.post-section-container .new-post .post-pannel .post-pannel-content {
		margin-bottom: 2vw;
	}
	.post-section-container .new-post .post-pannel,
	.post-section-container .new-post .post-pannel .post-pannel-content {
		margin-bottom: 0;
	}
	.post-section-container .new-post .post-pannel {
		justify-content: space-between;
		padding: 0 0 0 0vw;
	}
	.posting-container {
		width: 100%;
		margin-top: 2.5vw;
		padding-bottom: 25vw;
	}
	.posting-content {
		padding: 2vw;
		margin: 3vw 0;
		padding-bottom: 1vw;
	}
	.posting-content .profile-content .menu-dots {
		width: 0.8vw;
	}
	.posting-content .profile-content .menu-dots img {
		width: 100%;
		height: auto;
	}
	.posting-content .profile-content .menu-container .user-menu-content {
		padding: 1vw;
		width: 15vw;
	}
	.posting-content .profile-text {
		margin: 2vw 0vw 2vw 0;
	}
	.custom-select .select-caret i {
		font-size: 1.7vw;
		color: var(--close-button);
	}
	.custom-select select {
		font-size: 1.7vw;
		padding: 1vw 0.8vw;
		padding-right: 3vw;
		padding-left: 0.5vw;
	}
	.right-sidebar .components-content {
		margin-bottom: 2.5vw;
	}
	.posting-content .profile-text .profile-subtext h2.bold-header {
		font-weight: bold;
		margin-bottom: 1vw;
	}
	.like-pannel-container .like-pannel-content .icon-content h2,
	.email-icon-button .email-text h3,
	.posting-content .comments-section .comments-text h2 {
		margin-left: 0.5vw;
	}
	.posting-content .like-pannel .like-pannel-container,
	.posting-content .like-pannel .like-pannel-container .like-pannel-content {
		gap: 5%;
	}
	.posting-content .like-pannel .like-pannel-container .like-pannel-content,
	.like-icon {
		gap: 0%;
	}
	.like-pannel-container .like-pannel-content .like-icon i {
		font-size: 2.5vw;
	}
	.like-pannel-container .like-pannel-content .like-icon img,
	.email-icon-button button img {
		width: 2.5vw;
	}
	.like-pannel-container .like-pannel-content .icon-content h2,
	.email-icon-button .email-text h3,
	.posting-content .comments-section .comments-text h2 {
		margin-left: 0.5vw;
	}
	.email-icon-button .email-text h3 {
		margin-left: 0;
	}

	.email-icon-button button {
		height: auto !important;
	}
	.open .comment_box .reply-pic {
		width: 8vw !important;
		height: 4.2vw;
		object-fit: cover;
	}
	.comment_box .profile-pic {
		/* width: 4vw !important;
		height: 4vw;
		margin-right: 2% !important; */
		width: 9vw !important;
		height: 5vw;
		margin-right: 2% !important;
	}
	.comment_box > .input-msg {
		padding: 0.8vw 1.5vw !important;
	}
	.comment_box > .input-file {
		width: 14% !important;
	}
	.comment_box > .input-file.img {
		width: 100%;
	}
	.comment_box > .send-button {
		width: unset !important;
		font-size: 2vw;
		height: auto !important;
	}
	.posting-content .comments-section .comments-text {
		padding: 1.5vw 0;
	}
	.comment-box {
		padding: 2vw 0;
	}

	/* modals */
	.contact-modal.open {
		padding-top: 18vw !important;
	}
	.invite-modal {
		width: 70% !important;
	}
	.forgot-modal {
		height: auto !important;
	}

	.image-area {
		width: 15vw;
	}
}
@media screen and (max-width: 599px) {
	.posting-container  .posting-content  .profile-text .profile-subtext  .truncate .content-css span  h1 {
		font-size: 2.6vw;
		background-color: transparent;
		color: black;
		font-family: "Roboto",sans-serif;
	}
	.post-section {
		margin: 28vw 1vw 0;
	}
	.post-section-container .new-post .post-field .field-profile {
		/* max-height: 5vw;
    max-width: 11vw; */
		max-width: 80vw;
		max-height: 70vw;
		height: 7vw;
		width: 7vw;
	}
	.post-section-container .new-post .post-field .form-field {
		width: 82.5vw;
	}
	.post-section-container .new-post .post-field .form-field input,
	.posting-content .add-comment-msg .msg-field input {
		padding: 0.8vw 1.5vw;
		/* font-size: 2.6vw; */
		font-size: 3vw;
		width: 100%;
	}
	.post-section-container .new-post .post-field .form-field input::placeholder,
	.posting-content .add-comment-msg .msg-field input::placeholder {
		font-size: 2.4vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.right-sidebar .components-content .component-image img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 5.8vw;
		height: auto;
	}
	.edit-profile-content .profile-contact-icon,
	.your-posts-container .pannel-content .pannel-icon,
	.right-sidebar .components-content .component-image,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon {
		width: 6vw;
	}
	.post-section-container .new-post .post-field .field-profile,
	.posting-content .profile-content .post-info .post-profile,
	.comment-box .comment-giving-box .user-comment .comment-profile,
	.comment-box .comment-giving-box .user-comment .reply-profile,
	.posting-content .add-comment-msg .msg-profile {
		width: 9vw;
		height: 9vw;
	}
	.comment-box .comment-giving-box .user-comment .comment-profile {
		width: 9vw;
		height: 8vw;
	}
	.post-pannel .post-pannel-content .pannel-content p {
		font-size: 2.4vw;
	}
	.posting-content .profile-content .post-info .post-content .post-header h2 {
		font-size: 3vw;
	}
	.posting-content .profile-content .post-info .post-content .posted h3 {
		font-size: 2.2vw;
	}
	.posting-content .profile-text .profile-subtext h2.bold-header,
	.posting-content .profile-text .profile-subtext h2.link-header,
	.posting-content .profile-text .profile-subtext h2.truncate span {
		font-size: 2.6vw;
	}
	.posting-content .profile-content .menu-dots {
		width: 1vw;
	}
	.posting-content .profile-content .menu-container .user-menu-content {
		padding: 2vw;
		width: fit-content;
		right: 3vw;
		white-space: nowrap;
	}
	.posting-content .profile-content .menu-container .user-menu-content h2 {
		font-size: 2.5vw;
	}
	.posting-content .profile-content .menu-container .user-menu-content h2.edit {
		margin-bottom: 2vw;
	}
	.like-pannel-container .like-pannel-content i.far,
	.like-pannel-container .like-pannel-content h2.header {
		font-size: 2.6vw;
	}
	.like-pannel-container .like-pannel-content .like-icon img,
	.email-icon-button button img {
		width: 2.8vw;
	}
	
	.feed-section .left-sidebar {
		right: 0;
		left: auto;
		background-color: var(--grey);
		z-index: 10;
		/* top: 20vw; */
		width: 60%;
		padding: 7vw 4vw 20vw 4vw;
	}
	.feed-section .right-sidebar {
		width: 60%;
		z-index: 10;
		padding: 7vw 4vw 20vw 4vw;
		background-color: var(--grey);
	}
	.email-icon-button .email-text h3 {
		font-size: 1.9vw;
	}
	.user-comment .comment-giving .user .user-name h3 {
		font-size: 2.4vw;
	}
	.comment-box .reply-container .display-reply p,
	.comment-box .reply-container .reply-count p {
		font-size: 2.8vw;
	}

	/* sidebar changes css start */
	.right-sidebar .active-users .active-users-content {
		margin-bottom: 3.5vw;
	}
	.right-sidebar .active-users .active-users-content .user-image,
	.edit-profile-content .edit-profile .profile-content {
		width: 9vw;
		height: 9vw;
	}
	.right-sidebar .active-users .active-users-content .user-image img {
		object-fit: cover;
	}
	.edit-profile-content .edit-profile {
		justify-content: flex-start;
		margin-bottom: 5vw;
		gap: 6%;
	}
	.edit-profile-content .edit-profile .profile-name {
		width: 67%;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit {
		justify-content: flex-start;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit img {
		width: 4vw;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit h1 {
		font-size: 3vw;
	}
	.accordion-item {
		background-color: transparent;
	}
	.your-post-accordion .view-icon {
		width: 6vw;
		height: 6vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 100%;
		height: 100%;
	}
	.right-sidebar .components-content .component-image img{
		width: 5vw;
	}
	.accordion-button {
		font-size: 3vw;
		background-color: transparent;
	}
	/* sidebar changes css end */

	/* dashboard changes start */
	.posting-content .like-pannel .like-pannel-container,
	.posting-content .like-pannel .like-pannel-container .like-pannel-content {
		gap: 0%;
	}
	.posting-content .like-pannel .like-pannel-container .like-contents,
	.posting-content .like-pannel .like-pannel-container .comment-pannel-content,
	.posting-content .like-pannel .like-pannel-container .like-pannel-content {
		width: unset;
	}
	.email-icon-button {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.email-icon-button .email-text h3 {
		font-size: 2.6vw;
	}
	.posting-content .like-pannel {
		padding: 2vw 0;
	}
	.like-pannel-container .like-pannel-content .icon-content h2,
	.email-icon-button .email-text h3,
	.posting-content .comments-section .comments-text h2,
	.email-icon-button .email-text h3 {
		margin-left: 1vw;
	}
	.like-pannel-container .like-pannel-content .like-icon i {
		font-size: 3.6vw;
	}
	.like-pannel-container .like-pannel-content .like-icon img,
	.email-icon-button button img,
	.like-pannel-container .like-pannel-content .like-icon i {
		width: 4vw;
	}
	.comment-box .comment-giving-box .user-comment .comment-giving {
		padding: 1vw 2vw;
		margin-bottom: 1vw;
	}

	.user-comment .comment-giving .user .user-name h3 {
		margin-bottom: 2vw;
	}
	.posting-content {
		padding-bottom: 3vw;
	}
	.comment_box > .input-msg {
		padding: 2vw 2vw !important;
		margin-right: 2% !important;
	}
	.comment_box > .input-file {
		width: unset !important;
	}
	.comment_box > .send-button {
		font-size: 3vw;
		padding: 1vw 2vw;
	}
	.user-comment .comment-giving .user .user-name h3,
	.user-comment .comment-giving .user .user-reply h3,
	.user-comment .comment-giving .reply-timing p {
		font-size: 3vw;
	}
	.comment-box .reply-container .display-reply p,
	.comment-box .reply-container .reply-count p {
		margin: 1vw 0 0 0;
		left: 6.5vw;
	}
	.comment_box > .send-button {
		margin-left: 2%;
	}
	.open .comment_box .reply-pic {
		width: 10vw !important;
		height: 6.5vw;
		object-fit: cover;
	}
	.comment_box .profile-pic {
		/* width: 6vw !important;
		height: 6vw; */
		width: 11.5vw !important;
		height: 8vw;
		margin-right: 2% !important;
	}
	.user-content {
		width: 50%;
	}
	.custom-select select {
		font-size: 3vw;
		padding: 1vw 1vw;
	}
	.your-posts-container .pannel-container {
		margin-top: 3vw;
	}
	.your-posts-container .pannel-content {
		margin-bottom: 2.5vw;
	}
	.your-posts-container .pannel-content .pannel-name h2 {
		font-size: 3vw;
	}
	.edit-profile-content .profile-contacts {
		margin-bottom: 3vw;
	}
	.post-pannel .post-pannel-content .pannel-content p,
	.right-sidebar .components-content .component-info h2,
	.edit-profile-content .profile-contacts h2 {
		font-size: 3vw;
	}
	/* dashboard changes end */

	/* post job page sidebar changes start */
	.image-area {
		background: transparent;
		width: 25vw !important;
	}
	/* post job page sidebar changes end */
}
@media screen and (max-width: 499px) {
	.post-section {
		margin: 30vw 1.4vw 0;
	}
	.post-section-container .new-post .post-field .field-profile {
		height: 7vw;
		max-width: 110vw;
		width: 7vw;
		max-height: 50vw;
	}
	.post-section-container .new-post .post-field .form-field {
		width: 82.5vw;
	}
	.post-section-container .new-post .post-field .form-field input,
	.posting-content .add-comment-msg .msg-field input {
		padding: 1.6vw 1.5vw;
		/* font-size: 2.5vw; */
		width: 100%;
	}
	.post-section-container .new-post .post-field .form-field input::placeholder,
	.posting-content .add-comment-msg .msg-field input::placeholder {
		font-size: 2.8vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.right-sidebar .components-content .component-image img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 6vw;
		height: auto;
	}
	.edit-profile-content .profile-contact-icon,
	.your-posts-container .pannel-content .pannel-icon,
	.right-sidebar .components-content .component-image,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon {
		width: 7.5vw;
	}
	.post-pannel .post-pannel-content .pannel-content p {
		font-size: 2.9vw;
	}
	.posting-content .profile-content .post-info .post-content .post-header h2 {
		font-size: 3.4vw;
	}
	.posting-content .profile-content .post-info .post-content .posted h3 {
		font-size: 2.6vw;
	}
	.posting-content .profile-text .profile-subtext h2.bold-header,
	.posting-content .profile-text .profile-subtext h2.link-header,
	.posting-content .profile-text .profile-subtext h2.truncate span {
		font-size: 2.8vw;
	}
	.posting-content .profile-content .menu-dots {
		width: 1.1vw;
	}
	.posting-content .profile-content .menu-container .user-menu-content h2 {
		font-size: 2.8vw;
	}
}
@media screen and (max-width: 399px) {
	.post-section {
		margin: 32vw 1.7vw 0;
	}
	.post-section-container .new-post .post-field .field-profile {
		height: 8vw;
		max-width: 110vw;
		width: 8vw;
		max-height: 50vw;
	}
	.post-section-container .new-post .post-field .form-field {
		width: 80.5vw;
	}
	.post-section-container .new-post .post-field .form-field input,
	.posting-content .add-comment-msg .msg-field input {
		padding: 1.9vw 1.5vw;
		/* font-size: 2.5vw; */
		width: 100%;
	}
	.post-section-container .new-post .post-field .form-field input::placeholder,
	.posting-content .add-comment-msg .msg-field input::placeholder {
		font-size: 3.1vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.right-sidebar .components-content .component-image img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 6.3vw;
		height: auto;
	}
	.post-pannel .post-pannel-content .pannel-content p {
		font-size: 3vw;
	}
	.posting-content .profile-content .post-info .post-content .post-header h2 {
		font-size: 3.6vw;
	}
	.posting-content .profile-content .post-info .post-content .posted h3 {
		font-size: 2.9vw;
	}
	.posting-content .profile-text .profile-subtext h2.bold-header,
	.posting-content .profile-text .profile-subtext h2.link-header,
	.posting-content .profile-text .profile-subtext h2.truncate span {
		font-size: 3.15vw;
	}
	.posting-content .profile-content .menu-dots {
		width: 1.2vw;
	}

	.posting-content .profile-content .menu-container .user-menu-content h2 {
		font-size: 3.1vw;
	}

	.user-comment .comment-giving .user .user-name h3 {
		font-size: 3.3vw;
	}
}