.ad_layout01{
    display: grid;
    grid: 
    "title title"   
    "image description";
    grid-auto-columns: 1fr;
    padding: 1.5vw;
}

.layout01_outer_div > div > h6{
    font-size: 1vw;
}

.layout01-title{
    grid-area: title;
    text-align: center;
    background-color: var(--medium-grey);
    padding: 1.5vw;
    margin-bottom: 2vw ;
}
.layout01-title h3{
    font-size: 1.3vw;
    color: #ffffff;
}

.layout01-image{
    grid-area: image;
    border-right: black 0.5px solid;
    padding-right:1.5vw ;
}

.layout01-image img{
    width: 100%;
}

.layout01-des{
    padding-left:1.5vw ;
    grid-area: description;
    font-size: 1.1vw;
}

.layout01-des .content-css{
    text-align: left;
}

.layout01_outer_div{
    border: black solid 0.2vw;
    border-radius: 1vw;
    padding: 1vw;
    margin-bottom: 1.5vw;
}

.profile-content .menu-dots img {
    width: 0.4vw;
    cursor: pointer;
}

.profile-content .menu-dots {
    transition: all 0.3s ease;
}

.profile-content .menu-container {
    position: relative;
}

.profile-content .menu-container .user-menu-content h2 {
    color: var(--close-button);
    font-weight: bold;
    cursor: pointer;
    font-size: 1.2vw;
    margin-bottom: 0;
}

.profile-content .menu-container .user-menu-content {
    background-color: var(--post-background);
    position: absolute;
    border: 0.1vw solid var(--close-button);
    padding: 0.5vw;
    right: 1vw;
    top: 0vw;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 9vw;
    display: none;
}

.profile-content .menu-container .user-menu-content.open {
    opacity: 1;
    display: block;
}

.layout01_outer_div .profile-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@media screen and (max-width: 900px){
    .layout01-des{
        padding-left:1.5vw ;
        font-size: 1.8vw;
    }
    .layout01_outer_div > div > h6{
        font-size: 1.3vw;
    }
    .layout01-title h3{
        font-size: 2.5vw;
    }

    .layout01_outer_div .profile-content .menu-dots img {
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 599px){
    .layout01-des{
        padding-left:1.5vw ;
        font-size: 2.5vw;
    }
    .layout01_outer_div > div > h6{
        font-size: 2vw;
    }
    .layout01-title h3{
        font-size:2.5vw;
    }
    .layout01_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.5vw;
    }

    .layout01_outer_div .profile-content .menu-container .user-menu-content {
        padding: 2vw;
        width: -moz-fit-content;
        width: fit-content;
        right: 3vw;
        white-space: nowrap;
    }
}

@media screen and (max-width: 499px){
    .layout01_outer_div .profile-content .menu-dots {
        width: 1.1vw;
    }

    .layout01_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.8vw;
    }
}

@media screen and (max-width: 399px){
    .layout01_outer_div .profile-content .menu-dots {
        width: 1.2vw;
    }

    .layout01_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 3.1vw;
    }
}
.layout01_outer_div .ad_layout01 .layout01-des span a.my-anchor-css-class{
    color: var(--red);
}

.layout01-image-path{
    max-height: 500px;
    object-fit: scale-down;
}