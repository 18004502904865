.creategroup-content .partner-container label {
	color: black;
	width: 20vw;
}

.creategroup-content .partner-container input {
	border: 1px solid #ccc;
	font-size: 1.24vw;
}

.creategroup-content .partner-container input::placeholder {
	font-size: 1.24vw;
}

.creategroup-content .partner_btn {
	width: 30% !important;
	align-self: center;
	margin-top: 2vw;
}

.creategroup-content .partner_btn:hover {
	color: var(--white) !important;
	background-color: var(--medium-grey) !important;
}

.input_div {
	width: 100%;
}

@media screen and (max-width: 900px) {
	.creategroup-content .partner-container input,
	.creategroup-content .partner-container input::placeholder {
		font-size: 2vw;
	}

	.creategroup-content .partner-container label {
		font-size: 2vw;
	}

	#partnersinput {
		width: 100%;
	}

	#partner-message {
		flex-direction: column;
		margin-top: 4vw;
	}
}
@media screen and (max-width: 599px) {
	.creategroup-content .partner-container input,
	.creategroup-content .partner-container input::placeholder {
		font-size: 3.1vw;
	}

	.creategroup-content .partner-container label {
		font-size: 2.8vw;
	}
	.creategroup-content .partner_btn {
		width: fit-content !important;
	}
	#partnersinput {
		width: 100%;
	}
}
