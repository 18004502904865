.bsgpsK div:first-child {
    white-space: unset !important;
}

.rdt_TableCell[data-column-id="3"] {
    line-height: 1.5;
}

.actionBtns {
    font-size: 15px;
    background-color: var(--post-background);
    border: 1px solid var(--grey);
    border-radius: 0.3vw;
    color: var(--close-button);
    font-size: 0.97vw;
    width: 4vw;
    margin: 10px 0px;
    text-align: center;
}

.refBtn {
    font-size: 15px;
}

.rdt_TableCol {
    font-size: 12px;
    font-weight: bold;
}

.rdt_TableCell {
    font-size: 12px;
}

.rdt_TableCol .lnndaO {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
}

.dataTable-container {
    position: relative;
    margin: 15vw 10vw 15vw 10vw;
    padding-bottom: 10vw;
}

.dataTable {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.downloadCsv {
    background-color: var(--black);
    color: var(--white);
    font-weight: bold;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: none;
    border-radius: 0.3vw;
    font-size: 1.1vw;
    padding: 0.3vw 0.8vw;
}

.downloadCsv:hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--black);
}

.downCsvDiv {
    text-align: right;
    margin-bottom: 1%;
}

.contact-container label {
    font-size: 1.5vw;
    font-weight: bold;
    color: var(--white);
}

.contact-container .email {
    word-break: break-all;
}

.contact-container .response {
    margin-bottom: 1vw;
    padding: 0.6vw 1vw;
    border-radius: 0.3vw;
    width: 100%;
    font-size: 1.4vw;
    color: var(--black);
    position: relative;
    transition: 0.1s ease-out;
    background-color: #fff;
}

.login-content-fields {
    align-items: unset;
}

.ticketListContainer.open {
    padding-top: 0;
}

.reportAssign {
    width: 100%;
    margin-top: 2vw;
}

.allDet {
    margin-right: 1%;
    vertical-align: middle;
}

.close {
    display: none;
}

.HeadingText {
    margin-bottom: 3%;
}

.creategroup-modal .creategroup-content form select {
    background-color: #fff;
}

#createGroups {
    width: 35%;
}

.rdt_TableHeadRow .iPecdQ {
    min-width: 145px;
}

.rdt_TableCol_Sortable .izRDMy {
    white-space: normal;
}

@media (max-width: 900px) {
    .dataTable-container {
        margin: 22vw 10vw 15vw 10vw;
    }

    .actionBtns {
        font-size: 1.8vw;
        width: 8vw;
    }

    .rdt_TableCol {
        font-size: 2vw;
    }

    .rdt_TableCell {
        font-size: 1.8vw;
    }

    .rdt_TableHeadRow .iPecdQ {
        min-width: 155px;
    }

    .downloadCsv {
        font-size: 2vw;
    }

    .modal-container {
        padding: 1vw;
        align-items: center;
    }

    .creategroup-modal .creategroup-content .statusSelect {
        font-weight: normal;
        color: var(--black);
    }
}

@media screen and (max-width: 599px) {
    .dataTable-container {
        margin: 32vw 3vw 15vw 3vw;
        padding-bottom: 20vw;
    }
    .downCsvDiv {
        margin-bottom: 3%;
    }
    .downloadCsv {
        font-size: 3vw !important;
        margin-right: 2%;
    }
    .dataTable {
        margin-bottom: 15vw;
    }
    .iPecdQ,
    .izRDMy,
    .cJMYXl {
        font-size: 3vw !important;
    }
    .actionBtns {
        font-size: 3vw !important;
        width: 15vw;
    }
    .gEuYxO {
        min-height: 3vw;
    }
}
