/* user-page start */
.user-profile-section {
	position: relative;
	height: 100%;
	margin: 0 auto 0 auto;
}

.sidenav {
	height: 100%;
	width: 23%;
	position: fixed;
	z-index: 1;
	top: 12vw;
	left: 0;
	bottom: 0;
	padding: 1vw 1.5vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	border-right: 1px solid var(--light-grey);
}

.sidenav .sidenav-container {
	position: relative;
	margin-top: 4vw;
}

.sidenav .chat-arrow {
	position: absolute;
	top: 1vw;
	right: 2vw;
}

.sidenav .chat-arrow img {
	width: 2vw;
}

.sidenav .sidenav-container .sidenav-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5%;
	padding: 0.9vw 1.5vw;
	cursor: pointer;
}

.sidenav .sidenav-container .sidenav-content.active {
	border: 0.15vw solid #757171;
	border-radius: 0.3vw;
}

.sidenav .sidenav-container .sidenav-content img {
	width: 2vw;
}

.sidenav .sidenav-container .sidenav-content h2 {
	margin-bottom: 0;
	color: var(--black);
	font-weight: bold;
}

.sidenav .sidenav-container .sidenav-content h2.inactive-block {
	color: var(--medium-grey);
}

.user-profile-container {
	height: 100%;
	/* overflow: scroll; */
	margin-left: 26vw;
	margin-right: 12vw;
	/* top: 19vw; */
	top: 15vw;
	position: relative;
}

.user-profile-container .user-profile {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-template-rows: repeat(3, 20vw); */
	grid-template-rows: repeat(3, auto);
	grid-auto-flow: row;
	/* gap: 5%; */
	padding-bottom: 12vw;
}

.user-profile-container .user-profiles {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	justify-content: flex-end;
	align-items: center;
	border-radius: 0.3vw;
	/* padding: 1vw 0; */
	padding: 2vw 1vw;
	margin-bottom: 2vw;
	margin-right: 2vw;
	box-shadow: inset 0px 0px 10px 0px #ababab, -50px -50px 0px -100px #dddddd;
	-webkit-box-shadow: inset 0px 0px 10px 0px #ababab,
		-50px -50px 0px -100px #dddddd;
	-moz-box-shadow: inset 0px 0px 10px 0px #ababab,
		-50px -50px 0px -100px #dddddd;
	-o-box-shadow: inset 0px 0px 10px 0px #ababab, -50px -50px 0px -100px #dddddd;
}

.user-profile-container .user-profiles .user-profile-image {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	width: 4vw;
	height: 4vw;
}

.user-profile-container .user-profiles .user-profile-image img {
	max-width: 100%;
	width: 4vw;
	height: 4vw;
}

.user-profile-container .user-profiles .user-profile-image.blue {
	background-color: var(--blue);
}

.user-profile-container .user-profiles .user-profile-image.purple {
	background-color: var(--purple);
}

.user-profile-container .user-profiles .user-profile-image.golden {
	background-color: var(--golden);
}

.user-profile-container .user-profiles .user-profile-image h2 {
	position: absolute;
	top: 2.6vw;
	left: 2.6vw;
	color: var(--white);
	font-weight: bold;
	margin-bottom: 0;
}

.user-profile-container .user-profiles .user-profile-info {
	text-align: center;
	display: flex;
	flex-direction: column;
	word-break: break-word;
}

.user-profile-container .user-profiles .user-profile-info h2 {
	font-weight: bold;
	margin: 1vw 0;
}

.user-profile-container .user-profiles .user-profile-info h3 {
	color: var(--close-button);
	font-weight: bold;
	margin-bottom: 1vw;
}

.user-profile-container .user-profiles .user-profile-connect button {
	background-color: var(--black);
	color: var(--white);
	font-weight: bold;
	width: 8vw;
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-profile-container .user-profiles .user-profile-connect button.disabled {
	display: none;
}

.user-profile-container .user-profiles .user-profile-connect button:hover {
	background-color: rgba(0, 0, 0, 0.4);
	color: var(--black);
}

.user-profile-container .show-user {
	position: relative;
}

.show-user .show-user-form-group {
	position: absolute;
	top: -5vw;
	right: -8vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.show-user .color-box {
	position: absolute;
	display: flex;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	width: 2.5vw;
	height: 2.5vw;
	top: -5.15vw;
	right: -8.45vw;
}

.show-user .show-user-form-group label.show-user-text {
	font-size: 1.5vw;
	font-weight: bold;
	color: var(--close-button);
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.show-user .show-user-form-group input[type="checkbox"] {
	display: none;
	cursor: pointer;
}

.show-user-form-group input[type="checkbox"] + label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--close-button);
	border: 1px solid var(--close-button);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 0vw;
	margin-left: 1.5vw;
	width: 1.5vw;
	height: 1.5vw;
	z-index: 2;
}

.show-user-form-group input[type="checkbox"]:checked + label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.5vw;
	right: 0.5vw;
	width: 0.4vw;
	height: 1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	transform: rotate(45deg);
	z-index: 2;
}
/* user-page end */

@media screen and (max-width: 900px) {
	.sidenav {
		width: 26%;
		top: 28vw;
		padding: 1vw 2vw;
	}
	.sidenav .sidenav-container {
		margin-bottom: 38vw;
	}
	.user-profile-container {
		margin-left: 30vw;
		margin-right: 10vw;
		top: 28vw;
	}
	.show-user .color-box {
		width: 4vw;
		height: 4vw;
		top: -5.5vw;
		right: -3.8vw;
	}
	.show-user .show-user-form-group {
		top: -5vw;
		right: -3vw;
	}
	.show-user .show-user-form-group label.show-user-text {
		font-size: 2vw;
	}
	.show-user-form-group input[type="checkbox"] + label:before {
		margin-left: 2.5vw;
		width: 2.5vw;
		height: 2.5vw;
	}
	.show-user-form-group input[type="checkbox"]:checked + label:after {
		top: 0.2vw;
		right: 0.8vw;
		width: 0.8vw;
		height: 1.9vw;
		border-width: 0 0.4vw 0.4vw 0;
	}
	.user-profile-container .user-profile {
		display: grid;
		padding-bottom: 15vw;
		grid-template-columns: repeat(2, 1fr);
	}
	.user-profile-container .user-profiles {
		padding: 3vw 3vw;
		margin-bottom: 4vw;
		margin-right: 4vw;
	}
	.user-profile-container .user-profiles .user-profile-connect button,
	.sidenav .chat-arrow img {
		width: 100%;
	}
	.sidenav .chat-arrow {
		width: 3.5vw;
	}
	.sidenav .sidenav-container .sidenav-content {
		gap: 8%;
		padding: 1.5vw 1.5vw;
	}
	.sidenav .sidenav-container .sidenav-content img {
		width: 3vw;
	}
}

/* inactive-block (h2 header) */
.sidenav .sidenav-container .sidenav-content h2.inactive-block {
	color: var(--medium-grey);
}

.user-profile-image {
	text-align: center;
}

@media screen and (max-width: 599px) {
	.sidenav .chat-arrow {
		display: none;
	}
	.sidenav {
		background-color: #fff;
		top: 40vw;
		border-right: 0;
		box-shadow: 0 1rem 10px rgb(0 0 0 / 50%);
		width: 50%;
		padding: 4vw 4vw;
		overflow-y: auto;
		height: auto;
		padding-bottom: 25vw;
	}
	.sidenav .sidenav-container {
		margin-bottom: 0vw;
		margin-top: 0vw;
	}
	.sidenav .sidenav-container .sidenav-content {
		padding: 2.5vw 1.5vw;
	}
	.sidenav .sidenav-container .sidenav-content img {
		width: 6vw;
	}
	.sidenav .sidenav-container .sidenav-content h2 {
		font-size: 3vw;
	}
	.user-profile-container {
		margin-left: 4vw;
		margin-right: 0vw;
		top: 40vw;
	}
	.user-profile-container .user-profiles {
		padding: 5vw 3vw;
	}
	.user-profile-container .user-profiles .user-profile-image {
		width: 10vw;
		height: 10vw;
	}
	.user-profile-container .user-profiles .user-profile-info h2 {
		margin: 3vw 0;
		font-size: 3vw;
	}
	.user-profile-container .user-profiles .user-profile-info h3 {
		margin-bottom: 3vw;
		font-size: 2.5vw;
	}
	.user-profile-container .user-profiles .user-profile-connect button,
	.sidenav .chat-arrow img {
		width: 100%;
		padding: 1vw 5vw;
	}
	.show-user .show-user-form-group label.show-user-text {
		font-size: 3.5vw;
	}
	.show-user .color-box {
		width: 5.2vw;
		height: 5.4vw;
		top: -5vw;
		right: 3.3vw;
	}
	.show-user .show-user-form-group {
		top: -5vw;
		right: 4vw;
	}
	.show-user-form-group input[type="checkbox"] + label:before {
		margin-left: 4vw;
		width: 3.5vw;
		height: 3.5vw;
	}
	.show-user-form-group input[type="checkbox"]:checked + label:after {
		top: 0.7vw;
		right: 1.2vw;
		width: 1vw;
		height: 3vw;
		border-width: 0 0.6vw 0.6vw 0;
	}
	#mycircle-hamburger {
		position: relative;
		top: -6vw;
	}
}
