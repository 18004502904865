@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap);
* {
	box-sizing: border-box;
}
body {
	margin: 0;
	padding: 0;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.main {
	height: 100vh;
}

.scroll4::-webkit-scrollbar {
	width: 0.8vw;
}

.scroll4::-webkit-scrollbar-thumb {
	background: var(--close-button);
	border-radius: 0.5vw;
}

.scroll4::-webkit-scrollbar-track {
	background: #ddd;
	border-radius: 0.5vw;
}

/*global css*/

body {
	font-family: "Roboto", sans-serif;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
	/* word-break: break-word; */
}

:root {
	--grey: #c4c4c4;
	--light-grey: #ccc;
	--light-grey-one: #eaeaea;
	--dark-grey: #565656;
	--medium-grey: #8a8989;
	--black: #000;
	--white: #fff;
	--blue: #0e32b5;
	--red: #ca0d0d;
	--green: #166009;
	--close-button: #757171;
	--post-background: #eee;
	--post-icon: #767676;
	--golden: #9d6b09;
	--purple: #b50ea4;
}

h1 {
	font-size: 1.5vw;  /*1.4vw, 24px*/
	margin-bottom: 0;
}

h2 {
	font-size: 1.2vw; /*1.1vw, 18px*/
	margin-bottom: 0;
}

h3 {
	font-size: 0.97vw; /*14px*/
	margin-bottom: 0;
}

p {
	font-size: 0.83vw; /*12px*/
	font-size: 1vw;
	margin-bottom: 0;
}

button {
	border: none;
	background-color: #fff;
	background-color: var(--white);
	color: #000;
	color: var(--black);
	border-radius: 0.3vw;
	font-size: 1.3vw;
	padding: 0.3vw 0.8vw;
	transition: all 0.3s ease-in-out;
}

button.active {
	background-color: #c4c4c4;
	background-color: var(--grey);
	color: #000;
	color: var(--black);
}

button:focus,
input:focus {
	outline: none;
}

input {
	border: none;
}

a {
	color: unset;
	text-decoration: none;
}

a:hover {
	color: unset;
}

@media screen and (max-width: 900px) {
	h1 {
		font-size: 2.5vw;
	}
	h2 {
		font-size: 1.8vw;
	}
	h3 {
		font-size: 1.6vw;
	}
	p {
		font-size: 1.7vw;
	}
	button {
		border-radius: 3px;
		font-size: 2vw;
		padding: 1vw 2vw;
	}
}

@media screen and (max-width: 599px) {
	h1 {
		font-size: 3.5vw;
	}
	h2 {
		font-size: 2.5vw;
	}
	h3 {
		font-size: 1.8vw;
	}
	p {
		font-size: 2vw;
	}
	button {
		font-size: 3vw;
		padding: 2vw 2vw;
	}
	#mycircle-hamburger,
	#listing-hamburger,
	#hamburger-chat {
		cursor: pointer;
	}
}

#react-select-1-listbox,
#react-select-2-listbox,
#react-select-3-listbox,
#react-select-4-listbox,
#react-select-5-listbox {
	right: 0 !important;
	text-align: left;
	z-index: 99;
}

button {
	cursor: pointer !important;
}

button:disabled,
button[disabled] {
	/* background-color: var(--medium-grey) !important; */
	cursor: not-allowed !important;
}

.postmodal-content button:disabled,
.posting-content button:disabled {
	background-color: #fff !important;
	background-color: var(--white) !important;
}



/* navbar start */
.navbar,
.footer {
	background-color: var(--grey);
	padding: 0vw 2vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navbar {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 99;
	width: 100%;
	height: 4vw;
}

.navbar .navbar-logo {
	cursor: pointer;
}

.navbar .navbar-logo img {
	width: 8vw;
}

.navbar .navbar-action-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar .navbar-action-buttons .login-button {
	margin-right: 0.8vw;
}

.navbar .navbar-action-buttons .login-button button:hover,
.navbar .navbar-action-buttons .join-button button:hover {
	background-color: var(--grey);
}
/* navbar end */

/* navbar-login start */

.navbar-action-search {
	display: block;
	display: flex;
	align-items: center;
}
/* login-header page start */
.navbar-action-search .form {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin-left: 3vw;
}

.navbar-action-search .form .caret {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 28%;
	left: 10%;
	cursor: pointer;
}

.navbar-action-search .form .caret i,
.navbar-action-search .form .search-button i {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5vw;
}

.navbar-action-search .form input {
	border: none;
	width: 90%;
	padding: 0.2vw 4vw;
	border-radius: 0.3vw;
	font-size: 1.4vw;
	height: 3vw;
}

.navbar-action-search .form input:focus {
	outline: none;
}

.navbar-action-search .form input::-webkit-input-placeholder {
	color: var(--grey);
}

.navbar-action-search .form input:-ms-input-placeholder {
	color: var(--grey);
}

.navbar-action-search .form input::placeholder {
	color: var(--grey);
}

.navbar-action-search .form .search-button {
	display: flex;
	position: absolute;
	bottom: 0;
	justify-content: flex-start;
	align-items: stretch;
	right: 1.7vw;
	padding: 0.49vw;
	top: 0vw;
}

.navbar-action-search .form .search-button .search-icon i {
	font-size: 1vw;
}

.new-msg-logo{
	font-size: 2.2vw;
}

.notification-action {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 12%;
}

.notification-action > div {
	/* padding: 0.5vw; */
	cursor: pointer;
}

.noNotify{
	margin-left: 2%;
}

.notification-action .add-button button.add {
	/* background-color: var(--light-grey-one); */
	/* border: 1px solid var(--close-button); */
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 2.5vw;
	height: 2.5vw;
}

.notification-action .add-button button.add i {
	font-size: 2vw;
	/* color: var(--close-button); */
}

.notification-action img {
	width: 2.5vw;
}

.notifyList .user-image {
	width: 3vw;
	height: 3vw;
	border-radius: 50%;
}

.notification-action .profile-display {
	overflow: hidden;
	width: 2.2vw;
	height: 2.2vw;
	border-radius: 50%;
}

.notification-action .profile-display img.user-image {
	max-width: 100%;
	width: 2.5vw;
	height: 2.5vw;
}
.notification-action .fa-bars,
.notification-action .fa-search,
.notification-action .fa-plus-circle {
	font-size: 2.5vw;
	display: none;
}

.toolbar {
	position: fixed;
	top: 4vw;
	width: 100%;
	/* left: 25vw; */
	/* z-index: 999; */
	z-index: 9;
}

.toolbar .toolbar-container {
	background-color: var(--light-grey);
	border: 1px solid var(--white);
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* width: 47vw; */
	padding: 0.3vw 2.5vw;
	border-radius: 0.3vw;
	width: 50%;
	margin: 0 auto;
}

.toolbar .toolbar-container .toolbar-content > img {
	width: 2.4vw;
}

.toolbar .toolbar-container .toolbar-content > p {
	margin-bottom: 0;
}

.toolbar .toolbar-container .toolbar-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.toolbar-content-a {
	margin-top: 0.3vw;
}
/* login-header page end */

.dropdown {
	padding: 0 !important;
	height: 4vw;
	display: flex;
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
	padding: 0 !important;
	background-color: transparent !important;
	border-color: transparent !important;
}

.btn-check:focus + .btn-success,
.btn-success:focus,
.btn-success {
	border-color: transparent !important;
	background-color: transparent !important;
	box-shadow: unset !important;
}
.left-sidebar-div,
.right-sidebar-div {
	display: block;
}

.dropdown-item {
	padding: 0.5vw 2vw;
	font-size: 1.24vw;
	font-weight: 600;
}

.dropdown button {
	padding: 0 !important;
}

.dropdown-menu.show {
	background-color: #c4c4c4;
}

.dropdown-menu {
	min-width: unset !important;
	border-radius: 0.3vw !important;
}

.dropdown-toggle::after {
	display: none !important;
}

/* navbar-login end */

.notifyList {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	/* display: block; */
	font-size: 1.24vw;
	display: flex;
	grid-gap: 3%;
	grid-gap: 3%;
	gap: 3%;
	align-items: centers;
}

.responseBtns {
	display: flex;
	grid-gap: 2%;
	gap: 2%;
	padding: 0 5% 5%;
}

.responseBtns button {
	font-size: 1.24vw;
	padding: 2px 10px 2px !important;
}

.bell-notification {
	position: relative;
}

.bell-notification:hover .dropdown-content {
	display: block;
}

.bell-notification a:hover,
.bell-notification a:focus,
.bell-notification a:active {
	background-color: #e9ecef;
}

.notiUserName {
	font-weight: bold;
	font-size: 1.2vw;
}

.notiUserName img {
	margin-right: 2%;
	border-radius: 50%;
}

/* .dropdown-content a{
	display: flex;
	gap: 3%;
} */

.toolbar-content-a .fa-home {
	font-size: 2.2vw;
}
*/ .google_serach_icon {
	font-size: 1vw;
}

.userNameMsg {
	display: flex;
	align-items: baseline;
}


.within_serach {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	margin-left: 1vw;
}

.within_serach label {
	padding-left: 5px;
}

.message-notification {
	position: relative;
}

.notiCount {
	position: absolute;
	color: #fff;
	background-color: #000;
	border-radius: 50%;
	height: 1.5vw;
	width: 1.5vw;
	vertical-align: middle;
	text-align: center;
	right: -10%;
	font-size: 1vw;
}

#notiDropdown {
	min-width: 500px !important;
	max-height: 60vh;
	overflow: auto;
	height: auto;
}

/* media queries from 900px */
@media screen and (max-width: 900px) {
	.navbar {
		height: auto;
	}
	.navbar,
	.footer {
		padding: 1vw 2vw;
	}
	.navbar .navbar-logo {
		width: 15vw;
	}
	.navbar .navbar-logo img {
		width: 100%;
	}
	.google_serach_icon {
		font-size: 2vw;
	}
	.navbar .navbar-action-buttons .login-button {
		margin-right: 2vw;
	}

	/* after login navbar */
	.navbar-action-search .form .caret {
		top: 30%;
		left: 4%;
	}
	.navbar-action-search .form .caret i,
	.navbar-action-search .form .search-button i,
	.navbar-action-search .form .search-button .search-icon i {
		font-size: 2vw;
	}

	.navbar-action-search .form input {
		width: 25vw;
		padding: 1vw 3vw;
		border-radius: 3px;
		font-size: 2vw;
		height: auto;
	}
	.navbar .navbar-action-search .SearReset{
		padding: 1vw 1vw;
		margin-left: 10px;
	}
	.navbar-action-search .form .search-button {
		right: 0%;
		padding: 1vw;
	}
	.navbar-action-search .form .search-icon {
		padding: 0;
	}
	.notification-action {
		width: unset;
	}
	.message-notification {
		width: 4vw;
		margin-right: 2vw;
	}
	.notifyList .user-image {
		width: 5vw;
		border-radius: 50%;
		height: 5vw;
	}
	.responseBtns button {
		font-size: 1.5vw;
	}
	.notification-action .profile-display {
		width: 4vw;
		height: 4vw;
		margin-right: 2vw;
	}
	.notiCount {
		height: 2.5vw;
		width: 2.5vw;
		right: 22%;
		font-size: 1.5vw;
		padding-top: 4%;
	}
	.dropdown-item {
		font-size: 1.8vw;
	}
	.dropdown-menu.show {
		padding: 1vw;
	}

	/* toolbar */
	.toolbar {
		top: 8vw;
	}
	.toolbar .toolbar-container {
		border: 2px solid var(--white);
		padding: 2vw 3vw;
		border-radius: 3px;
		width: 65%;
		box-sizing: content-box;
	}
	.toolbar-content-a {
		margin-top: 0vw;
	}
	.toolbar-content-a .fa-home {
		font-size: 3vw;
	}
	.toolbar .toolbar-container .toolbar-content > img {
		width: 4vw;
	}

	.notification-action img {
		width: 4vw;
		height: 4vw;
	}

	#notiDropdown {
		min-width: 300px !important;
	}
	.navbar-action-search .within_serach label {
		font-size: 2vw;
	}

	.new-msg-logo{
		font-size: 4vw;
	}
	
}

@media screen and (max-width: 599px) {
	/* .navbar{
		height: 9vw;
	}	 */
	.navbar,
	.footer {
		padding: 2vw 2vw;
	}
	.navbar .navbar-logo {
		width: 20vw;
	}
	.navbar .navbar-logo img {
		/* width: 15vw; */
		width: 100%;
	}
	.navbar .navbar-action-buttons .login-button {
		margin-right: 2vw;
	}
	.navbar-action-search .form input {
		padding: 2vw 8vw;
		font-size: 3vw;
		width: 100%;
	}
	.navbar-action-search .form input::-webkit-input-placeholder {
		font-size: 3vw;
	}
	.navbar-action-search .form input:-ms-input-placeholder {
		font-size: 3vw;
	}
	.navbar-action-search .form input::placeholder {
		font-size: 3vw;
	}
	.navbar-action-search .form .caret i {
		font-size: 3vw;
	}
	.navbar-action-search .form .search-button .search-icon i {
		font-size: 3vw;
	}
	.notification-action img {
		width: 5.2vw;
		height: 5.2vw;
	}
	.notification-action {
		justify-content: center;
		align-items: center;
		width: unset;
		line-height: 2;
	}
	/* .notification-action {
		width: 33vw;
	} */

	.dropdown {
		height: auto;
	}

	.notification-action .fa-bars,
	.notification-action .fa-search,
	.notification-action .fa-plus-circle {
		display: block;
		font-size: 4.5vw;
		margin-left: 2vw;
	}
	.left-sidebar-div,
	.right-sidebar-div {
		display: none;
	}
	.google_serach_icon {
		font-size: 3vw;
	}
	.toolbar .toolbar-container {
		width: 100%;
		padding: 3vw 0 2vw 0;
		border: none;
		border-radius: 0;
		justify-content: space-evenly;
	}
	.toolbar {
		top: 12vw;
	}
	.notification-action .profile-display {
		width: 4.6vw;
		height: 4.6vw;
		margin-right: 0;
	}
	.navbar-action-search {
		display: none;
	}
	.toolbar .toolbar-container .toolbar-content > img {
		width: 5vw;
	}
	.toolbar .toolbar-container .toolbar-content > p {
		font-size: 2.5vw;
	}
	.notiCount {
		font-size: 2vw;
		height: 3vw;
		width: 3vw;
		right: -6%;
	}
	.dropdown-item {
		font-size: 2.4vw;
	}
	.notifyList .userNameMsg .notiUserName {
		font-size: 2.7vw;
	}
	.notifyList .userNameMsg span {
		font-size: 2.5vw;
	}
	.notifyList div:not(.userNameMsg) {
		width: 15vw;
	}
	.notifyList .user-image {
		width: 10vw;
		height: 10vw;
	}
	.responseBtns {
		padding: 2% 5% 2%;
	}
	.responseBtns button {
		font-size: 2.5vw;
	}
	.message-notification {
		width: 100%;
		margin-right: 0vw;
		margin-left: 2vw;
	}
	.notification-action > div {
		margin-left: 2vw;
	}

	.new-msg-logo{
		font-size: 5vw;
	}
}
@media screen and (max-width: 499px) {
	
	.notification-action img {
		width: 5.9vw;
		height: 5.9vw;
	}
	.notification-action .fa-bars,
	.notification-action .fa-plus-circle {
		font-size: 6.1vw;
		/* margin-right: 2VW; */
	}
	.google_serach_icon {
		font-size: 3.1vw;
	}
	.notification-action .profile-display {
		width: 5.8vw;
		height: 5.8vw;
		margin-right: 0;
	}
	
	.toolbar {
		top: 12vw;
	}
	
	.toolbar .toolbar-container .toolbar-content > img {
		width: 6vw;
	}
	.toolbar .toolbar-container .toolbar-content > p {
		font-size: 2.7vw;
	}
	.notiCount {
		font-size: 2vw;
		height: 3vw;
		width: 3vw;
		right: 1%;
	}
	.dropdown-item {
		font-size: 2.8vw;
	}
	.notifyList .userNameMsg .notiUserName {
		font-size: 3.3vw;
	}
	.notifyList .userNameMsg span {
		font-size: 3.1vw;
	}
}
@media screen and (max-width: 399px) {
	
	.notification-action img {
		width: 6.5vw;
		height: 6.5vw;
	}
	.google_serach_icon {
		font-size: 3.8vw;
	}
	.notification-action .profile-display {
		width: 6.2vw;
		height: 6.2vw;
		margin-right: 0;
	}

	.toolbar {
		top: 12vw;
	}
	
	.toolbar .toolbar-container .toolbar-content > img {
		width: 6.5vw;
	}
	.toolbar .toolbar-container .toolbar-content > p {
		font-size: 3.1vw;
	}
	.notiCount {
		font-size: 2.7vw;
		height: 4vw;
		width: 4vw;
		right: -8%;
	}
	.dropdown-item {
		font-size: 3.1vw;
	}
	.notifyList .userNameMsg .notiUserName {
		font-size: 4.2vw;
	}
	.notifyList .userNameMsg span {
		font-size: 4.1vw;
	}
	.notifyList {
		display: block;
	}
}

@media screen and (max-width: 350px) {
	.toolbar {
		top: 13vw;
	}
}

.link-tag {
	color: var(--black) !important;
	text-decoration: underline !important;
	word-break: break-word;
}

.link-header {
	margin-top: 2vw;
	margin-bottom: 2vw;
}

.like-pannel-container .header {
	cursor: pointer;
}

/* feed-page start */
.left-sidebar::-webkit-scrollbar,
.right-sidebar::-webkit-scrollbar {
	display: none;
	scrollbar-width: none; /* for Firefox */
}

.feed-section .left-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	z-index: 1;
	top: 10vw;
	left: 0;
	bottom: 0;
	padding: 1vw 0vw 18vw 3vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
}

.feed-section .left-sidebar .chat-arrow {
	position: absolute;
	top: 0vw;
	right: 2vw;
}

.feed-section .left-sidebar .chat-arrow img {
	width: 2vw;
}

.edit-profile-content .edit-profile {
	display: flex;
	/* justify-content: space-between; */
	grid-gap: 5%;
	gap: 5%;
	align-items: center;
	margin-bottom: 2vw;
	cursor: pointer;
}

.edit-profile-content .edit-profile .profile-content {
	border-radius: 50%;
	overflow: hidden;
	width: 4vw;
	height: 4vw;
	border: 0;
}

.edit-profile-content .edit-profile .profile-content img {
	/* width: 100%; */
	width: 4vw;
	height: 4vw;
}

.edit-profile-content .edit-profile .profile-name {
	width: 13vw;
}

.edit-profile-content .edit-profile .profile-name h1 {
	margin-bottom: 0;
}

.edit-profile-content .edit-profile .profile-name .name-edit {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 4%;
	gap: 4%;
	margin-bottom: 0.4vw;
}

.edit-profile-content .edit-profile .profile-name .name-edit h1 {
	margin-bottom: 0;
	font-weight: 600;
}

.edit-profile-content .edit-profile .profile-name .name-edit img {
	width: 1.5vw;
}

.edit-profile-content .profile-contacts {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 4%;
	gap: 4%;
	margin-bottom: 1.5vw;
	cursor: pointer;
}

.edit-profile-content .profile-contacts .profile-contact-icon img {
	width: 2vw;
}

.edit-profile-content .profile-contacts h2 {
	margin-bottom: 0;
	font-weight: bold;
	color: var(--medium-grey);
}

.edit-profile-content .profile-contacts .profile-contact-number {
	position: relative;
	left: 0vw;
}

.edit-profile-content .profile-contacts .profile-number {
	left: 0vw;
}

.homepageChatBox{
	width: 100%;
	min-height: 45vh;
    height: 45vh;
	padding: 2vw 2vw 0vw !important;
	text-align: left;
}

.your-posts-container {
	margin-top: 5vw;
}

.your-posts-container .your-post-accordion,
.your-posts-container .pannel-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 5%;
	gap: 5%;
}

.your-post-accordion .view-icon {
	align-self: flex-start;
}

.your-posts-container .your-post-accordion .view-icon img,
.your-posts-container .pannel-content .pannel-icon img {
	width: 2vw;
}

.view-accordion-content form input {
	position: relative;
	width: 100%;
	padding: 0.5vw 0.8vw;
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	font-size: 1.2vw;
}

.view-accordion-content form input::-webkit-input-placeholder {
	color: var(--close-button);
}

.view-accordion-content form input:-ms-input-placeholder {
	color: var(--close-button);
}

.view-accordion-content form input::placeholder {
	color: var(--close-button);
}

.view-accordion-content form input:focus {
	outline: none;
}

.view-accordion-content form .form-button {
	position: relative;
}

.view-accordion-content form .form-button button {
	position: absolute;
	top: -2.8vw;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0;
	background-color: transparent;
}

.view-accordion-content form .form-button button i {
	font-size: 1vw;
}

.textComment{
	width: 74%;
    border: none;
    border-radius: 0.3vw;
}

.textComment:focus-visible{
    border: none;
    border-radius: 0.3vw;
}
/* accordion start */

.accordion-item {
	border: 0;
}

.accordion-body {
	padding: 0;
}

.accordion-button {
	padding: 0;
	font-size: 1.1vw;
	color: var(--black);
	font-weight: bold;
	align-items: flex-start;
	justify-content: flex-start;
}

.accordion-button:not(.collapsed) {
	color: var(--black);
	background-color: unset;
	box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
	background-image: unset;
	-webkit-transform: rotate(-180deg);
	        transform: rotate(-180deg);
}

.accordion-button:focus {
	z-index: unset;
	border-color: none;
	outline: 0;
	box-shadow: none;
}

.accordion-button::after {
	flex-shrink: 0;
	content: "\f078";
	font-family: "Font Awesome 5 Free";
	width: unset;
	height: unset;
	margin-left: 2.19vw;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	background-image: unset;
}

/* accordion end */

/* select bootstrap start */
.custom-select {
	background-color: #fff;
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	box-sizing: border-box;
	margin: 1.5vw 0;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 1;
}

.custom-select:hover {
	border-color: #999;
}

.custom-select::before {
	position: absolute;
	top: 0;
	right: 0;
	display: none;
}

.custom-select select {
	background-color: transparent;
	border: 0 none;
	box-shadow: none;
	color: var(--close-button);
	display: block;
	font-size: 1.1vw;
	line-height: normal;
	margin: 0;
	padding: 0.5vw 0.8vw;
	width: 100%;
	box-sizing: border-box;
	-webkit-appearance: none;
	appearance: none;
}

.custom-select select::-ms-expand {
	display: none; /* to ie 10 */
}

.custom-select select:focus {
	outline: none;
}

.custom-select .select-caret {
	position: absolute;
	top: 0;
	right: 1vw;
	bottom: 0;
	z-index: -1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom-select .select-caret i {
	font-size: 1vw;
	color: var(--close-button);
}
/* select bootstrap end */

.view-accordion-content .show-post {
	position: relative;
	margin-top: 1.5vw;
}

.view-accordion-content .show-post .view-color-box {
	position: absolute;
	display: flex;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	width: 2.2vw;
	height: 2.2vw;
	top: -0.2vw;
	left: 1.9vw;
}

.view-accordion-content .show-post .post-form-group {
	display: flex;
	justify-content: center;
	align-items: center;
}

.view-accordion-content .show-post .post-form-group .post-check {
	justify-content: flex-start;
}

.view-accordion-content .show-post .post-form-group input[type="checkbox"] {
	display: none;
	cursor: pointer;
}

.view-accordion-content .show-post .post-form-group label.info-text {
	font-size: 1.2vw;
	font-weight: bold;
	color: var(--close-button);
	display: flex;
	justify-content: center;
	align-items: center;
}

.view-accordion-content .show-post .post-form-group label.label-text {
	position: relative;
	left: -3vw;
}

.view-accordion-content .show-post .post-form-group label.label-text-one {
	left: -3.2vw;
}

.view-accordion-content
	.show-post
	.post-form-group
	input[type="checkbox"]:checked
	+ .label-text:after {
	top: 0.3vw;
	left: 0.6vw;
}

.view-accordion-content
	.show-post
	.post-form-group
	input[type="checkbox"]:checked
	+ .label-text-one:after {
	top: 0.5vw;
	left: 1vw;
}

.view-accordion-content .show-post .post-form-group label.text-gvr {
	text-transform: uppercase;
}

.view-accordion-content
	.show-post
	.post-form-group
	input[type="checkbox"]
	+ label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--close-button);
	border: 1px solid var(--close-button);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 1vw;
	margin-left: 0vw;
	width: 1.5vw;
	height: 1.5vw;
	z-index: 2;
}

.view-accordion-content
	.show-post
	.post-form-group
	input[type="checkbox"]:checked
	+ label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.3vw;
	left: 2.9vw;
	width: 0.4vw;
	height: 1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	z-index: 2;
}

.view-accordion-content
	.show-post
	.post-check
	input[type="checkbox"]
	+ label:before {
	background-color: var(--grey);
	border: 1px solid var(--grey);
	margin-right: 1vw;
	margin-left: 0vw;
	width: 2.2vw;
	height: 2.2vw;
}

.your-posts-container .pannel-container {
	margin-top: 2vw;
}

.your-posts-container .pannel-content {
	align-items: center;
	margin-bottom: 1.5vw;
}

.your-posts-container .pannel-content .pannel-name h2 {
	margin-bottom: 0;
	font-weight: bold;
	color: var(--black);
}

.feed-section .right-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	/* position: absolute; */
	z-index: 1;
	top: 9vw;
	right: 0;
	bottom: 0;
	padding: 1vw 3vw 18vw 0vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
}

.topSpace .overflowModal{
	height: 35vw;
	justify-content: flex-start;
	overflow-y: auto;
	margin-top: 0;
}

@media screen and (max-width: 900px){
	.topSpace .overflowModal {
		height: 55vw;
	}
}

@media screen and (max-width: 599px){
	.topSpace .overflowModal {
		height: auto;
	}
}

.right-sidebar .components-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 1.5vw;
	grid-gap: 8%;
	gap: 8%;
	cursor: pointer;
}

.right-sidebar .components-content .component-image img {
	width: 2vw;
}

.right-sidebar .components-content .component-info h2 {
	margin-bottom: 0;
	font-weight: bold;
	transition: all 0.3s ease-in-out;
}

.right-sidebar .components-content .component-info h2.black-header {
	color: var(--black);
}

.right-sidebar .components-content .component-info h2.grey-header {
	color: var(--medium-grey);
}

.right-sidebar .components-content .component-info h2.grey-header:hover {
	color: var(--black);
}

.right-sidebar .active-users {
	margin-top: 5vw;
}

/* .active-users {
	overflow-y: scroll;
} */

.right-sidebar .active-users .active-users-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 8%;
	gap: 8%;
	margin-bottom: 1.5vw;
}

.right-sidebar .active-users .active-users-content .user-image {
	border-radius: 50%;
	overflow: hidden;
	width: 3vw;
	height: 3vw;
}

.right-sidebar .active-users .active-users-content .user-image img {
	width: 100%;
	object-fit: cover;
	height: 100%;
}

.right-sidebar .active-users .active-users-content .user-content h2,
.right-sidebar .active-users h2 {
	font-weight: bold;
	margin-bottom: 0;
	word-break: break-all;
}

.user-content {
	width: 10vw;
}

.active-users-content .active_user_list {
	width: 100%;
}

.active-users-content .user-content{
	cursor: pointer;
}

.right-sidebar .active-users h2 {
	margin-bottom: 2vw;
}

.right-sidebar .active-users .active-users-content .user-content h2 span {
	text-transform: uppercase;
}

/* post-section start */
.post-section {
	/* margin: 13.5vw 25vw 0; */
	margin: 11vw 25vw 0;
}

.post-section-container a {
	color: unset;
	text-decoration: none;
}

.post-section-container .new-post {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	padding: 1.5vw;
	width: 100%;
}

.post-section-container .new-post .post-field,
.post-section-container .new-post .post-pannel,
.post-section-container .new-post .post-pannel .post-pannel-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0.5vw;
}

.post-section-container .new-post .post-field {
	grid-gap: 2%;
	gap: 2%;
}

.post-section-container .new-post .post-field .field-profile,
.posting-content .profile-content .post-info .post-profile,
.comment-box .comment-giving-box .user-comment .comment-profile,
.comment-box .comment-giving-box .user-comment .reply-profile,
.posting-content .add-comment-msg .msg-profile {
	border-radius: 50%;
	overflow: hidden;
	width: 3vw;
	height: 3vw;
	border: 0;
}

.post-section-container .new-post .post-field .field-profile img,
.posting-content .profile-content .post-info .post-profile img,
.comment-box .comment-giving-box .user-comment .comment-profile img,
.comment-box .comment-giving-box .user-comment .reply-profile img,
.posting-content .add-comment-msg .msg-profile img,
.edit-profile-content .edit-profile .profile-content img,
.posting-content .profile-content .post-info .post-profile img {
	max-width: 100%;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.post-section-container .new-post .post-field .form-field input,
.posting-content .add-comment-msg .msg-field input {
	padding: 0.6vw 1.5vw;
	border-radius: 0.3vw;
	font-size: 1.1vw;
	width: 37vw;
}

.post-section-container .new-post .post-field .form-field input::-webkit-input-placeholder, .posting-content .add-comment-msg .msg-field input::-webkit-input-placeholder {
	color: var(--close-button);
	font-size: 1.2vw;
}

.post-section-container .new-post .post-field .form-field input:-ms-input-placeholder, .posting-content .add-comment-msg .msg-field input:-ms-input-placeholder {
	color: var(--close-button);
	font-size: 1.2vw;
}

.post-section-container .new-post .post-field .form-field input::placeholder,
.posting-content .add-comment-msg .msg-field input::placeholder {
	color: var(--close-button);
	font-size: 1.2vw;
}

.post-section-container .new-post .post-pannel {
	justify-content: space-around;
	padding: 0 0 0 3.2vw;
	margin-bottom: 0;
}

.post-section-container .new-post .post-pannel .post-pannel-content {
	margin-bottom: 0;
}


.post-pannel .post-pannel-content .pannel-content p {
	margin-left: 0.3vw;
	color: var(--close-button);
}

.posting-container {
	width: 100%;
	margin-top: 1.5vw;
	padding-bottom: 4.5vw;
}
.loader-loading{
	padding-bottom: 5vw !important;
}

.posting-content {
	padding: 1.5vw;
	background-color: var(--post-background);
	border-radius: 0.3vw;
	margin: 1.5vw 0;
	padding-bottom: 0;
}

.posting-content .profile-content,
.posting-content .profile-content .post-info {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.posting-content .profile-content .post-info {
	justify-content: flex-start;
	align-items: center;
	grid-gap: 3%;
	gap: 3%;
	width: 100%;
}

.posting-content .profile-content .post-info .post-profile {
	width: 3vw;
	height: 3vw;
	align-self: flex-start;
}

.cross-icon-header{
	cursor: not-allowed !important;
}

.posting-content .profile-content .post-info .post-content .post-header h2 {
	font-weight: bold;
}

.posting-content .profile-content .post-info .post-content .post-header h2,
.posting-content .profile-content .post-info .post-content .posted h3 {
	margin-bottom: 0.2vw;
}

.posting-content .profile-content .post-info .post-content .posted {
	color: var(--close-button);
}

.posting-content .profile-content .menu-dots img {
	width: 0.4vw;
	cursor: pointer;
}

.posting-content .profile-text {
	margin: 1vw -1vw 1vw 0;
}

.posting-content .profile-text .profile-subtext h2.stack-header {
	margin-bottom: 2vw;
}

.posting-content .profile-text .profile-subtext h2.bold-header {
	font-weight: bold;
	margin-bottom: 0.8vw;
}

.posting-content .profile-text .profile-subtext h2.truncate span {
	color: var(--black);
}

.posting-content
	.profile-text
	.profile-subtext
	h2.truncate
	span
	a.my-anchor-css-class {
	color: var(--red);
}

.posting-content .feeds-container {
	/* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1vw; */
	/* width: 100%; */
	/* margin-top: 2vw; */
	margin-bottom: 1.5vw;
}

.posting-content .feeds-container .feeds-content {
	display: grid;
	/* grid-template-columns: repeat(2, 0fr); */
	grid-template-columns: 1fr 1fr;
	grid-gap: 1vw;
	width: 100%;
}

.posting-content .feeds-container .single-feed-img {
	display: block;
}

.posting-content .feeds-container .feeds-content img {
	/* width: 21vw; */
	width: 100%;
	cursor: pointer;
}

.posting-content .feeds-container .feeds-second {
	display: grid;
	/* grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 14vw); */
	grid-gap: 1.2vw;
}

.posting-content .feeds-container .feeds-second .feeds-content img {
	width: 21.2vw;
	cursor: pointer;
}

.posting-content .like-pannel {
	border-top: 0.15vw solid var(--light-grey);
	/* border-bottom: 0.15vw solid var(--light-grey); */
	padding: 1vw 0;
}

.posting-content .like-pannel.next-post-pannel {
	border-bottom: 0;
}

.posting-content .like-pannel .like-pannel-container,
.posting-content .like-pannel .like-pannel-container .like-pannel-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.posting-content .like-pannel .like-pannel-container .like-pannel-content {
	justify-content: flex-start;
	grid-gap: 8%;
	gap: 8%;
	width: 100%;
}

.posting-content .like-pannel .like-pannel-container .like-contents {
	width: 75%;
}

.posting-content .like-pannel .like-pannel-container .comment-pannel-content {
	width: 115%;
	transition: all 0.3s ease-in-out;
}

.like-pannel-container .like-pannel-content .like-icon img,
.email-icon-button button img {
	width: 1.7vw;
	cursor: pointer;
}

.like-pannel-container .like-pannel-content .like-icon i {
	font-size: 1.7vw;
	color: var(--close-button);
	cursor: pointer;
}


.like-icon {
	display: flex;
	align-items: center;
	grid-gap: 10%;
	gap: 10%;
}

.like-icon .icon-content {
	word-break: unset;
}

.icon-content .header {
	line-height: 1;
}

.like-pannel-container .like-pannel-content .icon-content.like-number {
	cursor: pointer;
}

.like-pannel-container .like-pannel-content .icon-content h2,
.email-icon-button .email-text h3,
.posting-content .comments-section .comments-text h2 {
	margin-bottom: 0;
	color: var(--close-button);
}

.email-icon-button {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.email-icon-button button {
	background-color: transparent;
	padding: 0;
}

.posting-content .comments-section .comments-text {
	padding: 1vw 0;
	border-bottom: 0.15vw solid var(--light-grey);
}

.comment-box {
	padding: 1.5vw 0 0.5vw 0;
}

.comment-box .comment-giving-box,
.comment-box .comment-giving-box .user-comment,
.posting-content .add-comment-msg {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	grid-gap: 3%;
	gap: 3%;
}

.posting-content .add-comment-msg {
	display: block;
	grid-gap: 3%;
	gap: 3%;
}

.comment-box .comment-giving-box .user-comment,
.posting-content .add-comment-msg {
	grid-gap: 3%;
	gap: 3%;
	width: 100%;
}

.posting-content .add-comment-msg {
	margin-top: 1.6vw;
	padding-bottom: 2vw;
}

.posting-content .add-comment-msg.add-comment-reply {
	display: none;
}

.posting-content .add-comment-msg.add-comment-reply.open {
	/* display: flex; */
	padding-left: 6vw;
	/* align-items: flex-start; */
	display: block;
}

.posting-content .add-comment-msg.add-comment-reply .msg-profile img {
	width: 3vw;
	height: 3vw;
	max-width: 100%;
}

.posting-content .add-comment-msg.add-comment-reply .msg-profile {
	width: 3vw;
	height: 3vw;
}

.posting-content .add-comment-msg.add-comment-reply .msg-field {
	/* width: 72%; */
	width: 100%;
}

.posting-content .add-comment-msg.add-comment-reply .msg-field input {
	width: 100%;
}

.comment-box .comment-giving-box .user-comment .comment-profile {
	border: none;
	width: 3.3vw;
	height: 3vw;
}

.user-comment .comment-giving-container {
	width: 100%;
}

.comment-box .comment-giving-box .user-comment .comment-profile img {
	width: 100%;
	height: 100%;
}

.comment-box .comment-giving-box .user-comment .comment-giving {
	background-color: var(--white);
	border-radius: 0.3vw;
	padding: 0.7vw 1.5vw;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 1vw;
}

.user-comment .comment-giving .user .user-name h3,
.user-comment .comment-giving .user .user-reply h3,
.user-comment .comment-giving .reply-timing p {
	margin-bottom: 0;
	white-space: pre-line;
}

.user-comment .comment-giving .user .user-name h3 {
	font-weight: bold;
	margin-bottom: 0.5vw;
}

.comment-box .reply-container {
	display: flex;
}

.comment-box .reply-container .display-reply {
	cursor: pointer;
}

.comment-box .reply-container .display-reply p,
.comment-box .reply-container .reply-count p {
	margin: 0.5vw 0 0 0;
	position: relative;
	left: 6.5vw;
}

.comment-box .reply-container .reply-count p {
	left: 7.5vw;
}

.comment-box .comment-giving-box .user-comment .reply-profile {
	border: none;
	width: 3vw;
	height: 3vw;
}

.comment-box .comment-giving-box .user-comment .reply-profile img {
	width: 3vw;
	height: 3vw;
}

.comment-box ul.give-reply-box {
	list-style-type: none;
	padding-left: 6vw;
	margin-top: 1vw;
	display: none;
	margin-bottom: 0;
}

.comment-box ul.give-reply-box.open {
	display: block;
}

.comment-box .comment-giving-box .user-comment .reply-giving {
	width: 90%;
}

.posting-content .add-comment-msg .msg-profile {
	border: none;
}

.posting-content .add-comment-msg .msg-field input {
	padding: 1vw 1.5vw;
}

.posting-content .pexit-feeds {
	grid-template-columns: repeat(1, 1fr);
}

.posting-content .pexit-feeds .pexit-content img {
	width: 43.4vw;
	cursor: pointer;
}

/* post-section end */

/* hover effect start */
.post-pannel .post-pannel-content .pannel-content p,
.right-sidebar .components-content .component-info h2,
.edit-profile-content .profile-contacts h2 {
	transition: all 0.3s ease-in-out;
}

.post-pannel .post-pannel-content .pannel-content p:hover,
.right-sidebar .components-content .component-info h2:hover,
.edit-profile-content .profile-contacts h2:hover {
	color: var(--black);
}
/* hover effect end */

/* feed-page end */

.post-media-section {
	text-align: left;
	color: var(--white);
}

.srv-validation-message {
	text-align: left;
	color: #c40000;
	padding-bottom: 1vw;
}

.posting-content .comment-container {
	display: none;
}

.posting-content .comment-container.open {
	border-top: 0.15vw solid var(--light-grey);
	display: block;
	transition: all 0.3s ease-in-out;
}

/* like modal css start */
.like-modal-container.open::-webkit-scrollbar {
	display: none;
	scrollbar-width: none; /* for Firefox */
}
.like-modal-container {
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.51) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	margin: 0;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s ease-in-out;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
	z-index: 99;
}

.like-modal-container.open {
	opacity: 1;
	visibility: visible;
	padding-top: 14vw;
	padding-bottom: 0vw;
	overflow-y: scroll;
	transition: 0.3s ease-in-out;
}

.like-adjust-modal {
	height: 100% !important;
}

.like-modal {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 5%;
	gap: 5%;
	width: 100%;
	height: 100%;
	height: 100%;
	margin-bottom: 2vw;
}

.like-modal .user-name h1 {
	font-weight: bold;
	color: var(--white);
	margin-bottom: 1vw;
}

.like-modal .user-profile {
	border-radius: 50%;
	overflow: hidden;
	width: 4vw;
	height: 4vw;
}

.like-modal .user-profile img {
	max-width: 100%;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
/* like modal css end */

/* menu-container css start */
.posting-content .profile-content .menu-dots {
	transition: all 0.3s ease;
}
.posting-content .profile-content .menu-container {
	position: relative;
}

.posting-content .profile-content .menu-container .user-menu-content {
	background-color: var(--post-background);
	position: absolute;
	border: 0.1vw solid var(--close-button);
	padding: 0.5vw;
	right: 1vw;
	top: 0vw;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	width: 9vw;
	display: none;
}

.posting-content .profile-content .menu-container .user-menu-content.open {
	opacity: 1;
	display: block;
}

.posting-content .profile-content .menu-container .user-menu-content h2 {
	color: var(--close-button);
	font-weight: bold;
	cursor: pointer;
}

.posting-content .profile-content .menu-container .user-menu-content h2.edit {
	margin-bottom: 1vw;
}
/* menu-container css end */

/* message modal css start */
.message-modal {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: flex-start;
	padding: 4vw;
	background-color: #8a8989;
	width: 45%;
	border-radius: 0.3vw;
	position: relative;
}

.msg-content-fields {
	width: 100%;
}

.message-modal h1 {
	font-weight: bold;
	color: var(--white);
}

.message-modal h1 span {
	color: var(--black);
}

.message-modal form textarea.msg-description {
	padding: 2vw;
	border-radius: 0.5vw;
	width: 100%;
}

.message-modal form textarea.msg-description:focus {
	outline: none;
}

.login-content-fields form button.msg-button {
	background-color: var(--white) !important;
	color: var(--medium-grey) !important;
	width: 12vw !important;
	padding: 1vw 2vw !important;
	margin-top: 2vw;
}

.login-content-fields form button.msg-button:hover {
	background-color: var(--medium-grey) !important;
	color: var(--white) !important;
}

.login-content-fields form button.msg-button h2 {
	font-weight: bold;
}
/* message modal css end */

/* delete modal start  */
.delete-modal {
	height: 53% !important;
	width: 50%;
}

.contact-modal.open {
	padding-top: 5vw !important;
}

.creategroup-modal .creategroup-content.delete-buttons-container {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 6%;
	gap: 6%;
}

.creategroup-modal .creategroup-content.delete-buttons-container button {
	width: 14vw;
	padding: 1vw 1.5vw;
	color: var(--close-button);
}

.creategroup-modal .creategroup-content.delete-buttons-container button h2 {
	font-weight: bold;
}

.creategroup-modal
	.creategroup-content.delete-buttons-container
	button.cancel-button:hover {
	background-color: var(--light-grey);
}

.creategroup-modal
	.creategroup-content.delete-buttons-container
	button.delete-button {
	background-color: var(--dark-grey);
	color: var(--white);
}

.creategroup-modal
	.creategroup-content.delete-buttons-container
	button.delete-button:hover {
	background-color: var(--black);
	color: var(--white);
}
/* delete modal end  */

/* change password */
.forgot-modal {
	height: auto !important;
}

/* invite friends start */
.invite-modal {
	width: 55vw !important;
}
.creategroup-modal .creategroup-content.invite-friends-modal {
	width: 95%;
}

.contact-container {
	display: flex;
	justify-content: center;
	align-items: baseline;
	text-align: left;
	width: 100%;
}

.creategroup-modal .creategroup-content .contact-container label.name {
	width: 20vw;
}

.creategroup-modal .creategroup-content .contact-container label.attachmentTag {
	margin-top: 1vw;
}

.creategroup-modal .creategroup-content .attachmentListDiv{
	width: 100%;
	display: flex;
	flex-direction: column;
}

.profile-subtext .content-css h1 {
	font-size:1.2vw;
	white-space: pre-line;
}

.content-css span{
	white-space: pre-line;
}

.staticEditTitle{
	background-color: #fff;
    border-radius: 0.3vw;
    padding: 1vw;
    text-align: left;
}

.staticEditDesc{
	background-color: #fff;
    margin-top: 2%;
    border-radius: 0.3vw;
    padding: 1vw;
    text-align: left;
}

@media screen and (max-width: 900px) {
	.profile-subtext .content-css h1 {
		font-size: 1.8vw;
	}
	.textComment{
		height: 7vh;
    	font-size: 2vw;
		width: 100%;
	}
	
}

@media screen and (max-width: 599px) {
	.profile-subtext .content-css h1 {
		font-size: 2.6vw;
	}

	.textComment{
		height: 10vh;
		font-size: 3vw;
		width: 100%;
	}
}

.contact-container .attachmentListDiv .attachmentList{
	width: 3vw;
    margin-right: 2%;
}

.tox-tinymce {
	width: 100%;
	border-radius: 0.3vw !important;
	margin-bottom: 0.5vw;
}

.creategroup-modal .creategroup-content form button.invite-button {
	width: 10vw;
}
/* invite friends end */

.document_image {
	max-width: 60px;
}

.sendEmailCont .srv-validation-message {
	text-align: center;
}

.CommentCont {
	width: 100%;
}

.userCont {
	display: flex;
	justify-content: space-between;
}

/* Comment Box Start */

.comment_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.comment_box .profile-pic {
	width: 4vw;
	height: 4vw;
	border-radius: 50% !important;
	margin-right: 1% !important;
}

.open .comment_box .reply-pic {
	/* width: 3.5vw;
    height: 2.5vw; */
    object-fit: cover;
}

.comment_box .cmnt-pic {
	width: 4vw;
	height: 3.5vw;
}

.comment_box .profile-pic {
	/* width: 4vw; */
	width: 3vw;
	height: 3vw;
	object-fit: cover;
}

.comment_box > .input-msg {
	padding: 0.5vw !important;
	margin-right: 1% !important;
}

.comment_box > .input-file {
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
	cursor: pointer;
}
.comment_box > .input-file .input_file_element {
	display: none !important;
}

.comment_box > .send-button {
	font-size: 1.2vw;
	cursor: pointer;
	margin-left: 1%;
}

.selected_media_file {
	border: 1px solid #ccc;
	padding: 1%;
	margin-top: 1%;
}
/* Comment Box End */

.image-area {
	position: relative;
	width: 8vw;
	background: transparent;
	margin-right: 5%;
	margin-top: 3%;
}

.image-area img {
	max-width: 100%;
	height: auto;
	cursor: pointer;
}

.remove-image {
	position: absolute;
	padding: 3px 7px 5px;
	top: -11px;
	right: -11px;
	border-radius: 10em;
	text-decoration: none;
	font: 700 15px/15px sans-serif;
	background: #555;
	border: 1px solid #fff;
	color: #fff;
	box-shadow: 0 2px 6px rgb(0 0 0 / 50%), inset 0 2px 4px rgb(0 0 0 / 30%);
	text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
	transition: background 0.5s;
}

.remove-image:hover {
	background: #e54e4e;
	padding: 3px 7px 5px;
	top: -11px;
	right: -11px;
}

.remove-image:active {
	background: #e54e4e;
	top: -10px;
	right: -11px;
}

.d_preview_images {
	justify-content: flex-start;
	width: 100%;
	margin-top: 1%;
	flex-wrap: wrap;
}

.input-group .btn {
	position: relative;
	z-index: 2;
	display: flex;
}

.sendModalHeader .noteleft{
	text-align: left;
}
.posting-container  .posting-content  .profile-text .profile-subtext  .truncate .content-css span  h1 {
	font-size: 1.2vw;
	background-color: transparent;
	color: black;
	font-family: "Roboto",sans-serif;
}

.profile-text .profile-subtext h2.truncate p span.read-or-hide {
	color: var(--red);
	cursor: pointer;
}


/* postpage padding css from top section and two sidebars */
@media screen and (max-width: 900px) {
	.posting-container  .posting-content  .profile-text .profile-subtext  .truncate .content-css span  h1 {
		font-size: 1.8vw;
		background-color: transparent;
		color: black;
		font-family: "Roboto",sans-serif;
	}
	.post-section {
		margin: 23vw 25vw 0;
	}
	.feed-section .left-sidebar {
		padding: 13vw 0vw 24vw 2vw;
	}
	.feed-section .right-sidebar {
		padding: 13vw 2vw 24vw 0vw;
	}
	.edit-profile-content .edit-profile .profile-content {
		width: 6vw;
		height: 6vw;
	}
	.edit-profile-content .edit-profile .profile-content img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit {
		justify-content: space-between;
		grid-gap: 4%;
		gap: 4%;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit img,
	.edit-profile-content .profile-contacts .profile-contact-icon img {
		width: 100%;
		height: 100%;
	}

	.edit-profile-content .edit-profile .profile-name .name-edit img {
		width: 2vw;
	}

	.edit-profile-content .edit-profile .profile-name h1 {
		font-size: 2.5vw;
	}

	.edit-profile-content .edit-profile .profile-name .name-edit h1 {
		font-size: 2.1vw;
	}

	.edit-profile-content .profile-contacts {
		grid-gap: 4%;
		gap: 4%;
		margin-bottom: 2vw;
		width: 100%;
	}
	.edit-profile-content .profile-contact-icon,
	.your-posts-container .pannel-content .pannel-icon,
	.right-sidebar .components-content .component-image,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon {
		width: 3vw;
	}
	.edit-profile-content .profile-contacts .profile-contact-number,
	.edit-profile-content .profile-contacts .profile-number {
		left: 0vw;
	}
	.your-posts-container {
		margin-top: 6vw;
	}
	.accordion-button {
		font-size: 2vw;
	}
	.accordion-button::after {
		margin-left: 1vw;
	}
	.your-post-accordion .view-icon {
		width: 4vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 100%;
		height: auto;
	}
	.right-sidebar .components-content .component-image img{
		width: 2.5vw;
	}
	.post-section-container .new-post {
		padding: 1.8vw;
		width: 100%;
	}

	.post-section-container .new-post .post-field .field-profile,
	.posting-content .profile-content .post-info .post-profile,
	.comment-box .comment-giving-box .user-comment .comment-profile,
	.comment-box .comment-giving-box .user-comment .reply-profile,
	.posting-content .add-comment-msg .msg-profile {
		width: 5vw;
		height: 5vw;
	}

	.comment-box .comment-giving-box .user-comment .comment-profile {
		width: 6vw;
	}

	.post-section-container .new-post .post-field .field-profile {
		height: 5vw;
		max-width: 11vw;
		width: 5vw;
		display: flex;
		align-items: flex-start;
		max-height: 5vw;
		justify-content: center;
	}

	.post-section-container .new-post .post-field .field-profile img,
	.posting-content .profile-content .post-info .post-profile img,
	.comment-box .comment-giving-box .user-comment .comment-profile img,
	.comment-box .comment-giving-box .user-comment .reply-profile img,
	.posting-content .add-comment-msg .msg-profile img {
		max-width: 100%;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.post-section-container .new-post .post-field .form-field {
		width: 40.5vw;
	}
	.post-section-container .new-post .post-field .form-field input,
	.posting-content .add-comment-msg .msg-field input {
		padding: 0.8vw 1.5vw;
		font-size: 2vw;
		width: 100%;
	}
	.post-section-container .new-post .post-field .form-field input::-webkit-input-placeholder, .posting-content .add-comment-msg .msg-field input::-webkit-input-placeholder {
		font-size: 2vw;
	}
	.post-section-container .new-post .post-field .form-field input:-ms-input-placeholder, .posting-content .add-comment-msg .msg-field input:-ms-input-placeholder {
		font-size: 2vw;
	}
	.post-section-container .new-post .post-field .form-field input::placeholder,
	.posting-content .add-comment-msg .msg-field input::placeholder {
		font-size: 2vw;
	}
	.post-section-container .new-post .post-field,
	.post-section-container .new-post .post-pannel,
	.post-section-container .new-post .post-pannel .post-pannel-content {
		margin-bottom: 2vw;
	}
	.post-section-container .new-post .post-pannel,
	.post-section-container .new-post .post-pannel .post-pannel-content {
		margin-bottom: 0;
	}
	.post-section-container .new-post .post-pannel {
		justify-content: space-between;
		padding: 0 0 0 0vw;
	}
	.posting-container {
		width: 100%;
		margin-top: 2.5vw;
		padding-bottom: 25vw;
	}
	.posting-content {
		padding: 2vw;
		margin: 3vw 0;
		padding-bottom: 1vw;
	}
	.posting-content .profile-content .menu-dots {
		width: 0.8vw;
	}
	.posting-content .profile-content .menu-dots img {
		width: 100%;
		height: auto;
	}
	.posting-content .profile-content .menu-container .user-menu-content {
		padding: 1vw;
		width: 15vw;
	}
	.posting-content .profile-text {
		margin: 2vw 0vw 2vw 0;
	}
	.custom-select .select-caret i {
		font-size: 1.7vw;
		color: var(--close-button);
	}
	.custom-select select {
		font-size: 1.7vw;
		padding: 1vw 0.8vw;
		padding-right: 3vw;
		padding-left: 0.5vw;
	}
	.right-sidebar .components-content {
		margin-bottom: 2.5vw;
	}
	.posting-content .profile-text .profile-subtext h2.bold-header {
		font-weight: bold;
		margin-bottom: 1vw;
	}
	.like-pannel-container .like-pannel-content .icon-content h2,
	.email-icon-button .email-text h3,
	.posting-content .comments-section .comments-text h2 {
		margin-left: 0.5vw;
	}
	.posting-content .like-pannel .like-pannel-container,
	.posting-content .like-pannel .like-pannel-container .like-pannel-content {
		grid-gap: 5%;
		gap: 5%;
	}
	.posting-content .like-pannel .like-pannel-container .like-pannel-content,
	.like-icon {
		grid-gap: 0%;
		gap: 0%;
	}
	.like-pannel-container .like-pannel-content .like-icon i {
		font-size: 2.5vw;
	}
	.like-pannel-container .like-pannel-content .like-icon img,
	.email-icon-button button img {
		width: 2.5vw;
	}
	.like-pannel-container .like-pannel-content .icon-content h2,
	.email-icon-button .email-text h3,
	.posting-content .comments-section .comments-text h2 {
		margin-left: 0.5vw;
	}
	.email-icon-button .email-text h3 {
		margin-left: 0;
	}

	.email-icon-button button {
		height: auto !important;
	}
	.open .comment_box .reply-pic {
		width: 8vw !important;
		height: 4.2vw;
		object-fit: cover;
	}
	.comment_box .profile-pic {
		/* width: 4vw !important;
		height: 4vw;
		margin-right: 2% !important; */
		width: 9vw !important;
		height: 5vw;
		margin-right: 2% !important;
	}
	.comment_box > .input-msg {
		padding: 0.8vw 1.5vw !important;
	}
	.comment_box > .input-file {
		width: 14% !important;
	}
	.comment_box > .input-file.img {
		width: 100%;
	}
	.comment_box > .send-button {
		width: unset !important;
		font-size: 2vw;
		height: auto !important;
	}
	.posting-content .comments-section .comments-text {
		padding: 1.5vw 0;
	}
	.comment-box {
		padding: 2vw 0;
	}

	/* modals */
	.contact-modal.open {
		padding-top: 18vw !important;
	}
	.invite-modal {
		width: 70% !important;
	}
	.forgot-modal {
		height: auto !important;
	}

	.image-area {
		width: 15vw;
	}
}
@media screen and (max-width: 599px) {
	.posting-container  .posting-content  .profile-text .profile-subtext  .truncate .content-css span  h1 {
		font-size: 2.6vw;
		background-color: transparent;
		color: black;
		font-family: "Roboto",sans-serif;
	}
	.post-section {
		margin: 28vw 1vw 0;
	}
	.post-section-container .new-post .post-field .field-profile {
		/* max-height: 5vw;
    max-width: 11vw; */
		max-width: 80vw;
		max-height: 70vw;
		height: 7vw;
		width: 7vw;
	}
	.post-section-container .new-post .post-field .form-field {
		width: 82.5vw;
	}
	.post-section-container .new-post .post-field .form-field input,
	.posting-content .add-comment-msg .msg-field input {
		padding: 0.8vw 1.5vw;
		/* font-size: 2.6vw; */
		font-size: 3vw;
		width: 100%;
	}
	.post-section-container .new-post .post-field .form-field input::-webkit-input-placeholder, .posting-content .add-comment-msg .msg-field input::-webkit-input-placeholder {
		font-size: 2.4vw;
	}
	.post-section-container .new-post .post-field .form-field input:-ms-input-placeholder, .posting-content .add-comment-msg .msg-field input:-ms-input-placeholder {
		font-size: 2.4vw;
	}
	.post-section-container .new-post .post-field .form-field input::placeholder,
	.posting-content .add-comment-msg .msg-field input::placeholder {
		font-size: 2.4vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.right-sidebar .components-content .component-image img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 5.8vw;
		height: auto;
	}
	.edit-profile-content .profile-contact-icon,
	.your-posts-container .pannel-content .pannel-icon,
	.right-sidebar .components-content .component-image,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon {
		width: 6vw;
	}
	.post-section-container .new-post .post-field .field-profile,
	.posting-content .profile-content .post-info .post-profile,
	.comment-box .comment-giving-box .user-comment .comment-profile,
	.comment-box .comment-giving-box .user-comment .reply-profile,
	.posting-content .add-comment-msg .msg-profile {
		width: 9vw;
		height: 9vw;
	}
	.comment-box .comment-giving-box .user-comment .comment-profile {
		width: 9vw;
		height: 8vw;
	}
	.post-pannel .post-pannel-content .pannel-content p {
		font-size: 2.4vw;
	}
	.posting-content .profile-content .post-info .post-content .post-header h2 {
		font-size: 3vw;
	}
	.posting-content .profile-content .post-info .post-content .posted h3 {
		font-size: 2.2vw;
	}
	.posting-content .profile-text .profile-subtext h2.bold-header,
	.posting-content .profile-text .profile-subtext h2.link-header,
	.posting-content .profile-text .profile-subtext h2.truncate span {
		font-size: 2.6vw;
	}
	.posting-content .profile-content .menu-dots {
		width: 1vw;
	}
	.posting-content .profile-content .menu-container .user-menu-content {
		padding: 2vw;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		right: 3vw;
		white-space: nowrap;
	}
	.posting-content .profile-content .menu-container .user-menu-content h2 {
		font-size: 2.5vw;
	}
	.posting-content .profile-content .menu-container .user-menu-content h2.edit {
		margin-bottom: 2vw;
	}
	.like-pannel-container .like-pannel-content i.far,
	.like-pannel-container .like-pannel-content h2.header {
		font-size: 2.6vw;
	}
	.like-pannel-container .like-pannel-content .like-icon img,
	.email-icon-button button img {
		width: 2.8vw;
	}
	
	.feed-section .left-sidebar {
		right: 0;
		left: auto;
		background-color: var(--grey);
		z-index: 10;
		/* top: 20vw; */
		width: 60%;
		padding: 7vw 4vw 20vw 4vw;
	}
	.feed-section .right-sidebar {
		width: 60%;
		z-index: 10;
		padding: 7vw 4vw 20vw 4vw;
		background-color: var(--grey);
	}
	.email-icon-button .email-text h3 {
		font-size: 1.9vw;
	}
	.user-comment .comment-giving .user .user-name h3 {
		font-size: 2.4vw;
	}
	.comment-box .reply-container .display-reply p,
	.comment-box .reply-container .reply-count p {
		font-size: 2.8vw;
	}

	/* sidebar changes css start */
	.right-sidebar .active-users .active-users-content {
		margin-bottom: 3.5vw;
	}
	.right-sidebar .active-users .active-users-content .user-image,
	.edit-profile-content .edit-profile .profile-content {
		width: 9vw;
		height: 9vw;
	}
	.right-sidebar .active-users .active-users-content .user-image img {
		object-fit: cover;
	}
	.edit-profile-content .edit-profile {
		justify-content: flex-start;
		margin-bottom: 5vw;
		grid-gap: 6%;
		gap: 6%;
	}
	.edit-profile-content .edit-profile .profile-name {
		width: 67%;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit {
		justify-content: flex-start;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit img {
		width: 4vw;
	}
	.edit-profile-content .edit-profile .profile-name .name-edit h1 {
		font-size: 3vw;
	}
	.accordion-item {
		background-color: transparent;
	}
	.your-post-accordion .view-icon {
		width: 6vw;
		height: 6vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 100%;
		height: 100%;
	}
	.right-sidebar .components-content .component-image img{
		width: 5vw;
	}
	.accordion-button {
		font-size: 3vw;
		background-color: transparent;
	}
	/* sidebar changes css end */

	/* dashboard changes start */
	.posting-content .like-pannel .like-pannel-container,
	.posting-content .like-pannel .like-pannel-container .like-pannel-content {
		grid-gap: 0%;
		gap: 0%;
	}
	.posting-content .like-pannel .like-pannel-container .like-contents,
	.posting-content .like-pannel .like-pannel-container .comment-pannel-content,
	.posting-content .like-pannel .like-pannel-container .like-pannel-content {
		width: unset;
	}
	.email-icon-button {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.email-icon-button .email-text h3 {
		font-size: 2.6vw;
	}
	.posting-content .like-pannel {
		padding: 2vw 0;
	}
	.like-pannel-container .like-pannel-content .icon-content h2,
	.email-icon-button .email-text h3,
	.posting-content .comments-section .comments-text h2,
	.email-icon-button .email-text h3 {
		margin-left: 1vw;
	}
	.like-pannel-container .like-pannel-content .like-icon i {
		font-size: 3.6vw;
	}
	.like-pannel-container .like-pannel-content .like-icon img,
	.email-icon-button button img,
	.like-pannel-container .like-pannel-content .like-icon i {
		width: 4vw;
	}
	.comment-box .comment-giving-box .user-comment .comment-giving {
		padding: 1vw 2vw;
		margin-bottom: 1vw;
	}

	.user-comment .comment-giving .user .user-name h3 {
		margin-bottom: 2vw;
	}
	.posting-content {
		padding-bottom: 3vw;
	}
	.comment_box > .input-msg {
		padding: 2vw 2vw !important;
		margin-right: 2% !important;
	}
	.comment_box > .input-file {
		width: unset !important;
	}
	.comment_box > .send-button {
		font-size: 3vw;
		padding: 1vw 2vw;
	}
	.user-comment .comment-giving .user .user-name h3,
	.user-comment .comment-giving .user .user-reply h3,
	.user-comment .comment-giving .reply-timing p {
		font-size: 3vw;
	}
	.comment-box .reply-container .display-reply p,
	.comment-box .reply-container .reply-count p {
		margin: 1vw 0 0 0;
		left: 6.5vw;
	}
	.comment_box > .send-button {
		margin-left: 2%;
	}
	.open .comment_box .reply-pic {
		width: 10vw !important;
		height: 6.5vw;
		object-fit: cover;
	}
	.comment_box .profile-pic {
		/* width: 6vw !important;
		height: 6vw; */
		width: 11.5vw !important;
		height: 8vw;
		margin-right: 2% !important;
	}
	.user-content {
		width: 50%;
	}
	.custom-select select {
		font-size: 3vw;
		padding: 1vw 1vw;
	}
	.your-posts-container .pannel-container {
		margin-top: 3vw;
	}
	.your-posts-container .pannel-content {
		margin-bottom: 2.5vw;
	}
	.your-posts-container .pannel-content .pannel-name h2 {
		font-size: 3vw;
	}
	.edit-profile-content .profile-contacts {
		margin-bottom: 3vw;
	}
	.post-pannel .post-pannel-content .pannel-content p,
	.right-sidebar .components-content .component-info h2,
	.edit-profile-content .profile-contacts h2 {
		font-size: 3vw;
	}
	/* dashboard changes end */

	/* post job page sidebar changes start */
	.image-area {
		background: transparent;
		width: 25vw !important;
	}
	/* post job page sidebar changes end */
}
@media screen and (max-width: 499px) {
	.post-section {
		margin: 30vw 1.4vw 0;
	}
	.post-section-container .new-post .post-field .field-profile {
		height: 7vw;
		max-width: 110vw;
		width: 7vw;
		max-height: 50vw;
	}
	.post-section-container .new-post .post-field .form-field {
		width: 82.5vw;
	}
	.post-section-container .new-post .post-field .form-field input,
	.posting-content .add-comment-msg .msg-field input {
		padding: 1.6vw 1.5vw;
		/* font-size: 2.5vw; */
		width: 100%;
	}
	.post-section-container .new-post .post-field .form-field input::-webkit-input-placeholder, .posting-content .add-comment-msg .msg-field input::-webkit-input-placeholder {
		font-size: 2.8vw;
	}
	.post-section-container .new-post .post-field .form-field input:-ms-input-placeholder, .posting-content .add-comment-msg .msg-field input:-ms-input-placeholder {
		font-size: 2.8vw;
	}
	.post-section-container .new-post .post-field .form-field input::placeholder,
	.posting-content .add-comment-msg .msg-field input::placeholder {
		font-size: 2.8vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.right-sidebar .components-content .component-image img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 6vw;
		height: auto;
	}
	.edit-profile-content .profile-contact-icon,
	.your-posts-container .pannel-content .pannel-icon,
	.right-sidebar .components-content .component-image,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon {
		width: 7.5vw;
	}
	.post-pannel .post-pannel-content .pannel-content p {
		font-size: 2.9vw;
	}
	.posting-content .profile-content .post-info .post-content .post-header h2 {
		font-size: 3.4vw;
	}
	.posting-content .profile-content .post-info .post-content .posted h3 {
		font-size: 2.6vw;
	}
	.posting-content .profile-text .profile-subtext h2.bold-header,
	.posting-content .profile-text .profile-subtext h2.link-header,
	.posting-content .profile-text .profile-subtext h2.truncate span {
		font-size: 2.8vw;
	}
	.posting-content .profile-content .menu-dots {
		width: 1.1vw;
	}
	.posting-content .profile-content .menu-container .user-menu-content h2 {
		font-size: 2.8vw;
	}
}
@media screen and (max-width: 399px) {
	.post-section {
		margin: 32vw 1.7vw 0;
	}
	.post-section-container .new-post .post-field .field-profile {
		height: 8vw;
		max-width: 110vw;
		width: 8vw;
		max-height: 50vw;
	}
	.post-section-container .new-post .post-field .form-field {
		width: 80.5vw;
	}
	.post-section-container .new-post .post-field .form-field input,
	.posting-content .add-comment-msg .msg-field input {
		padding: 1.9vw 1.5vw;
		/* font-size: 2.5vw; */
		width: 100%;
	}
	.post-section-container .new-post .post-field .form-field input::-webkit-input-placeholder, .posting-content .add-comment-msg .msg-field input::-webkit-input-placeholder {
		font-size: 3.1vw;
	}
	.post-section-container .new-post .post-field .form-field input:-ms-input-placeholder, .posting-content .add-comment-msg .msg-field input:-ms-input-placeholder {
		font-size: 3.1vw;
	}
	.post-section-container .new-post .post-field .form-field input::placeholder,
	.posting-content .add-comment-msg .msg-field input::placeholder {
		font-size: 3.1vw;
	}
	.your-posts-container .your-post-accordion .view-icon img,
	.your-posts-container .pannel-content .pannel-icon img,
	.right-sidebar .components-content .component-image img,
	.post-section-container
		.new-post
		.post-pannel
		.post-pannel-content
		.pannel-icon
		img {
		width: 6.3vw;
		height: auto;
	}
	.post-pannel .post-pannel-content .pannel-content p {
		font-size: 3vw;
	}
	.posting-content .profile-content .post-info .post-content .post-header h2 {
		font-size: 3.6vw;
	}
	.posting-content .profile-content .post-info .post-content .posted h3 {
		font-size: 2.9vw;
	}
	.posting-content .profile-text .profile-subtext h2.bold-header,
	.posting-content .profile-text .profile-subtext h2.link-header,
	.posting-content .profile-text .profile-subtext h2.truncate span {
		font-size: 3.15vw;
	}
	.posting-content .profile-content .menu-dots {
		width: 1.2vw;
	}

	.posting-content .profile-content .menu-container .user-menu-content h2 {
		font-size: 3.1vw;
	}

	.user-comment .comment-giving .user .user-name h3 {
		font-size: 3.3vw;
	}
}
.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
	border: 0.9vw solid #f3f3f3;
	border-radius: 50%;
	border-top: 0.9vw solid #393939;
	width: 4vw;
	height: 4vw;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@media screen and (max-width: 900px) {
	.loader {
		border: 1vw solid #f3f3f3;
		border-top: 1vw solid #393939;
		width: 5vw;
		height: 5vw;
	}
}

/* login-popup start */
.code-select {
	background-image: unset;
	padding-right: 1vw;
	width: 8vw;
	font-size: 1.66vw;
	border-radius: 0.3vw;
	padding: 0.57vw 1vw;
	color: var(--grey);
}
.country-select {
	background-image: unset;
	font-size: 1.66vw;
	padding: 0.6vw 1vw;
	border-radius: 0.3vw;
	color: var(--grey);
}

.caret-content .country-select{
	margin-bottom: 1vw;
}

.caret-content {
	position: relative;
}

.caret-content .select-caret {
	position: absolute;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	right: 1vw;
}

.caret-content .select-caret i {
	font-size: 1.66vw;
	color: var(--grey);
}

.input-group .password__show {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 1vw;
	top: 1.3vw;
}

.input-group .password__show i {
	font-size: 1.5vw;
	cursor: pointer;
}

/* change password start */
.creategroup-modal .creategroup-content form label {
	font-size: 1.3vw;
	font-weight: bold;
	color: var(--white);
}

.submit-button {
	margin-top: 1vw;
}

.submit-button button.submit-pass {
	width: 50% !important;
}

.input-group .password__change {
	top: 2.8vw;
}
/* change password end */

/* forgot password */
.forgot-pass-link {
	text-align: left;
	margin-bottom: 1vw;
	transition: all 0.3s ease;
	width: 42%;
	cursor: pointer;
}
.forgot-pass-link h2 {
	font-weight: bold;
	margin: 0.5vw 0.5vw 0.5vw 1vw;
}
.forgot-pass-link:hover {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 50vw;
	text-decoration: underline;
}

/* forgot modal css start */
.forgot-header {
	color: var(--white);
	text-align: left;
}

.forgot-header h1 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.forgot-modal {
	align-items: flex-start !important;
}

.forgot-pass-content {
	text-align: left;
}

.creategroup-modal .creategroup-content form input.forgot-email {
	width: 100%;
	margin: 2vw 0 2vw 0;
	border-radius: 0.3vw;
}

.forgot-buttons {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.forgot-buttons button.forgot-content {
	width: 100%;
	border-radius: 50vw;
	background-color: var(--close-button) !important;
	color: var(--white) !important;
}

.forgot-buttons button.forgot-content:hover {
	background-color: var(--white) !important;
	color: var(--close-button) !important;
}

.forgot-buttons button.back-button {
	background-color: transparent !important;
	margin-top: 2vw;
}

.forgot-buttons button.back-button:hover {
	text-decoration: underline;
	background-color: transparent !important;
	color: var(--white) !important;
}

/* forgot modal css end */

.input-group {
	position: relative;
}

.form_control {
	background-color: var(--white);
	position: relative;
}
.form_control + label {
	position: absolute;
	top: 0.6vw;
	left: 1vw;
	font-size: 1.66vw;
	color: var(--grey);
	transition: 0.1s ease-in-out;
}
.form_control:focus {
	outline: none;
	transition: all 0.1s ease-in-out;
}
.form_control:focus + label,
.form_control.has_class + label {
	top: -1.3vw;
	color: var(--close-button);
	font-size: 1.66vw;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
	> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
	border-radius: 0.3vw;
}

.login-popup {
	margin: 0vw 30vw;
	padding: 8vw 0;
}

.login-popup-container {
	position: relative;
	background-color: var(--grey);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2vw 4vw;
	border-radius: 0.3vw;
	text-align: center;
	transition: 0.3s ease-in-out;
}

@media screen and (max-width: 800px) {
}
.login-popup-container button.login-close-modal {
	background-color: var(--close-button);
}

button.close-button-modal {
	position: absolute;
	background-color: #757571;
	color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2vw;
	height: 2vw;
	top: 1vw;
	right: 1vw;
}

button.close-button-modal i {
	font-size: 1.5vw;
}

.login-popup-container .pexit-logo img {
	width: 10vw;
}

.login-popup-container .login-container h1.header {
	margin: 1vw 0;
}

.login-popup-container .login-container h1.login-header {
	margin-bottom: 3vw;
}

.login-content-fields {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.social-button{
	width: 100%;
}

.staticEdit_outerdiv{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.staticEdit_outerdiv .staticEdit_innerdiv{
	width:100%;
	font-size: 0.95vw;
} 

.staticEdit_outerdiv button{
	height: 30px;
	margin-left: 50px;
	padding: 0 3vw;
} 

.login-content-fields form,
.social-buttons-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
	align-items: center;
}

.linkedin h1 {
	color: var(--grey);
	font-weight: bold;
	font-size: 1.66vw;
}

.login-content-fields form input,
.creategroup-modal .creategroup-content form textarea,
.creategroup-modal .creategroup-content form select {
	margin-bottom: 1vw;
	padding: 0.6vw 1vw;
	border-radius: 0.3vw;
	width: 100%;
	font-size: 1.66vw;
	color: var(--black);
	position: relative;
	transition: 0.1s ease-out;
}

.social-buttons-content .social-button button,
.login-content-fields form button.login-button {
	padding: 0.8vw 5vw;
	margin-bottom: 1vw;
	width: 100%;
	transition: all 0.3s ease-in-out;
}

.social-buttons-content .social-button button:hover,
.login-content-fields form button.login-button:hover {
	background-color: var(--medium-grey);
	color: var(--white);
}

.login-content-fields form button.login-button {
	margin-bottom: 0;
}

.login-content-fields form button.login-button {
	background-color: var(--medium-grey);
}

.social-buttons-content .social-button button h1,
.login-content-fields form button.login-button {
	font-weight: bold;
}

.login-content-fields form input::-webkit-input-placeholder, .creategroup-modal .creategroup-content form textarea::-webkit-input-placeholder {
	color: var(--grey);
	font-weight: bold;
	font-size: 1.66vw;
}

.login-content-fields form input:-ms-input-placeholder, .creategroup-modal .creategroup-content form textarea:-ms-input-placeholder {
	color: var(--grey);
	font-weight: bold;
	font-size: 1.66vw;
}

.login-content-fields form input::placeholder,
.social-buttons-content .social-button button,
.creategroup-modal .creategroup-content form textarea::placeholder,
.creategroup-modal .creategroup-content form select {
	color: var(--grey);
	font-weight: bold;
	font-size: 1.66vw;
}

.login-content-fields form button.login-button {
	color: var(--white);
	transition: all 0.3s ease-in-out;
}

.login-content-fields form button.login-button:hover {
	background-color: var(--white);
	color: var(--grey);
}

.login-popup-container .login-container .seperator {
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-popup-container .login-container .seperator .seperator-text {
	margin: 0 3vw;
}

.login-popup-container .login-container .seperator .line {
	background-color: var(--black);
	height: 0.1vw;
	width: 11vw;
}

/* login-popup end */

/* joinnow-popup start */
.login-popup-container .joinnow-container h1.header {
	margin: 0 0 2vw;
}

.joinnow-popup {
	margin: 0vw 15vw;
}

.login-container.joinnow-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	grid-gap: 1%;
	gap: 1%;
}

.joinnow-social-buttons {
	width: 64%;
}

.login-popup-container .joinnow-content-fields form {
	width: 80%;
}

.login-popup-container .joinnow-content-fields form h1.joinnow-text {
	margin: 1vw 1vw 2vw;
}

.joinnow-content-fields form .mobile-number {
	display: flex;
	grid-gap: 5%;
	gap: 5%;
}

.joinnow-content-fields form .mobile-number .country-code {
	width: 25%;
}
#joinnowcontent {
	align-items: center;
}
/* joinnow-popup end */

/* creategroup-popup start */
.creategroup-modal-container.open::-webkit-scrollbar,
.create-post-modal.open::-webkit-scrollbar,
.chat-message-modal.open::-webkit-scrollbar,
.search-modal-container.open::-webkit-scrollbar,
.sendmessage-modal-container.open::-webkit-scrollbar,
.sending-modal.open::-webkit-scrollbar,
.group-modal-container::-webkit-scrollbar {
	display: none;
	scrollbar-width: none; /* for Firefox */
}

.creategroup-modal-container,
.create-post-modal,
.chat-message-modal,
.search-modal-container,
.sendmessage-modal-container,
.sending-modal,
.group-modal-container {
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.51) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
}

.creategroup-modal-container,
.create-post-modal,
.chat-message-modal,
.search-modal-container,
.sendmessage-modal-container,
.sending-modal,
.group-modal-container {
	/* margin: 8vw 26vw 8vw 24vw; */
	margin: 0;
	/* padding: 8vw 0; */
	opacity: 0;
	visibility: hidden;
	transition: 0.3s ease-in-out;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	display: none;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
	z-index: 99;
}

.creategroup-modal-container.open,
.create-post-modal.open,
.chat-message-modal.open,
.search-modal-container.open,
.sendmessage-modal-container.open,
.sending-modal.open,
.group-modal-container.open {
	opacity: 1;
	visibility: visible;
	/* padding-top: 14vw;
	padding-bottom: 5vw; */
	/* padding-bottom: 0vw; */
	overflow-y: scroll;
	transition: 0.3s ease-in-out;
	display: flex;
	/* z-index: 99999; */
}

.creategroup-modal-container.topSpace {
	padding-top: 8vw;
}

.creategroup-modal-container.add.open {
	padding-top: 10vw;
	padding-bottom: 0vw;
}

#modaloverflow {
	height: 35vw;
	justify-content: flex-start;
	overflow-y: auto;
	margin-top: 0;
}

.CommentCont {
	width: 100%;
}

.userCont {
	display: flex;
	justify-content: space-between;
}
.creategroup-modal-container.add.isOpenValidation {
	padding-top: 15vw;
}

.creategroup-modal-container.add.isOpenValidation {
	padding-top: 15vw;
}

.creategroup-modal {
	position: relative;
	background-color: var(--medium-grey);
	border-radius: 0.3vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2vw 4vw;
	text-align: center;
	/* height: 90vh !important;
	overflow-y: auto; */
	min-width: 50%;
	max-width: 50%;
}

button.close-button-modal {
	background-color: var(--grey);
	transition: all 0.3s ease-in-out;
}

button.close-button-modal.dark-button {
	background-color: var(--dark-grey);
}

.creategroup-modal .creategroup-header {
	border-bottom: 0.1vw solid var(--white);
	width: 96%;
}

.creategroup-modal .creategroup-header h1 {
	font-weight: bold;
	color: var(--white);
	margin-bottom: 1vw;
}

.creategroup-modal .creategroup-header h2 {
	margin-bottom: 1vw;
}

.creategroup-modal .creategroup-content {
	margin: 2vw 0;
	width: 66%;
}

.creategroup-modal .creategroup-content form textarea {
	border: none;
}

.creategroup-modal .creategroup-content form textarea:focus {
	outline: none;
}

.creategroup-modal .creategroup-content form input::-webkit-input-placeholder, .creategroup-modal .creategroup-content form textarea::-webkit-input-placeholder {
	font-size: 1.1vw;
	padding: 0.7vw;
}

.creategroup-modal .creategroup-content form input:-ms-input-placeholder, .creategroup-modal .creategroup-content form textarea:-ms-input-placeholder {
	font-size: 1.1vw;
	padding: 0.7vw;
}

.creategroup-modal .creategroup-content form input,
.creategroup-modal .creategroup-content form textarea,
.creategroup-modal .creategroup-content form input::placeholder,
.creategroup-modal .creategroup-content form textarea::placeholder,
.creategroup-modal .creategroup-content form select,
.creategroup-modal .creategroup-content form button.creategroup-button {
	font-size: 1.1vw;
	padding: 0.7vw;
}

.creategroup-modal .creategroup-content form button.creategroup-button {
	color: var(--close-button);
	background-color: var(--white);
	transition: all 0.3s ease-in-out;
}

.creategroup-modal .creategroup-content form button.creategroup-button:hover {
	background-color: var(--close-button);
	color: var(--white);
}

.creategroup-modal .creategroup-content form button.creategroup-button h2,
.creategroup-modal .creategroup-content form h2.header-text {
	font-weight: bold;
}

.creategroup-modal .creategroup-content form h2.header-text {
	color: var(--white);
	margin: 0.5vw 0 1.5vw;
	max-width: 32vw;
	/* width: 155%;
	position: relative;
	left: -6.8vw; */
}

.creategroup-modal .creategroup-content form select {
	border: none;
	background-image: unset;
}

.creategroup-modal .creategroup-content form .public-select {
	position: relative;
	width: 100%;
}

.creategroup-modal .creategroup-content form .public-select .public-caret {
	position: absolute;
	top: -1vw;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0;
	right: 0;
}

.creategroup-modal .creategroup-content form .public-select i {
	font-size: 1.66vw;
	color: var(--medium-grey);
}

/* creategroup-popup end */
/* sendmessage-popup start */
.send-message-modal {
	width: 45%;
	margin-top: 8vw;
	margin-bottom: 8vw;
	justify-content: flex-start;
}

#profileviewmodal {
	justify-content: flex-start;
	height: 70vh !important;
	margin-bottom: 8vw;
	overflow: auto;
}

.dropdown-container {
	display: none;
	position: absolute;
	background-color: var(--post-background);
	color: var(--close-button);
	width: 100%;
	top: 4vw;
	border-radius: 0.3vw;
	z-index: 9;
	padding: 1vw;
	text-align: left;
	height: 25vh;
	overflow-y: scroll;
	transition: height 0.3s ease-in-out;
}

/* .dropdown-container option {
	font-size: 1.5vw;
	font-weight: bold;
} */

.dropdown-container.show {
	display: block;
}

.dropdown-container .dropdown-info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 5%;
	gap: 5%;
	margin-bottom: 1vw;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.dropdown-container .dropdown-info:hover {
	background-color: var(--grey);
}

.dropdown-container .dropdown-info .profile-info {
	border-radius: 50%;
	width: 5vw;
	height: 5vw;
	overflow: hidden;
}

.dropdown-container .dropdown-info .profile-info img {
	width: 100%;
	height: 100%;
}

.dropdown-container .dropdown-info .name-info h2 {
	font-weight: bold;
}

.send-message-modal .sendmessage-content {
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 3vw;
}

.sendmessage-content .send-message-profile {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	align-items: center;
	grid-gap: 3%;
	gap: 3%;
}

.sendmessage-content .send-message-profile .profile {
	border-radius: 50%;
	overflow: hidden;
	width: 4vw;
	height: 4vw;
}

.sendmessage-content .send-message-profile .profile img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.sendmessage-content .send-message-profile .profile-name h1 {
	color: var(--white);
	font-weight: bold;
}

.sendmessage-content form.sendmessage-form {
	position: relative;
	margin-top: 2vw;
}

.sendmessage-form > div {
	width: 100%;
}

.sendmessage-content form.sendmessage-form button.search {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 1vw;
	top: 0;
	right: 0;
}

.sendmessage-content form.sendmessage-form button.search i {
	font-size: 2vw;
}

.sendmessage-content form.send-message-type {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.send-message-modal .sendmessage-content form input::-webkit-input-placeholder {
	color: var(--medium-grey);
}

.send-message-modal .sendmessage-content form input:-ms-input-placeholder {
	color: var(--medium-grey);
}

.send-message-modal .sendmessage-content form input::placeholder {
	color: var(--medium-grey);
}

.sendmessage-content form input.sendmessage {
	width: 70%;
	margin-bottom: 0;
}

.sendmessage-content form button.send {
	width: 25%;
	font-size: 1.66vw;
	margin: 0.3vw 0;
	background-color: var(--post-background);
	color: var(--close-button);
	transition: all 0.3s ease-in-out;
}

.sendmessage-content form button.send h1 {
	font-weight: bold;
}
/* sendmessage-popup end */

/* contactsupplier-popup start */
.contactsuuplier-modal {
	width: 45%;
}
/* contactsupplier-popup end */
/* downloadcaepipe-popup start */
.creategroup-modal .creategroup-content form button.caepipe-program {
	margin-top: 3vw;
}
/* downloadcaepipe-popup end */

/* profile-count modal start */
.profile-count-modal {
	/* height: 100%; */
}
/* profile-count modal end */

/* company-profile modal start */
.company-profile-container {
	position: absolute;
	background-color: #8a8989;
	padding: 4vw;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 50vw;
	overflow-y: auto;
	border-radius: 0.3vw;
	margin-top: 1vw;
}

.company-profile-container .cmpy-modal {
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 0;
}
/* company-profile modal end */

/* createpost-popup start */
.creategroup-modal .creategroup-content {
	margin: 2vw 0 0;
}

.postcreate-modal {
	width: 52%;
}

.create-post-content {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	width: 100%;
}

.sendmessage-content .create-post-profile {
	width: 100%;
	grid-gap: 3%;
	gap: 3%;
	justify-content: flex-start;
}

.sendmessage-content .create-post-profile .post-profile {
	border-radius: 50%;
	overflow: hidden;
	width: 5vw;
	height: 5vw;
}

.sendmessage-content .create-post-profile .post-profile img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.sendmessage-content .create-post-profile .post-name {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.sendmessage-content .create-post-profile .post-name h2 {
	color: var(--white);
	font-weight: bold;
	margin-bottom: 1vw;
	text-align: left;
}

.sendmessage-content .create-post-profile .post-name .my-group,
.sendmessage-content .createpost-form textarea,
.sendmessage-content .createpost-form input {
	background-color: var(--post-background);
	border-radius: 0.3vw;
}

.sendmessage-content .create-post-profile .post-name .individual-group {
	margin-top: 1vw;
}

.sendmessage-content .createpost-form textarea::-webkit-input-placeholder, .sendmessage-content .createpost-form input::-webkit-input-placeholder {
	color: var(--close-button) !important;
	font-weight: bold;
}

.sendmessage-content .createpost-form textarea:-ms-input-placeholder, .sendmessage-content .createpost-form input:-ms-input-placeholder {
	color: var(--close-button) !important;
	font-weight: bold;
}

.sendmessage-content .createpost-form textarea::placeholder,
.sendmessage-content .createpost-form input::placeholder {
	color: var(--close-button) !important;
	font-weight: bold;
}

.sendmessage-content .create-post-profile .post-name .my-group img.post-globe {
	width: 2vw;
	height: 2vw;
	padding: 0.2vw;
	top: 0.3vw;
	position: relative;
}

.sendmessage-content
	.create-post-profile
	.post-name
	.my-group
	select.selectpicker {
	border: none;
	padding: 0.5vw 2.8vw 0.5vw 0.5vw;
	background-color: transparent;
	border-left: 1px solid var(--light-grey);
	border-radius: 0;
	color: var(--close-button);
	font-size: 0.97vw;
	font-weight: bold;
}

.sendmessage-content
	.create-post-profile
	.post-name
	.individual-group
	select.individual-select {
	border-left: none;
}

.sendmessage-content .create-post-profile .post-name .my-group span {
	border-left: 1px solid var(--light-grey);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	right: 0;
}

.sendmessage-content .create-post-profile .post-name .my-group span i {
	font-size: 1.5vw;
	color: var(--close-button);
}

.form-control:focus {
	border-color: none;
	box-shadow: unset;
}

/* show-user start */
.show-user .show-user-form-group {
	position: absolute;
	top: -5vw;
	right: -8vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.show-user .color-box {
	position: absolute;
	display: flex;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	width: 2.5vw;
	height: 2.5vw;
	top: -5.15vw;
	right: -8.45vw;
}

.show-user .show-user-form-group label.show-user-text {
	font-weight: bold;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
}

.show-user .show-user-form-group input[type="checkbox"] {
	display: none;
	cursor: pointer;
}

.show-user-form-group input[type="checkbox"] + label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--close-button);
	border: 1px solid var(--close-button);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 0vw;
	margin-left: 1.5vw;
	width: 1.5vw;
	height: 1.5vw;
	z-index: 2;
}

.show-user-form-group input[type="checkbox"]:checked + label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.5vw;
	right: 0.5vw;
	width: 0.4vw;
	height: 1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	z-index: 2;
}

.create-post-content .show-user {
	position: relative;
	width: 100%;
	margin-bottom: 0.8vw;
}

.show-user .createpost-colorbox {
	top: -0.35vw;
	right: -0.35vw;
	width: 2.2vw;
	height: 2.2vw;
}

.show-user .show-user-post-group {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	top: 0;
	right: 0;
}

.show-user .createpost-colorbox {
	width: 2.2vw !important;
	height: 2.2vw !important;
	top: -2.3vw !important;
	right: 0.65vw !important;
}

.show-user .show-user-post-group {
	top: -2vw !important;
	right: 1vw !important;
}

.show-user .show-user-post-group label.post-user-text {
	font-size: 0.97vw !important;
	color: var(--white) !important;
}

.create-post-content
	.show-user-form-group
	input[type="checkbox"]:checked
	+ label:after {
	top: 0.2vw;
}

.postmodal-pannel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 1vw;
}

.postmodal-pannel .postmodal-content {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.postmodal-pannel .postmodal-content-image {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	padding: 7px;
}

.postmodal-pannel .postmodal-content-image img {
	/* width: 3vw;
	height: 3vw; */
	width: 100%;
}

.postmodal-pannel .postmodal-content h2 {
	color: var(--white);
	margin-top: 0.5vw;
}

.postmodal-pannel .postmodal-content h2,
.postmodal-pannel .postmodal-content button h1 {
	font-weight: bold;
}

.postmodal-pannel .postmodal-content button {
	background-color: var(--post-background);
	color: var(--close-button);
	padding: 0.6vw 2.5vw;
	transition: all 0.3s ease-in-out;
}

.postmodal-pannel .postmodal-content button:hover {
	background-color: var(--close-button);
	color: var(--post-background);
}
/* createpost-popup end */

/* profile count popup start */
.creategroup-modal .creategroup-content.count-container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	/* gap: 5%; */
	overflow-y: auto;
}
/* profile count popup end */

/* chatmessage-popup start */
#chat-modal {
	cursor: pointer;
}
.chat-send-message-modal {
	padding: 1.5vw 2vw 1vw;
	width: 40%;
}

.creategroup-modal .chat-group-box {
	border-bottom: 0.1vw solid var(--white);
	width: 100%;
}

.creategroup-modal .chat-group-box h1 {
	margin-bottom: 2vw;
}

.chat-message-container {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	padding: 1vw 2vw;
	margin: 2vw 0 1vw;
	width: 100%;
}

.chat-message-container .chat-message-profile {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 4%;
	gap: 4%;
}

.chat-message-profile .profile-content {
	border-radius: 50%;
	overflow: hidden;
	width: 5vw;
	height: 5vw;
}

.chat-message-profile .profile-content img {
	width: 5vw;
}

.chat-message-profile .profile-info h2,
.chat-message-container .chat-message-content h3 {
	font-weight: bold;
}

.chat-message-container .chat-message-content {
	margin: 1vw 0;
}

.chat-message-content .chat-date {
	text-align: center;
	margin-bottom: 2vw;
}

.chat-message-content .message-box-one,
.chat-message-content .message-box-two {
	position: relative;
	margin-bottom: 2vw;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.chat-message-content .message-box-two {
	justify-content: flex-end;
	align-items: flex-end;
}

.chat-message-content .message-box-one .message-content-one,
.chat-message-content .message-box-two .message-content-two {
	background-color: var(--white);
	border-radius: 0.3vw;
	border: 1px solid var(--light-grey);
	padding: 1vw 2vw;
	width: 50%;
	text-align: left;
	/* margin-top: 1vw; */
}

.chat-message-content .message-box-two .message-content-two {
	background-color: transparent;
	border: 1px solid var(--medium-grey);
}

.chat-message-content .message-box-one .message-content-one h3,
.chat-message-content .message-box-one .time-content-one h3,
.chat-message-content .message-box-two .message-content-two h3,
.chat-message-content .message-box-two .time-content-two h3 {
	color: var(--close-button);
}

.chat-message-content .message-box-one .time-content-one,
.chat-message-content .message-box-two .time-content-two {
	position: relative;
	top: 0.5vw;
	left: 2vw;
}

.chat-message-content .message-box-two .time-content-two {
	left: -10.5vw;
}

.chat-message-form {
	margin: 1vw 0;
	position: relative;
	width: 100%;
}

.chat-message-form input::-webkit-input-placeholder {
	font-size: 1.24vw;
	color: var(--medium-grey);
}

.chat-message-form input:-ms-input-placeholder {
	font-size: 1.24vw;
	color: var(--medium-grey);
}

.chat-message-form input,
.chat-message-form input::placeholder {
	font-size: 1.24vw;
	color: var(--medium-grey);
}

.chat-message-form input {
	border-radius: 0.3vw;
	padding: 1vw 1.5vw;
	color: var(--black);
	width: 100%;
}

.chat-message-form button {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	right: 1vw;
}

.chat-message-form button i {
	font-size: 1.5vw;
}
/* chatmessage-popup end */

/* search-popup start */
.search-field-container {
	margin-top: 2vw;
}

.navbar-action-search .search-form-popup {
	margin-left: 0vw;
}

.search-radiobutton {
	margin: 2vw 3vw 0vw;
}

.search-radiobutton input[type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}
.search-radiobutton input[type="radio"]:checked + label,
.search-radiobutton input[type="radio"]:not(:checked) + label {
	position: relative;
	padding-left: 6vw;
	cursor: pointer;
	line-height: 2;
	display: inline-block;
	color: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: bold;
	margin: 1vw 0;
}
.search-radiobutton input[type="radio"]:checked + label:before,
.search-radiobutton input[type="radio"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 2.5vw;
	height: 2.5vw;
	border: 1px solid #ddd;
	border-radius: 100%;
	background-color: var(--light-grey);
}
.search-radiobutton input[type="radio"]:checked + label:after,
.search-radiobutton input[type="radio"]:not(:checked) + label:after {
	content: "";
	width: 1.5vw;
	height: 1.5vw;
	background-color: var(--close-button);
	position: absolute;
	top: 0.5vw;
	left: 0.5vw;
	border-radius: 100%;
	transition: all 0.2s ease;
}
.search-radiobutton input[type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
.search-radiobutton input[type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
/* search-popup end */

/* multi-select */
.option-multi-select {
	margin-bottom: 0vw;
	background-image: unset;
	padding: 0;
}

.login > .option-multi-select > div{
padding: 0.6vw 1vw ;
}

.creategroup-content .option-multi-select {
	margin-bottom: 1vw;
}

.css-1s2u09g-control {
	border: none !important;
}

.css-319lph-ValueContainer {
	padding: 0 !important;
	text-align: left !important;
	font-size: 1.24vw !important;
}

.css-14el2xx-placeholder {
	font-size: 1.24vw !important;
	color: var(--grey) !important;
}

.css-1okebmr-indicatorSeparator,
.css-tlfecz-indicatorContainer {
	display: none !important;
}

.css-12jo7m5 {
	font-size: 1vw !important;
}

.srv-validation-message {
	font-size: 1.1vw;
}

@media screen and (max-width: 900px) {
	.css-319lph-ValueContainer,
	.css-14el2xx-placeholder {
		font-size: 2vw !important;
	}

	.creategroup-modal .creategroup-content form label {
		font-size: 2vw;
	}

	.creategroup-modal .creategroup-content form input::-webkit-input-placeholder, .creategroup-modal .creategroup-content form textarea::-webkit-input-placeholder {
		font-size: 2vw;
		padding: 1.5vw;
		margin-bottom: 2vw;
	}

	.creategroup-modal .creategroup-content form input:-ms-input-placeholder, .creategroup-modal .creategroup-content form textarea:-ms-input-placeholder {
		font-size: 2vw;
		padding: 1.5vw;
		margin-bottom: 2vw;
	}

	.creategroup-modal .creategroup-content form input,
	.creategroup-modal .creategroup-content form textarea,
	.creategroup-modal .creategroup-content form input::placeholder,
	.creategroup-modal .creategroup-content form textarea::placeholder,
	.creategroup-modal .creategroup-content form select,
	.creategroup-modal .creategroup-content form button.creategroup-button {
		font-size: 2vw;
		padding: 1.5vw;
		margin-bottom: 2vw;
	}

	.sendmessage-content form input.sendmessage {
		margin-bottom: 0;
	}

	.staticEdit_outerdiv .staticEdit_innerdiv{
		width:100%;
		/*font-size: 2vw;*/
	} 

	button.close-button-modal i {
		font-size: 2vw;
	}

	/* create post modal start */
	#modaloverflow {
		height: 55vw;
	}
	.creategroup-modal-container.add.isOpenValidation {
		padding-top: 20vw;
	}
	.creategroup-modal {
		padding: 3vw 4vw !important;
		max-width: 100% !important;
		width: 70%;
		overflow-y: auto;
		margin-bottom: 8vw;
		margin-top: 8vw;
	}
	button.close-button-modal {
		top: 2vw;
		right: 2vw;
	}
	.creategroup-modal .creategroup-content {
		margin: 4vw 0 0;
	}
	.sendmessage-content .create-post-profile {
		align-items: flex-start;
		grid-gap: 4%;
		gap: 4%;
	}
	.sendmessage-content .create-post-profile .post-profile img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.sendmessage-content .create-post-profile .post-name {
		width: 80%;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		img.post-globe {
		width: 3vw;
		height: 3vw;
		padding: 0.2vw;
		top: 0.7vw;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		select.selectpicker {
		padding: 1vw 3vw 1vw 1vw;
		font-size: 1.6vw;
	}
	.sendmessage-content .create-post-profile .post-name .my-group span i {
		font-size: 1.6vw;
	}
	.create-post-content .show-user {
		margin-bottom: 2vw;
	}
	
	.show-user .createpost-colorbox {
		width: 4vw !important;
		height: 4vw !important;
		top: -2.5vw !important;
		right: 0.4vw !important;
	}
	.show-user .show-user-post-group label.post-user-text {
		font-size: 2vw !important;
	}
	.sendmessage-content form.sendmessage-form {
		margin-top: 2vw;
	}
	.postmodal-pannel {
		margin-top: 2vw;
	}
	.postmodal-pannel .postmodal-content-image {
		width: 6vw;
		height: 6vw;
	}
	.postmodal-pannel .postmodal-content-image img {
		width: 100%;
	}
	.srv-validation-message {
		font-size: 2vw;
	}
	.postmodal-pannel .postmodal-content button {
		padding: 1vw 2.5vw;
	}
	.creategroup-modal-container.topSpace {
		padding-top: 18vw;
	}
	/* create post modal end */

	/* profile view modal start */
	#profileviewmodal {
		height: 60vw !important;
	}
	.like-modal {
		margin-bottom: 3vw;
	}
	/* profile view modal end */

	/* create group modal start */
	.create-model {
		padding-top: 18vw !important;
	}
	.creategroup-modal .creategroup-content form h2.header-text {
		max-width: 100%;
	}
	.creategroup-content .option-multi-select {
		margin-bottom: 2vw;
	}
	.creategroup-modal .creategroup-content form .public-select i {
		font-size: 2vw;
	}
	.creategroup-modal .creategroup-content form button.creategroup-button {
		width: 20vw;
	}
	/* create group modal end */

	/* message modal start */
	.like-modal-container.open {
		padding-bottom: 10vw !important;
		height: 100%;
	}
	.message-modal {
		width: 70%;
	}
	/* message modal end */

	/* textarea */
	.tox:not([dir="rtl"]) {
		width: 100% !important;
		margin-bottom: 2vw;
	}

	/* react selectors */
	.css-tj5bde-Svg {
		width: 2vw;
		height: 2vw;
	}
	.css-12jo7m5 {
		font-size: 2vw !important;
	}

	/* contact supplier modal start */
	.contactSup_modal {
		padding-top: 25vw !important;
	}
	/* contact supplier modal end */

	/* change password modal start */
	.input-group .password__change {
		top: 4vw;
	}
	.input-group .password__show i {
		font-size: 2.5vw;
	}
	/* change password modal end */

	/* login modal start */
	.login-popup {
		margin: 0vw 15vw;
		padding: 13vw 0;
	}
	.login-popup-container {
		padding: 4vw 4vw;
	}
	.login-popup-container .pexit-logo {
		width: 15vw;
	}
	.login-popup-container .pexit-logo img {
		width: 100%;
	}
	.login-popup-container .login-container {
		width: 70%;
	}
	.login-popup-container .login-container h1.header {
		margin: 2vw 0;
	}

	.login-content-fields form input,
	.creategroup-modal .creategroup-content form textarea,
	.creategroup-modal .creategroup-content form select {
		padding: 1vw 1vw;
		font-size: 2vw;
	}
	.social-buttons-content .social-button button,
	.login-content-fields form button.login-button {
		padding: 1vw 1vw;
		margin-bottom: 1vw;
		width: 100%;
		transition: all 0.3s ease-in-out;
	}
	.forgot-pass-link {
		padding: 1vw;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}
	/* login modal end */

	/* join now modal start */
	#joinnowmodal {
		width: 100%;
	}
	.joinnow-social-buttons {
		width: 65%;
	}
	.country-select {
		font-size: 2vw;
		padding: 1vw 1vw;
	}
	.login-content-fields form input::-webkit-input-placeholder, .creategroup-modal .creategroup-content form textarea::-webkit-input-placeholder {
		font-size: 2vw;
	}
	.login-content-fields form input:-ms-input-placeholder, .creategroup-modal .creategroup-content form textarea:-ms-input-placeholder {
		font-size: 2vw;
	}
	.login-content-fields form input::placeholder,
	.social-buttons-content .social-button button,
	.creategroup-modal .creategroup-content form textarea::placeholder,
	.creategroup-modal .creategroup-content form select,
	.linkedin h1,
	.caret-content .select-caret i {
		font-size: 2vw;
	}
	.code-select {
		font-size: 2vw;
		padding: 0.87vw 1vw;
	}
	/* join now modal end */
	#mycaepipe {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	/* checkbox changes in  createpost modal start */
	.show-user #createcolorbox,
	.show-user #sharecolorbox,
	.show-user #updatecolorbox {
		width: 3.5vw !important;
		height: 3.5vw !important;
		top: -2.3vw !important;
		right: 0.5vw !important;
	}
	/* checkbox changes in  createpost modal end */
}

/* mobile view popup css */
@media screen and (max-width: 599px) {
	/* login modal end */
	.login-popup {
		margin: 0vw 1vw;
		padding: 20vw 0;
	}
	.login-popup-container {
		padding: 6vw;
	}
	.login-popup-container .pexit-logo {
		width: 20vw;
	}
	.login-popup-container .login-container {
		width: 80%;
	}
	.login-popup-container .login-container h1.header {
		margin: 3vw 0;
	}
	.login-content-fields form input,
	.creategroup-modal .creategroup-content form textarea,
	.creategroup-modal .creategroup-content form select {
		padding: 2vw 2vw;
		font-size: 3vw;
		margin-bottom: 2vw;
	}
	.input-group .password__show {
		right: 1vw;
		top: 2.7vw;
	}
	.input-group .password__show i {
		font-size: 3.5vw;
	}
	.forgot-pass-link {
		margin-bottom: 2vw;
		width: 50%;
	}
	.login-popup-container .login-container .seperator {
		margin-bottom: 2vw;
	}
	.login-popup-container .login-container .seperator .line {
		width: 100%;
	}
	.social-buttons-content .social-button {
		width: 100%;
	}
	.social-buttons-content .social-button button,
	.login-content-fields form button.login-button {
		margin-bottom: 2vw;
		padding: 2vw 1vw;
	}
	.login-content-fields form input::-webkit-input-placeholder, .creategroup-modal .creategroup-content form textarea::-webkit-input-placeholder {
		font-size: 3vw;
	}
	.login-content-fields form input:-ms-input-placeholder, .creategroup-modal .creategroup-content form textarea:-ms-input-placeholder {
		font-size: 3vw;
	}
	.login-content-fields form input::placeholder,
	.social-buttons-content .social-button button,
	.creategroup-modal .creategroup-content form textarea::placeholder,
	.creategroup-modal .creategroup-content form select,
	.linkedin h1,
	.caret-content .select-caret i {
		font-size: 3vw;
	}
	button.close-button-modal {
		height: 7vw;
		width: 7vw;
	}
	button.close-button-modal i {
		font-size: 3vw;
	}
	/* login modal end */

	/* join now modal start */
	.login-container.joinnow-container {
		grid-gap: 6%;
		gap: 6%;
	}
	.login-popup-container .joinnow-content-fields form {
		width: 100%;
	}
	.joinnow-social-buttons {
		width: 70%;
	}
	#joinnowcontent {
		align-items: flex-end;
	}
	#joinnowcontent .joinnow-header {
		width: 100%;
	}
	.joinnow-content-fields form .mobile-number {
		grid-gap: 3%;
		gap: 3%;
	}
	.code-select {
		font-size: 3vw;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}
	.country-select {
		font-size: 3vw;
		padding: 2vw 2vw;
	}
	.login-popup-container .joinnow-content-fields form h1.joinnow-text {
		margin: 4vw 0vw 4vw;
	}
	#joinnowcaret {
		width: unset;
	}
	/* join now modal end */

	/* create group modal start */
	.creategroup-modal-container.add.isOpenValidation {
		padding-top: 5vw;
	}
	.creategroup-modal {
		padding: 6vw 4vw !important;
		width: 100%;
		margin: 5vw 1vw;
		justify-content: flex-start;
	}
	.sendmessage-content .create-post-profile .post-profile {
		width: 8vw;
		height: 8vw;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		img.post-globe {
		width: 5vw;
		height: 5vw;
		padding: 0.5vw;
		top: 0.3vw;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		select.selectpicker,
	.sendmessage-content .create-post-profile .post-name .my-group span i {
		font-size: 2.2vw;
	}
	.sendmessage-content
		.create-post-profile
		.post-name
		.my-group
		select.selectpicker {
		padding: 1vw 10vw 1vw 1vw;
	}
	.create-post-content .show-user {
		margin-bottom: 3vw;
	}
	.show-user #createpostmodal,
	.show-user #sharepostmodal,
	.show-user #updatepostmodal {
		width: 4vw !important;
		height: 4vw !important;
		top: -1.8vw !important;
		right: 0.2vw !important;
	}
	.show-user .show-user-post-group label.post-user-text {
		font-size: 3vw !important;
	}
	.create-post-content
		.show-user-form-group
		input[type="checkbox"]:checked
		+ label:after {
		top: 1vw;
	}
	.sendmessage-content form.sendmessage-form {
		margin-top: 4vw;
	}

	.creategroup-modal .creategroup-content form input::-webkit-input-placeholder, .creategroup-modal .creategroup-content form textarea::-webkit-input-placeholder {
		font-size: 3vw;
		padding: 2vw;
		margin-bottom: 2vw;
	}

	.creategroup-modal .creategroup-content form input:-ms-input-placeholder, .creategroup-modal .creategroup-content form textarea:-ms-input-placeholder {
		font-size: 3vw;
		padding: 2vw;
		margin-bottom: 2vw;
	}

	.creategroup-modal .creategroup-content form input,
	.creategroup-modal .creategroup-content form textarea,
	.creategroup-modal .creategroup-content form input::placeholder,
	.creategroup-modal .creategroup-content form textarea::placeholder,
	.creategroup-modal .creategroup-content form select,
	.creategroup-modal .creategroup-content form button.creategroup-button {
		font-size: 3vw;
		padding: 2vw;
		margin-bottom: 2vw;
	}
	.postmodal-pannel .postmodal-content-image {
		width: 12vw;
		height: 12vw;
	}
	.postmodal-pannel .postmodal-content button {
		padding: 2vw 2.5vw;
	}
	.postmodal-pannel .postmodal-content h2 {
		margin-top: 2vw;
	}
	.creategroup-modal .creategroup-content form .public-select i {
		font-size: 4vw;
	}
	/* create group modal end */

	/* invite modal start */
	.contact-modal.open {
		padding-top: 5vw !important;
	}
	.invite-modal {
		width: 100% !important;
	}
	.creategroup-modal .creategroup-content .contact-container label.name {
		width: 40vw;
	}
	.creategroup-modal .creategroup-content form label {
		font-size: 3vw;
	}
	/* invite modal end */

	/* react selector */
	.css-319lph-ValueContainer,
	.css-14el2xx-placeholder {
		font-size: 3vw !important;
	}
	.css-tj5bde-Svg {
		width: 4vw;
		height: 4vw;
	}

	/* send message modal start */
	.sendmessage-content .send-message-profile .profile {
		width: 8vw;
		height: 8vw;
	}
	.sendmessage-content .send-message-profile .profile img {
		width: 100%;
		height: 100%;
	}
	.sendmessage-content form.send-message-type {
		flex-direction: column;
		width: 100%;
	}
	.creategroup-modal .creategroup-content form input {
		width: 100%;
	}
	.sendmessage-content form button.send {
		margin: 1vw 0;
	}
	/* send message modal end */

	/* report modal start */
	.report-modal-container {
		padding-top: 5vw;
	}
	.report-modal {
		width: 100%;
	}
	.creategroup-modal .creategroup-content {
		margin: 6vw 0 0;
	}
	.report-content i,
	.report-content .form-select {
		font-size: 3vw;
	}
	.report-content .form-select {
		padding: 2vw;
	}
	.report-submit .submit {
		padding: 2vw;
		margin-top: 5vw;
	}
	.srv-validation-message {
		font-size: 3vw;
	}
	.company-profile-container .cmpy-modal iframe {
		width: 100%;
		height: 100vh;
	}
	/* report modal end */

	/* apply for job modal start */
	form button.jobapplyBtn {
		font-size: 3vw;
		padding: 2vw 1vw;
	}

	.show-job-container {
		margin: 3vw 0 0 0;
	}
	.creategroup-modal-container.applyjobmodal {
		padding-top: 5vw;
		padding-bottom: 0vw;
	}
	.input-group .password__show {
		right: 1vw;
		top: 3vw;
	}
	.submit-button {
		margin-top: 6vw;
	}

	.creategroup-modal .creategroup-content form button.creategroup-button {
		margin-top: 2vw;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}
	/* apply for job modal end */

	/* message modal start */
	.login-content-fields form,
	.social-buttons-content {
		align-items: flex-start;
	}
	.like-modal-container.open {
		padding-bottom: 5vw !important;
	}
	.like-modal {
		margin: 5vw 0vw 5vw 0vw;
	}
	.message-modal form textarea.msg-description {
		font-size: 3vw;
	}
	.login-content-fields form button.msg-button {
		width: 20vw !important;
		padding: 2vw 2vw !important;
		margin-top: 4vw;
	}
	.message-modal {
		width: 100%;
		/* height: 100vw; */
	}
	#messagemodal {
		margin: 5vw 3vw 5vw 3vw;
		height: 60vh;
	}
	/* message modal end */

	/* search modal start */
	.search-radiobutton {
		margin: 7vw 3vw 0vw;
	}

	.search-radiobutton input[type="radio"]:checked + label,
	.search-radiobutton input[type="radio"]:not(:checked) + label {
		padding-left: 10vw;
		margin: 3vw 0;
	}
	.search-radiobutton input[type="radio"]:checked + label:before,
	.search-radiobutton input[type="radio"]:not(:checked) + label:before {
		width: 4.5vw;
		height: 4.5vw;
	}
	.search-radiobutton input[type="radio"]:checked + label:after,
	.search-radiobutton input[type="radio"]:not(:checked) + label:after {
		width: 3vw;
		height: 3vw;
		top: 0.75vw;
		left: 0.75vw;
	}
	/* search modal end */

	#mySupplier {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		padding: 3vw 8vw;
	}
	.creategroup-modal-container,
	.create-post-modal,
	.chat-message-modal,
	.search-modal-container,
	.sendmessage-modal-container,
	.sending-modal,
	.group-modal-container {
		align-items: flex-start;
	}
	#profileviewmodal {
		height: 100vw !important;
	}
	.like-modal .user-profile {
		width: 9vw;
		height: 9vw;
	}
	.like-modal .user-profile img {
		width: 100%;
		height: 100%;
	}
	.creategroup-modal-container.add.open {
		padding-top: 10vw;
		padding-bottom: 0vw;
	}
	.creategroup-modal-container.topSpace {
		padding-top: 5vw;
	}
	#modaloverflow {
		height: auto;
	}
	.creategroup-modal .creategroup-content.invite-friends-modal {
		width: 100%;
	}
	.contactSup_modal {
		padding-top: 5vw !important;
	}

	/* createpost modal changes start */
	.show-user-form-group input[type="checkbox"] + label#createlabelbox::after,
	.show-user-form-group input[type="checkbox"] + label#sharelabelbox::after,
	.show-user-form-group input[type="checkbox"] + label#updatelabelbox::after {
		top: 0.4vw;
	}

	.show-user .createpost-colorbox,
	.show-user .createpost-colorbox,
	.show-user .createpost-colorbox {
		width: 6vw !important;
		height: 6vw !important;
		top: -2.6vw !important;
		right: -0.1vw !important;
	}
	.sendmessage-content .create-post-profile .post-name h2 {
		font-size: 3vw;
	}
	.sendmessage-content .create-post-profile .post-name .my-group span i {
		display: block;
	}
	/* createpost modal changes end */

	/* modals fonts changes start */
	.creategroup-modal .creategroup-content form button.creategroup-button h2,
	.creategroup-modal .creategroup-content form h2.header-text,
	.creategroup-modal .creategroup-content.delete-buttons-container button h2,
	.login-content-fields form button.msg-button h2 {
		font-size: 3vw;
	}

	.creategroup-modal .creategroup-content form button.creategroup-button h2,
	.creategroup-modal .creategroup-content.delete-buttons-container button h2,
	.login-content-fields form button.msg-button h2 {
		white-space: nowrap;
	}
	.staticEdit_outerdiv .staticEdit_innerdiv{
		/*font-size: 3.1vw;*/
		width: 100%;
	} 
	/* modals fonts changes end */
}

.css-tj5bde-Svg {
	display: block !important;
}

.create-model {
	padding-top: 5vw !important;
}

@media screen and (max-width: 900px) {
	.create-model {
		padding-top: 18vw !important;
	}
}

@media screen and (max-width: 599px) {
	.create-model {
		padding-top: 5vw !important;
	}
}
.uploadGroupImage label{
	width: 56%;
	margin-bottom: 1.2vw;
}
.uploadGroupImage{
	display: flex;
	flex-direction: row;
	width: -webkit-max-content;
	width: max-content;
	align-items: center;
}
/* product-page start */

.product-section .left-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	z-index: 1;
	top: 13vw;
	left: 0;
	bottom: 0;
	padding: 3vw 4vw 25vw 2vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
	text-align: left;
}

.product-section .left-sidebar .chat-arrow {
	position: absolute;
	top: 0vw;
	right: 4vw;
}

.product-section .left-sidebar .chat-arrow img {
	width: 2vw;
}

.sidebar-container .header-content h2,
.content-container .sidebar-content .header-content h3 {
	font-weight: bold;
}

.sidebar-container .content-container {
	margin-top: 3vw;
}

.content-container .sidebar-content {
	margin-bottom: 2.5vw;
}

.content-container .sidebar-content input {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1.2vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.content-container .sidebar-content input::-webkit-input-placeholder {
	font-size: 1.2vw;
}

.content-container .sidebar-content input:-ms-input-placeholder {
	font-size: 1.2vw;
}

.content-container .sidebar-content input::placeholder {
	font-size: 1.2vw;
}

.content-container .sidebar-content input::-webkit-input-placeholder {
	color: var(--close-button);
}

.content-container .sidebar-content input:-ms-input-placeholder {
	color: var(--close-button);
}

.content-container .sidebar-content input::placeholder,
.content-container .sidebar-content .header-content h3 {
	color: var(--close-button);
}

.content-container .sidebar-content .caret-content{
	margin-top: 1vw;
	/* padding: 0vw 1vw; */
	border:1px solid var(--grey);
	border-radius: 0.3vw;
}

.content-container .sidebar-content .caret-content .select-caret {
	position: absolute;
	right: 1vw;
	top: 0vw;
	bottom: 0vw;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--close-button);
	z-index: 1;
}

.content-container .sidebar-content .caret-content .select-caret i,
.content-container .sidebar-content .caret-content .form-select {
	font-size: 1.2vw;
	color: var(--close-button);
}

.content-container .sidebar-content .caret-content .form-select {
	background-image: none;
	padding: 0.5vw 1.5vw;
	border-radius: 0.3vw;
}

/* location-select */
.content-container
	.sidebar-content
	.caret-content
	.form-select.location-select {
	padding: 0;
}

.content-container .sidebar-button {
	/* float: right; */
	text-align: right;
	margin-right: 1.5vw;
}

.content-container .sidebar-button button {
	background-color: var(--post-background);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	color: var(--close-button);
	font-size: 0.97vw;
	width: 7vw;
}

.product-container {
	position: relative;
	margin: 11vw 14vw 15vw 25vw;
	padding-bottom: 1vw;
}

.product-container .product-suppliers {
	position: absolute;
	right: -10vw;
}

.product-container .product-suppliers label {
	color: var(--close-button);
	font-weight: bold;
	font-size: 1.1vw;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
}

.product-container .product-suppliers input[type="checkbox"] {
	display: none;
	cursor: pointer;
}

.product-container .product-suppliers input[type="checkbox"] + label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 0vw;
	margin-left: 1.5vw;
	width: 1.5vw;
	height: 1.5vw;
	z-index: 2;
}

.product-container
	.product-suppliers
	input[type="checkbox"]:checked
	+ label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.1vw;
	right: 0.5vw;
	width: 0.5vw;
	height: 1.1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	z-index: 2;
}

.product-container .product-box-container {
	border-left: 1px solid var(--grey);
	margin-left: -2vw;
}

.product-container .product-box-container .product-boxes {
	/* padding: 3vw 0vw 4vw 2vw; */
	padding: 1vw 0vw 6vw 2vw;
}

.product-container .product-box-container .product-header h1 {
	font-weight: bold;
}

.product-boxes .boxes-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-template-rows: repeat(5, 23.6vw); */
	/* grid-template-rows: repeat(5, 23vw); */
	grid-gap: 2vw;
	margin-top: 2vw;
	/* width: 101%; */
}

.product-boxes-content {
	position: relative;
	border-radius: 0.3vw;
	border: 1px solid var(--white);
	box-shadow: rgba(60, 64, 67, 0.3) 0px 0px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	/* width: 250px; */
	height: 100%;
}

.product-boxes-content .product-email,
.supplier-box-container .product-email {
	position: absolute;
	right: 0vw;
	top: 0vw;
}

.product-boxes-content .product-edit,
.supplier-box-container .product-edit {
	position: absolute;
	right: 0vw;
	bottom: 0vw;
}

.product-boxes-content .product-email button,
.supplier-box-container .product-email button {
	background-color: transparent;
}

.product-boxes-content .product-edit button,
.supplier-box-container .product-edit button {
	background-color: transparent;
}

.product-boxes-content button.renewPro{
	background-color: var(--grey);
	
}

.product-boxes-content .product-email img,
.supplier-box-container .product-email img {
	width: 3vw;
}

.product-boxes-content .product-edit img,
.supplier-box-container .product-edit img {
	width: 1.5vw;
}

.product-boxes-content .product-image img {
	/* width: 19vw; */
	height: 15vw;
	width: 100%;
    object-fit: cover;
}

.product-boxes-content .product-info {
	padding: 1vw;
}

.product-boxes-content .product-info h2,
.supplier-box-container .supplier-info h1,
.supplier-box-container .supplier-info h2,
.supplier-box-container .supplier-boxes-content h2 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.product-boxes-content .product-info h2.grey-header,
.supplier-box-container .supplier-info h2.grey-header {
	color: var(--close-button);
	word-break: break-all;
}
/* product-page end */



.css-14el2xx-placeholder,
.css-qc6sy-singleValue {
	color: var(--close-button) !important;
}

.css-tj5bde-Svg {
	display: none;
}

.suppliers-label {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.caret-content div:nth-child(3){
	padding: 0px 1vw;
}

.grey-header .content-css .my-anchor-css-class {
	color: var(--red);
}

.grey-header .content-css span{
	font-size: 1.2vw !important;
	color: var(--close-button) !important;
	font-weight: bold;
}

@media screen and (max-width: 900px) {
	.product-boxes .boxes-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 3vw;
		margin-top: 4vw;
	}
	.product-container {
		margin: 24vw 14vw 15vw 28vw;
	}
	.product-section .left-sidebar {
		width: 26%;
		top: 24vw;
		padding: 3vw 3vw 50vw 2vw;
	}
	.content-container .sidebar-content .input-field {
		padding: 0.8vw 1.5vw;
		font-size: 2vw;
		width: 100%;
	}
	.content-container .sidebar-content input::-webkit-input-placeholder {
		font-size: 2vw;
	}
	.content-container .sidebar-content input:-ms-input-placeholder {
		font-size: 2vw;
	}
	.content-container .sidebar-content .caret-content .select-caret i,
	.content-container .sidebar-content .caret-content .form-select,
	.content-container .sidebar-content input::placeholder {
		font-size: 2vw;
	}
	.content-container
		.sidebar-content
		.caret-content
		.form-select.location-select {
		padding: 0vw 1.5vw;
	}
	.product-section .left-sidebar .chat-arrow {
		width: 3vw;
	}
	.product-section .left-sidebar .chat-arrow img {
		width: 100%;
	}
	.content-container .sidebar-button button {
		font-size: 2vw;
		/* width: 15vw; */
		width: 100%;
		height: auto !important;
	}
	.content-container .sidebar-content {
		margin-bottom: 3vw;
	}
	.sidebar-container .sidebar-content .header-content h3 {
		margin-bottom: 1vw;
	}
	.product-boxes-content .product-email img,
	.supplier-box-container .product-email img {
		width: 5vw;
	}
	.product-boxes-content .product-edit img,
	.supplier-box-container .product-edit img {
		width: 100%;
	}
	.product-container .product-box-container .product-boxes {
		padding: 3vw 0vw 6vw 3vw;
	}
	.content-container .sidebar-content input {
		padding: 0.5vw 1.5vw;
		font-size: 2vw;
		width: 100%;
	}
}

@media screen and (max-width: 599px) {
	.product-container {
		margin: 34vw 4vw 15vw 4vw;
	}
	.product-container .product-box-container {
		border-left: 0;
	}
	.product-container .product-box-container .product-boxes {
		padding: 6vw 0vw 15vw 3vw;
	}
	.product-container .product-suppliers {
		right: 0vw;
		top: 1vw;
	}
	.product-section .left-sidebar .chat-arrow {
		display: none;
	}
	.product-boxes-content .product-info h2.grey-header p {
		font-size: 3vw;
		line-height: 1.3;
	}
	.product-boxes-content .product-info h2,
	.supplier-box-container .supplier-info h1,
	.supplier-box-container .supplier-info h2,
	.supplier-box-container .supplier-boxes-content h2 {
		font-size: 3vw;
	}
	.product-section .left-sidebar {
		width: 75%;
		top: 40vw;
		padding: 6vw 4vw 70vw 4vw;
		background-color: #fff;
		box-shadow: 0 1rem 1rem rgb(0 0 0 / 50%);
	}
	.sidebar-container .header-content h2 {
		font-size: 3.5vw;
		margin-bottom: 2vw;
	}
	.content-container .sidebar-content .header-content h3 {
		font-size: 3vw;
	}
	.sidebar-container .content-container {
		margin-top: 4vw;
	}
	.content-container .sidebar-content {
		margin-bottom: 4vw;
	}
	.content-container .sidebar-content .input-field {
		padding: 2vw 2vw;
		font-size: 3vw;
	}
	.content-container .sidebar-content input::-webkit-input-placeholder {
		font-size: 3vw;
	}
	.content-container .sidebar-content input:-ms-input-placeholder {
		font-size: 3vw;
	}
	.content-container .sidebar-content .caret-content .select-caret i,
	.content-container .sidebar-content .caret-content .form-select,
	.content-container .sidebar-content input::placeholder {
		font-size: 3vw;
	}
	.content-container .sidebar-content .caret-content .form-select {
		padding: 2vw 2vw;
	}
	.content-container .sidebar-button {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.content-container .sidebar-button button {
		font-size: 3vw;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		padding: 2vw 8vw;
	}
	.product-boxes-content .product-email img,
	.supplier-box-container .product-email img {
		width: 12vw;
	}
	.content-container .sidebar-content input {
		padding: 2vw 2vw;
		font-size: 3vw;
		margin-top: 3vw;
	}
	.content-container .sidebar-content span {
		font-size: 3.5vw;
	}
}




.chat-button {
	position: fixed;
	bottom: 10vw;
	right: 5vw;
	cursor: pointer;
	z-index: 9;
}

.chat-button .chat-button-content {
	width: 5vw;
}

.chat-button .chat-button-content img {
	width: 100%;
}

@media screen and (max-width: 900px) {
	/* chat-button start */
	.chat-button {
		right: 1vw;
		bottom: 9vw;
	}
	.chat-button .chat-button-content {
		width: 8vw;
	}
	.chat-button .chat-button-content img {
		width: 100%;
	}
	/* chat-button end */
}

@media screen and (max-width: 599px) {
	.chat-button .chat-button-content {
		width: 15vw;
	}
	.chat-button {
		bottom: 20vw;
	}
}

.report-modal-container {
	padding-top: 12vw;
	padding-bottom: 12vw;
	z-index: 999;
}

.report-modal {
	height: 100%;
	width: 50%;
	align-items: center;
	justify-content: flex-start;
	padding: 4vw;
}

.creategroup-modal .report-box {
	width: 100%;
}

.report-content {
	position: relative;
	margin: 0vw 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.report-content i {
	position: absolute;
	right: 1vw;
	color: var(--medium-grey);
	font-size: 1vw;
}

.report-content .form-select {
	color: var(--medium-grey);
	padding: 1vw;
	font-size: 1.5vw;
	background-image: unset;
}

.report-submit .submit {
	width: 100%;
	padding: 1vw;
	margin-top: 3vw;
	background-color: var(--black);
	color: var(--white);
	font-weight: bold;
}

#reportpopup {
	justify-content: flex-start;
	height: 80vh;
}

@media screen and (max-width: 599px) {
	.report-modal-container {
		padding-top: 5vw;
		padding-bottom: 0vw;
	}
	.report-modal {
		height: 100vw;
	}
	#reportpopup {
		height: 70vh;
	}
}

.ad_layout01{
    display: grid;
    grid: 
    "title title"   
    "image description";
    grid-auto-columns: 1fr;
    padding: 1.5vw;
}

.layout01_outer_div > div > h6{
    font-size: 1vw;
}

.layout01-title{
    grid-area: title;
    text-align: center;
    background-color: var(--medium-grey);
    padding: 1.5vw;
    margin-bottom: 2vw ;
}
.layout01-title h3{
    font-size: 1.3vw;
    color: #ffffff;
}

.layout01-image{
    grid-area: image;
    border-right: black 0.5px solid;
    padding-right:1.5vw ;
}

.layout01-image img{
    width: 100%;
}

.layout01-des{
    padding-left:1.5vw ;
    grid-area: description;
    font-size: 1.1vw;
}

.layout01-des .content-css{
    text-align: left;
}

.layout01_outer_div{
    border: black solid 0.2vw;
    border-radius: 1vw;
    padding: 1vw;
    margin-bottom: 1.5vw;
}

.profile-content .menu-dots img {
    width: 0.4vw;
    cursor: pointer;
}

.profile-content .menu-dots {
    transition: all 0.3s ease;
}

.profile-content .menu-container {
    position: relative;
}

.profile-content .menu-container .user-menu-content h2 {
    color: var(--close-button);
    font-weight: bold;
    cursor: pointer;
    font-size: 1.2vw;
    margin-bottom: 0;
}

.profile-content .menu-container .user-menu-content {
    background-color: var(--post-background);
    position: absolute;
    border: 0.1vw solid var(--close-button);
    padding: 0.5vw;
    right: 1vw;
    top: 0vw;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 9vw;
    display: none;
}

.profile-content .menu-container .user-menu-content.open {
    opacity: 1;
    display: block;
}

.layout01_outer_div .profile-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@media screen and (max-width: 900px){
    .layout01-des{
        padding-left:1.5vw ;
        font-size: 1.8vw;
    }
    .layout01_outer_div > div > h6{
        font-size: 1.3vw;
    }
    .layout01-title h3{
        font-size: 2.5vw;
    }

    .layout01_outer_div .profile-content .menu-dots img {
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 599px){
    .layout01-des{
        padding-left:1.5vw ;
        font-size: 2.5vw;
    }
    .layout01_outer_div > div > h6{
        font-size: 2vw;
    }
    .layout01-title h3{
        font-size:2.5vw;
    }
    .layout01_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.5vw;
    }

    .layout01_outer_div .profile-content .menu-container .user-menu-content {
        padding: 2vw;
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
        right: 3vw;
        white-space: nowrap;
    }
}

@media screen and (max-width: 499px){
    .layout01_outer_div .profile-content .menu-dots {
        width: 1.1vw;
    }

    .layout01_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.8vw;
    }
}

@media screen and (max-width: 399px){
    .layout01_outer_div .profile-content .menu-dots {
        width: 1.2vw;
    }

    .layout01_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 3.1vw;
    }
}
.layout01_outer_div .ad_layout01 .layout01-des span a.my-anchor-css-class{
    color: var(--red);
}

.layout01-image-path{
    max-height: 500px;
    object-fit: scale-down;
}
.ad_layout02{
    display: grid;
    grid: 
    "title title"   
    "image image ";
    /* grid-auto-columns: 1fr; */
    grid-auto-columns: 1fr 0fr;
    padding: 2vw;
}

.layout02_outer_div > div > h6{
    font-size: 1vw;
}

.layout02-title{
    grid-area: title;
    text-align: center;
    background-color: var(--medium-grey);
    padding: 1.3vw;
    margin-bottom: 2vw ;
}
.layout02-title h3{
    font-size: 1.3vw;
    color: #ffffff;
}

.layout02-image{
    /* grid-area: image; */
    max-height: 40vh;
    width: 100%;
    text-align: center;
}

.layout02-image img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
.layout02_outer_div{
    border: black solid 0.2vw;
    border-radius: 1vw;
    padding: 2vw;
    margin-bottom: 1.5vw;
}

.profile-content .menu-dots img {
    width: 0.4vw;
    cursor: pointer;
}

.profile-content .menu-dots {
    transition: all 0.3s ease;
}

.profile-content .menu-container {
    position: relative;
}

.profile-content .menu-container .user-menu-content h2 {
    color: var(--close-button);
    font-weight: bold;
    cursor: pointer;
    font-size: 1.2vw;
    margin-bottom: 0;
}

.profile-content .menu-container .user-menu-content {
    background-color: var(--post-background);
    position: absolute;
    border: 0.1vw solid var(--close-button);
    padding: 0.5vw;
    right: 1vw;
    top: 0vw;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 9vw;
    display: none;
}

.profile-content .menu-container .user-menu-content.open {
    opacity: 1;
    display: block;
}

.layout02_outer_div .profile-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ad_layout02 .iframeutub{
    max-height: unset;
}

.iframeutub iframe{
    height: 400px;
}


@media screen and (max-width: 900px){
    .layout02-des{
        padding-left:1.5vw ;
        font-size: 1.8vw;
    }
    .layout02_outer_div > div > h6{
        font-size: 1.3vw;
    }
    .layout02-title h3{
        font-size: 1.8vw;
    }

    .layout02_outer_div .profile-content .menu-dots img {
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 599px){
    .layout02-des{
        padding-left:1.5vw ;
        font-size: 2.5vw;
    }
    .layout02_outer_div > div > h6{
        font-size: 2vw;
    }
    .layout02-title h3{
        font-size: 2.5vw;
    }

    .layout02_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.5vw;
    }

    .layout02_outer_div .profile-content .menu-container .user-menu-content {
        padding: 2vw;
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
        right: 3vw;
        white-space: nowrap;
    }

}

@media screen and (max-width: 499px){
    .layout02_outer_div .profile-content .menu-dots {
        width: 1.1vw;
    }

    .layout02_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.8vw;
    }
}

@media screen and (max-width: 399px){
    .layout02_outer_div .profile-content .menu-dots {
        width: 1.2vw;
    }

    .layout02_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 3.1vw;
    }
}
.ad_layout03{
    display: grid;
    grid: 
    "image description";
    grid-auto-columns: 1fr;
    padding: 1.5vw;
}

.layout03_outer_div > div > h6{
    font-size: 1vw;
}

.layout03-image{
    grid-area: image;
    border-right: black 0.5px solid;
    padding-right:1.5vw ;
}

.layout03-image img{
    width: 100%;
}

.layout03-des{
    padding-left:1.5vw ;
    grid-area: description;
    font-size: 1.1vw;
}

.layout03-des .content-css{
    text-align: left;
}

.profile-content .menu-dots img {
    width: 0.4vw;
    cursor: pointer;
}

.profile-content .menu-dots {
    transition: all 0.3s ease;
}

.profile-content .menu-container {
    position: relative;
}

.profile-content .menu-container .user-menu-content h2 {
    color: var(--close-button);
    font-weight: bold;
    cursor: pointer;
    font-size: 1.2vw;
    margin-bottom: 0;
}

.profile-content .menu-container .user-menu-content {
    background-color: var(--post-background);
    position: absolute;
    border: 0.1vw solid var(--close-button);
    padding: 0.5vw;
    right: 1vw;
    top: 0vw;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 9vw;
    display: none;
}

.profile-content .menu-container .user-menu-content.open {
    opacity: 1;
    display: block;
}

.layout03_outer_div .profile-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@media screen and (max-width: 900px){
    .layout03-des{
        padding-left:1.5vw ;
        font-size: 1.8vw;
        word-spacing: 0.1px;
    }
    .layout03_outer_div > div > h6{
        font-size: 1.3vw;
    }
        .layout01-title h3{
        font-size: 1.8vw;
    }
    .layout03_outer_div .profile-content .menu-dots img {
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 599px){
    .layout03-des{
        padding-left:1.5vw ;
        font-size: 2.5vw;
    }
    .layout03_outer_div > div > h6{
        font-size: 2vw;
    }

    .layout03_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.5vw;
    }

    .layout03_outer_div .profile-content .menu-container .user-menu-content {
        padding: 2vw;
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
        right: 3vw;
        white-space: nowrap;
    }
}

@media screen and (max-width: 499px){
    .layout03_outer_div .profile-content .menu-dots {
        width: 1.1vw;
    }

    .layout03_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.8vw;
    }
}

@media screen and (max-width: 399px){
    .layout03_outer_div .profile-content .menu-dots {
        width: 1.2vw;
    }

    .layout03_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 3.1vw;
    }
}
.layout03_outer_div{
    border: black solid 0.2vw;
    border-radius: 1vw;
    padding: 1vw;
    margin-bottom: 1.5vw;
}
.layout03_outer_div .ad_layout03 .layout03-des span a.my-anchor-css-class{
    color: var(--red);
}

.layout03-image-path{
    max-height: 500px;
    object-fit: cover;
}
.sp_layout {
    display: grid;
    grid:
        "title title"
        "image image "
        "desc desc ";
    /* grid-auto-columns: 1fr; */
    grid-auto-columns: 1fr 0fr;
    padding: 2vw;
}

.layout-desc {
    grid-area: desc;
    /* max-height: 50vh; */
    width: 100%;
    text-align: center;
}

.layout-image {
    grid-area: image;
    max-height: 40vh;
    width: 100%;
    text-align: center;
}

.layout-image .doc_image {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.layout-docu img{
    width: 100%;
    /* width: 5vw;
    height: 5vw; */
}

.layout-docu .doc_doc{
    width: 5vw;
    height: 5vw;
}


.layout-image iframe{
    width: 100%;
}

.layout-desc .content-css {
   margin-top: 2vw;
   text-align: left;
}

.sponsor_layout_outer_div .profile-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.sponsor_layout_outer_div .profile-content .post-info{
    justify-content: flex-start;
    align-items: center;
    grid-gap: 3%;
    grid-gap: 3%;
    gap: 3%;
    display: flex;
    width: 100%;
}

.sponsor_layout_outer_div .profile-content .post-info .post-profile{
    width: 3vw;
    height: 3vw;
    align-self: flex-start;
    border-radius: 50%;
    overflow: hidden;
    border: 0;
}

.sponsor_layout_outer_div .profile-content .post-info .post-profile img{
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.layout-desc span a.my-anchor-css-class {
    color: var(--red);
}

.sp_layout #layout-desc .content-css span{
    font-size: 1.2vw !important;
}

.profile-content .menu-dots img {
    width: 0.4vw;
    cursor: pointer;
}

.profile-content .menu-dots {
    transition: all 0.3s ease;
}

.profile-content .menu-container {
    position: relative;
}

.profile-content .menu-container .user-menu-content h2 {
    color: var(--close-button);
    font-weight: bold;
    cursor: pointer;
    font-size: 1.2vw;
    margin-bottom: 0;
}

.profile-content .menu-container .user-menu-content {
    background-color: var(--post-background);
    position: absolute;
    border: 0.1vw solid var(--close-button);
    padding: 0.5vw;
    right: 1vw;
    top: 0vw;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 9vw;
    display: none;
}

.profile-content .menu-container .user-menu-content.open {
    opacity: 1;
    display: block;
}

@media screen and (max-width: 900px){

    .sponsor_layout_outer_div .profile-content .menu-dots img {
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 599px){
    .sponsor_layout_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.5vw;
    }

    .sponsor_layout_outer_div .profile-content .menu-container .user-menu-content {
        padding: 2vw;
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
        right: 3vw;
        white-space: nowrap;
    }

}

@media screen and (max-width: 499px){
    .sponsor_layout_outer_div .profile-content .menu-dots {
        width: 1.1vw;
    }

    .sponsor_layout_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.8vw;
    }
}

@media screen and (max-width: 399px){
    .sponsor_layout_outer_div .profile-content .menu-dots {
        width: 1.2vw;
    }

    .sponsor_layout_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 3.1vw;
    }
}
.creategroup-modal {
	position: relative;
	background-color: var(--medium-grey);
	border-radius: 0.3vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2vw 4vw;
	text-align: center;
	min-width: 40vw;
	max-width: 50%;
}

.report-modal {
	width: unset;
}

.creategroup-modal .creategroup-content {
	width: 100%;
}

@media screen and (max-width: 900px) {
	/* report modal start */
	.report-modal {
		width: 70%;
	}
	.report-content i,
	.report-content .form-select {
		font-size: 2vw;
	}
	/* report modal end */
}

@media screen and (max-width: 599px) {
	/* report modal start */
	.report-modal {
		width: 100%;
		justify-content: flex-start;
		/* height: 100vh !important; */
	}
	.report-content i,
	.report-content .form-select {
		font-size: 3vw;
	}
	.report-content .form-select {
		padding: 2vw;
		font-size: 3vw;
	}
	.report-submit .submit {
		padding: 2vw;
		margin-top: 5vw;
	}
	#changePassword {
		top: 5vw;
	}
	.creategroup-modal {
		justify-content: flex-start;
	}
	/* report modal end */
}

/* footer start */
.footer {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9;
	width: 100%;
	padding: 0.1vw 2vw;
}

.footer .footer-content p {
	margin-bottom: 0;
	font-size: 1.24vw;
}

.footer .footer-list ul {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.footer .footer-list ul li {
	padding: 0.5vw 2.5vw;
	position: relative;
}

.footer .footer-list ul li a {
	color: var(--black);
	text-decoration: none;
	font-size: 1.24vw;
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.footer .footer-list ul li a:hover {
	color: var(--white);
}

.footer .footer-list ul li:nth-child(6) {
	padding-right: 0;
	padding-left: 0;
}

.footer .footer-list ul li select {
	background-color: transparent;
	/* background-image: unset; */
	width: 16vw;
	border: 0;
	font-family: inherit;
	font-size: 1.24vw;
	cursor: pointer;
	padding: 0.5vw 2.5vw 0.5vw 2.5vw;
	color: var(--black);
}

.footer-chevron-icon {
	position: absolute;
	top: 0vw;
	right: 1vw;
	bottom: 0;
	z-index: -1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-chevron-icon i {
	font-size: 1.24vw;
}

.footer .footer-list ul li .chevron-icon {
	position: absolute;
	top: 1vw;
	right: 3vw;
	cursor: pointer;
}

.footer .footer-list ul li .chevron-icon i {
	font-size: 1.24vw;
	font-weight: bold;
}

.footer .footer-list ul li select:focus {
	outline: none;
}

/* media queries from 900px */
@media screen and (max-width: 900px) {
	.footer {
		padding: 1vw 2vw;
	}
	.footer .footer-list ul li {
		padding: 0.5vw 2vw;
	}
	.footer .footer-list ul li a {
		font-size: 1.7vw;
	}
	.footer .footer-list ul li select {
		width: 100%;
		font-size: 1.7vw;
	}
	.footer .footer-content p {
		font-size: 1.7vw;
	}
}

@media screen and (max-width: 599px) {
	.footer {
		padding: 2vw 2vw;
		flex-direction: column-reverse;
	}
	.footer .footer-content p {
		margin-top: 1vw;
		font-size: 2.5vw;
	}
	.footer .footer-list ul li {
		padding: 1vw 2vw;
	}
	.footer .footer-list ul li a,
	.footer .footer-list ul li select {
		font-size: 2.5vw;
	}
	.footer .footer-list ul li select {
		padding: 0vw 4vw 0vw 3vw;
	}

	.goog-te-gadget .goog-te-combo {
		margin: 0 !important;
	}

	.goog-te-combo {
		margin-right: 4px !important;
	}
}

/* header-section start */
.header-section {
	padding: 6vw 2vw 6vw 2vw;
}

.header-section .header-section-content h1 {
	margin-bottom: 0;
}

.header-section .header-section-image {
	background-image: url(/static/media/landing-page.aa32dbc3.png);
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 98% auto;
	height: 44vw;
	/* padding: 22vw; */
}
/* header-section end */

@media screen and (max-width: 900px) {
	/* header section start */
	.header-section {
		padding: 12vw 2vw;
	}
	.header-section .header-section-image {
		height: 48vw;
	}
	/* header section end */
}

@media screen and (max-width: 599px) {
	.header-section {
		padding: 18vw 2vw;
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.header-section .header-section-image {
		height: unset;
		/* flex-grow: 1; */
		flex: 1 1;
		background-size: cover;
		background-position: center;
	}
	.header-section .header-section-content h1 {
		margin-bottom: 2vw;
	}
}

/* about-us section start */
.about-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 6vw 17vw;
}

.after-login-section {
	/* padding: 15vw 17vw 7vw; */
	padding: 12vw 17vw 7vw;
}

.about-section .about-section-header h1 {
	font-size: 1.7vw;
	font-weight: bold;
	color: var(--blue);
	margin-bottom: 2vw;
}

.about-section .about-section-content p {
	font-size: 1.3vw;
	margin-bottom: 1.5vw;
}

.about-section .about-section-content p.para {
	margin-bottom: 0;
}

.about-section .about-section-content p span {
	color: var(--red);
	font-weight: bold;
}

.about-section .about-section-buttons {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 2vw;
}

.about-section .about-section-buttons button .button-image img {
	width: 11vw;
}

.about-section .about-section-buttons .google-button button {
	padding-left: 0;
}

.click-here,
.pdf,
.video {
	color: #0d6efd;
	text-decoration: underline;
	transition: all 0.3s ease-in-out;
}
/* about-us section end */

.line {
	text-decoration: underline;
}

@media screen and (max-width: 900px) {
	.about-section {
		padding: 12vw 17vw;
	}
	.after-login-section {
		padding: 22vw 12vw 10vw;
	}

	.about-section .about-section-content p {
		font-size: 1.7vw;
	}

	.about-section .about-section-header h1 {
		font-size: 2.5vw;
	}

	.about-section-content {
		width: 100%;
	}
}



@media screen and (max-width: 599px) {
	.about-section .about-section-header h1 {
		font-size: 3vw;
	}

	.about-section .about-section-content p {
		font-size: 2.75vw;
	}
	.about-section {
		padding: 17vw 5vw 22vw 5vw;
	}

	.after-login-section {
		padding: 34vw 12vw 10vw;
		padding-bottom: 25vw;
	}
	.about-section .about-section-buttons button .button-image img {
		width: 100%;
	}
}

.line{
    text-decoration: underline;
}

.pricing-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 7vw 8vw;
}

.after-login {
	/* padding: 13vw 8vw; */
	padding: 10vw 8vw;
}

.img-big {
	width: 100%;
}

@media screen and (max-width: 900px) {
	.after-login {
		padding: 20vw 8vw 12vw 8vw;
	}
}

@media screen and (max-width: 599px) {
	.after-login {
		padding: 30vw 5vw 20vw 5vw;
		padding-top: 32vw;
	}
}

.creategroup-content .partner-container label {
	color: black;
	width: 20vw;
}

.creategroup-content .partner-container input {
	border: 1px solid #ccc;
	font-size: 1.24vw;
}

.creategroup-content .partner-container input::-webkit-input-placeholder {
	font-size: 1.24vw;
}

.creategroup-content .partner-container input:-ms-input-placeholder {
	font-size: 1.24vw;
}

.creategroup-content .partner-container input::placeholder {
	font-size: 1.24vw;
}

.creategroup-content .partner_btn {
	width: 30% !important;
	align-self: center;
	margin-top: 2vw;
}

.creategroup-content .partner_btn:hover {
	color: var(--white) !important;
	background-color: var(--medium-grey) !important;
}

.input_div {
	width: 100%;
}

@media screen and (max-width: 900px) {
	.creategroup-content .partner-container input::-webkit-input-placeholder {
		font-size: 2vw;
	}
	.creategroup-content .partner-container input:-ms-input-placeholder {
		font-size: 2vw;
	}
	.creategroup-content .partner-container input,
	.creategroup-content .partner-container input::placeholder {
		font-size: 2vw;
	}

	.creategroup-content .partner-container label {
		font-size: 2vw;
	}

	#partnersinput {
		width: 100%;
	}

	#partner-message {
		flex-direction: column;
		margin-top: 4vw;
	}
}
@media screen and (max-width: 599px) {
	.creategroup-content .partner-container input::-webkit-input-placeholder {
		font-size: 3.1vw;
	}
	.creategroup-content .partner-container input:-ms-input-placeholder {
		font-size: 3.1vw;
	}
	.creategroup-content .partner-container input,
	.creategroup-content .partner-container input::placeholder {
		font-size: 3.1vw;
	}

	.creategroup-content .partner-container label {
		font-size: 2.8vw;
	}
	.creategroup-content .partner_btn {
		width: -webkit-fit-content !important;
		width: -moz-fit-content !important;
		width: fit-content !important;
	}
	#partnersinput {
		width: 100%;
	}
}

/* about-us-login start */
.about-login-section {
  padding: 15vw 17vw;
}
/* about-us-login end */

.content-container .sidebar-content .input-field {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.sidebar-or-text {
	text-align: center;
	font-weight: 600;
}
.content-container .resetSubmit {
	/* display: flex;
	justify-content: space-between; */
	margin-right: 3%;
}

.content-container .sidebar-button {
	float: unset;
	text-align: right;
	margin-right: 1.5vw;
}

.clickDiv {
	margin-top: 2vw;
}

.clickHere {
	color: #0d6efd;
	text-decoration: underline;
}

.sidebar-container .sidebar-content .header-content h3 {
	color: var(--close-button);
	font-weight: bold;
}

.content-container .uploadSection {
	display: flex;
	justify-content: start;
	align-items: center;
	margin-bottom: 0;
}

.submitBtnDiv {
	margin-top: 2vw;
}

.uploadInputs input {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	margin-bottom: 2vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.sidebar-content .input-field {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.editImgDiv {
	position: relative;
}

.editImg {
	margin-top: 2%;
	width: 100%;
}

.prodImg {
	position: absolute;
	right: 0;
	top: 0;
	height: 40px;
	cursor: pointer;
}

.dnone {
	display: none;
}

.image-area-content {
	position: relative;
	width: unset;
	background: transparent;
	margin-right: 5%;
	margin-top: 3%;
}

.image-area-content img {
	max-width: 100%;
	/* height: auto; */
	height: 4vw;
	cursor: pointer;
}

.view_img img {
	cursor:unset;
}

.product-header {
	display: flex;
	justify-content: space-between;
}

.quarterly{
	margin-top: 2%;
	background-color: var(--grey);
	padding: 1vw;
}

.adjustImg {
	width: 120%;
	flex-wrap: wrap;
}

.sponsorAssets{
	width: 3vw;
}

.sponsorLinks{
	width: auto;
	word-break: break-all;
	text-decoration: underline;
}

@media screen and (max-width: 599px) {
	.image-area-content {
		width: unset;
	}
	.sidebar-content .input-field,
	.uploadInputs input {
		padding: 2vw 2vw;
		font-size: 3vw;
		margin-top: 3vw;
		width: 100%;
	}
}

/* user-page start */
.user-profile-section {
	position: relative;
	height: 100%;
	margin: 0 auto 0 auto;
}

.sidenav {
	height: 100%;
	width: 23%;
	position: fixed;
	z-index: 1;
	top: 12vw;
	left: 0;
	bottom: 0;
	padding: 1vw 1.5vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	border-right: 1px solid var(--light-grey);
}

.sidenav .sidenav-container {
	position: relative;
	margin-top: 4vw;
}

.sidenav .chat-arrow {
	position: absolute;
	top: 1vw;
	right: 2vw;
}

.sidenav .chat-arrow img {
	width: 2vw;
}

.sidenav .sidenav-container .sidenav-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 5%;
	gap: 5%;
	padding: 0.9vw 1.5vw;
	cursor: pointer;
}

.sidenav .sidenav-container .sidenav-content.active {
	border: 0.15vw solid #757171;
	border-radius: 0.3vw;
}

.sidenav .sidenav-container .sidenav-content img {
	width: 2vw;
}

.sidenav .sidenav-container .sidenav-content h2 {
	margin-bottom: 0;
	color: var(--black);
	font-weight: bold;
}

.sidenav .sidenav-container .sidenav-content h2.inactive-block {
	color: var(--medium-grey);
}

.user-profile-container {
	height: 100%;
	/* overflow: scroll; */
	margin-left: 26vw;
	margin-right: 12vw;
	/* top: 19vw; */
	top: 15vw;
	position: relative;
}

.user-profile-container .user-profile {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-template-rows: repeat(3, 20vw); */
	grid-template-rows: repeat(3, auto);
	grid-auto-flow: row;
	/* gap: 5%; */
	padding-bottom: 12vw;
}

.user-profile-container .user-profiles {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	justify-content: flex-end;
	align-items: center;
	border-radius: 0.3vw;
	/* padding: 1vw 0; */
	padding: 2vw 1vw;
	margin-bottom: 2vw;
	margin-right: 2vw;
	box-shadow: inset 0px 0px 10px 0px #ababab, -50px -50px 0px -100px #dddddd;
	-webkit-box-shadow: inset 0px 0px 10px 0px #ababab,
		-50px -50px 0px -100px #dddddd;
	-moz-box-shadow: inset 0px 0px 10px 0px #ababab,
		-50px -50px 0px -100px #dddddd;
	-o-box-shadow: inset 0px 0px 10px 0px #ababab, -50px -50px 0px -100px #dddddd;
}

.user-profile-container .user-profiles .user-profile-image {
	position: relative;
	border-radius: 50%;
	overflow: hidden;
	width: 4vw;
	height: 4vw;
}

.user-profile-container .user-profiles .user-profile-image img {
	max-width: 100%;
	width: 4vw;
	height: 4vw;
}

.user-profile-container .user-profiles .user-profile-image.blue {
	background-color: var(--blue);
}

.user-profile-container .user-profiles .user-profile-image.purple {
	background-color: var(--purple);
}

.user-profile-container .user-profiles .user-profile-image.golden {
	background-color: var(--golden);
}

.user-profile-container .user-profiles .user-profile-image h2 {
	position: absolute;
	top: 2.6vw;
	left: 2.6vw;
	color: var(--white);
	font-weight: bold;
	margin-bottom: 0;
}

.user-profile-container .user-profiles .user-profile-info {
	text-align: center;
	display: flex;
	flex-direction: column;
	word-break: break-word;
}

.user-profile-container .user-profiles .user-profile-info h2 {
	font-weight: bold;
	margin: 1vw 0;
}

.user-profile-container .user-profiles .user-profile-info h3 {
	color: var(--close-button);
	font-weight: bold;
	margin-bottom: 1vw;
}

.user-profile-container .user-profiles .user-profile-connect button {
	background-color: var(--black);
	color: var(--white);
	font-weight: bold;
	width: 8vw;
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-profile-container .user-profiles .user-profile-connect button.disabled {
	display: none;
}

.user-profile-container .user-profiles .user-profile-connect button:hover {
	background-color: rgba(0, 0, 0, 0.4);
	color: var(--black);
}

.user-profile-container .show-user {
	position: relative;
}

.show-user .show-user-form-group {
	position: absolute;
	top: -5vw;
	right: -8vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.show-user .color-box {
	position: absolute;
	display: flex;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	width: 2.5vw;
	height: 2.5vw;
	top: -5.15vw;
	right: -8.45vw;
}

.show-user .show-user-form-group label.show-user-text {
	font-size: 1.5vw;
	font-weight: bold;
	color: var(--close-button);
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.show-user .show-user-form-group input[type="checkbox"] {
	display: none;
	cursor: pointer;
}

.show-user-form-group input[type="checkbox"] + label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--close-button);
	border: 1px solid var(--close-button);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 0vw;
	margin-left: 1.5vw;
	width: 1.5vw;
	height: 1.5vw;
	z-index: 2;
}

.show-user-form-group input[type="checkbox"]:checked + label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.5vw;
	right: 0.5vw;
	width: 0.4vw;
	height: 1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	z-index: 2;
}
/* user-page end */

@media screen and (max-width: 900px) {
	.sidenav {
		width: 26%;
		top: 28vw;
		padding: 1vw 2vw;
	}
	.sidenav .sidenav-container {
		margin-bottom: 38vw;
	}
	.user-profile-container {
		margin-left: 30vw;
		margin-right: 10vw;
		top: 28vw;
	}
	.show-user .color-box {
		width: 4vw;
		height: 4vw;
		top: -5.5vw;
		right: -3.8vw;
	}
	.show-user .show-user-form-group {
		top: -5vw;
		right: -3vw;
	}
	.show-user .show-user-form-group label.show-user-text {
		font-size: 2vw;
	}
	.show-user-form-group input[type="checkbox"] + label:before {
		margin-left: 2.5vw;
		width: 2.5vw;
		height: 2.5vw;
	}
	.show-user-form-group input[type="checkbox"]:checked + label:after {
		top: 0.2vw;
		right: 0.8vw;
		width: 0.8vw;
		height: 1.9vw;
		border-width: 0 0.4vw 0.4vw 0;
	}
	.user-profile-container .user-profile {
		display: grid;
		padding-bottom: 15vw;
		grid-template-columns: repeat(2, 1fr);
	}
	.user-profile-container .user-profiles {
		padding: 3vw 3vw;
		margin-bottom: 4vw;
		margin-right: 4vw;
	}
	.user-profile-container .user-profiles .user-profile-connect button,
	.sidenav .chat-arrow img {
		width: 100%;
	}
	.sidenav .chat-arrow {
		width: 3.5vw;
	}
	.sidenav .sidenav-container .sidenav-content {
		grid-gap: 8%;
		gap: 8%;
		padding: 1.5vw 1.5vw;
	}
	.sidenav .sidenav-container .sidenav-content img {
		width: 3vw;
	}
}

/* inactive-block (h2 header) */
.sidenav .sidenav-container .sidenav-content h2.inactive-block {
	color: var(--medium-grey);
}

.user-profile-image {
	text-align: center;
}

@media screen and (max-width: 599px) {
	.sidenav .chat-arrow {
		display: none;
	}
	.sidenav {
		background-color: #fff;
		top: 40vw;
		border-right: 0;
		box-shadow: 0 1rem 10px rgb(0 0 0 / 50%);
		width: 50%;
		padding: 4vw 4vw;
		overflow-y: auto;
		height: auto;
		padding-bottom: 25vw;
	}
	.sidenav .sidenav-container {
		margin-bottom: 0vw;
		margin-top: 0vw;
	}
	.sidenav .sidenav-container .sidenav-content {
		padding: 2.5vw 1.5vw;
	}
	.sidenav .sidenav-container .sidenav-content img {
		width: 6vw;
	}
	.sidenav .sidenav-container .sidenav-content h2 {
		font-size: 3vw;
	}
	.user-profile-container {
		margin-left: 4vw;
		margin-right: 0vw;
		top: 40vw;
	}
	.user-profile-container .user-profiles {
		padding: 5vw 3vw;
	}
	.user-profile-container .user-profiles .user-profile-image {
		width: 10vw;
		height: 10vw;
	}
	.user-profile-container .user-profiles .user-profile-info h2 {
		margin: 3vw 0;
		font-size: 3vw;
	}
	.user-profile-container .user-profiles .user-profile-info h3 {
		margin-bottom: 3vw;
		font-size: 2.5vw;
	}
	.user-profile-container .user-profiles .user-profile-connect button,
	.sidenav .chat-arrow img {
		width: 100%;
		padding: 1vw 5vw;
	}
	.show-user .show-user-form-group label.show-user-text {
		font-size: 3.5vw;
	}
	.show-user .color-box {
		width: 5.2vw;
		height: 5.4vw;
		top: -5vw;
		right: 3.3vw;
	}
	.show-user .show-user-form-group {
		top: -5vw;
		right: 4vw;
	}
	.show-user-form-group input[type="checkbox"] + label:before {
		margin-left: 4vw;
		width: 3.5vw;
		height: 3.5vw;
	}
	.show-user-form-group input[type="checkbox"]:checked + label:after {
		top: 0.7vw;
		right: 1.2vw;
		width: 1vw;
		height: 3vw;
		border-width: 0 0.6vw 0.6vw 0;
	}
	#mycircle-hamburger {
		position: relative;
		top: -6vw;
	}
}

.full-height {
	height: calc(100vh);
}

.header {
	cursor: unset;
}

.job-container {
	/* margin: 11vw 14vw 15vw 25vw; */
	margin: 9vw 14vw 15vw 25vw;
}

@media screen and (max-width: 900px) {
	.job-container {
		margin: 24vw 14vw 15vw 28vw;
	}
}

@media screen and (max-width: 599px) {
	.job-container {
		margin: 34vw 4vw 15vw 4vw;
	}
}

.content-container .sidebar-content .radiobtn {
    margin-right: 2%;
}
    

.preview11 {
	width: 50%;
	display: inline-block;
	/* float: left; */
}

.preview12 {
	width: 50%;
	display: inline-block;
	text-align: justify;
}

.preview12 p {
	word-break: break-all;
	margin-left: 10%;
}

.preview21 {
	width: 100%;
}

.preview21 img {
	width: 100%;
}

.mainDiv {
	margin-bottom: 10%;
	padding: 5%;
	border: 1px solid black;
}

.mainDiv h3 {
	margin-bottom: 10%;
}

.tox:not([dir="rtl"]) {
	width: 100% !important;
}

.centerTitle {
	text-align: center;
}

.marTop {
	margin-top: 0.5vw;
}

.content-container .sidebar-button button {
	min-height: 2.1vw;
}

@media screen and (max-width: 599px) {
	.marTop {
		font-size: 3vw;
	}
}

.sidenav .sidenav-container .sidenav-content h2.active-block {
	color: var(--black);
}

.sidenav .sidenav-container .sidenav-content h2.inactive-block {
	color: var(--medium-grey);
}

.blocked h2 {
	font-weight: bold;
}

/* group-page start */
.user-profile-container .seperator {
	position: relative;
	background-color: var(--light-grey);
	margin-top: 5%;
	height: 0.15vw;
	width: 105%;
	left: 0vw;
}

.show-user .show-user-form-group.show-group {
	position: absolute;
	top: 2vw;
	right: -5vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.show-group input[type="checkbox"] + label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 0vw;
	margin-left: 1vw;
	width: 2vw;
	height: 2vw;
	z-index: 2;
}

.show-group input[type="checkbox"]:checked + label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.4vw;
	right: 0.7vw;
	width: 0.5vw;
	height: 1.1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	z-index: 2;
}

.user-profile-container .group-profile {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-template-rows: repeat(1, 20vw); */
	grid-template-rows: repeat(1, auto);
	grid-auto-flow: row;
	/* gap: 5%; */
	padding-bottom: 12vw;
	margin-top: 10%;
}

.group-profile-container {
	top: 0vw;
	/* margin-top: 15%; */
	margin-top: 11%;
	height: unset;
}

.user-profile-container .view-profile {
	/* padding-bottom: 0vw; */
	padding-bottom: 4vw;
}

.view-post-container {
	position: relative;
	height: unset;
	top: 0;
	margin-left: 0;
	margin-right: 0;
	overflow: hidden;
}

.view-post-container .view-post-profiles .user-profile-image.green {
	background-color: var(--green);
}

.view-post-container .view-post-profiles .user-profile-image.red {
	background-color: var(--red);
}

.view-post-profiles {
	position: relative;
}

.view-post-profiles .edit-image {
	position: absolute;
	top: 1vw;
	right: 1.7vw;
	cursor: pointer;
}

.view-post-profiles .edit-image img {
	width: 1.5vw;
}

.view-post-profiles .exit-icon {
	cursor: pointer;
}

.view-post-profiles .exit-icon img {
	width: 2.2vw;
}

.view-post-profiles .view-info h3.view-text {
	color: var(--black);
}

.view-post-container .new-group {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0vw;
	right: 0%;
}

.view-post-container .new-group-search-bar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 1vw;
}

.new-group-search-bar .new-group-content .grp-search{
	border: 1px solid var(--grey);
    padding: 0.5vw 1.5vw 0.5vw 1.5vw;
    font-size: 1.1vw;
    border-radius: 0.3vw;
    margin-left: 1vw;
}

.new-group-search-bar .new-group-content label{
	font-size: 1.1vw;
	color: var(--black);
	font-weight: bold;
}

.new-group-search-bar .new-group-content .grp-search-icon{
	position: absolute;
	bottom: 0;
	justify-content: flex-start;
	align-items: stretch;
	right: 0vw;
	padding: 0.49vw;
	top: 0vw;
	background: transparent;
}

.view-post-container .new-group .new-group-content h2 {
	color: var(--black);
	font-weight: bold;
}

.view-post-container .new-group-search-bar .new-group-content h2 {
	color: var(--black);
	font-weight: bold;
}

.view-post-container .new-group .new-group-button button img {
	width: 3vw;
}


.disnone {
	display: none;
}
/* group-page end */

@media screen and (max-width: 900px) {
	.group-profile-container {
		top: 7vw;
		margin-top: 15%;
	}
	.view-post-container .new-group .new-group-button button img {
		width: 4vw;
	}
	.user-profile-container .group-profile {
		display: grid;
		padding-bottom: 16vw;
		margin-top: 13%;
		grid-template-columns: repeat(2, 1fr);
	}
	.view-post-profiles .edit-image {
		width: 3vw;
	}
	.view-post-profiles .edit-image img {
		width: 100%;
	}
	.user-profile-container .user-profiles .user-profile-image img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.user-profile-container .user-profiles .user-profile-image {
		width: 7vw;
		height: 7vw;
	}
	.user-profile-container .user-profiles .user-profile-info h2 {
		margin: 2vw 0;
	}
	.user-profile-container .user-profiles .user-profile-info h3 {
		margin-bottom: 2vw;
	}
	.view-post-profiles .exit-icon img {
		width: 3.7vw;
	}
	.show-group input[type="checkbox"] + label:before {
		width: 3vw;
		height: 3vw;
	}
	.show-group input[type="checkbox"]:checked + label:after {
		top: 0.4vw;
		right: 1vw;
		width: 0.8vw;
		height: 1.9vw;
		border-width: 0 0.4vw 0.4vw 0;
	}

	/* update group modal */
	.create-modal {
		padding-top: 18vw !important;
	}
	.view-post-container .new-group {
		right: 0%;
	}	

	.new-group-search-bar .new-group-content .grp-search{
		font-size: 1.8vw;
	}
	
	.new-group-search-bar .new-group-content label{
		font-size: 1.8vw;
	}
}

@media screen and (max-width: 599px) {
	.create-modal {
		padding-top: 5vw !important;
	}
	.group-profile-container {
		top: 18vw;
		margin-top: 15%;
	}
	.user-profile-container .user-profiles .user-profile-image {
		width: 10vw;
		height: 10vw;
	}
	.view-post-profiles .edit-image,
	.view-post-profiles .exit-icon img,
	.view-post-container .new-group .new-group-button button img {
		width: 6vw;
	}
	.view-post-container .new-group .new-group-content h2 {
		font-size: 2.8vw;
	}

	.new-group-search-bar .new-group-content .grp-search{
		font-size: 2.8vw;
	}
	
	.new-group-search-bar .new-group-content label{
		font-size: 2.8vw;
	}

	.show-user .show-user-form-group.show-group {
		top: 2vw;
		right: 2vw;
	}
	.show-group input[type="checkbox"] + label:before {
		width: 5vw;
		height: 5vw;
		margin-left: 2vw;
	}
	.show-group input[type="checkbox"]:checked + label:after {
		top: 0.5vw;
		right: 1.5vw;
		width: 1.4vw;
		height: 3.6vw;
		border-width: 0 0.6vw 0.6vw 0;
	}
	.view-post-container .new-group {
		top: 6vw;
	}
	.view-post-container .new-group-search-bar {
		top: 8vw;
	}
	.user-profile-container .seperator {
		width: 100%;
	}
	.user-profile-container .group-profile {
		padding-bottom: 33vw;
	}
}

/* detailed-software page start */
.detailed-software {
	margin: 15vw 12vw 15vw 12vw;
	padding-bottom: 8vw;
	/* height: 100vh; */
}

.detailed-software .detailed-container {
	position: relative;
}

.detailed-container .download-button {
	position: absolute;
	right: 2vw;
	top: -1vw;
}

.detailed-container .report-button {
	position: absolute;
	right: -2vw;
	top: 0vw;
}

.report-button:hover .report-container {
	display: block;
}

.detailed-container .report-container {
	position: absolute;
	right: -7vw;
	top: 2vw;
	display: none;
	white-space: nowrap;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.detailed-container .report-container button.report-block {
	border: 0.1vw solid var(--black);
	padding: 1vw;
}

.detailed-container
	.report-button:hover
	+ .detailed-container
	.report-container {
	display: block;
}

.detailed-container .download-button .report-button i {
	font-size: 1vw;
}

.detailed-container .download-button button {
	background-color: transparent;
}

.detailed-container .download-button button img {
	width: 5vw;
}

.detailed-container .detail-header h1,
.content-container .caepipe-location .location-icon .icon-content h1,
.caepipe-info .evalution-content h1,
.caepipe-info .evalution-content h2,
.content-container .caepipe-location .location-icon h1,
.content-container .caepipe-location .location-icon h2,
.content-container .caepipe-location h1,
.detailed-container .caepipe-forum h2,
.related-software-container .container-header h1,
.detailed-container .service-description .service-description-content h1 {
	font-weight: bold;
}

.content-container .caepipe-location .location-icon h2.grey-header,
.content-container .caepipe-location h1.grey-header,
.caepipe-info .evalution-content .evalution-para h2.grey-header,
.detailed-container .caepipe-forum h2,
.detailed-container
	.service-description
	.service-description-content
	h1.grey-header {
	color: var(--close-button);
}

.detailed-container .content-container {
	display: flex;
	justify-content: flex-start;
	/* justify-content: center; */
	align-items: stretch;
	grid-gap: 7%;
	gap: 7%;
	margin: 3vw 9vw 5vw 2vw;
}

.detailed-container .content-container .caepipe-image {
	display: flex;
}

.detailed-container .content-container .caepipe-image img {
	width: 23vw;
}

.detailed-container .content-container .caepipe-location {
	display: flex;
	justify-content: flex-start;
	grid-gap: 16%;
	gap: 16%;
}

.content-container .caepipe-location .location-icon .icon-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.content-container .caepipe-location .location-icon .icon-content button {
	margin-left: 1vw;
}

.content-container .caepipe-location .location-icon .icon-content button img {
	width: 1.2vw;
}

.caepipe-info .evalution-content {
	margin-top: 4vw;
}

.caepipe-info .evalution-content h1,
.caepipe-info .evalution-content h2,
.detailed-container .caepipe-forum h2 {
	margin-bottom: 1.5vw;
}

.detailed-container .caepipe-forum {
	margin: 2vw 3vw 2vw 2vw;
}

.related-software-container .container-header {
	border-bottom: 1px solid var(--light-grey);
}

.related-software-container .container-header h1 {
	margin-bottom: 2vw;
}

.related-software-container .related-content {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-template-rows: repeat(1, 23.5vw); */
	grid-gap: 2vw;
	margin: 2vw;
}

.software-boxes-content .related-image img {
	width: 100%;
}
/* detailed-software page end */

/* detailed-service page start */
.detailed-container .content-container-part {
	margin: 3vw 9vw 2vw 2vw;
}

.location-icon .service-icon-content button {
	margin-left: 3vw;
}

.detailed-container .content-container .service-location {
	margin-bottom: 2vw;
}

.detailed-container .content-container .service-location-two {
	margin-bottom: 3vw;
}

.detailed-container .content-container .service-location h2.grey-headers {
	margin-top: 0.5vw;
}

.detailed-container .service-description .service-description-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.detailed-container .service-description {
	margin: 1vw 3vw 2vw 2vw;
}

.detailed-container .caepipe-forum-part {
	margin: 1vw 5vw 0vw 0vw;
}

/* .detailed-container .caepipe-forum-part h2 span {
  color: var(--red);
} */

.software-boxes-content .related-jobs-image img {
	width: 100%;
}

.software-boxes-content .software-info .related-jobs-content {
	padding-right: 0;
}
/* detailed-service page end */

/* detailed-job page start */

/* detailed-job page end */

/* detailed-product page start */
.detailed-container .caepipe-forum h2.black-header {
	color: var(--black);
}

.related-description .image-gallery-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	grid-gap: 3%;
	gap: 3%;
	/* width: 106%; */
	padding: 2vw 2vw 0vw 2vw;
}

.image-gallery-container .image-gallery-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.image-gallery-container .image-gallery-content .gallery-image img {
	width: 15vw;
}

.image-gallery-container .image-gallery-content .gallery-content h2 {
	font-weight: bold;
	margin-top: 1vw;
}
/* detailed-product page end */

/* product-categories page start */
.detailed-container .content-container .categories-button {
	margin-bottom: 0vw;
}

.company-profile-button button.company-profile {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	padding: 1vw;
}

.company-profile-button button .icon-button {
	margin-left: 2vw;
}

.company-profile-button button .icon-button img {
	width: 1.2vw;
}

.responsive-map {
	overflow: hidden;
	padding: 14vw;
	position: relative;
	height: 0;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	border-radius: 0.3vw;
	margin: 3vw 2vw 3vw 2vw;
}

.responsive-map iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

/* product-categories-tabs start */
/* Style the tab */
.tab-product-categories {
	float: left;
}

/* Style the buttons inside the tab */
.tab-product-categories button {
	display: block;
	background-color: inherit;
	color: black;
	width: 100%;
	border: none;
	outline: none;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
	padding: 1vw 5vw 1vw 1vw;
	font-size: 1.24vw;
	font-weight: bold;
	background: transparent;
	box-shadow: 0 0 0.4vw rgb(0 0 0 / 50%);
	border-radius: 0.3vw;
}

/* Change background color of buttons on hover */
.tab-product-categories button:hover {
	background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab-product-categories button.active {
	background-color: #757171;
	color: #fff;
}

/* Style the tab content */
.tabcontent-categories {
	float: left;
	padding: 0px 1vw 14vw;
	/* border: 1px solid #ccc; */
	width: 80%;
	border-left: none;
	/* height: 300px; */
	margin-left: 1vw;
}

.related-software-container .product-categories-filter {
	margin-top: 3vw;
}

.product-boxes .categories-box-container {
	margin-top: 0;
	grid-template-rows: auto;
	grid-template-columns: repeat(3, 0fr);
}

.product-boxes-content .categories-image img {
	/* width: 18vw; */
	width: 100%;
}
/* product-categories-tabs end */

/* product-categories page end */

/* pdf viewer */
#sidenav-container {
	display: none;
}

.detailed-software .related-description {
	width: 100%;
	/* height: 600px; */
	max-height: 600px;
	/* height: fit-content; */
	overflow: auto;
}

.detailed-software .related-description iframe {
	width: 100%;
	height: 600px;
	/* height: fit-content; */
	overflow: auto;
}

.detailed-software .related-description iframe img {
	width: 100%;
}

@media screen and (max-width: 900px) {
	.detailed-container .report-button {
		right: -5vw;
		top: 0;
	}
	.detailed-container .report-container {
		right: 0vw;
		top: 7vw;
	}
}

@media screen and (max-width: 768px) {
	/* detailed-software page start */
	.detailed-software {
		margin: 20vw 12vw 15vw 12vw;
	}

	.detailed-container .content-container {
		margin: 3vw 6vw 5vw 2vw;
	}
	/* detailed-software page end */

	/* product-categories page start */
	.tab-product-categories button {
		font-size: 1.5vw;
	}

	.tabcontent-categories {
		padding: 0px 1vw 14vw;
		width: 75%;
	}

	.product-boxes .categories-box-container {
		grid-template-rows: auto;
		grid-template-columns: repeat(3, 0fr);
		width: 100%;
	}
	/* product-categories page end */
}

@media screen and (max-width: 599px) {
	.detailed-container .report-container {
		position: absolute;
		right: 0vw;
		top: 8vw;
		z-index: 2;
	}
}

@media screen and (max-width: 499px) {
	.detailed-software .related-description {
		width: 100%;
		overflow: auto;
		height: 50vh;
	}
}

/* ################ SKY TABS ##############*/
/* tabs start */

.slider-box-container {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4vw 3vw;
}

.related-description .content1-header {
	border-bottom: 1px solid var(--grey);
}

.related-description .content1-header h2 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.related-description .content1-para {
	margin-top: 1vw;
}

.related-description .content1-para h2 {
	font-weight: bold;
	color: var(--close-button);
}

.related-description .content1-para h2 span {
	color: var(--red);
}

/* tabs end */

/**/
/* defaults */
/**/

.sky-tabs > input:checked + label span span {
	background-color: var(--close-button);
	color: var(--white);
}
.sky-tabs {
	font-size: 0;
	text-align: left;
	/* margin-top: 3vw; */
	margin: 3vw 0vw 0vw 1vw;
}
.sky-tabs > input {
	position: absolute;
	display: none;
}
.sky-tabs > label {
	transition: all 0.3s ease-in-out;
	position: relative;
	z-index: 1;
	display: inline-block;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	padding: 1px;
	padding-top: 0;
	padding-left: 0;
	cursor: pointer;
}
.sky-tabs > label.inverse {
	float: right;
	padding-right: 0;
	padding-left: 1px;
}
.sky-tabs > label.disabled {
	cursor: default;
}
.sky-tabs > label span {
	display: block;
}
.sky-tabs > label span span {
	padding: 1vw 0.98vw;
	font-size: 1.24vw;
	font-weight: bold;
	background: transparent;
	box-shadow: 0 0 0.4vw rgb(0 0 0 / 50%);
	border-radius: 0.3vw;
	transition: background 0.4s, color 0.4s;
	-o-transition: background 0.4s, color 0.4s;
	-ms-transition: background 0.4s, color 0.4s;
	-moz-transition: background 0.4s, color 0.4s;
	-webkit-transition: background 0.4s, color 0.4s;
}

.sky-tabs > label.disabled span span {
	background: transparent;
	color: inherit;
}
.sky-tabs > input:checked + label {
	cursor: default;
}

.sky-tabs .typography {
	border-radius: 0.3vw;
}
.sky-tabs .typography h3,
.sky-tabs .typography p {
	color: #000;
}

.sky-tabs > ul {
	list-style: none;
	position: relative;
	display: block;
	font-size: 13px;
	padding: 0;
	margin: 0;
}
.sky-tabs > ul > li {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: auto;
	padding: 0vw 0vw 3vw;
	margin-top: -0.1vw;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	opacity: 0;
	-o-transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-moz-transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%;
	-o-transition: opacity 0.8s, -o-transform 0.8s;
	-ms-transition: opacity 0.8s, -ms-transform 0.8s;
	-moz-transition: opacity 0.8s, -moz-transform 0.8s;
	-webkit-transition: opacity 0.8s, -webkit-transform 0.8s;
}
.sky-tabs > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	position: relative;
	z-index: 1;
	opacity: 1;
}

/* animations */
/**/
.sky-tabs-anim-slide-up > ul > li {
	-o-transform: translateY(-15%);
	-ms-transform: translateY(-15%);
	-moz-transform: translateY(-15%);
	-webkit-transform: translateY(-15%);
}
.sky-tabs-anim-slide-right > ul > li {
	-o-transform: translateX(15%);
	-ms-transform: translateX(15%);
	-moz-transform: translateX(15%);
	-webkit-transform: translateX(15%);
}
.sky-tabs-anim-slide-down > ul > li {
	-o-transform: translateY(15%);
	-ms-transform: translateY(15%);
	-moz-transform: translateY(15%);
	-webkit-transform: translateY(15%);
}
.sky-tabs-anim-slide-left > ul > li {
	-o-transform: translateX(-15%);
	-ms-transform: translateX(-15%);
	-moz-transform: translateX(-15%);
	-webkit-transform: translateX(-15%);
}
.sky-tabs-anim-slide-up-left > ul > li {
	-o-transform: translate(-15%, -15%);
	-ms-transform: translate(-15%, -15%);
	-moz-transform: translate(-15%, -15%);
	-webkit-transform: translate(-15%, -15%);
}
.sky-tabs-anim-slide-up-right > ul > li {
	-o-transform: translate(15%, -15%);
	-ms-transform: translate(15%, -15%);
	-moz-transform: translate(15%, -15%);
	-webkit-transform: translate(15%, -15%);
}
.sky-tabs-anim-slide-down-right > ul > li {
	-o-transform: translate(15%, 15%);
	-ms-transform: translate(15%, 15%);
	-moz-transform: translate(15%, 15%);
	-webkit-transform: translate(15%, 15%);
}
.sky-tabs-anim-slide-down-left > ul > li {
	-o-transform: translate(-15%, 15%);
	-ms-transform: translate(-15%, 15%);
	-moz-transform: translate(-15%, 15%);
	-webkit-transform: translate(-15%, 15%);
}
.sky-tabs > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-webkit-transform: translate(0, 0);
}

.sky-tabs-anim-scale > ul > li {
	-o-transform: scale(0.1, 0.1);
	-ms-transform: scale(0.1, 0.1);
	-moz-transform: scale(0.1, 0.1);
	-webkit-transform: scale(0.1, 0.1);
}
.sky-tabs-anim-scale > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs-anim-scale > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs-anim-scale > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs-anim-scale > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-moz-transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
}

.sky-tabs-anim-rotate > ul > li {
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
}
.sky-tabs-anim-rotate.sky-tabs-pos-right > ul > li,
.sky-tabs-anim-rotate.sky-tabs-pos-top-right > ul > li {
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
}
.sky-tabs-anim-rotate > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs-anim-rotate > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs-anim-rotate > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs-anim-rotate > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
}

.sky-tabs-anim-flip > ul {
	perspective: 2000px;
	-o-perspective: 2000px;
	-ms-perspective: 2000px;
	-moz-perspective: 2000px;
	-webkit-perspective: 2000px;
	perspective-origin: 50% 50%;
	-o-perspective-origin: 50% 50%;
	-ms-perspective-origin: 50% 50%;
	-moz-perspective-origin: 50% 50%;
	-webkit-perspective-origin: 50% 50%;
}
.sky-tabs-anim-flip > ul > li {
	-o-transform: rotateX(-90deg);
	-ms-transform: rotateX(-90deg);
	-moz-transform: rotateX(-90deg);
	-webkit-transform: rotateX(-90deg);
}
.sky-tabs-anim-flip.sky-tabs-pos-left > ul > li {
	-o-transform: rotateY(90deg);
	-ms-transform: rotateY(90deg);
	-moz-transform: rotateY(90deg);
	-webkit-transform: rotateY(90deg);
}
.sky-tabs-anim-flip.sky-tabs-pos-right > ul > li {
	-o-transform: rotateY(-90deg);
	-ms-transform: rotateY(-90deg);
	-moz-transform: rotateY(-90deg);
	-webkit-transform: rotateY(-90deg);
}
.sky-tabs-anim-flip > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs-anim-flip > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs-anim-flip > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs-anim-flip > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	-o-transition-delay: 0.2s;
	-ms-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	-webkit-transition-delay: 0.2s;
}

@media screen and (max-width: 425px) {
	.sky-tabs > label span span {
		padding: 1vw 0.98vw;
		font-size: 1.5vw;
	}
}

.image-gallery-container {
	position: relative;
}

.image-gallery-container iframe {
	/* position: absolute; */
	top: 0;
	left: 0;
	width: 68vw;
	height: 100%;
}

.truncate span a.my-anchor-css-class {
	color: var(--red);
}

.truncate span {
	color: var(--close-button);
}

.truncate p{
	color: var(--black);
	font-size: 1.2vw;
}

h2.prodDesc span p{
	color: var(--close-button);
}

h2.prodDesc span p b{
    color: var(--black);
}
/* detailed-software page start */
.detailed-software {
	/* margin: 15vw 12vw 15vw 12vw; */
	margin: 12vw 12vw 15vw 12vw;
	padding-bottom: 8vw;
	/* height: 100vh; */
}

.detailed-software .detailed-container {
	position: relative;
}

.detailed-container .download-button {
	position: absolute;
	right: 2vw;
	top: -1vw;
}

.detailed-container .download-button button {
	background-color: transparent;
}

.detailed-container .download-button button img {
	width: 5vw;
}

.detailed-container .download-button a img {
	width: 5vw;
}

.detailed-container .detail-header h1,
.content-container .caepipe-location .location-icon .icon-content h1,
.caepipe-info .evalution-content h1,
.caepipe-info .evalution-content h2,
.content-container .caepipe-location .location-icon h1,
.content-container .caepipe-location .location-icon h2,
.content-container .caepipe-location h1,
.detailed-container .caepipe-forum h2,
.related-software-container .container-header h1,
.detailed-container .service-description .service-description-content h1 {
	font-weight: bold;
}

.content-container .caepipe-location .location-icon h2.grey-header,
.content-container .caepipe-location h1.grey-header,
.caepipe-info .evalution-content .evalution-para h2.grey-header,
.detailed-container .caepipe-forum h2,
.detailed-container
	.service-description
	.service-description-content
	h1.grey-header {
	color: var(--close-button);
}

.detailed-container .content-container {
	display: flex;
	justify-content: flex-start;
	/* justify-content: center; */
	align-items: stretch;
	grid-gap: 7%;
	gap: 7%;
	margin: 3vw 9vw 5vw 2vw;
}

.detailed-container .content-container .caepipe-image {
	display: flex;
	border-radius: 0.3vw;
	border: 1px solid var(--white);
	box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
		rgb(60 64 67 / 15%) 0px 2px 6px 2px;
	width: 23vw;
	height: 100%;
}

.detailed-container .content-container .caepipe-image img {
	width: 100%;
	/* height: 100%; */
	height: 45vh;
    object-fit: cover;
}

.caepipe-info {
	padding-top: 1vw;
}

.detailed-container .content-container .caepipe-location {
	display: flex;
	justify-content: flex-start;
	grid-gap: 16%;
	gap: 16%;
}

.content-container .caepipe-location .location-icon .icon-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.content-container .caepipe-location .location-icon .icon-content button {
	margin-left: 1vw;
}

.content-container .caepipe-location .location-icon .icon-content button img {
	width: 1.2vw;
}

.caepipe-info .evalution-content {
	margin-top: 4vw;
}

.caepipe-info .evalution-content h1,
.caepipe-info .evalution-content h2,
.detailed-container .caepipe-forum h2 {
	margin-bottom: 1.5vw;
}

.detailed-container .caepipe-forum {
	margin: 2vw 3vw 2vw 2vw;
}

.related-software-container .container-header {
	border-bottom: 1px solid var(--light-grey);
}

.related-software-container .container-header h1 {
	margin-bottom: 2vw;
}

.related-software-container .related-content {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-template-rows: repeat(1, 23.5vw); */
	grid-gap: 1vw;
	margin: 2vw;
}

.software-boxes-content .related-image img {
	width: 100%;
}
/* detailed-software page end */

/* detailed-service page start */
.detailed-container .content-container-part {
	margin: 3vw 9vw 2vw 2vw;
}

.location-icon .service-icon-content button {
	margin-left: 3vw;
}

.detailed-container .content-container .service-location {
	margin-bottom: 2vw;
}

.detailed-container .content-container .service-location-two {
	margin-bottom: 3vw;
}

.detailed-container .content-container .service-location h2.grey-headers {
	margin-top: 0.5vw;
}

.detailed-container .service-description .service-description-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.detailed-container .service-description {
	margin: 1vw 3vw 2vw 2vw;
}

.detailed-container .caepipe-forum-part {
	margin: 1vw 5vw 0vw 0vw;
}

/* .detailed-container .caepipe-forum-part h2 span {
  color: var(--red);
} */

.software-boxes-content .related-jobs-image img {
	width: 100%;
}

.software-boxes-content .software-info .related-jobs-content {
	padding-right: 0;
}
/* detailed-service page end */

/* detailed-job page start */

/* detailed-job page end */

/* detailed-product page start */
.detailed-container .caepipe-forum h2.black-header {
	color: var(--black);
}

.related-description .image-gallery-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	grid-gap: 2%;
	gap: 2%;
	/* width: 106%; */
	padding: 2vw 2vw 0vw 0.5vw;
	width: 100%;
	max-width: 100%;
	flex-wrap: wrap;
	height: auto;
}

.image-gallery-container .image-gallery-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 2vw;
}

.image-gallery-container .image-gallery-content .gallery-image img {
	width: 15vw;
    height: 14vw;
    object-fit: contain;
	border-radius: 0.3vw;
}

.image-gallery-container .image-gallery-content .gallery-content h2 {
	font-weight: bold;
	margin-top: 1vw;
}
/* detailed-product page end */

/* product-categories page start */
.detailed-container .content-container .categories-button {
	margin-bottom: 0vw;
}

.company-profile-button button.company-profile {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	padding: 1vw;
}

.company-profile-button button .icon-button {
	margin-left: 2vw;
}

.company-profile-button button .icon-button img {
	width: 1.2vw;
}

.responsive-map {
	/* background-image: url(../assets/navrathan-map.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto; */
	overflow: hidden;
	padding: 14vw;
	position: relative;
	height: 0;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	border-radius: 0.3vw;
	margin: 3vw 2vw 3vw 2vw;
}

.responsive-map iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

/* product-categories-tabs start */
/* Style the tab */
.tab-product-categories {
	float: left;
}

/* Style the buttons inside the tab */
.tab-product-categories button {
	display: block;
	background-color: inherit;
	color: black;
	width: 100%;
	border: none;
	outline: none;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
	padding: 1vw 5vw 1vw 1vw;
	font-size: 1.24vw;
	font-weight: bold;
	background: transparent;
	box-shadow: 0 0 0.4vw rgb(0 0 0 / 50%);
	border-radius: 0.3vw;
}

/* Change background color of buttons on hover */
.tab-product-categories button:hover {
	background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab-product-categories button.active {
	background-color: #757171;
	color: #fff;
}

/* Style the tab content */
.tabcontent-categories {
	float: left;
	padding: 0px 1vw 14vw;
	/* border: 1px solid #ccc; */
	width: 80%;
	border-left: none;
	/* height: 300px; */
	margin-left: 1vw;
}


.related-software-container .product-categories-filter {
	margin-top: 3vw;
}

.product-boxes .categories-box-container {
	margin-top: 0;
	grid-template-rows: auto;
	grid-template-columns: repeat(3, 0fr);
}

.product-boxes-content .categories-image img {
	/* width: 18vw; */
	width: 100%;
}
/* product-categories-tabs end */

.creategroup-modal-container.applyjobmodal {
	/* padding-top: 0; */
	padding-top: 20vw;
	padding-bottom: 10vw;
}
/* product-categories page end */

.show-job-container {
	position: relative;
	background-color: var(--white);
	border: 1px solid var(--light-grey);
	border-radius: 0.3vw;
	margin: 1vw 0 0 0;
	padding: 2vw 2vw;
	text-align: left;
	margin-bottom: 1vw;
	overflow: auto;
}

.creategroup-modal .createjob-content {
	height: 40vw;
	overflow: auto;
	width: 100%;
}

.ordiff {
	font-weight: bold;
	margin-bottom: 1vw;
}

form button.jobapplyBtn {
	color: var(--close-button);
	background-color: var(--white);
	transition: all 0.3s ease-in-out;
	font-size: 1.1vw;
	padding: 0.7vw;
	font-weight: 700;
}

form .jobapplyBtn:hover {
	background-color: var(--close-button) !important;
	color: var(--white) !important;
}

.cvjobapply {
	background-color: #fff;
	font-size: 1.1vw !important;
}

.detailed-container .content-container #Softcaepipeimage img {
	/* width: 100%;
	height: fit-content; */
	width: 23vw;
    height: auto;
}

.detailed-container .content-container #Softcaepipeimage{
	height: 45vh;
    /* width: 100%; */
    align-items: center;
}

.related-jobs-container {
	width: 60vw;
}

.related-jobs-container a{
	width: 18vw;
}

@media screen and (max-width: 900px) {
	.detailed-software {
		margin: 24vw 12vw 15vw 12vw;
	}
	.content-container .caepipe-location .location-icon .icon-content button img {
		width: 1.5vw;
	}
	.detailed-container .content-container .caepipe-image img {
		width: 100%;
	}
	.detailed-container .content-container-part {
		margin: 4vw 9vw 4vw 2vw;
	}
	form button.jobapplyBtn {
		font-size: 2vw;
		padding: 1vw;
		width: 30%;
	}
	.cvjobapply {
		font-size: 2vw !important;
	}

	.detailed-container .content-container .caepipe-image {
		width: 30vw;
		height: 100%;
	}
	.detailed-container .download-button button img,
	.detailed-container .download-button a img {
		width: 7vw;
	}
	.detailed-software {
		padding-bottom: 20vw;
	}
}

@media screen and (max-width: 768px) {
	/* detailed-software page start */

	.detailed-container .content-container {
		margin: 3vw 6vw 5vw 2vw;
	}

	/* detailed-software page end */

	/* product-categories page start */
	.tab-product-categories button {
		font-size: 1.5vw;
	}

	.tabcontent-categories {
		padding: 0px 1vw 14vw;
		width: 75%;
	}

	/* product-categories page end */
}

@media screen and (max-width: 599px) {
	.cvjobapply {
		background-color: #fff;
		font-size: 3vw !important;
	}
	.creategroup-modal .createjob-content {
		height: 70vw;
	}
	form button.jobapplyBtn {
		padding: 2vw;
	}
	
	.creategroup-modal-container.applyjobmodal {
		padding-top: 5vw;
		padding-bottom: 0vw;
	}

	/* detail page css start */
	.detailed-container .content-container {
		flex-direction: column;
	}
	.detailed-software {
		margin: 32vw 3vw 15vw 3vw;
	}
	.detailed-container .report-button {
		right: -2vw;
		top: -1vw;
	}
	.detailed-container .download-button {
		right: 6vw;
		top: -2vw;
	}
	.detailed-container .download-button button img,
	.detailed-container .download-button a img {
		width: 10vw;
	}
	#caepipecontainer {
		margin: 7vw 2vw 4vw 2vw;
	}
	#caepipeimage {
		margin-bottom: 8vw;
		margin-top: 4vw;
	}
	.caepipe-info .evalution-content .evalution-para .grey-header {
		line-height: 1.8;
		font-size: 3vw;
	}
	.caepipe-info .evalution-content .evalution-para .grey-header p {
		font-size: 3vw;
	}
	.related-software-container .related-content {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 4vw;
		margin: 3vw;
	}
	.detailed-software {
		margin: 34vw 4vw 15vw 4vw;
		padding-bottom: 30vw;
	}
	.detailed-container .content-container {
		margin: 3vw 2vw 5vw 2vw;
	}
	.detailed-container .content-container .caepipe-image {
		width: 40vw;
		margin-bottom: 5vw;
	}
	.sky-tabs > label span span {
		font-size: 3vw;
		padding: 1vw;
	}
	.sky-tabs {
		overflow-x: hidden;
	}
	
	#productdetail {
		padding-bottom: 30vw;
	}
	.detailed-container .caepipe-forum h2 p {
		font-size: 2.5vw;
	}
	.detailed-container .content-container .service-location button img {
		width: 4vw;
	}
	/* detail page css end */
}

h2.jobDesc span{
	white-space: pre-line;
	color: var(--close-button);
}

h2.jobDesc span p{
	white-space: pre-line;
	color: var(--close-button);
}

/* ################ SKY TABS ##############*/
/* tabs start */

.slider-box-container {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4vw 3vw;
}

.related-description .content1-header {
	border-bottom: 1px solid var(--grey);
}

.related-description .content1-header h2 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.related-description .content1-para {
	margin-top: 1vw;
}

.related-description .content1-para h2 {
	font-weight: bold;
	color: var(--close-button);
}

.related-description .content1-para h2 span {
	color: var(--close-button);
}

/* tabs end */

/**/
/* defaults */
/**/

.sky-tabs > input:checked + label span span, #showJobApply {
	background-color: var(--close-button);
	color: var(--white);
}
.sky-tabs {
	font-size: 0;
	text-align: left;
	/* margin-top: 3vw; */
	margin: 3vw 0vw 0vw 1vw;
}
.sky-tabs > input {
	position: absolute;
	display: none;
}
.sky-tabs > label {
	transition: all 0.3s ease-in-out;
	position: relative;
	z-index: 1;
	display: inline-block;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	padding: 1px;
	padding-top: 0;
	padding-left: 0;
	cursor: pointer;
}
.sky-tabs > label.inverse {
	float: right;
	padding-right: 0;
	padding-left: 1px;
}
.sky-tabs > label.disabled {
	cursor: default;
}
.sky-tabs > label span {
	display: block;
}
.sky-tabs > label span span, #showJobApply {
	padding: 1vw 0.98vw;
	font-size: 1.24vw;
	font-weight: bold;
	/* background: transparent; */
	box-shadow: 0 0 0.4vw rgb(0 0 0 / 50%);
	border-radius: 0.3vw;
	transition: background 0.4s, color 0.4s;
	-o-transition: background 0.4s, color 0.4s;
	-ms-transition: background 0.4s, color 0.4s;
	-moz-transition: background 0.4s, color 0.4s;
	-webkit-transition: background 0.4s, color 0.4s;
}

.sky-tabs > label.disabled span span {
	background: transparent;
	color: inherit;
}
.sky-tabs > input:checked + label {
	cursor: default;
}

.sky-tabs .typography {
	border-radius: 0.3vw;
}
.sky-tabs .typography h3,
.sky-tabs .typography p {
	color: #000;
}

.sky-tabs > ul {
	list-style: none;
	position: relative;
	display: block;
	font-size: 13px;
	padding: 0;
	margin: 0;
}
.sky-tabs > ul > li {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: auto;
	padding: 0vw 0vw 3vw;
	margin-top: -0.1vw;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	opacity: 0;
	-o-transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-moz-transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%;
	-o-transition: opacity 0.8s, -o-transform 0.8s;
	-ms-transition: opacity 0.8s, -ms-transform 0.8s;
	-moz-transition: opacity 0.8s, -moz-transform 0.8s;
	-webkit-transition: opacity 0.8s, -webkit-transform 0.8s;
}
.sky-tabs > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	position: relative;
	z-index: 1;
	opacity: 1;
}
/* animations */
/**/
.sky-tabs-anim-slide-up > ul > li {
	-o-transform: translateY(-15%);
	-ms-transform: translateY(-15%);
	-moz-transform: translateY(-15%);
	-webkit-transform: translateY(-15%);
}
.sky-tabs-anim-slide-right > ul > li {
	-o-transform: translateX(15%);
	-ms-transform: translateX(15%);
	-moz-transform: translateX(15%);
	-webkit-transform: translateX(15%);
}
.sky-tabs-anim-slide-down > ul > li {
	-o-transform: translateY(15%);
	-ms-transform: translateY(15%);
	-moz-transform: translateY(15%);
	-webkit-transform: translateY(15%);
}
.sky-tabs-anim-slide-left > ul > li {
	-o-transform: translateX(-15%);
	-ms-transform: translateX(-15%);
	-moz-transform: translateX(-15%);
	-webkit-transform: translateX(-15%);
}
.sky-tabs-anim-slide-up-left > ul > li {
	-o-transform: translate(-15%, -15%);
	-ms-transform: translate(-15%, -15%);
	-moz-transform: translate(-15%, -15%);
	-webkit-transform: translate(-15%, -15%);
}
.sky-tabs-anim-slide-up-right > ul > li {
	-o-transform: translate(15%, -15%);
	-ms-transform: translate(15%, -15%);
	-moz-transform: translate(15%, -15%);
	-webkit-transform: translate(15%, -15%);
}
.sky-tabs-anim-slide-down-right > ul > li {
	-o-transform: translate(15%, 15%);
	-ms-transform: translate(15%, 15%);
	-moz-transform: translate(15%, 15%);
	-webkit-transform: translate(15%, 15%);
}
.sky-tabs-anim-slide-down-left > ul > li {
	-o-transform: translate(-15%, 15%);
	-ms-transform: translate(-15%, 15%);
	-moz-transform: translate(-15%, 15%);
	-webkit-transform: translate(-15%, 15%);
}
.sky-tabs > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-webkit-transform: translate(0, 0);
}

.sky-tabs-anim-scale > ul > li {
	-o-transform: scale(0.1, 0.1);
	-ms-transform: scale(0.1, 0.1);
	-moz-transform: scale(0.1, 0.1);
	-webkit-transform: scale(0.1, 0.1);
}
.sky-tabs-anim-scale > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs-anim-scale > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs-anim-scale > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs-anim-scale > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-moz-transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
}

.sky-tabs-anim-rotate > ul > li {
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
}
.sky-tabs-anim-rotate.sky-tabs-pos-right > ul > li,
.sky-tabs-anim-rotate.sky-tabs-pos-top-right > ul > li {
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
}
.sky-tabs-anim-rotate > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs-anim-rotate > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs-anim-rotate > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs-anim-rotate > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
}

.sky-tabs-anim-flip > ul {
	perspective: 2000px;
	-o-perspective: 2000px;
	-ms-perspective: 2000px;
	-moz-perspective: 2000px;
	-webkit-perspective: 2000px;
	perspective-origin: 50% 50%;
	-o-perspective-origin: 50% 50%;
	-ms-perspective-origin: 50% 50%;
	-moz-perspective-origin: 50% 50%;
	-webkit-perspective-origin: 50% 50%;
}
.sky-tabs-anim-flip > ul > li {
	-o-transform: rotateX(-90deg);
	-ms-transform: rotateX(-90deg);
	-moz-transform: rotateX(-90deg);
	-webkit-transform: rotateX(-90deg);
}
.sky-tabs-anim-flip.sky-tabs-pos-left > ul > li {
	-o-transform: rotateY(90deg);
	-ms-transform: rotateY(90deg);
	-moz-transform: rotateY(90deg);
	-webkit-transform: rotateY(90deg);
}
.sky-tabs-anim-flip.sky-tabs-pos-right > ul > li {
	-o-transform: rotateY(-90deg);
	-ms-transform: rotateY(-90deg);
	-moz-transform: rotateY(-90deg);
	-webkit-transform: rotateY(-90deg);
}
.sky-tabs-anim-flip > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs-anim-flip > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs-anim-flip > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs-anim-flip > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	-o-transition-delay: 0.2s;
	-ms-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	-webkit-transition-delay: 0.2s;
}
.sky-tabs  #showJobApply {
	display: inline !important;
	font-size: 1.24vw !important;
	cursor: pointer;
	margin-left: 10px;
	padding: 1.08vw 0.98vw;
}

@media (max-width: 900px) {
	.sky-tabs > label span span,.sky-tabs  #showJobApply {
		font-size: 2vw !important;
	}
}

@media screen and (max-width: 599px) {
	.sky-tabs > label span span,.sky-tabs  #showJobApply {
		font-size: 3vw !important;
		padding: 1vw;
	}
	.sky-tabs {
		overflow-x: hidden;
		margin: 6vw 0vw 0vw 0vw;
	}
	/* detail page css end */
}

.serviceContainer {
	/* margin: 11vw 14vw 15vw 25vw; */
	margin: 9vw 14vw 15vw 25vw;
}

.service-description-content .month-time {
	text-align: end;
}

@media screen and (max-width: 900px) {
	.serviceContainer {
		margin: 24vw 14vw 15vw 28vw;
	}
}

@media screen and (max-width: 599px) {
	.serviceContainer {
		margin: 34vw 4vw 15vw 4vw;
	}
}
#submitButtonService{
	margin-left: 1vw;
}
/* supplier-page start */
.product-container .product-box-container .supplier-boxes {
	padding: 3vw 0 6vw 2vw;
}

.supplier-box-container {
	position: relative;
	background-color: var(--post-background);
	border-radius: 0.3vw;
	padding: 2vw;
	margin: 3vw 0;
}

.supplier-box-container .product-email {
	top: 1vw;
}

.supplier-box-container .supplier-info h1 {
	margin-bottom: 1.5vw;
}

.supplier-box-container .supplier-info h2.header-text {
	margin-bottom: 0.5vw;
}

.supplier-box-container .supplier-boxes-content h2 {
	margin-top: 1vw;
}

.supplier-box-container .supplier-boxes-content .supplier-content {
	display: grid;
	grid-template-columns: repeat(3, 0fr);
	/* grid-template-rows: repeat(1, 19vw); */
	grid-gap: 2vw;
}

.suppliers-boxes-content {
	background-color: var(--white);
}

.suppliers-boxes-content {
	width: 17.2vw;
}

.product-boxes-content .supplier-info-name {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}
/* supplier-page end */

@media screen and (max-width: 900px) {
	.product-container .product-box-container .supplier-boxes {
		padding: 4vw 0 6vw 4vw;
	}
	.supplier-box-container {
		padding: 4vw;
		margin: 4vw 0;
	}
	.supplier-box-container .supplier-info h2.header-text {
		margin-bottom: 1vw;
	}
	.supplier-box-container .supplier-boxes-content .supplier-content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 5vw;
		grid-template-rows: auto;
		margin-top: 2vw;
	}
	.suppliers-boxes-content {
		width: 100%;
	}
}

/* product-categories page start */
.detailed-container .content-container .categories-button {
	margin-bottom: 0vw;
}

.company-profile-button button.company-profile {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	padding: 1vw;
}

.company-profile-button button .icon-button {
	margin-left: 2vw;
}

.company-profile-button button .icon-button img {
	width: 1.2vw;
}

.responsive-map {
	overflow: hidden;
	padding: 14vw;
	position: relative;
	height: 0;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	border-radius: 0.3vw;
	margin: 3vw 2vw 3vw 2vw;
}

.responsive-map iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.cmpy-profile-modal {
	width: 100vw;
}

.cmpy-profile-modal.open {
	padding-top: 15vw;
}

.cmpy-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 3vw 3vw;
	width: 100vh;
}

/* product-categories-tabs start */
/* Style the tab */
.tab-product-categories {
	float: left;
}

/* Style the buttons inside the tab */
.tab-product-categories button {
	display: block;
	background-color: inherit;
	color: black;
	width: 100%;
	border: none;
	outline: none;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
	padding: 1vw 5vw 1vw 1vw;
	font-size: 1.24vw;
	font-weight: bold;
	background: transparent;
	box-shadow: 0 0 0.4vw rgb(0 0 0 / 50%);
	border-radius: 0.3vw;
}

/* Change background color of buttons on hover */
.tab-product-categories button:hover {
	background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab-product-categories button.active {
	background-color: #757171;
	color: #fff;
}

/* Style the tab content */
.tabcontent-categories {
	float: left;
	padding: 0px 1vw 14vw;
	/* border: 1px solid #ccc; */
	width: 80%;
	border-left: none;
	/* height: 300px; */
	margin-left: 1vw;
}

.related-software-container .product-categories-filter {
	margin-top: 3vw;
}

.product-boxes .categories-box-container {
	margin-top: 0;
	grid-template-rows: auto;
	grid-template-columns: repeat(3, 1fr);
}

.product-boxes-content .categories-image img {
	/* width: 18vw; */
	width: 100%;
}
/* product-categories-tabs end */

/* product-categories page end */

@media (max-width: 900px) {
	.company-profile-container {
		height: 80vh;
	}
	.product-boxes .categories-box-container {
		grid-template-rows: auto;
		grid-template-columns: repeat(2, 1fr);
		width: 100%;
	}
	.company-profile-container {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	/* product-categories page start */
	.tab-product-categories button {
		font-size: 1.5vw;
	}

	.tabcontent-categories {
		padding: 0px 1vw 14vw;
		width: 75%;
	}

	/* product-categories page end */
}

.product-categories-filter {
	display: flex;
}

.tabcontent-categories.product-boxes {
	display: none;
}

.tabcontent-categories.product-boxes.open {
	display: block;
}

/* Model */
.like-modal-container.open {
	padding-bottom: 8vw;
}

.like-modal-container {
	height: 100vh;
}

.company-profile-container {
	justify-content: center;
	width: 60vw;
	padding: 0vw;
	align-items: start;
}

.company-profile-container .cmpy-modal iframe {
	width: 100%;
	height: 100vh;
}

@media screen and (max-width: 599px) {
	.company-profile-container {
		justify-content: center;
		width: 100%;
		align-items: start;
		margin: 5vw 1vw;
	}
	.product-categories-filter {
		flex-direction: column;
	}
	.company-profile-button button .icon-button img {
		width: 4vw;
	}
	.company-profile-button button.company-profile {
		padding: 2vw;
	}
	.tab-product-categories {
		margin-bottom: 5vw;
	}
	.tab-product-categories button.active,
	.tab-product-categories button {
		width: 50%;
		font-size: 3vw;
	}
	.tabcontent-categories {
		width: 100%;
		margin-left: 0vw;
		padding-left: 0;
	}

	.content-container .caepipe-location .location-icon .icon-content button img {
		width: 3vw;
	}
	.product-container .product-box-container .supplier-boxes {
		padding: 10vw 0 6vw 3vw;
	}
	.cmpy-profile-modal,
	.cmpy-modal {
		width: 100%;
	}
	.cmpy-modal {
		padding: 8vw 0;
	}

	.company-profile-container .cmpy-modal iframe {
		width: 100vw;
		height: 100vh;
	}
}

/* software-page start */
.softwares-container .softwares-check {
	position: absolute;
	right: 0vw;
}

.softwares-container {
	/* margin: 15vw 9vw 15vw 9.2vw; */
	margin: 11vw 9vw 15vw 9.2vw;
}

.softwares-container .software-box-container {
	border-left: none;
	margin-left: 0vw;
}

.softwares-container .software-box-container .software-boxes {
	/* padding: 0vw 0vw 4vw 0vw; */
	padding: 0vw 0vw 6vw 0vw;
}

.software-boxes .software-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	/* grid-template-rows: repeat(4, 23.2vw); */
}

.software-boxes-content .software-info .software-content h2 span {
	color: var(--close-button);
}

.software-container .software-boxes-content{
	padding-top: 2.5vw;
}

.software-boxes-content
	.software-info
	.software-content
	h2.truncate
	span
	a.my-anchor-css-class {
	color: var(--red);
}

.software-boxes-content .software-image img {
	
	height: auto;
	width: 100%;
	object-fit: cover;
	
}

.software-boxes-content .software-info {
	padding: 1vw 0vw 0vw 1vw;
}

.software-boxes-content .software-info .software-content {
	padding-right: 1vw;
}
/* software-page end */

@media (max-width: 900px) {
	.softwares-container {
		margin: 24vw 12vw 15vw 12vw;
	}

	.product-container .product-suppliers label {
		font-size: 2vw;
	}
	.product-container .product-suppliers input[type="checkbox"] + label:before {
		width: 3vw;
		height: 3vw;
	}
	.product-container
		.product-suppliers
		input[type="checkbox"]:checked
		+ label:after {
		top: 0.2vw;
		right: 1vw;
		width: 0.8vw;
		height: 1.9vw;
		border-width: 0 0.4vw 0.4vw 0;
	}
	.software-boxes .software-container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 3vw;
		margin-top: 4vw;
	}
	.software-boxes-content .software-info {
		padding: 2vw 0vw 2vw 1vw;
	}
}

@media screen and (max-width: 599px) {
	.softwares-container {
		margin: 34vw 4vw 15vw 4vw;
	}
	.product-container .product-suppliers label {
		font-size: 3vw;
	}
	.product-container .product-suppliers input[type="checkbox"] + label:before {
		width: 4vw;
		height: 4vw;
	}
	.product-container
		.product-suppliers
		input[type="checkbox"]:checked
		+ label:after {
		top: 0.5vw;
		right: 1.4vw;
		width: 1.2vw;
		height: 2.9vw;
		border-width: 0 0.6vw 0.6vw 0;
	}
	.software-boxes .software-container {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 4vw;
		margin-top: 5vw;
	}
}

.month-time {
	width: 50%;
}

.software_desc {
	color: #fff;
	text-align: left;
}

.software_desc p {
	font-size: 1.2vw;
}

.software_desc p a {
	color: #3130f5;
	cursor: pointer;
}

.software_desc p.center {
	text-align: center;
	margin: 1vw;
}

#caepipeimage {
	height: 100%;
	width: 100%;
}

.software-related-content{
	width: 60vw;
}

.software-related-content a{
	width: 18vw;
}

.software-related-content .software-boxes-content{
	padding-top: 3vw;
}

.software-related-content .software-boxes-content img{
	height: auto;
	/* height: 15vw;
    object-fit: cover; */
}

@media screen and (max-width: 900px) {
	#caepipecontainer {
		margin: 4vw 2vw 4vw 2vw;
		grid-gap: 4%;
		gap: 4%;
	}
}

.softErrorMsg{
	text-align: left;
	color: #ffffff;
	margin-bottom: 2vw;
}

.creategroup-modal .creategroup-content form button.softDownBtn{
	margin-top: 1vw;
	margin-bottom:1vw;
}
.dropdown-content {
	display: none;
	position: absolute;
	right: 0;
	background-color: var(--grey);
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	cursor: default;
	max-height: 58vh;
	overflow-y: auto;
}

.add-button:hover .dropdown-content {
	display: block;
}

.add-button a:hover,
.add-button a:focus,
.add-button a:active {
	background-color: #e9ecef;
}

.free-offer-heading-div {
	background-color: #fde9da;
	text-align: center;
	padding: 12px;
}

.free-offer-heading-text {
	font-weight: 600;
}

.heading-sub-text {
	text-align: center;
	font-weight: 600;
}

.free-service-heading-table {
	font-weight: 600;
	margin-top: 10px;
}

.free-service-tableDiv,
.paid-service-tableDiv {
	margin-top: 5px;
	margin-bottom: 100px;
}

.paid-service-heading-table {
	margin-top: 40px;
	font-weight: 600;
}

.content-container .sidebar-content .fieldwidth {
	width: 16vw !important;
}

.content-container .sidebar-content .input-field {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.sidebar-or-text {
	text-align: center;
	font-weight: 600;
}

.clickHere {
	color: #0d6efd;
	text-decoration: underline;
}

.clickDiv {
	margin-top: 2vw;
}

.software-btns {
	/* display: flex; */
}

.product-edit {
	top: unset;
	bottom: 0;
	right: 0;
	position: absolute;
}

.product-container {
	padding-bottom: 5vw;
}

.requiredField {
	color: red;
}

.tox-statusbar__branding,
.tox-statusbar__path {
	display: none;
}

.attachText {
	float: left;
	/* width: 155px; */
	font-size: 16px;
	margin: 0;
	color: var(--close-button);
	font-weight: 600;
}

.attachIconsDiv {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.attachIcons {
	margin: 0 4px;
}

/* tooltip START*/

.tooltip {
	position: relative;
	display: inline-block;
	opacity: 1;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 70px;
	background-color: #fff;
	color: #000;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 130%;
	margin-left: -60px;
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	border: 1px solid black;
}

/* tooltip END */

.content-container .sidebar-content .radiobtn {
	width: auto;
	position: unset;
	left: unset;
}

.content-container .sidebar-content .checkboxbtn {
	width: auto;
}

.slider-box-bg {
	background-color: unset;
	padding: unset;
	margin-top: 2.5vw;
}

.sky-mg {
	margin: unset;
}

#cuntry,
#stats,
#cmpny {
	height: auto;
	width: 100%;
}

.mg-top {
	margin-bottom: 1vw;
}

.content-container .sidebar-content #layout1,
.content-container .sidebar-content #layout2,
.content-container .sidebar-content #layout3 {
	position: unset;
	left: unset;
}

.centerImg {
	text-align: center;
}

.inlineBtn {
	display: flex;
}

.sky-tabs > ul > .tabover {
	overflow: unset;
}

table,
th,
td {
	border: 1px solid black;
	border-collapse: collapse;
	padding: 1vw;
}

.txtUnderline {
	text-decoration: underline;
}

.free-service-tableHeading {
	text-align: center;
	background-color: #d6eef3;
}

.free-offer-container {
	margin: 15vw 2vw;
	padding-bottom: 10vw;
}

.transactionHist {
	font-weight: 600;
	margin-bottom: 1vw;
}

.transTable {
	margin-bottom: 10px;
}

.transDiv {
	display: flex;
	justify-content: space-between;
	flex-direction: column-reverse;
}

.profile-container {
	margin: 15vw 20vw;
	padding-bottom: 5vw;
}

.image-content {
	margin-top: 1.5vw;
	display: inline-block;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.userImage {
	float: left;
}

.userImage h3 {
	margin-top: 10px;
	text-align: center;
}
.userImage a {
	color: #0d6efd;
}
.userdetails {
	float: left;
	margin-top: 0;
	padding-left: 30px;
}

.mini-Head {
	margin-top: 10px;
}

.editIcon {
	margin-left: 10px;
	cursor: pointer;
}

.prsnlDetails {
	margin-top: 20px;
}

.prsnlDetails ul {
	list-style-type: none;
	padding-left: 0vw;
}

.prsnlDetails ul label {
	float: left;
	width: 155px;
	font-size: 16px;
	margin: 0;
	color: var(--close-button);
	font-weight: 600;
}

.prsnlDetails li {
	line-height: 40px;
	margin-top: 10px;
}

.paraFont {
	font-size: 14px;
}

.gear {
	width: 70%;
	display: inline-block;
}

.datainfo {
	width: auto;
	max-width: 90%;
	text-align: justify;
	float: left;
	line-height: 20px;
	/* margin: 10px 0; */
	display: flex;
	grid-gap: 1%;
	gap: 1%;
}

.iconContent {
	margin-top: 40px;
}

.iconSpace {
	margin-bottom: 40px;
}

.attachIconsprofile {
	margin: 0 15px;
	z-index: 0;
}

.exp_head {
	width: 100%;
	display: inline-block;
	margin-top: 20px;
}

.exp_head > h2 {
	color: #a9b3bd;
	float: left;
	margin: 5px 26px;
	text-transform: none;
	width: 60%;
	font-size: 28px;
	font-weight: normal;
}
.addposi {
	background-color: var(--post-background);
	cursor: pointer;
}

.exp_head > p {
	background: var(--post-background);
	float: left;
	margin-left: 5px;
	padding: 2px 10px;
	/* width: 96px; */
	font-weight: bold;
}

.exp_head span {
	padding: 0 5px;
}

.expContentDiv,
.honorContent {
	margin: 0 26px;
}

.expContentDiv {
	margin-top: 40px;
}

.expDetails {
	margin-top: 20px;
}

.expDetails h2 {
	color: var(--close-button);
	font-weight: 600;
}

.expBtn .exp-button .addBtn {
	margin: 10px 26px;
	text-align: center;
	background: var(--post-background);
	width: 94%;
}

/* .exp-button{
    width: 96.4%;
  } */

.profile-container .sidebar-content .input-field {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}
.profile-container .sidebar-content .ProfileTitleInput {
	width: 100%;
}

.sidebar-space {
	margin-bottom: 2.5vw;
}

.selectSpace {
	margin-top: 1vw;
	width: 220px;
	display: inline-block;
}

.last-space {
	margin-bottom: 0.5vw;
}

.btns {
	display: flex;
}

#cancelbtn,
#expcancelbtn,
#educancelbtn {
	margin-left: 1vw !important;
}

.expBtn .expi-button #savebtn,
.expBtn .expi-button #cancelbtn,
.expBtn .expi-button #expcancelbtn,
.expBtn .expi-button #expsavebtn,
.expBtn .expi-button #edusavebtn,
.expi-button #educancelbtn {
	background-color: var(--post-background);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	color: var(--close-button);
	font-size: 0.97vw;
	width: 7vw;
	margin: 30px 0px;
	text-align: center;
	min-height: 2.1vw;
}

.honDiv {
	margin-bottom: 5vw;
}

.divBorder,
.expDiv {
	border: 1px solid var(--grey);
}

.divBorder {
	padding: 3vw;
}

.expDiv {
	margin-top: 2vw;
}

.displayInput {
	display: none;
}

.inpfield {
	border: unset;
	border-bottom: 1px solid var(--grey) !important;
	font-size: 1vw;
	border-radius: 0.3vw;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: block;
	margin-top: 0.5vw;
	padding: 0.1rem 4rem 0.1rem 0.5rem;
}

.saveCancelBtn {
	border: 1px solid var(--grey);
}

.SelectMonth {
	display: flex;
	width: 50%;
	grid-gap: 2%;
	gap: 2%;
	align-items: center;
}

.selectYear {
	width: 100%;
}

/* .fieldWidth {
  width: 100%;
} */

.checkClass {
	width: unset;
	display: inline-block;
	margin-right: 0.5vw;
}

.borBotm {
	border-bottom: 1px solid var(--grey);
}

/* accordion start*/

.accord {
	background-color: #eee;
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	transition: 0.4s;
}

.activ,
.accord:hover {
	background-color: #ccc;
}

.pan {
	padding: 20px 5px;
	display: none;
	background-color: white;
	overflow: hidden;
}

/* accordion end */

.prsnlLabel {
	display: unset;
}

#user-ttext:after {
	top: unset;
}

.content-css {
	word-break: break-all;
}

@media (max-width: 900px) {
	.product-container {
		padding-bottom: 15vw;
	}
	.content-container .sidebar-content .input-field {
		width: 100% !important;
		padding: 0.5vw 1.5vw;
		font-size: 2vw;
	}

}

@media screen and (max-width: 599px) {
	.content-container .sidebar-content .input-field {
		padding: 2vw 2vw;
		font-size: 3vw;
	}
	.sidebar-or-text,
	.content-container .clickDiv p {
		font-size: 3vw;
	}
	.content-container .sidebar-content .radiobtn {
		position: relative;
		top: 0.5vw;
	}
	.SelectMonth {
		width: 100%;
	}
}

@media screen and (max-width: 399px) {
	.userImage h3 {
		font-size: 4vw !important;
	}
}

.labelText{
    color: var(--close-button);
    margin-top: 2%;
}
/* chat-page start */
.group-container {
	padding-bottom: 2vw;
	margin-left: 4vw;
}

.showuser-profile {
	margin-bottom: 3vw;
	padding: 1vw;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 1vw;
	height: 23vw;
}

.showuser-profile img {
	width: 100%;
	height: 100%;
}

.show-members {
	border: 0.1vw solid var(--close-button);
	padding: 1vw;
	margin-bottom: 2vw;
	background-color: var(--medium-grey);
}

.show-members h2 {
	font-weight: bold;
	color: #fff;
}

.chat-page-section .left-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	/* position: absolute; */
	z-index: 1;
	top: 10vw;
	left: 0;
	bottom: 0;
	padding: 7vw 0vw 18vw 1vw;
	margin-right: 1vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
}

.chat-page-section .left-sidebar .chat-page-arrow {
	position: absolute;
	top: 2vw;
	right: 0vw;
}

.chat-page-section .left-sidebar .chat-page-arrow img {
	width: 2vw;
}

.messenger-container {
	border: 1px solid var(--light-grey);
}

.messenger-container .messenger-content {
	/* padding: 1vw 1vw 1vw 2vw; */
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: 1px solid var(--light-grey);
	cursor: pointer;
}

.messenger-container .messenger-content.show-members-content {
	justify-content: flex-start;
	grid-gap: 8%;
	gap: 8%;
}

.messenger-container .messenger-content:nth-child(6) {
	border-bottom: none;
}

.messenger-container .messenger-content.active-messenger-content {
	background-color: var(--post-background);
}

.messenger-container .messenger-content .user-profile.show-profile,
.display-user .user-info-container .user-profile.show-profile {
	overflow: hidden;
	width: 5vw;
	height: 5vw;
}

.messenger-container .messenger-content .user-profile.show-profile img,
.display-user .user-info-container .user-profile.show-profile img {
	width: 100%;
}

.messenger-container .messenger-content .user-content.users .user-name {
	word-break: break-word;
}

.messenger-container .messenger-content .user-content h3,
.messenger-container .messenger-content .active-time h3,
.display-user .user-info-container .user-name h2,
.display-user .new-message h2 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.messenger-container .messenger-content .user-content h3.grey-header,
.display-user .new-message h2.grey-header {
	color: var(--close-button);
}

.display-user .user-info-container .user-name h2 span {
	text-transform: uppercase;
}

.messenger-container .messenger-content .active-time {
	margin-top: 1vw;
}

.chat-page-section .chat-box-display {
	border-left: 1px solid var(--light-grey);
	/* margin: 14vw 4vw 10vw 24vw; */
}

.show-group-container {
	position: relative;
	background-color: var(--post-background);
	border: 1px solid var(--light-grey);
	border-radius: 0.3vw;
	margin: 1vw 0 0 0;
	padding: 2vw 2vw !important;
	margin: 14vw 12vw 6vw 24vw;
}

.show-info .info-header {
	border-bottom: 0.1vw solid var(--close-button);
	margin-bottom: 1vw;
}

.show-info .info-header h2 {
	font-weight: bold;
	margin-bottom: 1vw;
	text-transform: uppercase;
}

.show-info .info-description h3 {
	color: var(--close-button);
}

.show-info .info-description,
.show-info .experience-content,
.show-info .education-content {
	margin-bottom: 2vw;
}

.show-info .info-description.last-description {
	margin-bottom: 0;
}

.show-info .experience-content .exp-title,
.show-info .experience-content .exp-description,
.show-info .experience-content .exp-desc-content,
.show-info .education-content .edu-name,
.show-info .education-content .edu-grade,
.show-info .education-content .activities h3.activity-info {
	color: var(--close-button);
}

.show-info .experience-content .exp-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5vw;
}

.show-info .experience-content .exp-title h3.name,
.show-info .experience-content .exp-description h3.desc,
.show-info .education-content .edu-title h3.title,
.show-info .education-content .edu-grade span,
.show-info .education-content .activities h3.activity,
.show-info .info-description .created-name {
	font-weight: bold;
	color: #555;
}

.show-info .experience-content .exp-description,
.show-info .education-content .education,
.show-info .info-description .content,
.show-info .info-description h3.personal-info {
	margin-bottom: 0.5vw;
}

.show-info .info-description h3.personal-info span.date {
	width: 15vw;
	float: left;
}

.show-info .info-description h3.personal-info span.date-info {
	padding-left: 1vw;
}

.show-members-list {
	overflow-y: auto;
	overflow-x: hidden;
	height: 20vw;
}

/* chat-page end */

.no-connec {
	padding: 2vw;
}

.no-connec h2 {
	font-weight: bold;
}

/* profile-show  start */
.user-friend-status {
	position: relative;
}

.user-friend-status .user-status-container {
	position: absolute;
	top: 19vw;
	width: 100%;
	padding: 1vw;
	background-color: var(--medium-grey);
	opacity: 0.8;
}

.user-friend-status .user-status-container .user-status-one-container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	grid-gap: 6%;
	gap: 6%;
}

.user-friend-status .user-status-container .user-status-one-container h1,
.user-friend-status .user-status-container .user-status-one-container i {
	font-weight: bold;
	color: var(--white);
	cursor: pointer;
}

.user-friend-status .user-status-container .user-status-one-container i {
	font-size: 1.5vw;
}

.user-friend-status .user-status-container .friend-dropdown {
	position: absolute;
	background-color: var(--black);
	padding: 0.5vw 0.8vw;
	border-radius: 0.3vw;
	top: 3vw;
	right: 0vw;
	z-index: 9;
	cursor: pointer;
}

.user-friend-status .user-status-container .friend-dropdown h2 {
	color: var(--white);
	font-weight: bold;
}
/* profile-show  end */

@media (max-width: 900px) {
	.show-info .info-description h3 {
		font-size: 1.6vw;
	}

	.show-info .info-description p {
		font-size: 1.7vw;
	}

	.show-info .info-header h2 {
		font-size: 1.8vw;
	}

	.show-info h3,
	.showuser-container h3 {
		font-size: 1.6vw;
	}

	.left-sidebar .showuser-container h2 {
		font-size: 1.8vw;
	}

	.showuser-container .user-status-container h1 {
		font-size: 2.5vw;
	}

	.showuser-profile {
		margin-bottom: 6vw;
	}

	.user-friend-status .user-status-container .friend-dropdown {
		top: 4vw;
		right: 0vw;
	}
	.user-friend-status .user-status-container {
		top: 18vw;
	}
}

@media screen and (max-width: 599px) {
	.show-info .info-description h3 {
		font-size: 3vw;
	}
	.show-info .experience-content .exp-description,
	.show-info .education-content .education,
	.show-info .info-description .content,
	.show-info .info-description h3.personal-info {
		margin-bottom: 1.5vw;
	}
	.show-info .info-description,
	.show-info .experience-content,
	.show-info .education-content {
		margin-bottom: 4vw;
	}
	.show-info .info-header h2 {
		font-size: 3vw;
	}
	.show-info .info-description p {
		font-size: 3vw;
		line-height: 1.2;
	}
	.show-info .experience-content .exp-title {
		margin-bottom: 2vw;
	}
	.show-info h3,
	.showuser-container h3 {
		font-size: 3vw;
	}
	.show-info .info-header h2 {
		margin-bottom: 3vw;
	}

	.showuser-profile {
		margin-bottom: 10vw;
		height: 76vw;
	}

	/* show page */
	#user-detail-page {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	#user-detail-sidebar {
		position: relative;
		top: 0vw;
		margin-top: 34vw;
		width: 80%;
		padding: 0vw 0vw 10vw 0vw;
	}
	#user-detail-container {
		margin: 34vw 4vw 30vw 4vw;
		border-left: 1px solid var(--light-grey);
		margin-top: 0;
	}
	#user-container {
		margin-left: 0;
		width: 100%;
	}
	.user-friend-status .user-status-container .friend-dropdown {
		top: 7vw;
		right: 7vw;
		padding: 2vw 3vw;
	}
}

/* chat-page start */
.chat-page-section .left-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	/* position: absolute; */
	z-index: 1;
	top: 10vw;
	left: 0;
	bottom: 0;
	padding: 7vw 0vw 18vw 1vw;
	margin-right: 1vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
}

.chat-page-section .left-sidebar .chat-page-arrow {
	position: absolute;
	top: 2vw;
	right: 0vw;
}

.chat-page-section .left-sidebar .chat-page-arrow img {
	width: 2vw;
}


.leaveNoti {
	text-align: center;
	font-weight: bold;
	margin-bottom: 2vw;
}

.messenger-container {
	border: 1px solid var(--light-grey);
}

.messenger-container .messenger-content {
	padding: 1vw 1vw 1vw 1vw;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	border-bottom: 1px solid var(--light-grey);
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	position: relative;
	grid-gap: 4%;
	gap: 4%;
	flex-wrap: wrap;
}

.messenger-container .messenger-content:first-child {
	border-top: 0;
}

.messenger-container .messenger-content:last-child {
	border-bottom: 0;
}

.messenger-container .messenger-content:nth-child(6) {
	border-bottom: none;
}

.messenger-container .messenger-content.active-messenger-content {
	background-color: var(--post-background);
}

.messenger-container .messenger-content:hover {
	background-color: var(--post-background);
}

.selectedChat {
	background-color: var(--post-background);
}

.messenger-container .messenger-content .delete-msg,
.messenger-container .messenger-content .mail-icon {
	position: absolute;
	right: 1vw;
	bottom: 1vw;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
}

.messenger-container .messenger-content .mail-icon {
	right: 2.5vw;
}

.messenger-container .messenger-content .delete-msg i,
.messenger-container .messenger-content .mail-icon i {
	font-size: 1vw;
}

.delete-modal {
	height: 33vh !important;
}

.messenger-container .messenger-content .user-profile,
.display-user .user-info-container .user-profile {
	width: 4vw;
	height: 4vw;
	position: relative;
}

.messenger-container .messenger-content .grp-profile {
	height: auto;
	width: 4vw;
	flex-wrap: wrap;
	position: relative;
}
.messenger-container .messenger-content .grp-profile img {
	width: 2vw;
	height: 2vw;
}

.image-round {
	border-radius: 50%;
}

.messenger-container .messenger-content .user-profile img,
.display-user .user-info-container .user-profile img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 1.2vw;
	line-height: 1.2;
}

.messenger-container .messenger-content .user-content {
	margin: 1vw 0 0 0vw;
	/* width: 49%; */
}

.messenger-container .messenger-content .user-content.users {
	/* margin-right: -1vw; */
	width: 45%;
}

.messenger-container .messenger-content .user-content h3,
.messenger-container .messenger-content .active-time h3,
.display-user .user-info-container .user-name h2,
.display-user .new-message h2 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.messenger-container .messenger-content .user-content h3.grey-header,
.display-user .new-message h2.grey-header {
	color: var(--close-button);
}

.messenger-container .messenger-content .active-time {
	margin-top: 1vw;
}

.chat-page-section .chat-box-display {
	padding: 0 0vw 0 3vw;
	border-left: 1px solid var(--light-grey);
	margin: 14vw 4vw 10vw 24vw;
}

.chat-box-display .display-user,
.display-user .user-info-container,
.display-user .new-message {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.display-user .user-info-container {
	justify-content: flex-start;
	align-items: center;
	grid-gap: 2%;
	gap: 2%;
	width: 100%;
	z-index: 1;
}

.display-user .new-message {
	position: absolute;
	right: 4vw;
	z-index: 2;
}

.display-user .user-info-container .user-name h2,
.display-user .new-message h2 {
	margin-bottom: 0;
}

.display-user .new-message .new-message-button button {
	background-color: transparent;
}

.display-user .new-message .new-message-button button img {
	width: 2vw;
}

.popup-chatbox {
	position: relative;
	background-color: var(--post-background);
	border: 1px solid var(--light-grey);
	border-radius: 0.3vw;
	margin: 1vw 0 0 0;
	padding: 2vw 4vw 0vw;
	overflow-y: auto;
	/* height: 80vh; */
	min-height: 50vh;
	max-height: 80vh;
}


.popup-chatbox .menu-dots img {
	width: 0.5vw;
}

.popup-chatbox .chat-date {
	text-align: center;
	margin-bottom: 1vw;
}

.popup-chatbox .chat-date h1 {
	font-weight: bold;
}

.popup-chatbox .chat-user-one,
.popup-chatbox .chat-user-two {
	position: relative;
	margin-bottom: 7vw;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.popup-chatbox .chat-user-two {
	position: relative;
	margin-bottom: 7vw;
	justify-content: flex-end;
	align-items: flex-end;
}

.popup-chatbox .chat-user-one .chat-content-one,
.popup-chatbox .chat-user-two .chat-content-two {
	background-color: var(--white);
	border-radius: 0.3vw;
	border: 1px solid var(--light-grey);
	padding: 1.5vw;
	width: 50%;
	margin-top: 1vw;
}

.popup-chatbox .chat-user-two .chat-content-two {
	background-color: transparent;
	border: 1px solid var(--medium-grey);
}

.popup-chatbox .chat-user-one h1,
.popup-chatbox .chat-user-two h1 {
	font-weight: bold;
	color: var(--close-button);
}

.popup-chatbox .chat-user-one .chat-time-one,
.popup-chatbox .chat-user-two .chat-time-two {
	position: relative;
	top: 0.5vw;
	left: 2vw;
}

.popup-chatbox .chat-user-two .chat-time-two {
	left: -22vw;
}

.chat-box-display .type-message {
	margin-top: 1vw;
	padding-bottom: 7vw;
	display: flex;
	justify-content: space-between;
	align-items: start;
}

.chat-box-display .type-message form input.type-input {
	background-color: transparent;
	border: 1px solid var(--light-grey);
	border-radius: 0.3vw;
	padding: 1vw 1vw;
	font-size: 1.1vw;
	width: 56vw;
}

.chat-box-display .type-message form input::-webkit-input-placeholder {
	color: var(--close-button);
	font-size: 1.2vw;
}

.chat-box-display .type-message form input:-ms-input-placeholder {
	color: var(--close-button);
	font-size: 1.2vw;
}

.chat-box-display .type-message form input::placeholder {
	color: var(--close-button);
	font-size: 1.2vw;
}

.chat-box-display .type-message .send-button button {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	color: var(--close-button);
	padding: 1vw 1.5vw;
	font-weight: bold;
	width: 10vw;
	margin-right: 1vw;
}

.msgHeader{
	word-break: break-word;
}
/* chat-page end */

/* New group top bar start*/

#grpinfs {
	float: left;
	width: 100%;
	border: 1px solid #ccc;
	padding: 1vw;
	margin-bottom: 2vw;
	margin-top: 2vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#grpinfs .grpdets {
	float: left;
	width: auto;
}

#grpinfs .grpdets h1 {
	margin: 0px;
	padding: 0 0 0.8vw;
}

#grpinfs .optns {
	float: right;
	width: auto;
	font-size: 16px;
	cursor: pointer;
	color: #000;
}

.dropMain {
	height: unset;
	display: flex;
}

.grpOptions {
	list-style-type: none;
	padding: 0;
	margin-bottom: 0;
	white-space: nowrap;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.grpOptionslist {
	padding: 1vw;
	font-size: 1vw;
	cursor: pointer;
}

.grpOptionslist i {
	margin: 0 8px 0 0;
}

/* New group top bar end*/

.sendapply {
	width: auto;
	font-size: 1.66vw;
	margin: 0.3vw 0;
	background-color: var(--post-background);
	color: var(--close-button);
	transition: all 0.3s ease-in-out;
}

/* Chat Info */

.chat_info ul {
	list-style: none;
}

/* Messege */
.messegebox {
	display: flex;
	justify-items: center;
	flex-direction: column;
	margin-bottom: 1vw;
}

.messegebox.loggedUser {
	margin-left: 20%;
}

.messegebox.notloggedUser {
	margin-right: 20%;
}

.messegebox .message-content {
	display: flex;
	align-items: center;
}

.messegebox .message-content .message {
	background-color: var(--white);
	border-radius: 0.3vw;
	border: 1px solid var(--light-grey);
	padding: 1vw;
	margin-top: 0.5vw;
	margin-bottom: 0.5vw;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	font-size: 1.2vw;
	width: 100%;
}

.messegebox h1 {
	font-weight: bold;
	color: var(--close-button);
	font-size: 1.2vw;
}

.messegebox.loggedUser .forward-message-chk {
	margin-left: 2%;
}

.messegebox.notloggedUser .forward-message-chk {
	margin-right: 2%;
}

.messegebox .chat-time {
	padding-left: 3%;
}

.forward_users {
	float: left;
	width: 100%;
	border: 1px solid #ccc;
	margin-top: -2vw;
	margin-bottom: 2vw;
}

.forward_users .option-multi-select {
	margin-bottom: unset;
}

/* Add people modal start */
.sendmessage-content form.add-people-type {
	display: flex;
	flex-direction: row;
	justify-content: end;
	grid-gap: 2%;
	gap: 2%;
}
.convList {
	background-color: #fff;
	width: 100%;
	padding: 1.5vw;
	text-align: left;
	max-height: 35vh;
	overflow: auto;
}
.convList .active-users-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* grid-gap: 8%; */
	grid-gap: 2%;
	gap: 2%;
	margin-bottom: 1.5vw;
	margin-top: 1vw;
}
.convList .active-users-content .user-image img {
	height: 4vw;
	width: 4vw;
}
.disabled {
	opacity: 0.6;
	pointer-events: none;
}
/* Add people modal end */

@media screen and (max-width: 900px) {
	.chat-page-section .left-sidebar {
		padding: 14vw 0vw 26vw 1vw;
	}

	.chat-page-section .chat-box-display {
		margin: 24vw 4vw 10vw 27vw;
	}

	.chat-page-section .left-sidebar {
		width: 26%;
	}

	.chat-box-display .type-message form input.type-input {
		width: 50vw;
		font-size: 2vw;
	}

	.chat-box-display .type-message form input.type-input::-webkit-input-placeholder {
		font-size: 2vw;
	}

	.chat-box-display .type-message form input.type-input:-ms-input-placeholder {
		font-size: 2vw;
	}

	.chat-box-display .type-message form input.type-input::placeholder {
		font-size: 2vw;
	}

	.grpOptionslist {
		font-size: 1.5vw;
	}

	.grpOptions {
		width: 20vw;
	}

	button.close-button-modal {
		height: auto;
	}

	.chat-time h1 {
		font-size: 2vw;
	}
	.chat-box-display .type-message {
		margin-top: 2vw;
		padding-bottom: 11vw;
	}
	.display-user .new-message .new-message-button button img {
		width: 4vw;
	}
	.popup-chatbox {
		margin: 3vw 0 0 0;
	}
	.messenger-container .messenger-content .user-profile img,
	.display-user .user-info-container .user-profile img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.messenger-container .messenger-content .grp-profile {
		width: 6vw;
	}
	.messenger-container .messenger-content .grp-profile img {
		width: 3vw;
		height: 3vw;
	}
	.messenger-container .messenger-content .user-content.users {
		width: 65%;
	}
	.messenger-container .messenger-content .mail-icon {
		right: 4vw;
	}
	.messenger-container .messenger-content .delete-msg i,
	.messenger-container .messenger-content .mail-icon i {
		font-size: 2vw;
	}
	.messenger-container .messenger-content {
		align-items: center;
	}
	.chat-box-display .type-message .send-button button {
		width: 12vw;
	}
	.messegebox .message-content .message {
		font-size: 2vw;
	}
	.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
	.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 1.6vw;
	line-height: 1.3;
	}
	.messegebox{
		margin-bottom: 3vw;
	}
}

@media screen and (max-width: 599px) {
	.chat-page-section .chat-box-display {
		margin: 34vw 4vw 10vw 4vw;
		padding: 0 0vw 0 0vw;
		border-left: 0;
	}
	.chat-page-section .left-sidebar {
		width: 60%;
		background-color: white;
	}
	.chat-page-section .left-sidebar {
		padding: 0vw 0vw 26vw 0vw;
		position: absolute;
		top: 8vw;
	}
	.chat-page-section .left-sidebar .chat-page-arrow {
		display: none;
	}
	.chat-time h1 {
		font-size: 2.3vw;
	}
	.chat-box-display .type-message form {
		width: 80%;
	}
	.chat-box-display .type-message form input.type-input {
		width: 100%;
		font-size: 3vw;
		padding: 2vw 2vw;
	}
	.chat-box-display .type-message form input.type-input::-webkit-input-placeholder {
		font-size: 3vw;
	}
	.chat-box-display .type-message form input.type-input:-ms-input-placeholder {
		font-size: 3vw;
	}
	.chat-box-display .type-message form input.type-input::placeholder {
		font-size: 3vw;
	}
	.display-user .new-message h2 {
		font-size: 2.5vw;
		line-height: 1;
	}
	.display-user .user-info-container .hamburger-menu .fa-bars {
		font-size: 4.3vw;
		line-height: 1;
	}
	.display-user .user-info-container .user-profile {
		width: auto;
		height: auto;
	}
	.messenger-container .messenger-content .user-content h3 {
		font-size: 2.8vw;
		margin-bottom: 1vw;
	}
	.display-user .user-info-container .hamburger-menu {
		line-height: 1;
	}
	#hamburger-chat {
		width: 5vw;
	}
	.messenger-container .messenger-content {
		align-items: flex-start;
	}
	.messenger-container .messenger-content .delete-msg i,
	.messenger-container .messenger-content .mail-icon i {
		font-size: 2.7vw;
	}
	.messenger-container .messenger-content .mail-icon {
		right: 5vw;
	}

	/* chatpage css changes start */
	.messenger-container .messenger-content {
		padding: 3vw 3vw 3vw 3vw;
	}
	.messenger-container .messenger-content .delete-msg,
	.messenger-container .messenger-content .mail-icon {
		right: 5vw;
	}
	.messenger-container .messenger-content .mail-icon {
		right: 10vw;
	}
	.messenger-container .messenger-content .user-profile,
	.display-user .user-info-container .user-profile {
		width: 7vw;
		height: 7vw;
	}
	.display-user .user-info-container .user-name {
		width: 40%;
	}
	.messenger-container .messenger-content .grp-profile {
		width: 10vw;
	}
	.messenger-container .messenger-content .grp-profile img {
		width: 5vw;
		height: 5vw;
	}
	.messenger-container .messenger-content .user-content.users {
		width: 50%;
	}
	.messenger-container .messenger-content .active-time {
		margin-top: 1vw;
		flex: 1 1;
	}
	.messenger-container .messenger-content .active-time h3 {
		font-size: 3vw;
	}
	.messenger-container .messenger-content .active-time h3 {
		font-size: 2.5vw;
		text-align: end;
	}
	.chat-box-display .type-message {
		margin-top: 3vw;
		padding-bottom: 20vw;
	}
	.popup-chatbox {
		padding: 4vw 4vw 0vw;
		min-height: 65vh;
		max-height: 65vh;
	}
	.messegebox h1 {
		font-size: 3vw;
	}
	.grpOptions {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		white-space: nowrap;
	}
	.grpOptionslist {
		font-size: 2.5vw;
	}
	.messegebox .message-content .message {
		font-size: 3vw;
	}
	/* chatpage css changes end */

	/* show page css start */
	.user-friend-status .user-status-container {
		top: 66vw;
		padding: 3vw;
	}
	.showuser-container .user-status-container h1,
	.user-friend-status .user-status-container .user-status-one-container i {
		font-size: 3.5vw;
	}
	.show-members {
		padding: 3vw;
		margin-bottom: 3vw;
	}
	.left-sidebar .showuser-container h2 {
		font-size: 3vw;
	}
	.messenger-container .messenger-content .user-profile.show-profile,
	.display-user .user-info-container .user-profile.show-profile {
		width: 8vw;
		height: 8vw;
	}
	.show-members-list {
		height: 80vw;
	}
	.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
	.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 2.4vw;
	line-height: 1.4;
	}
	/* show page css end */
}
@media screen and (max-width: 499px) {
	.chat-page-section .left-sidebar {
		padding: 0vw 0vw 26vw 0vw;
	}
	.chat-time h1 {
		font-size: 2.6vw;
	}
	.chat-box-display .type-message .send-button button {
		width: 13vw;
		font-size: 3.3vw;
		padding: 1.5vw 1.5vw;
	}
	.display-user .user-info-container .user-name h2,
	.display-user .new-message h2 {
		font-size: 2.9vw;
	}
	.display-user .new-message .new-message-button button img {
		width: 4.4vw;
	}
	.display-user .user-info-container .hamburger-menu .fa-bars {
		font-size: 4.9vw;
	}
	.messenger-container .messenger-content .delete-msg i,
	.messenger-container .messenger-content .mail-icon i {
		font-size: 3vw;
	}
	.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
	.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 2.7vw;
	line-height: 1.1;
	}
}
@media screen and (max-width: 399px) {
	.chat-page-section .left-sidebar {
		padding: 0vw 0vw 26vw 0vw;
	}
	.chat-time h1 {
		font-size: 3vw;
	}
	.chat-box-display .type-message .send-button button {
		width: 14vw;
		font-size: 3.6vw;
	}
	.display-user .new-message h2 {
		font-size: 3.3vw;
		line-height: 1;
	}
	.display-user .new-message .new-message-button button img {
		width: 4.5vw;
	}
	.display-user .user-info-container .hamburger-menu .fa-bars {
		font-size: 5.3vw;
	}
	.messenger-container .messenger-content .user-content h3 {
		margin-bottom: 1.4vw;
	}
	.messenger-container .messenger-content .delete-msg i,
	.messenger-container .messenger-content .mail-icon i {
		font-size: 3.4vw;
	}
	.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
	.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 3vw;
	line-height: 1.1;
	}
}

.editsummary {
	margin-bottom: 10px;
	width: auto;
}

.userImage img {
	width: 150px;
	height: 150px;
}

.userImage {
	position: relative;
}

.userImage h3 {
	font-size: 15px;
	display: block;
	height: 30px;
	left: 0;
	line-height: 32px;
	color: #3e8bc1;
	text-align: center;
}

.userUpload {
	height: 100%;
	width: 150px;
	top: 0;
	position: absolute;
	cursor: pointer;
	opacity: 0;
}

.updtBtn {
	justify-content: center;
	margin-top: 5%;
}

.displat-flex {
	display: flex;
	align-items: center;
}

.inpfield {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.profileSelectCode {
	background-image: unset;
	background-color: #fff;
	height: unset;
    padding: 0 0.5rem;
}

.pcodeDrop{
	color: var(--black);
}

#editphone .dropdown{
	height: unset;
}

#editphone .dropdown-menu.show {
    background-color: #fff;
	height: 30vh;
    overflow: auto;
}

#editphone .dropdown-item{
	font-weight: 500;
	font-size: 1vw;
	padding: 0.5vw 1vw;
}

.expContentDiv {
	margin-top: 20px;
	padding-bottom: 20px;
}

.editButton {
	background: var(--post-background);
	float: right;
	margin-left: 5px;
	padding: 2px 10px;
	font-size: 14px;
}

.product-section .profile-container .sidebar-content input {
	width: 50%;
}

.presentExp{
	padding: 0.5vw 0.5vw;
}

#honaR .honorContent .expBtn .expi-button .removeBTN, #expi .expContentDiv .expBtn .expi-button .removeBTN, 
#educt .expContentDiv .expBtn .expi-button button.removeBTN{
	width: 100%;
	margin-left: 1vw;
}

@media screen and (max-width: 1200px) {
	.profileSelectCode {
		padding: 0.15rem 0.5rem;
	}
}


@media screen and (max-width: 900px) {

	.profileSelectCode {
		padding: 0 0.5rem;
		margin-top: 0;
	}

	.profile-container {
		margin: 24vw 12vw;
	}

	.divBorder h2 {
		font-size: 1.9vw;
	}

	.inpfield {
		font-size: 2vw;
		width: 100%;
		padding-right: 5vw;
	}

	.paraFont {
		font-size: 2vw;
	}

	.prsnlDetails ul label {
		font-size: 2vw;
	}

	.attachIconsprofile .pannel-icon .resImg {
		height: 6vw;
		width: 100% !important;
	}

	#editaddress .inpfield {
		width: 40vw;
	}

	.expBtn .expi-button #expsavebtn,
	.expBtn .expi-button #savebtn,
	.expBtn .expi-button #cancelbtn {
		font-size: 2vw;
		width: 15vw;
	}

	.profile-container .sidebar-content .input-field {
		font-size: 2vw;
	}

	.exp_head > h2 {
		font-size: 3vw;
	}

	.attachTextprofile {
		font-size: 2vw;
	}

	.expDetails h2,
	.addposi {
		font-size: 1.8vw;
	}

	.expDetails label {
		font-size: 2vw;
	}

	.expDetails p {
		font-size: 1.7vw;
	}

	.expDetails .checkClass {
		width: unset;
		margin-top: 0;
	}

	.expDetails h2 {
		color: var(--bs-body-color);
		font-weight: 600;
	}
}

@media screen and (max-width: 599px) {
	.profile-container {
		margin: 34vw 3vw;
		padding-bottom: 20vw;
	}
	.divBorder {
		padding: 6vw;
	}
	.divBorder h2 {
		font-size: 4vw;
	}
	.image-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		grid-gap: 6%;
		gap: 6%;
		margin-top: 4vw;
	}
	.userImage {
		position: relative;
		width: 50%;
		height: 50%;
	}
	.userImage img {
		width: 100%;
		height: 100%;
	}
	.userUpload {
		width: 100%;
		height: 100%;
	}
	.userdetails {
		float: unset;
		margin-top: 0;
		width: unset;
		padding-left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
	.inpfield {
		font-size: 3.1vw;
		width: 100%;
		margin-top: 2vw;
	}
	.caret-content,
	.gear {
		width: 100%;
	}

	.prsnlDetails li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
	.prsnlDetails ul label {
		font-size: 3vw;
	}
	.paraFont,
	.attachTextprofile {
		font-size: 3vw;
	}
	.attachIconsprofile {
		margin: 5px 15px;
	}
	.attachIconsprofile .pannel-icon .resImg {
		height: 100%;
		width: 100% !important;
	}
	.tooltip .tooltiptext {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		margin-left: -10vw;
		padding: 1vw 2vw;
	}
	.product-section .profile-container .sidebar-content input,
	#editaddress .inpfield {
		width: 100%;
	}
	.profile-container .sidebar-content .input-field {
		padding: 2vw 2vw;
		margin-top: 3vw;
	}
	.expBtn .expi-button #expsavebtn,
	.expBtn .expi-button #savebtn,
	.expBtn .expi-button #cancelbtn {
		font-size: 3vw;
		width: 20vw;
		padding: 1vw;
	}
	.expBtn.btns {
		margin-top: 3vw;
	}
	.datainfo {
		float: unset;
	}

	.expDiv {
		margin-top: 5vw;
		padding: 6vw;
	}
	.exp_head {
		margin-top: 0px;
	}
	.exp_head > h2 {
		font-size: 4vw;
		margin: 5px 0px;
		width: 100%;
	}
	.expDetails h2,
	.addposi {
		font-size: 3vw;
		margin-bottom: 3vw;
	}
	.expContentDiv,
	.honorContent {
		margin: 0;
	}
	.borBotm {
		border-bottom: none;
	}
	.expDetails label,
	.profile-container .sidebar-content .input-field {
		font-size: 3vw;
	}
	#cancelbtn,
	#expcancelbtn,
	#educancelbtn {
		margin-left: 3vw !important;
	}
}

.bsgpsK div:first-child {
    white-space: unset !important;
}

.rdt_TableCell[data-column-id="3"] {
    line-height: 1.5;
}

.actionBtns {
    font-size: 15px;
    background-color: var(--post-background);
    border: 1px solid var(--grey);
    border-radius: 0.3vw;
    color: var(--close-button);
    font-size: 0.97vw;
    width: 4vw;
    margin: 10px 0px;
    text-align: center;
}

.refBtn {
    font-size: 15px;
}

.rdt_TableCol {
    font-size: 12px;
    font-weight: bold;
}

.rdt_TableCell {
    font-size: 12px;
}

.rdt_TableCol .lnndaO {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
}

.dataTable-container {
    position: relative;
    margin: 15vw 10vw 15vw 10vw;
    padding-bottom: 10vw;
}

.dataTable {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.downloadCsv {
    background-color: var(--black);
    color: var(--white);
    font-weight: bold;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: none;
    border-radius: 0.3vw;
    font-size: 1.1vw;
    padding: 0.3vw 0.8vw;
}

.downloadCsv:hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--black);
}

.downCsvDiv {
    text-align: right;
    margin-bottom: 1%;
}

.contact-container label {
    font-size: 1.5vw;
    font-weight: bold;
    color: var(--white);
}

.contact-container .email {
    word-break: break-all;
}

.contact-container .response {
    margin-bottom: 1vw;
    padding: 0.6vw 1vw;
    border-radius: 0.3vw;
    width: 100%;
    font-size: 1.4vw;
    color: var(--black);
    position: relative;
    transition: 0.1s ease-out;
    background-color: #fff;
}

.login-content-fields {
    align-items: unset;
}

.ticketListContainer.open {
    padding-top: 0;
}

.reportAssign {
    width: 100%;
    margin-top: 2vw;
}

.allDet {
    margin-right: 1%;
    vertical-align: middle;
}

.close {
    display: none;
}

.HeadingText {
    margin-bottom: 3%;
}

.creategroup-modal .creategroup-content form select {
    background-color: #fff;
}

#createGroups {
    width: 35%;
}

.rdt_TableHeadRow .iPecdQ {
    min-width: 145px;
}

.rdt_TableCol_Sortable .izRDMy {
    white-space: normal;
}

@media (max-width: 900px) {
    .dataTable-container {
        margin: 22vw 10vw 15vw 10vw;
    }

    .actionBtns {
        font-size: 1.8vw;
        width: 8vw;
    }

    .rdt_TableCol {
        font-size: 2vw;
    }

    .rdt_TableCell {
        font-size: 1.8vw;
    }

    .rdt_TableHeadRow .iPecdQ {
        min-width: 155px;
    }

    .downloadCsv {
        font-size: 2vw;
    }

    .modal-container {
        padding: 1vw;
        align-items: center;
    }

    .creategroup-modal .creategroup-content .statusSelect {
        font-weight: normal;
        color: var(--black);
    }
}

@media screen and (max-width: 599px) {
    .dataTable-container {
        margin: 32vw 3vw 15vw 3vw;
        padding-bottom: 20vw;
    }
    .downCsvDiv {
        margin-bottom: 3%;
    }
    .downloadCsv {
        font-size: 3vw !important;
        margin-right: 2%;
    }
    .dataTable {
        margin-bottom: 15vw;
    }
    .iPecdQ,
    .izRDMy,
    .cJMYXl {
        font-size: 3vw !important;
    }
    .actionBtns {
        font-size: 3vw !important;
        width: 15vw;
    }
    .gEuYxO {
        min-height: 3vw;
    }
}

.premium-container {
	font-weight: bold;
	font-size: 1.5vw;
}

.premium-container label {
	width: 300px;
	float: left;
	margin-bottom: 7px;
}

.labinsep {
	/* width: 20px;
    float: left; */
	margin-right: 2vw;
}

.parentDiv {
	margin-bottom: 10px;
	align-items: flex-start;
	display: flex;
}

#prmcde {
	border: 1px solid #ced4da;
}

#apprm {
	text-decoration: underline;
	margin: 0 8px;
	cursor: pointer;
	font-size: 1.2vw;
}

.checkoutBtn {
	background-color: var(--black);
	color: var(--white);
	font-weight: bold;
	width: 11vw;
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	margin-top: 2.5vw;
	margin-bottom: 1.5vw;
}

.paypalBtn {
	display: flex;
	float: left;
	width: 18vw;
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	margin-top: 2.5vw;
	margin-bottom: 1.5vw;
}

.checkoutBtn:hover {
	background-color: rgba(0, 0, 0, 0.4);
	color: var(--black);
}

#chcknte {
	border-top: 2px solid #ced4da;
	padding: 10px 0;
	width: 100%;
	float: left;
}

#chcknte p {
	padding-bottom: 10px;
	font-size: 1.1vw;
	font-weight: normal;
}

#chcknte b {
	font-size: 1.2vw;
}

.noteHeading {
	font-weight: bold;
}

.ccavaenueBtn {
	margin-left: 1vw;
}

#sftdur {
	border-color: #ced4da;
	background-image: none;
	border-radius: 0.3vw;
	padding: 0.5vw 1.5vw;
	font-size: 1.2vw;
	color: var(--close-button);
	background-color: #fff;
}

@media screen and (max-width: 599px) {
	.parentDiv {
		margin-bottom: 10px;
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
	}
	.premium-container label {
		width: unset;
		float: unset;
		margin-bottom: 3vw;
		font-size: 5vw;
	}
	.labinsep {
		margin-right: 2vw;
		font-size: 5vw;
	}
	#pays {
		font-size: 5vw;
	}
	#sftdur {
		padding: 1vw 2vw;
		font-size: 4vw;
	}
	.ccavaenueBtn {
		margin-left: 1vw;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		padding: 2vw 5vw;
		font-size: 4vw;
	}
}

.highLighttext {
	color: #c00000;
}

.list-table {
	margin: 15vw 15vw;
}

@media screen and (max-width: 900px) {
	.list-table {
		margin: 25vw 13vw;
	}
	.free-offer-container {
		padding-bottom: 20vw;
	}
	#tranhis {
		width: 100%;
	}
	.transactionHist {
		margin-bottom: 2vw;
	}
	.transDiv {
		flex-direction: column-reverse;
		align-items: flex-end;
	}
	.content-container .printBtn {
		margin-bottom: 1vw;
	}
}

@media screen and (max-width: 599px) {
	.list-table {
		margin: 34vw 4vw;
	}
	.free-offer-container {
		padding-bottom: 25vw;
	}
	.transactionHist {
		margin-bottom: 3vw;
		font-size: 4vw;
	}
	#printbtn {
		padding: 1vw;
		font-size: 3vw;
		width: 18vw;
	}
}

.success-section{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15vw 17vw 7vw;
}

.success-section img{
    width: 200px;
    height: 200px;
}

.success-section-header{
    text-align: center;
}

.success-section .success-section-header h1{
    font-size: 2.22vw;
    font-weight: bold;
    color: var(--blue);
    margin-bottom: 2vw;
}
.creategroup-modal {
	position: relative;
	background-color: var(--medium-grey);
	border-radius: 0.3vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2vw 4vw;
	text-align: center;
	min-width: 40vw;
	max-width: 50%;
}

.report-modal {
	width: unset;
}

.creategroup-modal .creategroup-content {
	width: 100%;
}

@media screen and (max-width: 900px) {
	/* report modal start */
	.report-modal {
		width: 70%;
	}
	.report-content i,
	.report-content .form-select {
		font-size: 2vw;
	}
	/* report modal end */
}

@media screen and (max-width: 599px) {
	/* report modal start */
	.report-modal {
		width: 100%;
		justify-content: flex-start;
		/* height: 100vh !important; */
	}
	.report-content i,
	.report-content .form-select {
		font-size: 3vw;
	}
	.report-content .form-select {
		padding: 2vw;
		font-size: 3vw;
	}
	.report-submit .submit {
		padding: 2vw;
		margin-top: 5vw;
	}
	#changePassword {
		top: 5vw;
	}
	.creategroup-modal {
		justify-content: flex-start;
	}
	/* report modal end */
}

