.highLighttext {
	color: #c00000;
}

.list-table {
	margin: 15vw 15vw;
}

@media screen and (max-width: 900px) {
	.list-table {
		margin: 25vw 13vw;
	}
	.free-offer-container {
		padding-bottom: 20vw;
	}
	#tranhis {
		width: 100%;
	}
	.transactionHist {
		margin-bottom: 2vw;
	}
	.transDiv {
		flex-direction: column-reverse;
		align-items: flex-end;
	}
	.content-container .printBtn {
		margin-bottom: 1vw;
	}
}

@media screen and (max-width: 599px) {
	.list-table {
		margin: 34vw 4vw;
	}
	.free-offer-container {
		padding-bottom: 25vw;
	}
	.transactionHist {
		margin-bottom: 3vw;
		font-size: 4vw;
	}
	#printbtn {
		padding: 1vw;
		font-size: 3vw;
		width: 18vw;
	}
}
