/* chat-page start */
.group-container {
	padding-bottom: 2vw;
	margin-left: 4vw;
}

.showuser-profile {
	margin-bottom: 3vw;
	padding: 1vw;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 1vw;
	height: 23vw;
}

.showuser-profile img {
	width: 100%;
	height: 100%;
}

.show-members {
	border: 0.1vw solid var(--close-button);
	padding: 1vw;
	margin-bottom: 2vw;
	background-color: var(--medium-grey);
}

.show-members h2 {
	font-weight: bold;
	color: #fff;
}

.chat-page-section .left-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	/* position: absolute; */
	z-index: 1;
	top: 10vw;
	left: 0;
	bottom: 0;
	padding: 7vw 0vw 18vw 1vw;
	margin-right: 1vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
}

.chat-page-section .left-sidebar .chat-page-arrow {
	position: absolute;
	top: 2vw;
	right: 0vw;
}

.chat-page-section .left-sidebar .chat-page-arrow img {
	width: 2vw;
}

.messenger-container {
	border: 1px solid var(--light-grey);
}

.messenger-container .messenger-content {
	/* padding: 1vw 1vw 1vw 2vw; */
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: 1px solid var(--light-grey);
	cursor: pointer;
}

.messenger-container .messenger-content.show-members-content {
	justify-content: flex-start;
	gap: 8%;
}

.messenger-container .messenger-content:nth-child(6) {
	border-bottom: none;
}

.messenger-container .messenger-content.active-messenger-content {
	background-color: var(--post-background);
}

.messenger-container .messenger-content .user-profile.show-profile,
.display-user .user-info-container .user-profile.show-profile {
	overflow: hidden;
	width: 5vw;
	height: 5vw;
}

.messenger-container .messenger-content .user-profile.show-profile img,
.display-user .user-info-container .user-profile.show-profile img {
	width: 100%;
}

.messenger-container .messenger-content .user-content.users .user-name {
	word-break: break-word;
}

.messenger-container .messenger-content .user-content h3,
.messenger-container .messenger-content .active-time h3,
.display-user .user-info-container .user-name h2,
.display-user .new-message h2 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.messenger-container .messenger-content .user-content h3.grey-header,
.display-user .new-message h2.grey-header {
	color: var(--close-button);
}

.display-user .user-info-container .user-name h2 span {
	text-transform: uppercase;
}

.messenger-container .messenger-content .active-time {
	margin-top: 1vw;
}

.chat-page-section .chat-box-display {
	border-left: 1px solid var(--light-grey);
	/* margin: 14vw 4vw 10vw 24vw; */
}

.show-group-container {
	position: relative;
	background-color: var(--post-background);
	border: 1px solid var(--light-grey);
	border-radius: 0.3vw;
	margin: 1vw 0 0 0;
	padding: 2vw 2vw !important;
	margin: 14vw 12vw 6vw 24vw;
}

.show-info .info-header {
	border-bottom: 0.1vw solid var(--close-button);
	margin-bottom: 1vw;
}

.show-info .info-header h2 {
	font-weight: bold;
	margin-bottom: 1vw;
	text-transform: uppercase;
}

.show-info .info-description h3 {
	color: var(--close-button);
}

.show-info .info-description,
.show-info .experience-content,
.show-info .education-content {
	margin-bottom: 2vw;
}

.show-info .info-description.last-description {
	margin-bottom: 0;
}

.show-info .experience-content .exp-title,
.show-info .experience-content .exp-description,
.show-info .experience-content .exp-desc-content,
.show-info .education-content .edu-name,
.show-info .education-content .edu-grade,
.show-info .education-content .activities h3.activity-info {
	color: var(--close-button);
}

.show-info .experience-content .exp-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5vw;
}

.show-info .experience-content .exp-title h3.name,
.show-info .experience-content .exp-description h3.desc,
.show-info .education-content .edu-title h3.title,
.show-info .education-content .edu-grade span,
.show-info .education-content .activities h3.activity,
.show-info .info-description .created-name {
	font-weight: bold;
	color: #555;
}

.show-info .experience-content .exp-description,
.show-info .education-content .education,
.show-info .info-description .content,
.show-info .info-description h3.personal-info {
	margin-bottom: 0.5vw;
}

.show-info .info-description h3.personal-info span.date {
	width: 15vw;
	float: left;
}

.show-info .info-description h3.personal-info span.date-info {
	padding-left: 1vw;
}

.show-members-list {
	overflow-y: auto;
	overflow-x: hidden;
	height: 20vw;
}

/* chat-page end */

.no-connec {
	padding: 2vw;
}

.no-connec h2 {
	font-weight: bold;
}

/* profile-show  start */
.user-friend-status {
	position: relative;
}

.user-friend-status .user-status-container {
	position: absolute;
	top: 19vw;
	width: 100%;
	padding: 1vw;
	background-color: var(--medium-grey);
	opacity: 0.8;
}

.user-friend-status .user-status-container .user-status-one-container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 6%;
}

.user-friend-status .user-status-container .user-status-one-container h1,
.user-friend-status .user-status-container .user-status-one-container i {
	font-weight: bold;
	color: var(--white);
	cursor: pointer;
}

.user-friend-status .user-status-container .user-status-one-container i {
	font-size: 1.5vw;
}

.user-friend-status .user-status-container .friend-dropdown {
	position: absolute;
	background-color: var(--black);
	padding: 0.5vw 0.8vw;
	border-radius: 0.3vw;
	top: 3vw;
	right: 0vw;
	z-index: 9;
	cursor: pointer;
}

.user-friend-status .user-status-container .friend-dropdown h2 {
	color: var(--white);
	font-weight: bold;
}
/* profile-show  end */

@media (max-width: 900px) {
	.show-info .info-description h3 {
		font-size: 1.6vw;
	}

	.show-info .info-description p {
		font-size: 1.7vw;
	}

	.show-info .info-header h2 {
		font-size: 1.8vw;
	}

	.show-info h3,
	.showuser-container h3 {
		font-size: 1.6vw;
	}

	.left-sidebar .showuser-container h2 {
		font-size: 1.8vw;
	}

	.showuser-container .user-status-container h1 {
		font-size: 2.5vw;
	}

	.showuser-profile {
		margin-bottom: 6vw;
	}

	.user-friend-status .user-status-container .friend-dropdown {
		top: 4vw;
		right: 0vw;
	}
	.user-friend-status .user-status-container {
		top: 18vw;
	}
}

@media screen and (max-width: 599px) {
	.show-info .info-description h3 {
		font-size: 3vw;
	}
	.show-info .experience-content .exp-description,
	.show-info .education-content .education,
	.show-info .info-description .content,
	.show-info .info-description h3.personal-info {
		margin-bottom: 1.5vw;
	}
	.show-info .info-description,
	.show-info .experience-content,
	.show-info .education-content {
		margin-bottom: 4vw;
	}
	.show-info .info-header h2 {
		font-size: 3vw;
	}
	.show-info .info-description p {
		font-size: 3vw;
		line-height: 1.2;
	}
	.show-info .experience-content .exp-title {
		margin-bottom: 2vw;
	}
	.show-info h3,
	.showuser-container h3 {
		font-size: 3vw;
	}
	.show-info .info-header h2 {
		margin-bottom: 3vw;
	}

	.showuser-profile {
		margin-bottom: 10vw;
		height: 76vw;
	}

	/* show page */
	#user-detail-page {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	#user-detail-sidebar {
		position: relative;
		top: 0vw;
		margin-top: 34vw;
		width: 80%;
		padding: 0vw 0vw 10vw 0vw;
	}
	#user-detail-container {
		margin: 34vw 4vw 30vw 4vw;
		border-left: 1px solid var(--light-grey);
		margin-top: 0;
	}
	#user-container {
		margin-left: 0;
		width: 100%;
	}
	.user-friend-status .user-status-container .friend-dropdown {
		top: 7vw;
		right: 7vw;
		padding: 2vw 3vw;
	}
}
