/* product-page start */

.product-section .left-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	z-index: 1;
	top: 13vw;
	left: 0;
	bottom: 0;
	padding: 3vw 4vw 25vw 2vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
	text-align: left;
}

.product-section .left-sidebar .chat-arrow {
	position: absolute;
	top: 0vw;
	right: 4vw;
}

.product-section .left-sidebar .chat-arrow img {
	width: 2vw;
}

.sidebar-container .header-content h2,
.content-container .sidebar-content .header-content h3 {
	font-weight: bold;
}

.sidebar-container .content-container {
	margin-top: 3vw;
}

.content-container .sidebar-content {
	margin-bottom: 2.5vw;
}

.content-container .sidebar-content input {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1.2vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.content-container .sidebar-content input::placeholder {
	font-size: 1.2vw;
}

.content-container .sidebar-content input::placeholder,
.content-container .sidebar-content .header-content h3 {
	color: var(--close-button);
}

.content-container .sidebar-content .caret-content{
	margin-top: 1vw;
	/* padding: 0vw 1vw; */
	border:1px solid var(--grey);
	border-radius: 0.3vw;
}

.content-container .sidebar-content .caret-content .select-caret {
	position: absolute;
	right: 1vw;
	top: 0vw;
	bottom: 0vw;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--close-button);
	z-index: 1;
}

.content-container .sidebar-content .caret-content .select-caret i,
.content-container .sidebar-content .caret-content .form-select {
	font-size: 1.2vw;
	color: var(--close-button);
}

.content-container .sidebar-content .caret-content .form-select {
	background-image: none;
	padding: 0.5vw 1.5vw;
	border-radius: 0.3vw;
}

/* location-select */
.content-container
	.sidebar-content
	.caret-content
	.form-select.location-select {
	padding: 0;
}

.content-container .sidebar-button {
	/* float: right; */
	text-align: right;
	margin-right: 1.5vw;
}

.content-container .sidebar-button button {
	background-color: var(--post-background);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	color: var(--close-button);
	font-size: 0.97vw;
	width: 7vw;
}

.product-container {
	position: relative;
	margin: 11vw 14vw 15vw 25vw;
	padding-bottom: 1vw;
}

.product-container .product-suppliers {
	position: absolute;
	right: -10vw;
}

.product-container .product-suppliers label {
	color: var(--close-button);
	font-weight: bold;
	font-size: 1.1vw;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
}

.product-container .product-suppliers input[type="checkbox"] {
	display: none;
	cursor: pointer;
}

.product-container .product-suppliers input[type="checkbox"] + label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 0vw;
	margin-left: 1.5vw;
	width: 1.5vw;
	height: 1.5vw;
	z-index: 2;
}

.product-container
	.product-suppliers
	input[type="checkbox"]:checked
	+ label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.1vw;
	right: 0.5vw;
	width: 0.5vw;
	height: 1.1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	transform: rotate(45deg);
	z-index: 2;
}

.product-container .product-box-container {
	border-left: 1px solid var(--grey);
	margin-left: -2vw;
}

.product-container .product-box-container .product-boxes {
	/* padding: 3vw 0vw 4vw 2vw; */
	padding: 1vw 0vw 6vw 2vw;
}

.product-container .product-box-container .product-header h1 {
	font-weight: bold;
}

.product-boxes .boxes-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-template-rows: repeat(5, 23.6vw); */
	/* grid-template-rows: repeat(5, 23vw); */
	grid-gap: 2vw;
	margin-top: 2vw;
	/* width: 101%; */
}

.product-boxes-content {
	position: relative;
	border-radius: 0.3vw;
	border: 1px solid var(--white);
	box-shadow: rgba(60, 64, 67, 0.3) 0px 0px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	/* width: 250px; */
	height: 100%;
}

.product-boxes-content .product-email,
.supplier-box-container .product-email {
	position: absolute;
	right: 0vw;
	top: 0vw;
}

.product-boxes-content .product-edit,
.supplier-box-container .product-edit {
	position: absolute;
	right: 0vw;
	bottom: 0vw;
}

.product-boxes-content .product-email button,
.supplier-box-container .product-email button {
	background-color: transparent;
}

.product-boxes-content .product-edit button,
.supplier-box-container .product-edit button {
	background-color: transparent;
}

.product-boxes-content button.renewPro{
	background-color: var(--grey);
	
}

.product-boxes-content .product-email img,
.supplier-box-container .product-email img {
	width: 3vw;
}

.product-boxes-content .product-edit img,
.supplier-box-container .product-edit img {
	width: 1.5vw;
}

.product-boxes-content .product-image img {
	/* width: 19vw; */
	height: 15vw;
	width: 100%;
    object-fit: cover;
}

.product-boxes-content .product-info {
	padding: 1vw;
}

.product-boxes-content .product-info h2,
.supplier-box-container .supplier-info h1,
.supplier-box-container .supplier-info h2,
.supplier-box-container .supplier-boxes-content h2 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.product-boxes-content .product-info h2.grey-header,
.supplier-box-container .supplier-info h2.grey-header {
	color: var(--close-button);
	word-break: break-all;
}
/* product-page end */



.css-14el2xx-placeholder,
.css-qc6sy-singleValue {
	color: var(--close-button) !important;
}

.css-tj5bde-Svg {
	display: none;
}

.suppliers-label {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.caret-content div:nth-child(3){
	padding: 0px 1vw;
}

.grey-header .content-css .my-anchor-css-class {
	color: var(--red);
}

.grey-header .content-css span{
	font-size: 1.2vw !important;
	color: var(--close-button) !important;
	font-weight: bold;
}

@media screen and (max-width: 900px) {
	.product-boxes .boxes-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 3vw;
		margin-top: 4vw;
	}
	.product-container {
		margin: 24vw 14vw 15vw 28vw;
	}
	.product-section .left-sidebar {
		width: 26%;
		top: 24vw;
		padding: 3vw 3vw 50vw 2vw;
	}
	.content-container .sidebar-content .input-field {
		padding: 0.8vw 1.5vw;
		font-size: 2vw;
		width: 100%;
	}
	.content-container .sidebar-content .caret-content .select-caret i,
	.content-container .sidebar-content .caret-content .form-select,
	.content-container .sidebar-content input::placeholder {
		font-size: 2vw;
	}
	.content-container
		.sidebar-content
		.caret-content
		.form-select.location-select {
		padding: 0vw 1.5vw;
	}
	.product-section .left-sidebar .chat-arrow {
		width: 3vw;
	}
	.product-section .left-sidebar .chat-arrow img {
		width: 100%;
	}
	.content-container .sidebar-button button {
		font-size: 2vw;
		/* width: 15vw; */
		width: 100%;
		height: auto !important;
	}
	.content-container .sidebar-content {
		margin-bottom: 3vw;
	}
	.sidebar-container .sidebar-content .header-content h3 {
		margin-bottom: 1vw;
	}
	.product-boxes-content .product-email img,
	.supplier-box-container .product-email img {
		width: 5vw;
	}
	.product-boxes-content .product-edit img,
	.supplier-box-container .product-edit img {
		width: 100%;
	}
	.product-container .product-box-container .product-boxes {
		padding: 3vw 0vw 6vw 3vw;
	}
	.content-container .sidebar-content input {
		padding: 0.5vw 1.5vw;
		font-size: 2vw;
		width: 100%;
	}
}

@media screen and (max-width: 599px) {
	.product-container {
		margin: 34vw 4vw 15vw 4vw;
	}
	.product-container .product-box-container {
		border-left: 0;
	}
	.product-container .product-box-container .product-boxes {
		padding: 6vw 0vw 15vw 3vw;
	}
	.product-container .product-suppliers {
		right: 0vw;
		top: 1vw;
	}
	.product-section .left-sidebar .chat-arrow {
		display: none;
	}
	.product-boxes-content .product-info h2.grey-header p {
		font-size: 3vw;
		line-height: 1.3;
	}
	.product-boxes-content .product-info h2,
	.supplier-box-container .supplier-info h1,
	.supplier-box-container .supplier-info h2,
	.supplier-box-container .supplier-boxes-content h2 {
		font-size: 3vw;
	}
	.product-section .left-sidebar {
		width: 75%;
		top: 40vw;
		padding: 6vw 4vw 70vw 4vw;
		background-color: #fff;
		box-shadow: 0 1rem 1rem rgb(0 0 0 / 50%);
	}
	.sidebar-container .header-content h2 {
		font-size: 3.5vw;
		margin-bottom: 2vw;
	}
	.content-container .sidebar-content .header-content h3 {
		font-size: 3vw;
	}
	.sidebar-container .content-container {
		margin-top: 4vw;
	}
	.content-container .sidebar-content {
		margin-bottom: 4vw;
	}
	.content-container .sidebar-content .input-field {
		padding: 2vw 2vw;
		font-size: 3vw;
	}
	.content-container .sidebar-content .caret-content .select-caret i,
	.content-container .sidebar-content .caret-content .form-select,
	.content-container .sidebar-content input::placeholder {
		font-size: 3vw;
	}
	.content-container .sidebar-content .caret-content .form-select {
		padding: 2vw 2vw;
	}
	.content-container .sidebar-button {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.content-container .sidebar-button button {
		font-size: 3vw;
		width: fit-content;
		padding: 2vw 8vw;
	}
	.product-boxes-content .product-email img,
	.supplier-box-container .product-email img {
		width: 12vw;
	}
	.content-container .sidebar-content input {
		padding: 2vw 2vw;
		font-size: 3vw;
		margin-top: 3vw;
	}
	.content-container .sidebar-content span {
		font-size: 3.5vw;
	}
}
