.preview11 {
	width: 50%;
	display: inline-block;
	/* float: left; */
}

.preview12 {
	width: 50%;
	display: inline-block;
	text-align: justify;
}

.preview12 p {
	word-break: break-all;
	margin-left: 10%;
}

.preview21 {
	width: 100%;
}

.preview21 img {
	width: 100%;
}

.mainDiv {
	margin-bottom: 10%;
	padding: 5%;
	border: 1px solid black;
}

.mainDiv h3 {
	margin-bottom: 10%;
}

.tox:not([dir="rtl"]) {
	width: 100% !important;
}

.centerTitle {
	text-align: center;
}

.marTop {
	margin-top: 0.5vw;
}

.content-container .sidebar-button button {
	min-height: 2.1vw;
}

@media screen and (max-width: 599px) {
	.marTop {
		font-size: 3vw;
	}
}
