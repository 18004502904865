.header {
	cursor: unset;
}

.job-container {
	/* margin: 11vw 14vw 15vw 25vw; */
	margin: 9vw 14vw 15vw 25vw;
}

@media screen and (max-width: 900px) {
	.job-container {
		margin: 24vw 14vw 15vw 28vw;
	}
}

@media screen and (max-width: 599px) {
	.job-container {
		margin: 34vw 4vw 15vw 4vw;
	}
}
