.content-container .sidebar-content .input-field {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.sidebar-or-text {
	text-align: center;
	font-weight: 600;
}
.content-container .resetSubmit {
	/* display: flex;
	justify-content: space-between; */
	margin-right: 3%;
}

.content-container .sidebar-button {
	float: unset;
	text-align: right;
	margin-right: 1.5vw;
}

.clickDiv {
	margin-top: 2vw;
}

.clickHere {
	color: #0d6efd;
	text-decoration: underline;
}

.sidebar-container .sidebar-content .header-content h3 {
	color: var(--close-button);
	font-weight: bold;
}

.content-container .uploadSection {
	display: flex;
	justify-content: start;
	align-items: center;
	margin-bottom: 0;
}

.submitBtnDiv {
	margin-top: 2vw;
}

.uploadInputs input {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	margin-bottom: 2vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.sidebar-content .input-field {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.editImgDiv {
	position: relative;
}

.editImg {
	margin-top: 2%;
	width: 100%;
}

.prodImg {
	position: absolute;
	right: 0;
	top: 0;
	height: 40px;
	cursor: pointer;
}

.dnone {
	display: none;
}

.image-area-content {
	position: relative;
	width: unset;
	background: transparent;
	margin-right: 5%;
	margin-top: 3%;
}

.image-area-content img {
	max-width: 100%;
	/* height: auto; */
	height: 4vw;
	cursor: pointer;
}

.view_img img {
	cursor:unset;
}

.product-header {
	display: flex;
	justify-content: space-between;
}

.quarterly{
	margin-top: 2%;
	background-color: var(--grey);
	padding: 1vw;
}

.adjustImg {
	width: 120%;
	flex-wrap: wrap;
}

.sponsorAssets{
	width: 3vw;
}

.sponsorLinks{
	width: auto;
	word-break: break-all;
	text-decoration: underline;
}

@media screen and (max-width: 599px) {
	.image-area-content {
		width: unset;
	}
	.sidebar-content .input-field,
	.uploadInputs input {
		padding: 2vw 2vw;
		font-size: 3vw;
		margin-top: 3vw;
		width: 100%;
	}
}
