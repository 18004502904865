.editsummary {
	margin-bottom: 10px;
	width: auto;
}

.userImage img {
	width: 150px;
	height: 150px;
}

.userImage {
	position: relative;
}

.userImage h3 {
	font-size: 15px;
	display: block;
	height: 30px;
	left: 0;
	line-height: 32px;
	color: #3e8bc1;
	text-align: center;
}

.userUpload {
	height: 100%;
	width: 150px;
	top: 0;
	position: absolute;
	cursor: pointer;
	opacity: 0;
}

.updtBtn {
	justify-content: center;
	margin-top: 5%;
}

.displat-flex {
	display: flex;
	align-items: center;
}

.inpfield {
	height: fit-content;
}

.profileSelectCode {
	background-image: unset;
	background-color: #fff;
	height: unset;
    padding: 0 0.5rem;
}

.pcodeDrop{
	color: var(--black);
}

#editphone .dropdown{
	height: unset;
}

#editphone .dropdown-menu.show {
    background-color: #fff;
	height: 30vh;
    overflow: auto;
}

#editphone .dropdown-item{
	font-weight: 500;
	font-size: 1vw;
	padding: 0.5vw 1vw;
}

.expContentDiv {
	margin-top: 20px;
	padding-bottom: 20px;
}

.editButton {
	background: var(--post-background);
	float: right;
	margin-left: 5px;
	padding: 2px 10px;
	font-size: 14px;
}

.product-section .profile-container .sidebar-content input {
	width: 50%;
}

.presentExp{
	padding: 0.5vw 0.5vw;
}

#honaR .honorContent .expBtn .expi-button .removeBTN, #expi .expContentDiv .expBtn .expi-button .removeBTN, 
#educt .expContentDiv .expBtn .expi-button button.removeBTN{
	width: 100%;
	margin-left: 1vw;
}

@media screen and (max-width: 1200px) {
	.profileSelectCode {
		padding: 0.15rem 0.5rem;
	}
}


@media screen and (max-width: 900px) {

	.profileSelectCode {
		padding: 0 0.5rem;
		margin-top: 0;
	}

	.profile-container {
		margin: 24vw 12vw;
	}

	.divBorder h2 {
		font-size: 1.9vw;
	}

	.inpfield {
		font-size: 2vw;
		width: 100%;
		padding-right: 5vw;
	}

	.paraFont {
		font-size: 2vw;
	}

	.prsnlDetails ul label {
		font-size: 2vw;
	}

	.attachIconsprofile .pannel-icon .resImg {
		height: 6vw;
		width: 100% !important;
	}

	#editaddress .inpfield {
		width: 40vw;
	}

	.expBtn .expi-button #expsavebtn,
	.expBtn .expi-button #savebtn,
	.expBtn .expi-button #cancelbtn {
		font-size: 2vw;
		width: 15vw;
	}

	.profile-container .sidebar-content .input-field {
		font-size: 2vw;
	}

	.exp_head > h2 {
		font-size: 3vw;
	}

	.attachTextprofile {
		font-size: 2vw;
	}

	.expDetails h2,
	.addposi {
		font-size: 1.8vw;
	}

	.expDetails label {
		font-size: 2vw;
	}

	.expDetails p {
		font-size: 1.7vw;
	}

	.expDetails .checkClass {
		width: unset;
		margin-top: 0;
	}

	.expDetails h2 {
		color: var(--bs-body-color);
		font-weight: 600;
	}
}

@media screen and (max-width: 599px) {
	.profile-container {
		margin: 34vw 3vw;
		padding-bottom: 20vw;
	}
	.divBorder {
		padding: 6vw;
	}
	.divBorder h2 {
		font-size: 4vw;
	}
	.image-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 6%;
		margin-top: 4vw;
	}
	.userImage {
		position: relative;
		width: 50%;
		height: 50%;
	}
	.userImage img {
		width: 100%;
		height: 100%;
	}
	.userUpload {
		width: 100%;
		height: 100%;
	}
	.userdetails {
		float: unset;
		margin-top: 0;
		width: unset;
		padding-left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
	.inpfield {
		font-size: 3.1vw;
		width: 100%;
		margin-top: 2vw;
	}
	.caret-content,
	.gear {
		width: 100%;
	}

	.prsnlDetails li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
	.prsnlDetails ul label {
		font-size: 3vw;
	}
	.paraFont,
	.attachTextprofile {
		font-size: 3vw;
	}
	.attachIconsprofile {
		margin: 5px 15px;
	}
	.attachIconsprofile .pannel-icon .resImg {
		height: 100%;
		width: 100% !important;
	}
	.tooltip .tooltiptext {
		width: fit-content;
		margin-left: -10vw;
		padding: 1vw 2vw;
	}
	.product-section .profile-container .sidebar-content input,
	#editaddress .inpfield {
		width: 100%;
	}
	.profile-container .sidebar-content .input-field {
		padding: 2vw 2vw;
		margin-top: 3vw;
	}
	.expBtn .expi-button #expsavebtn,
	.expBtn .expi-button #savebtn,
	.expBtn .expi-button #cancelbtn {
		font-size: 3vw;
		width: 20vw;
		padding: 1vw;
	}
	.expBtn.btns {
		margin-top: 3vw;
	}
	.datainfo {
		float: unset;
	}

	.expDiv {
		margin-top: 5vw;
		padding: 6vw;
	}
	.exp_head {
		margin-top: 0px;
	}
	.exp_head > h2 {
		font-size: 4vw;
		margin: 5px 0px;
		width: 100%;
	}
	.expDetails h2,
	.addposi {
		font-size: 3vw;
		margin-bottom: 3vw;
	}
	.expContentDiv,
	.honorContent {
		margin: 0;
	}
	.borBotm {
		border-bottom: none;
	}
	.expDetails label,
	.profile-container .sidebar-content .input-field {
		font-size: 3vw;
	}
	#cancelbtn,
	#expcancelbtn,
	#educancelbtn {
		margin-left: 3vw !important;
	}
}
