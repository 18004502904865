.dropdown-content {
	display: none;
	position: absolute;
	right: 0;
	background-color: var(--grey);
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	cursor: default;
	max-height: 58vh;
	overflow-y: auto;
}

.add-button:hover .dropdown-content {
	display: block;
}

.add-button a:hover,
.add-button a:focus,
.add-button a:active {
	background-color: #e9ecef;
}

.free-offer-heading-div {
	background-color: #fde9da;
	text-align: center;
	padding: 12px;
}

.free-offer-heading-text {
	font-weight: 600;
}

.heading-sub-text {
	text-align: center;
	font-weight: 600;
}

.free-service-heading-table {
	font-weight: 600;
	margin-top: 10px;
}

.free-service-tableDiv,
.paid-service-tableDiv {
	margin-top: 5px;
	margin-bottom: 100px;
}

.paid-service-heading-table {
	margin-top: 40px;
	font-weight: 600;
}

.content-container .sidebar-content .fieldwidth {
	width: 16vw !important;
}

.content-container .sidebar-content .input-field {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}

.sidebar-or-text {
	text-align: center;
	font-weight: 600;
}

.clickHere {
	color: #0d6efd;
	text-decoration: underline;
}

.clickDiv {
	margin-top: 2vw;
}

.software-btns {
	/* display: flex; */
}

.product-edit {
	top: unset;
	bottom: 0;
	right: 0;
	position: absolute;
}

.product-container {
	padding-bottom: 5vw;
}

.requiredField {
	color: red;
}

.tox-statusbar__branding,
.tox-statusbar__path {
	display: none;
}

.attachText {
	float: left;
	/* width: 155px; */
	font-size: 16px;
	margin: 0;
	color: var(--close-button);
	font-weight: 600;
}

.attachIconsDiv {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.attachIcons {
	margin: 0 4px;
}

/* tooltip START*/

.tooltip {
	position: relative;
	display: inline-block;
	opacity: 1;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 70px;
	background-color: #fff;
	color: #000;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 130%;
	margin-left: -60px;
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	border: 1px solid black;
}

/* tooltip END */

.content-container .sidebar-content .radiobtn {
	width: auto;
	position: unset;
	left: unset;
}

.content-container .sidebar-content .checkboxbtn {
	width: auto;
}

.slider-box-bg {
	background-color: unset;
	padding: unset;
	margin-top: 2.5vw;
}

.sky-mg {
	margin: unset;
}

#cuntry,
#stats,
#cmpny {
	height: auto;
	width: 100%;
}

.mg-top {
	margin-bottom: 1vw;
}

.content-container .sidebar-content #layout1,
.content-container .sidebar-content #layout2,
.content-container .sidebar-content #layout3 {
	position: unset;
	left: unset;
}

.centerImg {
	text-align: center;
}

.inlineBtn {
	display: flex;
}

.sky-tabs > ul > .tabover {
	overflow: unset;
}

table,
th,
td {
	border: 1px solid black;
	border-collapse: collapse;
	padding: 1vw;
}

.txtUnderline {
	text-decoration: underline;
}

.free-service-tableHeading {
	text-align: center;
	background-color: #d6eef3;
}

.free-offer-container {
	margin: 15vw 2vw;
	padding-bottom: 10vw;
}

.transactionHist {
	font-weight: 600;
	margin-bottom: 1vw;
}

.transTable {
	margin-bottom: 10px;
}

.transDiv {
	display: flex;
	justify-content: space-between;
	flex-direction: column-reverse;
}

.profile-container {
	margin: 15vw 20vw;
	padding-bottom: 5vw;
}

.image-content {
	margin-top: 1.5vw;
	display: inline-block;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.userImage {
	float: left;
}

.userImage h3 {
	margin-top: 10px;
	text-align: center;
}
.userImage a {
	color: #0d6efd;
}
.userdetails {
	float: left;
	margin-top: 0;
	padding-left: 30px;
}

.mini-Head {
	margin-top: 10px;
}

.editIcon {
	margin-left: 10px;
	cursor: pointer;
}

.prsnlDetails {
	margin-top: 20px;
}

.prsnlDetails ul {
	list-style-type: none;
	padding-left: 0vw;
}

.prsnlDetails ul label {
	float: left;
	width: 155px;
	font-size: 16px;
	margin: 0;
	color: var(--close-button);
	font-weight: 600;
}

.prsnlDetails li {
	line-height: 40px;
	margin-top: 10px;
}

.paraFont {
	font-size: 14px;
}

.gear {
	width: 70%;
	display: inline-block;
}

.datainfo {
	width: auto;
	max-width: 90%;
	text-align: justify;
	float: left;
	line-height: 20px;
	/* margin: 10px 0; */
	display: flex;
	gap: 1%;
}

.iconContent {
	margin-top: 40px;
}

.iconSpace {
	margin-bottom: 40px;
}

.attachIconsprofile {
	margin: 0 15px;
	z-index: 0;
}

.exp_head {
	width: 100%;
	display: inline-block;
	margin-top: 20px;
}

.exp_head > h2 {
	color: #a9b3bd;
	float: left;
	margin: 5px 26px;
	text-transform: none;
	width: 60%;
	font-size: 28px;
	font-weight: normal;
}
.addposi {
	background-color: var(--post-background);
	cursor: pointer;
}

.exp_head > p {
	background: var(--post-background);
	float: left;
	margin-left: 5px;
	padding: 2px 10px;
	/* width: 96px; */
	font-weight: bold;
}

.exp_head span {
	padding: 0 5px;
}

.expContentDiv,
.honorContent {
	margin: 0 26px;
}

.expContentDiv {
	margin-top: 40px;
}

.expDetails {
	margin-top: 20px;
}

.expDetails h2 {
	color: var(--close-button);
	font-weight: 600;
}

.expBtn .exp-button .addBtn {
	margin: 10px 26px;
	text-align: center;
	background: var(--post-background);
	width: 94%;
}

/* .exp-button{
    width: 96.4%;
  } */

.profile-container .sidebar-content .input-field {
	border: 1px solid var(--grey);
	padding: 0.5vw 1.5vw;
	font-size: 1vw;
	margin-top: 1vw;
	border-radius: 0.3vw;
	width: 17vw;
}
.profile-container .sidebar-content .ProfileTitleInput {
	width: 100%;
}

.sidebar-space {
	margin-bottom: 2.5vw;
}

.selectSpace {
	margin-top: 1vw;
	width: 220px;
	display: inline-block;
}

.last-space {
	margin-bottom: 0.5vw;
}

.btns {
	display: flex;
}

#cancelbtn,
#expcancelbtn,
#educancelbtn {
	margin-left: 1vw !important;
}

.expBtn .expi-button #savebtn,
.expBtn .expi-button #cancelbtn,
.expBtn .expi-button #expcancelbtn,
.expBtn .expi-button #expsavebtn,
.expBtn .expi-button #edusavebtn,
.expi-button #educancelbtn {
	background-color: var(--post-background);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	color: var(--close-button);
	font-size: 0.97vw;
	width: 7vw;
	margin: 30px 0px;
	text-align: center;
	min-height: 2.1vw;
}

.honDiv {
	margin-bottom: 5vw;
}

.divBorder,
.expDiv {
	border: 1px solid var(--grey);
}

.divBorder {
	padding: 3vw;
}

.expDiv {
	margin-top: 2vw;
}

.displayInput {
	display: none;
}

.inpfield {
	border: unset;
	border-bottom: 1px solid var(--grey) !important;
	font-size: 1vw;
	border-radius: 0.3vw;
	width: fit-content;
	display: block;
	margin-top: 0.5vw;
	padding: 0.1rem 4rem 0.1rem 0.5rem;
}

.saveCancelBtn {
	border: 1px solid var(--grey);
}

.SelectMonth {
	display: flex;
	width: 50%;
	gap: 2%;
	align-items: center;
}

.selectYear {
	width: 100%;
}

/* .fieldWidth {
  width: 100%;
} */

.checkClass {
	width: unset;
	display: inline-block;
	margin-right: 0.5vw;
}

.borBotm {
	border-bottom: 1px solid var(--grey);
}

/* accordion start*/

.accord {
	background-color: #eee;
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	transition: 0.4s;
}

.activ,
.accord:hover {
	background-color: #ccc;
}

.pan {
	padding: 20px 5px;
	display: none;
	background-color: white;
	overflow: hidden;
}

/* accordion end */

.prsnlLabel {
	display: unset;
}

#user-ttext:after {
	top: unset;
}

.content-css {
	word-break: break-all;
}

@media (max-width: 900px) {
	.product-container {
		padding-bottom: 15vw;
	}
	.content-container .sidebar-content .input-field {
		width: 100% !important;
		padding: 0.5vw 1.5vw;
		font-size: 2vw;
	}

}

@media screen and (max-width: 599px) {
	.content-container .sidebar-content .input-field {
		padding: 2vw 2vw;
		font-size: 3vw;
	}
	.sidebar-or-text,
	.content-container .clickDiv p {
		font-size: 3vw;
	}
	.content-container .sidebar-content .radiobtn {
		position: relative;
		top: 0.5vw;
	}
	.SelectMonth {
		width: 100%;
	}
}

@media screen and (max-width: 399px) {
	.userImage h3 {
		font-size: 4vw !important;
	}
}
