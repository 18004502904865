/* about-us section start */
.about-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 6vw 17vw;
}

.after-login-section {
	/* padding: 15vw 17vw 7vw; */
	padding: 12vw 17vw 7vw;
}

.about-section .about-section-header h1 {
	font-size: 1.7vw;
	font-weight: bold;
	color: var(--blue);
	margin-bottom: 2vw;
}

.about-section .about-section-content p {
	font-size: 1.3vw;
	margin-bottom: 1.5vw;
}

.about-section .about-section-content p.para {
	margin-bottom: 0;
}

.about-section .about-section-content p span {
	color: var(--red);
	font-weight: bold;
}

.about-section .about-section-buttons {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 2vw;
}

.about-section .about-section-buttons button .button-image img {
	width: 11vw;
}

.about-section .about-section-buttons .google-button button {
	padding-left: 0;
}

.click-here,
.pdf,
.video {
	color: #0d6efd;
	text-decoration: underline;
	transition: all 0.3s ease-in-out;
}
/* about-us section end */

.line {
	text-decoration: underline;
}

@media screen and (max-width: 900px) {
	.about-section {
		padding: 12vw 17vw;
	}
	.after-login-section {
		padding: 22vw 12vw 10vw;
	}

	.about-section .about-section-content p {
		font-size: 1.7vw;
	}

	.about-section .about-section-header h1 {
		font-size: 2.5vw;
	}

	.about-section-content {
		width: 100%;
	}
}



@media screen and (max-width: 599px) {
	.about-section .about-section-header h1 {
		font-size: 3vw;
	}

	.about-section .about-section-content p {
		font-size: 2.75vw;
	}
	.about-section {
		padding: 17vw 5vw 22vw 5vw;
	}

	.after-login-section {
		padding: 34vw 12vw 10vw;
		padding-bottom: 25vw;
	}
	.about-section .about-section-buttons button .button-image img {
		width: 100%;
	}
}
