/* supplier-page start */
.product-container .product-box-container .supplier-boxes {
	padding: 3vw 0 6vw 2vw;
}

.supplier-box-container {
	position: relative;
	background-color: var(--post-background);
	border-radius: 0.3vw;
	padding: 2vw;
	margin: 3vw 0;
}

.supplier-box-container .product-email {
	top: 1vw;
}

.supplier-box-container .supplier-info h1 {
	margin-bottom: 1.5vw;
}

.supplier-box-container .supplier-info h2.header-text {
	margin-bottom: 0.5vw;
}

.supplier-box-container .supplier-boxes-content h2 {
	margin-top: 1vw;
}

.supplier-box-container .supplier-boxes-content .supplier-content {
	display: grid;
	grid-template-columns: repeat(3, 0fr);
	/* grid-template-rows: repeat(1, 19vw); */
	grid-gap: 2vw;
}

.suppliers-boxes-content {
	background-color: var(--white);
}

.suppliers-boxes-content {
	width: 17.2vw;
}

.product-boxes-content .supplier-info-name {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}
/* supplier-page end */

@media screen and (max-width: 900px) {
	.product-container .product-box-container .supplier-boxes {
		padding: 4vw 0 6vw 4vw;
	}
	.supplier-box-container {
		padding: 4vw;
		margin: 4vw 0;
	}
	.supplier-box-container .supplier-info h2.header-text {
		margin-bottom: 1vw;
	}
	.supplier-box-container .supplier-boxes-content .supplier-content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 5vw;
		grid-template-rows: auto;
		margin-top: 2vw;
	}
	.suppliers-boxes-content {
		width: 100%;
	}
}
