.ad_layout02{
    display: grid;
    grid: 
    "title title"   
    "image image ";
    /* grid-auto-columns: 1fr; */
    grid-auto-columns: 1fr 0fr;
    padding: 2vw;
}

.layout02_outer_div > div > h6{
    font-size: 1vw;
}

.layout02-title{
    grid-area: title;
    text-align: center;
    background-color: var(--medium-grey);
    padding: 1.3vw;
    margin-bottom: 2vw ;
}
.layout02-title h3{
    font-size: 1.3vw;
    color: #ffffff;
}

.layout02-image{
    /* grid-area: image; */
    max-height: 40vh;
    width: 100%;
    text-align: center;
}

.layout02-image img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
.layout02_outer_div{
    border: black solid 0.2vw;
    border-radius: 1vw;
    padding: 2vw;
    margin-bottom: 1.5vw;
}

.profile-content .menu-dots img {
    width: 0.4vw;
    cursor: pointer;
}

.profile-content .menu-dots {
    transition: all 0.3s ease;
}

.profile-content .menu-container {
    position: relative;
}

.profile-content .menu-container .user-menu-content h2 {
    color: var(--close-button);
    font-weight: bold;
    cursor: pointer;
    font-size: 1.2vw;
    margin-bottom: 0;
}

.profile-content .menu-container .user-menu-content {
    background-color: var(--post-background);
    position: absolute;
    border: 0.1vw solid var(--close-button);
    padding: 0.5vw;
    right: 1vw;
    top: 0vw;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 9vw;
    display: none;
}

.profile-content .menu-container .user-menu-content.open {
    opacity: 1;
    display: block;
}

.layout02_outer_div .profile-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ad_layout02 .iframeutub{
    max-height: unset;
}

.iframeutub iframe{
    height: 400px;
}


@media screen and (max-width: 900px){
    .layout02-des{
        padding-left:1.5vw ;
        font-size: 1.8vw;
    }
    .layout02_outer_div > div > h6{
        font-size: 1.3vw;
    }
    .layout02-title h3{
        font-size: 1.8vw;
    }

    .layout02_outer_div .profile-content .menu-dots img {
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 599px){
    .layout02-des{
        padding-left:1.5vw ;
        font-size: 2.5vw;
    }
    .layout02_outer_div > div > h6{
        font-size: 2vw;
    }
    .layout02-title h3{
        font-size: 2.5vw;
    }

    .layout02_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.5vw;
    }

    .layout02_outer_div .profile-content .menu-container .user-menu-content {
        padding: 2vw;
        width: -moz-fit-content;
        width: fit-content;
        right: 3vw;
        white-space: nowrap;
    }

}

@media screen and (max-width: 499px){
    .layout02_outer_div .profile-content .menu-dots {
        width: 1.1vw;
    }

    .layout02_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.8vw;
    }
}

@media screen and (max-width: 399px){
    .layout02_outer_div .profile-content .menu-dots {
        width: 1.2vw;
    }

    .layout02_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 3.1vw;
    }
}