/* header-section start */
.header-section {
	padding: 6vw 2vw 6vw 2vw;
}

.header-section .header-section-content h1 {
	margin-bottom: 0;
}

.header-section .header-section-image {
	background-image: url(../../../assets/landing-page.png);
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 98% auto;
	height: 44vw;
	/* padding: 22vw; */
}
/* header-section end */

@media screen and (max-width: 900px) {
	/* header section start */
	.header-section {
		padding: 12vw 2vw;
	}
	.header-section .header-section-image {
		height: 48vw;
	}
	/* header section end */
}

@media screen and (max-width: 599px) {
	.header-section {
		padding: 18vw 2vw;
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.header-section .header-section-image {
		height: unset;
		/* flex-grow: 1; */
		flex: 1;
		background-size: cover;
		background-position: center;
	}
	.header-section .header-section-content h1 {
		margin-bottom: 2vw;
	}
}
