.css-tj5bde-Svg {
	display: block !important;
}

.create-model {
	padding-top: 5vw !important;
}

@media screen and (max-width: 900px) {
	.create-model {
		padding-top: 18vw !important;
	}
}

@media screen and (max-width: 599px) {
	.create-model {
		padding-top: 5vw !important;
	}
}
.uploadGroupImage label{
	width: 56%;
	margin-bottom: 1.2vw;
}
.uploadGroupImage{
	display: flex;
	flex-direction: row;
	width: max-content;
	align-items: center;
}