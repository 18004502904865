.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
	border: 0.9vw solid #f3f3f3;
	border-radius: 50%;
	border-top: 0.9vw solid #393939;
	width: 4vw;
	height: 4vw;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 900px) {
	.loader {
		border: 1vw solid #f3f3f3;
		border-top: 1vw solid #393939;
		width: 5vw;
		height: 5vw;
	}
}
