/* group-page start */
.user-profile-container .seperator {
	position: relative;
	background-color: var(--light-grey);
	margin-top: 5%;
	height: 0.15vw;
	width: 105%;
	left: 0vw;
}

.show-user .show-user-form-group.show-group {
	position: absolute;
	top: 2vw;
	right: -5vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.show-group input[type="checkbox"] + label:before {
	content: "";
	-webkit-appearance: none;
	background-color: var(--grey);
	border: 1px solid var(--grey);
	border-radius: 0.3vw;
	padding: 0.5vw;
	display: inline-block;
	position: relative;
	cursor: pointer;
	margin-right: 0vw;
	margin-left: 1vw;
	width: 2vw;
	height: 2vw;
	z-index: 2;
}

.show-group input[type="checkbox"]:checked + label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0.4vw;
	right: 0.7vw;
	width: 0.5vw;
	height: 1.1vw;
	border: 1px solid #fff;
	border-width: 0 0.22vw 0.22vw 0;
	transform: rotate(45deg);
	z-index: 2;
}

.user-profile-container .group-profile {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-template-rows: repeat(1, 20vw); */
	grid-template-rows: repeat(1, auto);
	grid-auto-flow: row;
	/* gap: 5%; */
	padding-bottom: 12vw;
	margin-top: 10%;
}

.group-profile-container {
	top: 0vw;
	/* margin-top: 15%; */
	margin-top: 11%;
	height: unset;
}

.user-profile-container .view-profile {
	/* padding-bottom: 0vw; */
	padding-bottom: 4vw;
}

.view-post-container {
	position: relative;
	height: unset;
	top: 0;
	margin-left: 0;
	margin-right: 0;
	overflow: hidden;
}

.view-post-container .view-post-profiles .user-profile-image.green {
	background-color: var(--green);
}

.view-post-container .view-post-profiles .user-profile-image.red {
	background-color: var(--red);
}

.view-post-profiles {
	position: relative;
}

.view-post-profiles .edit-image {
	position: absolute;
	top: 1vw;
	right: 1.7vw;
	cursor: pointer;
}

.view-post-profiles .edit-image img {
	width: 1.5vw;
}

.view-post-profiles .exit-icon {
	cursor: pointer;
}

.view-post-profiles .exit-icon img {
	width: 2.2vw;
}

.view-post-profiles .view-info h3.view-text {
	color: var(--black);
}

.view-post-container .new-group {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0vw;
	right: 0%;
}

.view-post-container .new-group-search-bar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 1vw;
}

.new-group-search-bar .new-group-content .grp-search{
	border: 1px solid var(--grey);
    padding: 0.5vw 1.5vw 0.5vw 1.5vw;
    font-size: 1.1vw;
    border-radius: 0.3vw;
    margin-left: 1vw;
}

.new-group-search-bar .new-group-content label{
	font-size: 1.1vw;
	color: var(--black);
	font-weight: bold;
}

.new-group-search-bar .new-group-content .grp-search-icon{
	position: absolute;
	bottom: 0;
	justify-content: flex-start;
	align-items: stretch;
	right: 0vw;
	padding: 0.49vw;
	top: 0vw;
	background: transparent;
}

.view-post-container .new-group .new-group-content h2 {
	color: var(--black);
	font-weight: bold;
}

.view-post-container .new-group-search-bar .new-group-content h2 {
	color: var(--black);
	font-weight: bold;
}

.view-post-container .new-group .new-group-button button img {
	width: 3vw;
}


.disnone {
	display: none;
}
/* group-page end */

@media screen and (max-width: 900px) {
	.group-profile-container {
		top: 7vw;
		margin-top: 15%;
	}
	.view-post-container .new-group .new-group-button button img {
		width: 4vw;
	}
	.user-profile-container .group-profile {
		display: grid;
		padding-bottom: 16vw;
		margin-top: 13%;
		grid-template-columns: repeat(2, 1fr);
	}
	.view-post-profiles .edit-image {
		width: 3vw;
	}
	.view-post-profiles .edit-image img {
		width: 100%;
	}
	.user-profile-container .user-profiles .user-profile-image img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.user-profile-container .user-profiles .user-profile-image {
		width: 7vw;
		height: 7vw;
	}
	.user-profile-container .user-profiles .user-profile-info h2 {
		margin: 2vw 0;
	}
	.user-profile-container .user-profiles .user-profile-info h3 {
		margin-bottom: 2vw;
	}
	.view-post-profiles .exit-icon img {
		width: 3.7vw;
	}
	.show-group input[type="checkbox"] + label:before {
		width: 3vw;
		height: 3vw;
	}
	.show-group input[type="checkbox"]:checked + label:after {
		top: 0.4vw;
		right: 1vw;
		width: 0.8vw;
		height: 1.9vw;
		border-width: 0 0.4vw 0.4vw 0;
	}

	/* update group modal */
	.create-modal {
		padding-top: 18vw !important;
	}
	.view-post-container .new-group {
		right: 0%;
	}	

	.new-group-search-bar .new-group-content .grp-search{
		font-size: 1.8vw;
	}
	
	.new-group-search-bar .new-group-content label{
		font-size: 1.8vw;
	}
}

@media screen and (max-width: 599px) {
	.create-modal {
		padding-top: 5vw !important;
	}
	.group-profile-container {
		top: 18vw;
		margin-top: 15%;
	}
	.user-profile-container .user-profiles .user-profile-image {
		width: 10vw;
		height: 10vw;
	}
	.view-post-profiles .edit-image,
	.view-post-profiles .exit-icon img,
	.view-post-container .new-group .new-group-button button img {
		width: 6vw;
	}
	.view-post-container .new-group .new-group-content h2 {
		font-size: 2.8vw;
	}

	.new-group-search-bar .new-group-content .grp-search{
		font-size: 2.8vw;
	}
	
	.new-group-search-bar .new-group-content label{
		font-size: 2.8vw;
	}

	.show-user .show-user-form-group.show-group {
		top: 2vw;
		right: 2vw;
	}
	.show-group input[type="checkbox"] + label:before {
		width: 5vw;
		height: 5vw;
		margin-left: 2vw;
	}
	.show-group input[type="checkbox"]:checked + label:after {
		top: 0.5vw;
		right: 1.5vw;
		width: 1.4vw;
		height: 3.6vw;
		border-width: 0 0.6vw 0.6vw 0;
	}
	.view-post-container .new-group {
		top: 6vw;
	}
	.view-post-container .new-group-search-bar {
		top: 8vw;
	}
	.user-profile-container .seperator {
		width: 100%;
	}
	.user-profile-container .group-profile {
		padding-bottom: 33vw;
	}
}
