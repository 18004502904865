* {
	box-sizing: border-box;
}
body {
	margin: 0;
	padding: 0;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.main {
	height: 100vh;
}

.scroll4::-webkit-scrollbar {
	width: 0.8vw;
}

.scroll4::-webkit-scrollbar-thumb {
	background: var(--close-button);
	border-radius: 0.5vw;
}

.scroll4::-webkit-scrollbar-track {
	background: #ddd;
	border-radius: 0.5vw;
}
