.premium-container {
	font-weight: bold;
	font-size: 1.5vw;
}

.premium-container label {
	width: 300px;
	float: left;
	margin-bottom: 7px;
}

.labinsep {
	/* width: 20px;
    float: left; */
	margin-right: 2vw;
}

.parentDiv {
	margin-bottom: 10px;
	align-items: flex-start;
	display: flex;
}

#prmcde {
	border: 1px solid #ced4da;
}

#apprm {
	text-decoration: underline;
	margin: 0 8px;
	cursor: pointer;
	font-size: 1.2vw;
}

.checkoutBtn {
	background-color: var(--black);
	color: var(--white);
	font-weight: bold;
	width: 11vw;
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	margin-top: 2.5vw;
	margin-bottom: 1.5vw;
}

.paypalBtn {
	display: flex;
	float: left;
	width: 18vw;
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	margin-top: 2.5vw;
	margin-bottom: 1.5vw;
}

.checkoutBtn:hover {
	background-color: rgba(0, 0, 0, 0.4);
	color: var(--black);
}

#chcknte {
	border-top: 2px solid #ced4da;
	padding: 10px 0;
	width: 100%;
	float: left;
}

#chcknte p {
	padding-bottom: 10px;
	font-size: 1.1vw;
	font-weight: normal;
}

#chcknte b {
	font-size: 1.2vw;
}

.noteHeading {
	font-weight: bold;
}

.ccavaenueBtn {
	margin-left: 1vw;
}

#sftdur {
	border-color: #ced4da;
	background-image: none;
	border-radius: 0.3vw;
	padding: 0.5vw 1.5vw;
	font-size: 1.2vw;
	color: var(--close-button);
	background-color: #fff;
}

@media screen and (max-width: 599px) {
	.parentDiv {
		margin-bottom: 10px;
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
	}
	.premium-container label {
		width: unset;
		float: unset;
		margin-bottom: 3vw;
		font-size: 5vw;
	}
	.labinsep {
		margin-right: 2vw;
		font-size: 5vw;
	}
	#pays {
		font-size: 5vw;
	}
	#sftdur {
		padding: 1vw 2vw;
		font-size: 4vw;
	}
	.ccavaenueBtn {
		margin-left: 1vw;
		width: fit-content;
		padding: 2vw 5vw;
		font-size: 4vw;
	}
}
