.report-modal-container {
	padding-top: 12vw;
	padding-bottom: 12vw;
	z-index: 999;
}

.report-modal {
	height: 100%;
	width: 50%;
	align-items: center;
	justify-content: flex-start;
	padding: 4vw;
}

.creategroup-modal .report-box {
	width: 100%;
}

.report-content {
	position: relative;
	margin: 0vw 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.report-content i {
	position: absolute;
	right: 1vw;
	color: var(--medium-grey);
	font-size: 1vw;
}

.report-content .form-select {
	color: var(--medium-grey);
	padding: 1vw;
	font-size: 1.5vw;
	background-image: unset;
}

.report-submit .submit {
	width: 100%;
	padding: 1vw;
	margin-top: 3vw;
	background-color: var(--black);
	color: var(--white);
	font-weight: bold;
}

#reportpopup {
	justify-content: flex-start;
	height: 80vh;
}

@media screen and (max-width: 599px) {
	.report-modal-container {
		padding-top: 5vw;
		padding-bottom: 0vw;
	}
	.report-modal {
		height: 100vw;
	}
	#reportpopup {
		height: 70vh;
	}
}
