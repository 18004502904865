/* footer start */
.footer {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9;
	width: 100%;
	padding: 0.1vw 2vw;
}

.footer .footer-content p {
	margin-bottom: 0;
	font-size: 1.24vw;
}

.footer .footer-list ul {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.footer .footer-list ul li {
	padding: 0.5vw 2.5vw;
	position: relative;
}

.footer .footer-list ul li a {
	color: var(--black);
	text-decoration: none;
	font-size: 1.24vw;
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.footer .footer-list ul li a:hover {
	color: var(--white);
}

.footer .footer-list ul li:nth-child(6) {
	padding-right: 0;
	padding-left: 0;
}

.footer .footer-list ul li select {
	background-color: transparent;
	/* background-image: unset; */
	width: 16vw;
	border: 0;
	font-family: inherit;
	font-size: 1.24vw;
	cursor: pointer;
	padding: 0.5vw 2.5vw 0.5vw 2.5vw;
	color: var(--black);
}

.footer-chevron-icon {
	position: absolute;
	top: 0vw;
	right: 1vw;
	bottom: 0;
	z-index: -1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-chevron-icon i {
	font-size: 1.24vw;
}

.footer .footer-list ul li .chevron-icon {
	position: absolute;
	top: 1vw;
	right: 3vw;
	cursor: pointer;
}

.footer .footer-list ul li .chevron-icon i {
	font-size: 1.24vw;
	font-weight: bold;
}

.footer .footer-list ul li select:focus {
	outline: none;
}

/* media queries from 900px */
@media screen and (max-width: 900px) {
	.footer {
		padding: 1vw 2vw;
	}
	.footer .footer-list ul li {
		padding: 0.5vw 2vw;
	}
	.footer .footer-list ul li a {
		font-size: 1.7vw;
	}
	.footer .footer-list ul li select {
		width: 100%;
		font-size: 1.7vw;
	}
	.footer .footer-content p {
		font-size: 1.7vw;
	}
}

@media screen and (max-width: 599px) {
	.footer {
		padding: 2vw 2vw;
		flex-direction: column-reverse;
	}
	.footer .footer-content p {
		margin-top: 1vw;
		font-size: 2.5vw;
	}
	.footer .footer-list ul li {
		padding: 1vw 2vw;
	}
	.footer .footer-list ul li a,
	.footer .footer-list ul li select {
		font-size: 2.5vw;
	}
	.footer .footer-list ul li select {
		padding: 0vw 4vw 0vw 3vw;
	}

	.goog-te-gadget .goog-te-combo {
		margin: 0 !important;
	}

	.goog-te-combo {
		margin-right: 4px !important;
	}
}
