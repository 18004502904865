.pricing-section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 7vw 8vw;
}

.after-login {
	/* padding: 13vw 8vw; */
	padding: 10vw 8vw;
}

.img-big {
	width: 100%;
}

@media screen and (max-width: 900px) {
	.after-login {
		padding: 20vw 8vw 12vw 8vw;
	}
}

@media screen and (max-width: 599px) {
	.after-login {
		padding: 30vw 5vw 20vw 5vw;
		padding-top: 32vw;
	}
}
