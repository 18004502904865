.chat-button {
	position: fixed;
	bottom: 10vw;
	right: 5vw;
	cursor: pointer;
	z-index: 9;
}

.chat-button .chat-button-content {
	width: 5vw;
}

.chat-button .chat-button-content img {
	width: 100%;
}

@media screen and (max-width: 900px) {
	/* chat-button start */
	.chat-button {
		right: 1vw;
		bottom: 9vw;
	}
	.chat-button .chat-button-content {
		width: 8vw;
	}
	.chat-button .chat-button-content img {
		width: 100%;
	}
	/* chat-button end */
}

@media screen and (max-width: 599px) {
	.chat-button .chat-button-content {
		width: 15vw;
	}
	.chat-button {
		bottom: 20vw;
	}
}
