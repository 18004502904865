/* detailed-software page start */
.detailed-software {
	/* margin: 15vw 12vw 15vw 12vw; */
	margin: 12vw 12vw 15vw 12vw;
	padding-bottom: 8vw;
	/* height: 100vh; */
}

.detailed-software .detailed-container {
	position: relative;
}

.detailed-container .download-button {
	position: absolute;
	right: 2vw;
	top: -1vw;
}

.detailed-container .download-button button {
	background-color: transparent;
}

.detailed-container .download-button button img {
	width: 5vw;
}

.detailed-container .download-button a img {
	width: 5vw;
}

.detailed-container .detail-header h1,
.content-container .caepipe-location .location-icon .icon-content h1,
.caepipe-info .evalution-content h1,
.caepipe-info .evalution-content h2,
.content-container .caepipe-location .location-icon h1,
.content-container .caepipe-location .location-icon h2,
.content-container .caepipe-location h1,
.detailed-container .caepipe-forum h2,
.related-software-container .container-header h1,
.detailed-container .service-description .service-description-content h1 {
	font-weight: bold;
}

.content-container .caepipe-location .location-icon h2.grey-header,
.content-container .caepipe-location h1.grey-header,
.caepipe-info .evalution-content .evalution-para h2.grey-header,
.detailed-container .caepipe-forum h2,
.detailed-container
	.service-description
	.service-description-content
	h1.grey-header {
	color: var(--close-button);
}

.detailed-container .content-container {
	display: flex;
	justify-content: flex-start;
	/* justify-content: center; */
	align-items: stretch;
	gap: 7%;
	margin: 3vw 9vw 5vw 2vw;
}

.detailed-container .content-container .caepipe-image {
	display: flex;
	border-radius: 0.3vw;
	border: 1px solid var(--white);
	box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
		rgb(60 64 67 / 15%) 0px 2px 6px 2px;
	width: 23vw;
	height: 100%;
}

.detailed-container .content-container .caepipe-image img {
	width: 100%;
	/* height: 100%; */
	height: 45vh;
    object-fit: cover;
}

.caepipe-info {
	padding-top: 1vw;
}

.detailed-container .content-container .caepipe-location {
	display: flex;
	justify-content: flex-start;
	gap: 16%;
}

.content-container .caepipe-location .location-icon .icon-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.content-container .caepipe-location .location-icon .icon-content button {
	margin-left: 1vw;
}

.content-container .caepipe-location .location-icon .icon-content button img {
	width: 1.2vw;
}

.caepipe-info .evalution-content {
	margin-top: 4vw;
}

.caepipe-info .evalution-content h1,
.caepipe-info .evalution-content h2,
.detailed-container .caepipe-forum h2 {
	margin-bottom: 1.5vw;
}

.detailed-container .caepipe-forum {
	margin: 2vw 3vw 2vw 2vw;
}

.related-software-container .container-header {
	border-bottom: 1px solid var(--light-grey);
}

.related-software-container .container-header h1 {
	margin-bottom: 2vw;
}

.related-software-container .related-content {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* grid-template-rows: repeat(1, 23.5vw); */
	grid-gap: 1vw;
	margin: 2vw;
}

.software-boxes-content .related-image img {
	width: 100%;
}
/* detailed-software page end */

/* detailed-service page start */
.detailed-container .content-container-part {
	margin: 3vw 9vw 2vw 2vw;
}

.location-icon .service-icon-content button {
	margin-left: 3vw;
}

.detailed-container .content-container .service-location {
	margin-bottom: 2vw;
}

.detailed-container .content-container .service-location-two {
	margin-bottom: 3vw;
}

.detailed-container .content-container .service-location h2.grey-headers {
	margin-top: 0.5vw;
}

.detailed-container .service-description .service-description-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.detailed-container .service-description {
	margin: 1vw 3vw 2vw 2vw;
}

.detailed-container .caepipe-forum-part {
	margin: 1vw 5vw 0vw 0vw;
}

/* .detailed-container .caepipe-forum-part h2 span {
  color: var(--red);
} */

.software-boxes-content .related-jobs-image img {
	width: 100%;
}

.software-boxes-content .software-info .related-jobs-content {
	padding-right: 0;
}
/* detailed-service page end */

/* detailed-job page start */

/* detailed-job page end */

/* detailed-product page start */
.detailed-container .caepipe-forum h2.black-header {
	color: var(--black);
}

.related-description .image-gallery-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 2%;
	/* width: 106%; */
	padding: 2vw 2vw 0vw 0.5vw;
	width: 100%;
	max-width: 100%;
	flex-wrap: wrap;
	height: auto;
}

.image-gallery-container .image-gallery-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 2vw;
}

.image-gallery-container .image-gallery-content .gallery-image img {
	width: 15vw;
    height: 14vw;
    object-fit: contain;
	border-radius: 0.3vw;
}

.image-gallery-container .image-gallery-content .gallery-content h2 {
	font-weight: bold;
	margin-top: 1vw;
}
/* detailed-product page end */

/* product-categories page start */
.detailed-container .content-container .categories-button {
	margin-bottom: 0vw;
}

.company-profile-button button.company-profile {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	padding: 1vw;
}

.company-profile-button button .icon-button {
	margin-left: 2vw;
}

.company-profile-button button .icon-button img {
	width: 1.2vw;
}

.responsive-map {
	/* background-image: url(../assets/navrathan-map.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto; */
	overflow: hidden;
	padding: 14vw;
	position: relative;
	height: 0;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	border-radius: 0.3vw;
	margin: 3vw 2vw 3vw 2vw;
}

.responsive-map iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

/* product-categories-tabs start */
/* Style the tab */
.tab-product-categories {
	float: left;
}

/* Style the buttons inside the tab */
.tab-product-categories button {
	display: block;
	background-color: inherit;
	color: black;
	width: 100%;
	border: none;
	outline: none;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
	padding: 1vw 5vw 1vw 1vw;
	font-size: 1.24vw;
	font-weight: bold;
	background: transparent;
	box-shadow: 0 0 0.4vw rgb(0 0 0 / 50%);
	border-radius: 0.3vw;
}

/* Change background color of buttons on hover */
.tab-product-categories button:hover {
	background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab-product-categories button.active {
	background-color: #757171;
	color: #fff;
}

/* Style the tab content */
.tabcontent-categories {
	float: left;
	padding: 0px 1vw 14vw;
	/* border: 1px solid #ccc; */
	width: 80%;
	border-left: none;
	/* height: 300px; */
	margin-left: 1vw;
}


.related-software-container .product-categories-filter {
	margin-top: 3vw;
}

.product-boxes .categories-box-container {
	margin-top: 0;
	grid-template-rows: auto;
	grid-template-columns: repeat(3, 0fr);
}

.product-boxes-content .categories-image img {
	/* width: 18vw; */
	width: 100%;
}
/* product-categories-tabs end */

.creategroup-modal-container.applyjobmodal {
	/* padding-top: 0; */
	padding-top: 20vw;
	padding-bottom: 10vw;
}
/* product-categories page end */

.show-job-container {
	position: relative;
	background-color: var(--white);
	border: 1px solid var(--light-grey);
	border-radius: 0.3vw;
	margin: 1vw 0 0 0;
	padding: 2vw 2vw;
	text-align: left;
	margin-bottom: 1vw;
	overflow: auto;
}

.creategroup-modal .createjob-content {
	height: 40vw;
	overflow: auto;
	width: 100%;
}

.ordiff {
	font-weight: bold;
	margin-bottom: 1vw;
}

form button.jobapplyBtn {
	color: var(--close-button);
	background-color: var(--white);
	transition: all 0.3s ease-in-out;
	font-size: 1.1vw;
	padding: 0.7vw;
	font-weight: 700;
}

form .jobapplyBtn:hover {
	background-color: var(--close-button) !important;
	color: var(--white) !important;
}

.cvjobapply {
	background-color: #fff;
	font-size: 1.1vw !important;
}

.detailed-container .content-container #Softcaepipeimage img {
	/* width: 100%;
	height: fit-content; */
	width: 23vw;
    height: auto;
}

.detailed-container .content-container #Softcaepipeimage{
	height: 45vh;
    /* width: 100%; */
    align-items: center;
}

.related-jobs-container {
	width: 60vw;
}

.related-jobs-container a{
	width: 18vw;
}

@media screen and (max-width: 900px) {
	.detailed-software {
		margin: 24vw 12vw 15vw 12vw;
	}
	.content-container .caepipe-location .location-icon .icon-content button img {
		width: 1.5vw;
	}
	.detailed-container .content-container .caepipe-image img {
		width: 100%;
	}
	.detailed-container .content-container-part {
		margin: 4vw 9vw 4vw 2vw;
	}
	form button.jobapplyBtn {
		font-size: 2vw;
		padding: 1vw;
		width: 30%;
	}
	.cvjobapply {
		font-size: 2vw !important;
	}

	.detailed-container .content-container .caepipe-image {
		width: 30vw;
		height: 100%;
	}
	.detailed-container .download-button button img,
	.detailed-container .download-button a img {
		width: 7vw;
	}
	.detailed-software {
		padding-bottom: 20vw;
	}
}

@media screen and (max-width: 768px) {
	/* detailed-software page start */

	.detailed-container .content-container {
		margin: 3vw 6vw 5vw 2vw;
	}

	/* detailed-software page end */

	/* product-categories page start */
	.tab-product-categories button {
		font-size: 1.5vw;
	}

	.tabcontent-categories {
		padding: 0px 1vw 14vw;
		width: 75%;
	}

	/* product-categories page end */
}

@media screen and (max-width: 599px) {
	.cvjobapply {
		background-color: #fff;
		font-size: 3vw !important;
	}
	.creategroup-modal .createjob-content {
		height: 70vw;
	}
	form button.jobapplyBtn {
		padding: 2vw;
	}
	
	.creategroup-modal-container.applyjobmodal {
		padding-top: 5vw;
		padding-bottom: 0vw;
	}

	/* detail page css start */
	.detailed-container .content-container {
		flex-direction: column;
	}
	.detailed-software {
		margin: 32vw 3vw 15vw 3vw;
	}
	.detailed-container .report-button {
		right: -2vw;
		top: -1vw;
	}
	.detailed-container .download-button {
		right: 6vw;
		top: -2vw;
	}
	.detailed-container .download-button button img,
	.detailed-container .download-button a img {
		width: 10vw;
	}
	#caepipecontainer {
		margin: 7vw 2vw 4vw 2vw;
	}
	#caepipeimage {
		margin-bottom: 8vw;
		margin-top: 4vw;
	}
	.caepipe-info .evalution-content .evalution-para .grey-header {
		line-height: 1.8;
		font-size: 3vw;
	}
	.caepipe-info .evalution-content .evalution-para .grey-header p {
		font-size: 3vw;
	}
	.related-software-container .related-content {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 4vw;
		margin: 3vw;
	}
	.detailed-software {
		margin: 34vw 4vw 15vw 4vw;
		padding-bottom: 30vw;
	}
	.detailed-container .content-container {
		margin: 3vw 2vw 5vw 2vw;
	}
	.detailed-container .content-container .caepipe-image {
		width: 40vw;
		margin-bottom: 5vw;
	}
	.sky-tabs > label span span {
		font-size: 3vw;
		padding: 1vw;
	}
	.sky-tabs {
		overflow-x: hidden;
	}
	
	#productdetail {
		padding-bottom: 30vw;
	}
	.detailed-container .caepipe-forum h2 p {
		font-size: 2.5vw;
	}
	.detailed-container .content-container .service-location button img {
		width: 4vw;
	}
	/* detail page css end */
}

h2.jobDesc span{
	white-space: pre-line;
	color: var(--close-button);
}

h2.jobDesc span p{
	white-space: pre-line;
	color: var(--close-button);
}

/* ################ SKY TABS ##############*/
/* tabs start */

.slider-box-container {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4vw 3vw;
}

.related-description .content1-header {
	border-bottom: 1px solid var(--grey);
}

.related-description .content1-header h2 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.related-description .content1-para {
	margin-top: 1vw;
}

.related-description .content1-para h2 {
	font-weight: bold;
	color: var(--close-button);
}

.related-description .content1-para h2 span {
	color: var(--close-button);
}

/* tabs end */

/**/
/* defaults */
/**/

.sky-tabs > input:checked + label span span, #showJobApply {
	background-color: var(--close-button);
	color: var(--white);
}
.sky-tabs {
	font-size: 0;
	text-align: left;
	/* margin-top: 3vw; */
	margin: 3vw 0vw 0vw 1vw;
}
.sky-tabs > input {
	position: absolute;
	display: none;
}
.sky-tabs > label {
	transition: all 0.3s ease-in-out;
	position: relative;
	z-index: 1;
	display: inline-block;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	padding: 1px;
	padding-top: 0;
	padding-left: 0;
	cursor: pointer;
}
.sky-tabs > label.inverse {
	float: right;
	padding-right: 0;
	padding-left: 1px;
}
.sky-tabs > label.disabled {
	cursor: default;
}
.sky-tabs > label span {
	display: block;
}
.sky-tabs > label span span, #showJobApply {
	padding: 1vw 0.98vw;
	font-size: 1.24vw;
	font-weight: bold;
	/* background: transparent; */
	box-shadow: 0 0 0.4vw rgb(0 0 0 / 50%);
	border-radius: 0.3vw;
	transition: background 0.4s, color 0.4s;
	-o-transition: background 0.4s, color 0.4s;
	-ms-transition: background 0.4s, color 0.4s;
	-moz-transition: background 0.4s, color 0.4s;
	-webkit-transition: background 0.4s, color 0.4s;
}

.sky-tabs > label.disabled span span {
	background: transparent;
	color: inherit;
}
.sky-tabs > input:checked + label {
	cursor: default;
}

.sky-tabs .typography {
	border-radius: 0.3vw;
}
.sky-tabs .typography h3,
.sky-tabs .typography p {
	color: #000;
}

.sky-tabs > ul {
	list-style: none;
	position: relative;
	display: block;
	font-size: 13px;
	padding: 0;
	margin: 0;
}
.sky-tabs > ul > li {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: auto;
	padding: 0vw 0vw 3vw;
	margin-top: -0.1vw;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	opacity: 0;
	-o-transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-moz-transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%;
	-o-transition: opacity 0.8s, -o-transform 0.8s;
	-ms-transition: opacity 0.8s, -ms-transform 0.8s;
	-moz-transition: opacity 0.8s, -moz-transform 0.8s;
	-webkit-transition: opacity 0.8s, -webkit-transform 0.8s;
}
.sky-tabs > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	position: relative;
	z-index: 1;
	opacity: 1;
}
/* animations */
/**/
.sky-tabs-anim-slide-up > ul > li {
	-o-transform: translateY(-15%);
	-ms-transform: translateY(-15%);
	-moz-transform: translateY(-15%);
	-webkit-transform: translateY(-15%);
}
.sky-tabs-anim-slide-right > ul > li {
	-o-transform: translateX(15%);
	-ms-transform: translateX(15%);
	-moz-transform: translateX(15%);
	-webkit-transform: translateX(15%);
}
.sky-tabs-anim-slide-down > ul > li {
	-o-transform: translateY(15%);
	-ms-transform: translateY(15%);
	-moz-transform: translateY(15%);
	-webkit-transform: translateY(15%);
}
.sky-tabs-anim-slide-left > ul > li {
	-o-transform: translateX(-15%);
	-ms-transform: translateX(-15%);
	-moz-transform: translateX(-15%);
	-webkit-transform: translateX(-15%);
}
.sky-tabs-anim-slide-up-left > ul > li {
	-o-transform: translate(-15%, -15%);
	-ms-transform: translate(-15%, -15%);
	-moz-transform: translate(-15%, -15%);
	-webkit-transform: translate(-15%, -15%);
}
.sky-tabs-anim-slide-up-right > ul > li {
	-o-transform: translate(15%, -15%);
	-ms-transform: translate(15%, -15%);
	-moz-transform: translate(15%, -15%);
	-webkit-transform: translate(15%, -15%);
}
.sky-tabs-anim-slide-down-right > ul > li {
	-o-transform: translate(15%, 15%);
	-ms-transform: translate(15%, 15%);
	-moz-transform: translate(15%, 15%);
	-webkit-transform: translate(15%, 15%);
}
.sky-tabs-anim-slide-down-left > ul > li {
	-o-transform: translate(-15%, 15%);
	-ms-transform: translate(-15%, 15%);
	-moz-transform: translate(-15%, 15%);
	-webkit-transform: translate(-15%, 15%);
}
.sky-tabs > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-webkit-transform: translate(0, 0);
}

.sky-tabs-anim-scale > ul > li {
	-o-transform: scale(0.1, 0.1);
	-ms-transform: scale(0.1, 0.1);
	-moz-transform: scale(0.1, 0.1);
	-webkit-transform: scale(0.1, 0.1);
}
.sky-tabs-anim-scale > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs-anim-scale > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs-anim-scale > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs-anim-scale > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-moz-transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
}

.sky-tabs-anim-rotate > ul > li {
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
}
.sky-tabs-anim-rotate.sky-tabs-pos-right > ul > li,
.sky-tabs-anim-rotate.sky-tabs-pos-top-right > ul > li {
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
}
.sky-tabs-anim-rotate > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs-anim-rotate > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs-anim-rotate > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs-anim-rotate > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
}

.sky-tabs-anim-flip > ul {
	perspective: 2000px;
	-o-perspective: 2000px;
	-ms-perspective: 2000px;
	-moz-perspective: 2000px;
	-webkit-perspective: 2000px;
	perspective-origin: 50% 50%;
	-o-perspective-origin: 50% 50%;
	-ms-perspective-origin: 50% 50%;
	-moz-perspective-origin: 50% 50%;
	-webkit-perspective-origin: 50% 50%;
}
.sky-tabs-anim-flip > ul > li {
	-o-transform: rotateX(-90deg);
	-ms-transform: rotateX(-90deg);
	-moz-transform: rotateX(-90deg);
	-webkit-transform: rotateX(-90deg);
}
.sky-tabs-anim-flip.sky-tabs-pos-left > ul > li {
	-o-transform: rotateY(90deg);
	-ms-transform: rotateY(90deg);
	-moz-transform: rotateY(90deg);
	-webkit-transform: rotateY(90deg);
}
.sky-tabs-anim-flip.sky-tabs-pos-right > ul > li {
	-o-transform: rotateY(-90deg);
	-ms-transform: rotateY(-90deg);
	-moz-transform: rotateY(-90deg);
	-webkit-transform: rotateY(-90deg);
}
.sky-tabs-anim-flip > .sky-tab-content-1:checked ~ ul > .sky-tab-content-1,
.sky-tabs-anim-flip > .sky-tab-content-2:checked ~ ul > .sky-tab-content-2,
.sky-tabs-anim-flip > .sky-tab-content-3:checked ~ ul > .sky-tab-content-3,
.sky-tabs-anim-flip > .sky-tab-content-4:checked ~ ul > .sky-tab-content-4 {
	-o-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	-o-transition-delay: 0.2s;
	-ms-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	-webkit-transition-delay: 0.2s;
}
.sky-tabs  #showJobApply {
	display: inline !important;
	font-size: 1.24vw !important;
	cursor: pointer;
	margin-left: 10px;
	padding: 1.08vw 0.98vw;
}

@media (max-width: 900px) {
	.sky-tabs > label span span,.sky-tabs  #showJobApply {
		font-size: 2vw !important;
	}
}

@media screen and (max-width: 599px) {
	.sky-tabs > label span span,.sky-tabs  #showJobApply {
		font-size: 3vw !important;
		padding: 1vw;
	}
	.sky-tabs {
		overflow-x: hidden;
		margin: 6vw 0vw 0vw 0vw;
	}
	/* detail page css end */
}
