/*global css*/

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");

body {
	font-family: "Roboto", sans-serif;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
	/* word-break: break-word; */
}

:root {
	--grey: #c4c4c4;
	--light-grey: #ccc;
	--light-grey-one: #eaeaea;
	--dark-grey: #565656;
	--medium-grey: #8a8989;
	--black: #000;
	--white: #fff;
	--blue: #0e32b5;
	--red: #ca0d0d;
	--green: #166009;
	--close-button: #757171;
	--post-background: #eee;
	--post-icon: #767676;
	--golden: #9d6b09;
	--purple: #b50ea4;
}

h1 {
	font-size: 1.5vw;  /*1.4vw, 24px*/
	margin-bottom: 0;
}

h2 {
	font-size: 1.2vw; /*1.1vw, 18px*/
	margin-bottom: 0;
}

h3 {
	font-size: 0.97vw; /*14px*/
	margin-bottom: 0;
}

p {
	font-size: 0.83vw; /*12px*/
	font-size: 1vw;
	margin-bottom: 0;
}

button {
	border: none;
	background-color: var(--white);
	color: var(--black);
	border-radius: 0.3vw;
	font-size: 1.3vw;
	padding: 0.3vw 0.8vw;
	transition: all 0.3s ease-in-out;
}

button.active {
	background-color: var(--grey);
	color: var(--black);
}

button:focus,
input:focus {
	outline: none;
}

input {
	border: none;
}

a {
	color: unset;
	text-decoration: none;
}

a:hover {
	color: unset;
}

@media screen and (max-width: 900px) {
	h1 {
		font-size: 2.5vw;
	}
	h2 {
		font-size: 1.8vw;
	}
	h3 {
		font-size: 1.6vw;
	}
	p {
		font-size: 1.7vw;
	}
	button {
		border-radius: 3px;
		font-size: 2vw;
		padding: 1vw 2vw;
	}
}

@media screen and (max-width: 599px) {
	h1 {
		font-size: 3.5vw;
	}
	h2 {
		font-size: 2.5vw;
	}
	h3 {
		font-size: 1.8vw;
	}
	p {
		font-size: 2vw;
	}
	button {
		font-size: 3vw;
		padding: 2vw 2vw;
	}
	#mycircle-hamburger,
	#listing-hamburger,
	#hamburger-chat {
		cursor: pointer;
	}
}

#react-select-1-listbox,
#react-select-2-listbox,
#react-select-3-listbox,
#react-select-4-listbox,
#react-select-5-listbox {
	right: 0 !important;
	text-align: left;
	z-index: 99;
}

button {
	cursor: pointer !important;
}

button:disabled,
button[disabled] {
	/* background-color: var(--medium-grey) !important; */
	cursor: not-allowed !important;
}

.postmodal-content button:disabled,
.posting-content button:disabled {
	background-color: var(--white) !important;
}


