/* chat-page start */
.chat-page-section .left-sidebar {
	height: 100%;
	width: 23%;
	position: fixed;
	/* position: absolute; */
	z-index: 1;
	top: 10vw;
	left: 0;
	bottom: 0;
	padding: 7vw 0vw 18vw 1vw;
	margin-right: 1vw;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: scroll;
}

.chat-page-section .left-sidebar .chat-page-arrow {
	position: absolute;
	top: 2vw;
	right: 0vw;
}

.chat-page-section .left-sidebar .chat-page-arrow img {
	width: 2vw;
}


.leaveNoti {
	text-align: center;
	font-weight: bold;
	margin-bottom: 2vw;
}

.messenger-container {
	border: 1px solid var(--light-grey);
}

.messenger-container .messenger-content {
	padding: 1vw 1vw 1vw 1vw;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	border-bottom: 1px solid var(--light-grey);
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	position: relative;
	gap: 4%;
	flex-wrap: wrap;
}

.messenger-container .messenger-content:first-child {
	border-top: 0;
}

.messenger-container .messenger-content:last-child {
	border-bottom: 0;
}

.messenger-container .messenger-content:nth-child(6) {
	border-bottom: none;
}

.messenger-container .messenger-content.active-messenger-content {
	background-color: var(--post-background);
}

.messenger-container .messenger-content:hover {
	background-color: var(--post-background);
}

.selectedChat {
	background-color: var(--post-background);
}

.messenger-container .messenger-content .delete-msg,
.messenger-container .messenger-content .mail-icon {
	position: absolute;
	right: 1vw;
	bottom: 1vw;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
}

.messenger-container .messenger-content .mail-icon {
	right: 2.5vw;
}

.messenger-container .messenger-content .delete-msg i,
.messenger-container .messenger-content .mail-icon i {
	font-size: 1vw;
}

.delete-modal {
	height: 33vh !important;
}

.messenger-container .messenger-content .user-profile,
.display-user .user-info-container .user-profile {
	width: 4vw;
	height: 4vw;
	position: relative;
}

.messenger-container .messenger-content .grp-profile {
	height: auto;
	width: 4vw;
	flex-wrap: wrap;
	position: relative;
}
.messenger-container .messenger-content .grp-profile img {
	width: 2vw;
	height: 2vw;
}

.image-round {
	border-radius: 50%;
}

.messenger-container .messenger-content .user-profile img,
.display-user .user-info-container .user-profile img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 1.2vw;
	line-height: 1.2;
}

.messenger-container .messenger-content .user-content {
	margin: 1vw 0 0 0vw;
	/* width: 49%; */
}

.messenger-container .messenger-content .user-content.users {
	/* margin-right: -1vw; */
	width: 45%;
}

.messenger-container .messenger-content .user-content h3,
.messenger-container .messenger-content .active-time h3,
.display-user .user-info-container .user-name h2,
.display-user .new-message h2 {
	font-weight: bold;
	margin-bottom: 1vw;
}

.messenger-container .messenger-content .user-content h3.grey-header,
.display-user .new-message h2.grey-header {
	color: var(--close-button);
}

.messenger-container .messenger-content .active-time {
	margin-top: 1vw;
}

.chat-page-section .chat-box-display {
	padding: 0 0vw 0 3vw;
	border-left: 1px solid var(--light-grey);
	margin: 14vw 4vw 10vw 24vw;
}

.chat-box-display .display-user,
.display-user .user-info-container,
.display-user .new-message {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.display-user .user-info-container {
	justify-content: flex-start;
	align-items: center;
	gap: 2%;
	width: 100%;
	z-index: 1;
}

.display-user .new-message {
	position: absolute;
	right: 4vw;
	z-index: 2;
}

.display-user .user-info-container .user-name h2,
.display-user .new-message h2 {
	margin-bottom: 0;
}

.display-user .new-message .new-message-button button {
	background-color: transparent;
}

.display-user .new-message .new-message-button button img {
	width: 2vw;
}

.popup-chatbox {
	position: relative;
	background-color: var(--post-background);
	border: 1px solid var(--light-grey);
	border-radius: 0.3vw;
	margin: 1vw 0 0 0;
	padding: 2vw 4vw 0vw;
	overflow-y: auto;
	/* height: 80vh; */
	min-height: 50vh;
	max-height: 80vh;
}


.popup-chatbox .menu-dots img {
	width: 0.5vw;
}

.popup-chatbox .chat-date {
	text-align: center;
	margin-bottom: 1vw;
}

.popup-chatbox .chat-date h1 {
	font-weight: bold;
}

.popup-chatbox .chat-user-one,
.popup-chatbox .chat-user-two {
	position: relative;
	margin-bottom: 7vw;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.popup-chatbox .chat-user-two {
	position: relative;
	margin-bottom: 7vw;
	justify-content: flex-end;
	align-items: flex-end;
}

.popup-chatbox .chat-user-one .chat-content-one,
.popup-chatbox .chat-user-two .chat-content-two {
	background-color: var(--white);
	border-radius: 0.3vw;
	border: 1px solid var(--light-grey);
	padding: 1.5vw;
	width: 50%;
	margin-top: 1vw;
}

.popup-chatbox .chat-user-two .chat-content-two {
	background-color: transparent;
	border: 1px solid var(--medium-grey);
}

.popup-chatbox .chat-user-one h1,
.popup-chatbox .chat-user-two h1 {
	font-weight: bold;
	color: var(--close-button);
}

.popup-chatbox .chat-user-one .chat-time-one,
.popup-chatbox .chat-user-two .chat-time-two {
	position: relative;
	top: 0.5vw;
	left: 2vw;
}

.popup-chatbox .chat-user-two .chat-time-two {
	left: -22vw;
}

.chat-box-display .type-message {
	margin-top: 1vw;
	padding-bottom: 7vw;
	display: flex;
	justify-content: space-between;
	align-items: start;
}

.chat-box-display .type-message form input.type-input {
	background-color: transparent;
	border: 1px solid var(--light-grey);
	border-radius: 0.3vw;
	padding: 1vw 1vw;
	font-size: 1.1vw;
	width: 56vw;
}

.chat-box-display .type-message form input::placeholder {
	color: var(--close-button);
	font-size: 1.2vw;
}

.chat-box-display .type-message .send-button button {
	background-color: var(--post-background);
	border-radius: 0.3vw;
	color: var(--close-button);
	padding: 1vw 1.5vw;
	font-weight: bold;
	width: 10vw;
	margin-right: 1vw;
}

.msgHeader{
	word-break: break-word;
}
/* chat-page end */

/* New group top bar start*/

#grpinfs {
	float: left;
	width: 100%;
	border: 1px solid #ccc;
	padding: 1vw;
	margin-bottom: 2vw;
	margin-top: 2vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#grpinfs .grpdets {
	float: left;
	width: auto;
}

#grpinfs .grpdets h1 {
	margin: 0px;
	padding: 0 0 0.8vw;
}

#grpinfs .optns {
	float: right;
	width: auto;
	font-size: 16px;
	cursor: pointer;
	color: #000;
}

.dropMain {
	height: unset;
	display: flex;
}

.grpOptions {
	list-style-type: none;
	padding: 0;
	margin-bottom: 0;
	white-space: nowrap;
	width: fit-content;
}

.grpOptionslist {
	padding: 1vw;
	font-size: 1vw;
	cursor: pointer;
}

.grpOptionslist i {
	margin: 0 8px 0 0;
}

/* New group top bar end*/

.sendapply {
	width: auto;
	font-size: 1.66vw;
	margin: 0.3vw 0;
	background-color: var(--post-background);
	color: var(--close-button);
	transition: all 0.3s ease-in-out;
}

/* Chat Info */

.chat_info ul {
	list-style: none;
}

/* Messege */
.messegebox {
	display: flex;
	justify-items: center;
	flex-direction: column;
	margin-bottom: 1vw;
}

.messegebox.loggedUser {
	margin-left: 20%;
}

.messegebox.notloggedUser {
	margin-right: 20%;
}

.messegebox .message-content {
	display: flex;
	align-items: center;
}

.messegebox .message-content .message {
	background-color: var(--white);
	border-radius: 0.3vw;
	border: 1px solid var(--light-grey);
	padding: 1vw;
	margin-top: 0.5vw;
	margin-bottom: 0.5vw;
	width: fit-content;
	font-size: 1.2vw;
	width: 100%;
}

.messegebox h1 {
	font-weight: bold;
	color: var(--close-button);
	font-size: 1.2vw;
}

.messegebox.loggedUser .forward-message-chk {
	margin-left: 2%;
}

.messegebox.notloggedUser .forward-message-chk {
	margin-right: 2%;
}

.messegebox .chat-time {
	padding-left: 3%;
}

.forward_users {
	float: left;
	width: 100%;
	border: 1px solid #ccc;
	margin-top: -2vw;
	margin-bottom: 2vw;
}

.forward_users .option-multi-select {
	margin-bottom: unset;
}

/* Add people modal start */
.sendmessage-content form.add-people-type {
	display: flex;
	flex-direction: row;
	justify-content: end;
	gap: 2%;
}
.convList {
	background-color: #fff;
	width: 100%;
	padding: 1.5vw;
	text-align: left;
	max-height: 35vh;
	overflow: auto;
}
.convList .active-users-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* grid-gap: 8%; */
	gap: 2%;
	margin-bottom: 1.5vw;
	margin-top: 1vw;
}
.convList .active-users-content .user-image img {
	height: 4vw;
	width: 4vw;
}
.disabled {
	opacity: 0.6;
	pointer-events: none;
}
/* Add people modal end */

@media screen and (max-width: 900px) {
	.chat-page-section .left-sidebar {
		padding: 14vw 0vw 26vw 1vw;
	}

	.chat-page-section .chat-box-display {
		margin: 24vw 4vw 10vw 27vw;
	}

	.chat-page-section .left-sidebar {
		width: 26%;
	}

	.chat-box-display .type-message form input.type-input {
		width: 50vw;
		font-size: 2vw;
	}

	.chat-box-display .type-message form input.type-input::placeholder {
		font-size: 2vw;
	}

	.grpOptionslist {
		font-size: 1.5vw;
	}

	.grpOptions {
		width: 20vw;
	}

	button.close-button-modal {
		height: auto;
	}

	.chat-time h1 {
		font-size: 2vw;
	}
	.chat-box-display .type-message {
		margin-top: 2vw;
		padding-bottom: 11vw;
	}
	.display-user .new-message .new-message-button button img {
		width: 4vw;
	}
	.popup-chatbox {
		margin: 3vw 0 0 0;
	}
	.messenger-container .messenger-content .user-profile img,
	.display-user .user-info-container .user-profile img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.messenger-container .messenger-content .grp-profile {
		width: 6vw;
	}
	.messenger-container .messenger-content .grp-profile img {
		width: 3vw;
		height: 3vw;
	}
	.messenger-container .messenger-content .user-content.users {
		width: 65%;
	}
	.messenger-container .messenger-content .mail-icon {
		right: 4vw;
	}
	.messenger-container .messenger-content .delete-msg i,
	.messenger-container .messenger-content .mail-icon i {
		font-size: 2vw;
	}
	.messenger-container .messenger-content {
		align-items: center;
	}
	.chat-box-display .type-message .send-button button {
		width: 12vw;
	}
	.messegebox .message-content .message {
		font-size: 2vw;
	}
	.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
	.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 1.6vw;
	line-height: 1.3;
	}
	.messegebox{
		margin-bottom: 3vw;
	}
}

@media screen and (max-width: 599px) {
	.chat-page-section .chat-box-display {
		margin: 34vw 4vw 10vw 4vw;
		padding: 0 0vw 0 0vw;
		border-left: 0;
	}
	.chat-page-section .left-sidebar {
		width: 60%;
		background-color: white;
	}
	.chat-page-section .left-sidebar {
		padding: 0vw 0vw 26vw 0vw;
		position: absolute;
		top: 8vw;
	}
	.chat-page-section .left-sidebar .chat-page-arrow {
		display: none;
	}
	.chat-time h1 {
		font-size: 2.3vw;
	}
	.chat-box-display .type-message form {
		width: 80%;
	}
	.chat-box-display .type-message form input.type-input {
		width: 100%;
		font-size: 3vw;
		padding: 2vw 2vw;
	}
	.chat-box-display .type-message form input.type-input::placeholder {
		font-size: 3vw;
	}
	.display-user .new-message h2 {
		font-size: 2.5vw;
		line-height: 1;
	}
	.display-user .user-info-container .hamburger-menu .fa-bars {
		font-size: 4.3vw;
		line-height: 1;
	}
	.display-user .user-info-container .user-profile {
		width: auto;
		height: auto;
	}
	.messenger-container .messenger-content .user-content h3 {
		font-size: 2.8vw;
		margin-bottom: 1vw;
	}
	.display-user .user-info-container .hamburger-menu {
		line-height: 1;
	}
	#hamburger-chat {
		width: 5vw;
	}
	.messenger-container .messenger-content {
		align-items: flex-start;
	}
	.messenger-container .messenger-content .delete-msg i,
	.messenger-container .messenger-content .mail-icon i {
		font-size: 2.7vw;
	}
	.messenger-container .messenger-content .mail-icon {
		right: 5vw;
	}

	/* chatpage css changes start */
	.messenger-container .messenger-content {
		padding: 3vw 3vw 3vw 3vw;
	}
	.messenger-container .messenger-content .delete-msg,
	.messenger-container .messenger-content .mail-icon {
		right: 5vw;
	}
	.messenger-container .messenger-content .mail-icon {
		right: 10vw;
	}
	.messenger-container .messenger-content .user-profile,
	.display-user .user-info-container .user-profile {
		width: 7vw;
		height: 7vw;
	}
	.display-user .user-info-container .user-name {
		width: 40%;
	}
	.messenger-container .messenger-content .grp-profile {
		width: 10vw;
	}
	.messenger-container .messenger-content .grp-profile img {
		width: 5vw;
		height: 5vw;
	}
	.messenger-container .messenger-content .user-content.users {
		width: 50%;
	}
	.messenger-container .messenger-content .active-time {
		margin-top: 1vw;
		flex: 1;
	}
	.messenger-container .messenger-content .active-time h3 {
		font-size: 3vw;
	}
	.messenger-container .messenger-content .active-time h3 {
		font-size: 2.5vw;
		text-align: end;
	}
	.chat-box-display .type-message {
		margin-top: 3vw;
		padding-bottom: 20vw;
	}
	.popup-chatbox {
		padding: 4vw 4vw 0vw;
		min-height: 65vh;
		max-height: 65vh;
	}
	.messegebox h1 {
		font-size: 3vw;
	}
	.grpOptions {
		width: fit-content;
		white-space: nowrap;
	}
	.grpOptionslist {
		font-size: 2.5vw;
	}
	.messegebox .message-content .message {
		font-size: 3vw;
	}
	/* chatpage css changes end */

	/* show page css start */
	.user-friend-status .user-status-container {
		top: 66vw;
		padding: 3vw;
	}
	.showuser-container .user-status-container h1,
	.user-friend-status .user-status-container .user-status-one-container i {
		font-size: 3.5vw;
	}
	.show-members {
		padding: 3vw;
		margin-bottom: 3vw;
	}
	.left-sidebar .showuser-container h2 {
		font-size: 3vw;
	}
	.messenger-container .messenger-content .user-profile.show-profile,
	.display-user .user-info-container .user-profile.show-profile {
		width: 8vw;
		height: 8vw;
	}
	.show-members-list {
		height: 80vw;
	}
	.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
	.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 2.4vw;
	line-height: 1.4;
	}
	/* show page css end */
}
@media screen and (max-width: 499px) {
	.chat-page-section .left-sidebar {
		padding: 0vw 0vw 26vw 0vw;
	}
	.chat-time h1 {
		font-size: 2.6vw;
	}
	.chat-box-display .type-message .send-button button {
		width: 13vw;
		font-size: 3.3vw;
		padding: 1.5vw 1.5vw;
	}
	.display-user .user-info-container .user-name h2,
	.display-user .new-message h2 {
		font-size: 2.9vw;
	}
	.display-user .new-message .new-message-button button img {
		width: 4.4vw;
	}
	.display-user .user-info-container .hamburger-menu .fa-bars {
		font-size: 4.9vw;
	}
	.messenger-container .messenger-content .delete-msg i,
	.messenger-container .messenger-content .mail-icon i {
		font-size: 3vw;
	}
	.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
	.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 2.7vw;
	line-height: 1.1;
	}
}
@media screen and (max-width: 399px) {
	.chat-page-section .left-sidebar {
		padding: 0vw 0vw 26vw 0vw;
	}
	.chat-time h1 {
		font-size: 3vw;
	}
	.chat-box-display .type-message .send-button button {
		width: 14vw;
		font-size: 3.6vw;
	}
	.display-user .new-message h2 {
		font-size: 3.3vw;
		line-height: 1;
	}
	.display-user .new-message .new-message-button button img {
		width: 4.5vw;
	}
	.display-user .user-info-container .hamburger-menu .fa-bars {
		font-size: 5.3vw;
	}
	.messenger-container .messenger-content .user-content h3 {
		margin-bottom: 1.4vw;
	}
	.messenger-container .messenger-content .delete-msg i,
	.messenger-container .messenger-content .mail-icon i {
		font-size: 3.4vw;
	}
	.chat-box-display .popup-chatbox .messegebox .message-content .message .msgHeader,
	.chat-box-display .popup-chatbox .messegebox .chat-time h1{
	font-size: 3vw;
	line-height: 1.1;
	}
}
