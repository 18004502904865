.serviceContainer {
	/* margin: 11vw 14vw 15vw 25vw; */
	margin: 9vw 14vw 15vw 25vw;
}

.service-description-content .month-time {
	text-align: end;
}

@media screen and (max-width: 900px) {
	.serviceContainer {
		margin: 24vw 14vw 15vw 28vw;
	}
}

@media screen and (max-width: 599px) {
	.serviceContainer {
		margin: 34vw 4vw 15vw 4vw;
	}
}
#submitButtonService{
	margin-left: 1vw;
}