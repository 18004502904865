.sp_layout {
    display: grid;
    grid:
        "title title"
        "image image "
        "desc desc ";
    /* grid-auto-columns: 1fr; */
    grid-auto-columns: 1fr 0fr;
    padding: 2vw;
}

.layout-desc {
    grid-area: desc;
    /* max-height: 50vh; */
    width: 100%;
    text-align: center;
}

.layout-image {
    grid-area: image;
    max-height: 40vh;
    width: 100%;
    text-align: center;
}

.layout-image .doc_image {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.layout-docu img{
    width: 100%;
    /* width: 5vw;
    height: 5vw; */
}

.layout-docu .doc_doc{
    width: 5vw;
    height: 5vw;
}


.layout-image iframe{
    width: 100%;
}

.layout-desc .content-css {
   margin-top: 2vw;
   text-align: left;
}

.sponsor_layout_outer_div .profile-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.sponsor_layout_outer_div .profile-content .post-info{
    justify-content: flex-start;
    align-items: center;
    grid-gap: 3%;
    gap: 3%;
    display: flex;
    width: 100%;
}

.sponsor_layout_outer_div .profile-content .post-info .post-profile{
    width: 3vw;
    height: 3vw;
    align-self: flex-start;
    border-radius: 50%;
    overflow: hidden;
    border: 0;
}

.sponsor_layout_outer_div .profile-content .post-info .post-profile img{
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.layout-desc span a.my-anchor-css-class {
    color: var(--red);
}

.sp_layout #layout-desc .content-css span{
    font-size: 1.2vw !important;
}

.profile-content .menu-dots img {
    width: 0.4vw;
    cursor: pointer;
}

.profile-content .menu-dots {
    transition: all 0.3s ease;
}

.profile-content .menu-container {
    position: relative;
}

.profile-content .menu-container .user-menu-content h2 {
    color: var(--close-button);
    font-weight: bold;
    cursor: pointer;
    font-size: 1.2vw;
    margin-bottom: 0;
}

.profile-content .menu-container .user-menu-content {
    background-color: var(--post-background);
    position: absolute;
    border: 0.1vw solid var(--close-button);
    padding: 0.5vw;
    right: 1vw;
    top: 0vw;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 9vw;
    display: none;
}

.profile-content .menu-container .user-menu-content.open {
    opacity: 1;
    display: block;
}

@media screen and (max-width: 900px){

    .sponsor_layout_outer_div .profile-content .menu-dots img {
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 599px){
    .sponsor_layout_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.5vw;
    }

    .sponsor_layout_outer_div .profile-content .menu-container .user-menu-content {
        padding: 2vw;
        width: -moz-fit-content;
        width: fit-content;
        right: 3vw;
        white-space: nowrap;
    }

}

@media screen and (max-width: 499px){
    .sponsor_layout_outer_div .profile-content .menu-dots {
        width: 1.1vw;
    }

    .sponsor_layout_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 2.8vw;
    }
}

@media screen and (max-width: 399px){
    .sponsor_layout_outer_div .profile-content .menu-dots {
        width: 1.2vw;
    }

    .sponsor_layout_outer_div .profile-content .menu-container .user-menu-content h2 {
        font-size: 3.1vw;
    }
}