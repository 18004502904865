.sidenav .sidenav-container .sidenav-content h2.active-block {
	color: var(--black);
}

.sidenav .sidenav-container .sidenav-content h2.inactive-block {
	color: var(--medium-grey);
}

.blocked h2 {
	font-weight: bold;
}
