/* product-categories page start */
.detailed-container .content-container .categories-button {
	margin-bottom: 0vw;
}

.company-profile-button button.company-profile {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	padding: 1vw;
}

.company-profile-button button .icon-button {
	margin-left: 2vw;
}

.company-profile-button button .icon-button img {
	width: 1.2vw;
}

.responsive-map {
	overflow: hidden;
	padding: 14vw;
	position: relative;
	height: 0;
	box-shadow: 0px 0px 4px 2px var(--light-grey);
	border-radius: 0.3vw;
	margin: 3vw 2vw 3vw 2vw;
}

.responsive-map iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.cmpy-profile-modal {
	width: 100vw;
}

.cmpy-profile-modal.open {
	padding-top: 15vw;
}

.cmpy-modal {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 3vw 3vw;
	width: 100vh;
}

/* product-categories-tabs start */
/* Style the tab */
.tab-product-categories {
	float: left;
}

/* Style the buttons inside the tab */
.tab-product-categories button {
	display: block;
	background-color: inherit;
	color: black;
	width: 100%;
	border: none;
	outline: none;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
	padding: 1vw 5vw 1vw 1vw;
	font-size: 1.24vw;
	font-weight: bold;
	background: transparent;
	box-shadow: 0 0 0.4vw rgb(0 0 0 / 50%);
	border-radius: 0.3vw;
}

/* Change background color of buttons on hover */
.tab-product-categories button:hover {
	background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab-product-categories button.active {
	background-color: #757171;
	color: #fff;
}

/* Style the tab content */
.tabcontent-categories {
	float: left;
	padding: 0px 1vw 14vw;
	/* border: 1px solid #ccc; */
	width: 80%;
	border-left: none;
	/* height: 300px; */
	margin-left: 1vw;
}

.related-software-container .product-categories-filter {
	margin-top: 3vw;
}

.product-boxes .categories-box-container {
	margin-top: 0;
	grid-template-rows: auto;
	grid-template-columns: repeat(3, 1fr);
}

.product-boxes-content .categories-image img {
	/* width: 18vw; */
	width: 100%;
}
/* product-categories-tabs end */

/* product-categories page end */

@media (max-width: 900px) {
	.company-profile-container {
		height: 80vh;
	}
	.product-boxes .categories-box-container {
		grid-template-rows: auto;
		grid-template-columns: repeat(2, 1fr);
		width: 100%;
	}
	.company-profile-container {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	/* product-categories page start */
	.tab-product-categories button {
		font-size: 1.5vw;
	}

	.tabcontent-categories {
		padding: 0px 1vw 14vw;
		width: 75%;
	}

	/* product-categories page end */
}

.product-categories-filter {
	display: flex;
}

.tabcontent-categories.product-boxes {
	display: none;
}

.tabcontent-categories.product-boxes.open {
	display: block;
}

/* Model */
.like-modal-container.open {
	padding-bottom: 8vw;
}

.like-modal-container {
	height: 100vh;
}

.company-profile-container {
	justify-content: center;
	width: 60vw;
	padding: 0vw;
	align-items: start;
}

.company-profile-container .cmpy-modal iframe {
	width: 100%;
	height: 100vh;
}

@media screen and (max-width: 599px) {
	.company-profile-container {
		justify-content: center;
		width: 100%;
		align-items: start;
		margin: 5vw 1vw;
	}
	.product-categories-filter {
		flex-direction: column;
	}
	.company-profile-button button .icon-button img {
		width: 4vw;
	}
	.company-profile-button button.company-profile {
		padding: 2vw;
	}
	.tab-product-categories {
		margin-bottom: 5vw;
	}
	.tab-product-categories button.active,
	.tab-product-categories button {
		width: 50%;
		font-size: 3vw;
	}
	.tabcontent-categories {
		width: 100%;
		margin-left: 0vw;
		padding-left: 0;
	}

	.content-container .caepipe-location .location-icon .icon-content button img {
		width: 3vw;
	}
	.product-container .product-box-container .supplier-boxes {
		padding: 10vw 0 6vw 3vw;
	}
	.cmpy-profile-modal,
	.cmpy-modal {
		width: 100%;
	}
	.cmpy-modal {
		padding: 8vw 0;
	}

	.company-profile-container .cmpy-modal iframe {
		width: 100vw;
		height: 100vh;
	}
}
