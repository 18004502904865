.creategroup-modal {
	position: relative;
	background-color: var(--medium-grey);
	border-radius: 0.3vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2vw 4vw;
	text-align: center;
	min-width: 40vw;
	max-width: 50%;
}

.report-modal {
	width: unset;
}

.creategroup-modal .creategroup-content {
	width: 100%;
}

@media screen and (max-width: 900px) {
	/* report modal start */
	.report-modal {
		width: 70%;
	}
	.report-content i,
	.report-content .form-select {
		font-size: 2vw;
	}
	/* report modal end */
}

@media screen and (max-width: 599px) {
	/* report modal start */
	.report-modal {
		width: 100%;
		justify-content: flex-start;
		/* height: 100vh !important; */
	}
	.report-content i,
	.report-content .form-select {
		font-size: 3vw;
	}
	.report-content .form-select {
		padding: 2vw;
		font-size: 3vw;
	}
	.report-submit .submit {
		padding: 2vw;
		margin-top: 5vw;
	}
	#changePassword {
		top: 5vw;
	}
	.creategroup-modal {
		justify-content: flex-start;
	}
	/* report modal end */
}
